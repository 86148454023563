import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getAssetFor } from 'kiss/assets/redux'
import { Container, Title as KittenTitle } from '@kisskissbankbank/kitten'
import { pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'

const StyledTitle = styled.div`
  background-color: var(--color-primary-100);

  .kiss-GiftCardNewTitle__container {
    background: url(${(props) => props.backgroundUrl}) no-repeat;
    background-position: calc(100% - ${pxToRem(20)}) bottom;
    background-size: ${pxToRem(200)};
    padding-top: ${pxToRem(50)};
    padding-bottom: ${pxToRem(115)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      background-position: calc(100% - ${pxToRem(40)}) bottom;
      background-size: auto calc(100% - ${pxToRem(40)});
      padding-top: ${pxToRem(50)};
      padding-bottom: ${pxToRem(50)};
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      width: ${pxToRem(990)};
      background-position: right bottom;
      padding-top: ${pxToRem(100)};
      padding-bottom: ${pxToRem(100)};
    }
  }
`

const Title = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledTitle backgroundUrl={assetFor('gift-card/new/banner.svg')}>
      <Container className="kiss-GiftCardNewTitle__container">
        <KittenTitle tag="h1" modifier="tertiary">
          {t('gift_card.new.title', { parseHtml: true })}
        </KittenTitle>
      </Container>
    </StyledTitle>
  )
}

export default Title
