import { gql } from '@apollo/client'
import query from 'kiss/api/graphql-query'
import giftCardQuery from 'kiss/graphql/queries/gift_card/gift_card.graphql'
import isEmpty from 'lodash/fp/isEmpty'

export const getGiftCard = (id) => async (_, getState) => {
  const state = getState()

  try {
    const response = await query(
      giftCardQuery,
      { id },
      state,
      {
        fetchPolicy: 'network-only',
      },
      {
        tags: {
          payment: 'get-gift-card',
        },
      },
    )

    return response.giftCard
  } catch (error) {
    return error
  }
}

const CREATE_GIFT_CARD_MUTATION = gql`
  mutation CreateGiftCard($giftCard: GiftCardInput!) {
    createGiftCard(giftCard: $giftCard) {
      id
    }
  }
`

export const createGiftCard =
  ({ from, to, message, email, amount }) =>
  async (_, getState) => {
    const state = getState()

    try {
      const response = await query(
        CREATE_GIFT_CARD_MUTATION,
        {
          giftCard: {
            buyerName: from,
            redeemerName: to,
            message,
            email,
            amountCents: parseInt(amount) * 100,
          },
        },
        state,
        {},
        {
          tags: {
            payment: 'create-gift-card',
          },
        },
      )

      const result = response.createGiftCard

      if (!response) {
        throw new Error('createGiftCard failed')
      }

      if (isEmpty(result.id)) {
        throw new Error('createGiftCard id empty')
      }

      return result.id
    } catch (error) {
      return Promise.reject(error)
    }
  }

const UPDATE_GIFT_CARD_MUTATION = gql`
  mutation UpdateGifCard($id: ID!, $giftCard: GiftCardInput!) {
    updateGiftCard(id: $id, giftCard: $giftCard) {
      id
    }
  }
`

export const updateGiftCard =
  ({ from, to, message, email, amount, id }) =>
  async (_, getState) => {
    const state = getState()

    try {
      const response = await query(
        UPDATE_GIFT_CARD_MUTATION,
        {
          id,
          giftCard: {
            buyerName: from,
            redeemerName: to,
            message,
            email,
            amountCents: parseInt(amount) * 100,
          },
        },
        state,
        {},
        {
          tags: {
            payment: 'update-gift-card',
          },
        },
      )

      const result = response.updateGiftCard

      if (!response) {
        throw new Error('updateGiftCardMutation failed')
      }

      if (isEmpty(result.id)) {
        throw new Error('updateGiftCardMutation id empty')
      }

      return result.id
    } catch (error) {
      return Promise.reject(error)
    }
  }
