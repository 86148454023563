import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import { pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledIllustrationBlock = styled.div`
  display: flex;
  border-radius: 10px;
  height: ${pxToRem(168.83)};

  img {
    margin: auto;
    width: 100px;
    height: 128.83px;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    height: 520px;
    flex-direction: column;

    img {
      width: 326px;
      height: 420px;
    }
  }
`

const IllustrationBlock = ({ imagePath, backgroundColor }) => {
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledIllustrationBlock style={{ background: backgroundColor }}>
      <img src={assetFor(imagePath)} alt={imagePath} />
    </StyledIllustrationBlock>
  )
}

IllustrationBlock.propTypes = {
  imagePath: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
}

IllustrationBlock.defaultProps = {
  backgroundColor: 'white',
}

export default IllustrationBlock
