import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import Facebook from './facebook'
import Instagram from './instagram'
import LinkedIn from './linkedin'
import Twitter from './twitter'
import { Title, Marger } from '@kisskissbankbank/kitten'

const Social = () => {
  const t = useTranslation()
  return (
    <Marger top="8" bottom="8">
      <Marger bottom="3">
        <Title modifier="quinary">{t('mentor.signup.form.social.title')}</Title>
      </Marger>
      <Marger bottom="3">
        <Facebook />
      </Marger>
      <Marger bottom="3">
        <Instagram />
      </Marger>
      <Marger bottom="3">
        <LinkedIn />
      </Marger>
      <Marger bottom="3">
        <Twitter />
      </Marger>
    </Marger>
  )
}

export default Social
