import React from 'react'
import Label from 'kiss/components/formik/label'
import LocationInput from 'kiss/components/formik/location'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useField } from 'formik'

const Location = () => {
  const t = useTranslation()
  const [, , helpers] = useField('city')
  return (
    <>
      <Label htmlFor="location">
        {t('mentor.signup.form.personnal.location.label')}
      </Label>
      <LocationInput
        name="location"
        onSelect={({ value }) => helpers.setValue(value)}
        placeholder={t('mentor.signup.form.personnal.location.placeholder')}
      />
    </>
  )
}

export default Location
