import React from 'react'
import {
  pxToRem,
  Title,
  Button,
  Text,
  FlashIllustration,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'
import { getRouteFor, FUND_MY_PROJECT } from 'kiss/routes/redux'

const ProtectWrapper = styled.section`
  box-sizing: border-box;
  margin-top: ${pxToRem(100)};
  display: flex;
  margin-left: auto;
  margin-right: ${pxToRem(100)};
  max-width: ${pxToRem(1440)};
  gap: ${pxToRem(90)};

  @media (min-width: ${pxToRem(1512)}) {
    margin-right: auto;
  }

  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    margin-top: 0;
    padding-top: 0;
    flex-direction: column;
    margin-right: 0;
  }

  .kiss-Manifesto__protect__img {
    display: block;
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;

    img {
      display: block;
      object-fit: cover;
      width: 100%;

      @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
        max-height: ${pxToRem(450)};
        width: 100%;
        object-position: center;
      }
    }

    svg {
      position: absolute;
      margin-top: ${pxToRem(450)};
      margin-left: ${pxToRem(125)};

      @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
        margin-top: ${pxToRem(270)};
        margin-left: ${pxToRem(20)};
        width: 25%;
      }

      @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
        width: 100%;
        margin-top: ${pxToRem(350)};
      }
    }
  }

  .kiss-Manifesto__protect__container {
    width: 60vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: ${pxToRem(20)};
    box-sizing: border-box;

    @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
      padding-right: ${pxToRem(20)};
      padding-left: ${pxToRem(20)};
      width: 100vw;
      text-align: center;
      align-items: center;
      margin-top: ${pxToRem(230)};
    }
  }

  .k-Title {
    margin-bottom: ${pxToRem(20)};
  }

  .k-Button {
    margin-top: ${pxToRem(20)};
  }
`

const Protect = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)
  const routeFor = useSelector(getRouteFor)

  return (
    <ProtectWrapper>
      <div className="kiss-Manifesto__protect__img">
        <FlashIllustration />
        <img alt="" src={assetFor('manifesto/environment.png')} />
      </div>
      <div className="kiss-Manifesto__protect__container">
        <Title modifier="primary" noMargin family="antiqueolive">
          {t('manifesto.engagement.environment.title')}
        </Title>
        <Text size="huge" className="k-u-padding-right-decuple@sm-up">
          {t('manifesto.engagement.environment.description1', {
            parseHtml: true,
          })}
        </Text>
        <Text size="huge">
          {t('manifesto.engagement.environment.description2', {
            parseHtml: true,
          })}
        </Text>
        <Text size="huge">
          {t('manifesto.engagement.environment.description3', {
            parseHtml: true,
          })}
        </Text>
        <Button modifier="helium" as="a" href={routeFor(FUND_MY_PROJECT)}>
          {t('manifesto.create_project.button')}
        </Button>
      </div>
    </ProtectWrapper>
  )
}
export default Protect
