import { useQuery } from 'kiss/api/graphql-query'
import PRICING_PLANS_QUERY from 'kiss/graphql/queries/pricing_plans.graphql'
import filter from 'lodash/fp/filter'
import flatten from 'lodash/fp/flatten'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import orderBy from 'lodash/fp/orderBy'
import { useTranslation } from './use-translation'

const orderByFee = flow(orderBy(['feeRateWithoutVat'])(['asc']))

const usePricingPlans = ({ fetch = false } = { fetch: false }) => {
  const t = useTranslation()

  const { data, loading } = useQuery(PRICING_PLANS_QUERY, null, {
    skip: !fetch,
  })

  const formatPricingPlans = (pricingPlans) => {
    const orderedPricingPlans = orderByFee(pricingPlans)

    const pricingPlansWithFeatures = flow(
      map((plan) => {
        return {
          title: plan.title,
          label: plan.label,
          feeRate: plan.feeRate,
          features: flow(
            map(({ features }) => features),
            flatten,
            filter({ showOnShortDescription: true }),
          )(plan.featuresByCategory),
        }
      }),
    )(orderedPricingPlans)

    return flow(
      map.convert({ cap: false })((plan, index) => {
        const previousFeatures = pricingPlansWithFeatures.reduce(
          (acc, curr) => {
            if (curr.feeRate < plan.feeRate) {
              acc = acc.concat(curr.features)
            }
            return acc
          },
          [],
        )
        const previousPlan = pricingPlansWithFeatures[index - 1]?.title

        return {
          ...plan,
          items: [
            {
              title: previousPlan
                ? t('pricing_plans.previous_plan_features', {
                    previousPlan: previousPlan.toUpperCase(),
                  })
                : '',
            },
          ].concat(
            filter((feature) => {
              return !previousFeatures
                .map(({ label }) => label)
                .includes(feature.label)
            })(pricingPlansWithFeatures[index].features),
          ),
        }
      }),
    )(orderedPricingPlans)
  }

  return {
    loading,
    rawPricingPlans: data?.pricingPlans,
    pricingPlans: !fetch ? [] : formatPricingPlans(data?.pricingPlans),
    formatPricingPlans,
  }
}

export default usePricingPlans
