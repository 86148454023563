import React from 'react'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import pull from 'lodash/fp/pull'
import { useTranslation } from '../../hooks/use-translation'
import TagButton from 'kiss/components/buttons/tag'

const Tag = ({ id, name }) => {
  const t = useTranslation()
  const [field, , helpers] = useField(name)
  const engagments = field.value || []
  const isSelected = engagments.includes(id)
  return (
    <TagButton
      type="button"
      active={isSelected}
      onClick={() =>
        helpers.setValue(
          isSelected ? pull(id)(engagments) : [...engagments, id],
        )
      }
      disabled={!isSelected && engagments.length >= 3}
    >
      {t(`engagement.${id}.name`, { parseHtml: true })}
    </TagButton>
  )
}

const Engagements = ({ name }) => {
  return (
    <>
      <Tag name={name} id="changer-de-vie" />
      <Tag name={name} id="made-in-france" />
      <Tag name={name} id="bio" />
      <Tag name={name} id="zero-dechet" />
      <Tag name={name} id="recyclage" />
      <Tag name={name} id="developpement-local" />
      <Tag name={name} id="commerce-equitable" />
      <Tag name={name} id="biodiversite" />
      <Tag name={name} id="locavore" />
      <Tag name={name} id="bien-etre-animal" />
      <Tag name={name} id="autoproduction-culturelle" />
      <Tag name={name} id="media-independant" />
      <Tag name={name} id="education" />
      <Tag name={name} id="lgbtq" />
      <Tag name={name} id="feminisme" />
      <Tag name={name} id="antiracisme" />
      <Tag name={name} id="maladie-handicap" />
      <Tag name={name} id="solidarite-internationale" />
      <Tag name={name} id="aide-aux-migrants" />
      <Tag name={name} id="senior" />
    </>
  )
}

Engagements.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Engagements
