import React from 'react'
import EngagementsForm from 'kiss/components/formik/engagements'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Marger, Title } from '@kisskissbankbank/kitten'

const Engagements = () => {
  const t = useTranslation()
  return (
    <Marger bottom="8">
      <Title modifier="quinary" className="k-u-margin-bottom-triple">
        {t('mentor.signup.form.engagements.title', { parseHtml: true })}
      </Title>
      <EngagementsForm name="engagementCodes" />
    </Marger>
  )
}

export default Engagements
