import { useEffect } from 'react'
import { gql } from '@apollo/client'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'kiss/api/graphql-query'
import { getCartId } from '../cart/redux'
import { getProjectSlug } from '../redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { getRouteFor, PROJECT_ROUTE } from 'kiss/routes/redux'
import qs from 'qs'

const CART_CHECK_QUERY = gql`
  query CartCheckQuery($slug: String!, $cartId: ID) {
    project(slug: $slug) {
      id
      cart(id: $cartId) {
        id
        expired
        empty
      }
    }
  }
`

const CartChecker = () => {
  const cartId = useSelector(getCartId)
  const slug = useSelector(getProjectSlug)
  const { data, refetch } = useQuery(CART_CHECK_QUERY, { slug, cartId })
  const { pathname, search } = useLocation()
  const routeFor = useSelector(getRouteFor)
  const isExpired = data?.project?.cart?.expired ?? false
  const isEmpty = data?.project?.cart?.empty ?? false
  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true })
  useEffect(() => {
    refetch()
  }, [pathname])
  useEffect(() => {
    if (isExpired || isEmpty) {
      RoutingHelper.redirect(
        `${routeFor(PROJECT_ROUTE, {
          project: slug,
        })}?${qs.stringify({ ...parsedSearch, reason: 'expired' })}`,
      )
    }
  }, [isExpired, isEmpty])
  return null
}

export default CartChecker
