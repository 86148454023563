import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { useTranslation } from 'kiss/hooks/use-translation'
import RadioButtonSet from 'kiss/components/formik/radio-button-set'
import { GUTTER, pxToRem, ScreenConfig, Title } from '@kisskissbankbank/kitten'

const StyledAmount = styled.section`
  .kiss-Form-RadioButtonSet__orionGrid.k-Form-RadioButtonSet
    .k-Form-RadioButtonSet__radioContainer {
    display: grid;
    grid-template-columns: 1fr;
    gap: ${pxToRem(GUTTER)};

    @media (min-width: ${ScreenConfig.S.min}px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: ${ScreenConfig.L.min}px) {
      grid-template-columns: repeat(3, 1fr);
    }

    .k-Form-RadioButtonSet__radioButton {
      margin: 0;
    }
  }
`

const Amount = ({ values }) => {
  const t = useTranslation()

  return (
    <StyledAmount id="amount-section">
      <RadioButtonSet
        name="amount"
        className="kiss-Form-RadioButtonSet__orionGrid"
        items={values.map((amount) => ({
          key: `amount-${amount}`,
          id: `amount-${amount}`,
          text: t('project_info.amount_with_currency', {
            amount: t(amount, { formatNumber: true }),
            currency: t('currency_symbol.eur'),
            parseHtml: true,
          }),
          value: amount,
        }))}
      >
        <Title tag="p" modifier="quinary" className="k-u-margin-bottom-triple">
          {t('gift_card.new.form.amount.label')}
        </Title>
      </RadioButtonSet>
    </StyledAmount>
  )
}

Amount.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default Amount
