import React from 'react'
import {
  pxToRem,
  Title,
  Text,
  RainbowColorIllustration,
  HandAndFlowerColorIllustration,
  PencilColorIllustration,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'

const EngagementsWrapper = styled.section`
  max-width: ${pxToRem(1440)};
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    margin-top: ${pxToRem(80)};
  }

  .kiss-Manifesto__engagements__engagement {
    text-align: center;

    @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
      padding-right: ${pxToRem(20)};
      padding-left: ${pxToRem(20)};
    }
  }

  .kiss-Manifesto__engagements__engagement__illustrations {
    display: flex;
    gap: ${pxToRem(100)};
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    margin-top: ${pxToRem(80)};
  }

  .kiss-Manifesto__engagements__engagement__illustration {
    flex-direction: column;
  }
`

const Engagements = () => {
  const t = useTranslation()
  return (
    <EngagementsWrapper>
      <div className="kiss-Manifesto__engagements__engagement">
        <Title
          tag="h2"
          noMargin
          modifier="secondary"
          className="k-u-margin-bottom-quadruple"
          family="antiqueolive"
        >
          {t('manifesto.engagements.title')}
        </Title>
        <Text size="huge">
          {t('manifesto.engagements.description', { parseHtml: true })}
        </Text>

        <div className="kiss-Manifesto__engagements__engagement__illustrations">
          <div className="kiss-Manifesto__engagements__engagement__illustration">
            <RainbowColorIllustration />
            <Text
              weight="600"
              tag="p"
              size="huge"
              cssColor="var(--color-grey-900)"
              className="k-u-margin-top-quintuple"
            >
              {t('manifesto.engagements.engagement1', { parseHtml: true })}
            </Text>
            <Title noMargin style={{ fontSize: '96px' }}>
              1
            </Title>
          </div>
          <div className="kiss-Homepage__manifesto__illustration">
            <HandAndFlowerColorIllustration />
            <Text
              weight="600"
              tag="p"
              size="huge"
              cssColor="var(--color-grey-900)"
              className="k-u-margin-top-quintuple"
            >
              {t('manifesto.engagements.engagement2', { parseHtml: true })}
            </Text>
            <Title noMargin style={{ fontSize: '96px' }}>
              2
            </Title>
          </div>
          <div className="kiss-Homepage__manifesto__illustration">
            <PencilColorIllustration />
            <Text
              weight="600"
              tag="p"
              size="huge"
              cssColor="var(--color-grey-900)"
              className="k-u-margin-top-quintuple"
            >
              {t('manifesto.engagements.engagement3', { parseHtml: true })}
            </Text>
            <Title noMargin style={{ fontSize: '96px' }}>
              3
            </Title>
          </div>
        </div>
      </div>
    </EngagementsWrapper>
  )
}

export default Engagements
