import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import Name from './name'
import Page from './page'
import Phone from './phone'
import Website from './website'
import Location from './location'
import { Marger, Title } from '@kisskissbankbank/kitten'

const MentorSignupForm = () => {
  const t = useTranslation()
  return (
    <Marger top="8" bottom="8">
      <Marger bottom="3">
        <Title modifier="quinary">
          {t('mentor.signup.form.personnal.name.title')}
        </Title>
      </Marger>
      <Marger bottom="3">
        <Name />
      </Marger>
      <Marger bottom="3">
        <Page />
      </Marger>
      <Marger bottom="3">
        <Phone />
      </Marger>
      <Marger bottom="3">
        <Location />
      </Marger>
      <Marger bottom="3">
        <Website />
      </Marger>
    </Marger>
  )
}

export default MentorSignupForm
