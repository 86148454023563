import React from 'react'
import styled from 'styled-components'
import { CallToAction } from '../index'
import {
  Container,
  Grid,
  GridCol,
  TitleWithStroke,
  Paragraph,
  pxToRem,
  ScreenConfig,
  CONTAINER_PADDING,
} from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

const StyledDiscover = styled(Container)`
  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    .kiss-GiftCard-Discover__grid {
      align-items: center;
    }

    .kiss-GiftCard-Discover__imageCol {
      margin-left: ${pxToRem(-1 * CONTAINER_PADDING)};

      .kiss-GiftCard-Discover__imageContainer {
        position: relative;
        width: 100%;
        padding-top: calc((615 / 720) * 100%);

        .kiss-GiftCard-Discover__image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`

const Discover = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledDiscover>
      <Grid className="kiss-GiftCard-Discover__grid">
        <GridCol
          className="kiss-GiftCard-Discover__imageCol"
          col-s="10"
          offset-s="1"
          col-l="6"
          offset-l="0"
        >
          <div className="kiss-GiftCard-Discover__imageContainer">
            <img
              src={assetFor('gift-card/landing/causes.svg')}
              className="kiss-GiftCard-Discover__image"
              alt=""
              aria-hidden
            />
          </div>
        </GridCol>

        <GridCol col-s="10" offset-s="1" col-l="4">
          <TitleWithStroke
            tag="h3"
            modifier="tertiary"
            className="k-u-margin-top-quadruple k-u-margin-top-quintuple@s-up k-u-margin-top-decuple@l-up"
          >
            {t('gift_card.landing.discover.title')}
          </TitleWithStroke>

          <Paragraph
            modifier="secondary"
            className="k-u-margin-bottom-triple k-u-margin-bottom-quadruple@s-up"
          >
            {t('gift_card.landing.discover.paragraph', { parseHtml: true })}
          </Paragraph>

          <CallToAction className="k-u-margin-bottom-quintuple k-u-margin-bottom-decuple@l-up" />
        </GridCol>
      </Grid>
    </StyledDiscover>
  )
}

export default Discover
