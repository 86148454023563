import { Container, Grid, GridCol } from '@kisskissbankbank/kitten'
import isLuhn from 'fast-luhn'
import { Form as FormikForm, Formik } from 'formik'
import { addErrorAlert, addSuccessAlert } from 'kiss/app/alerts/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useGiftCardPayment } from 'kiss/payment'
import { getRouteFor, GIFT_CARD_NEW, GIFT_CARD_SHOW } from 'kiss/routes/redux'
import { scrollToTop } from 'kiss/utils/animation/scroll-to'
import { poll } from 'kiss/utils/poll'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import includes from 'lodash/fp/includes'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import * as Yup from 'yup'
import { createGiftCard, getGiftCard, updateGiftCard } from './actions'
import {
  Amount,
  CreditCard,
  Custom,
  Email,
  Loader,
  Stepper,
  Submit,
  Terms,
} from './components'

const defaultValues = {
  amount: '0',
  from: '',
  to: '',
  message: '',
  email: '',
  card: {
    number: '',
    expiry: '',
    cvc: '',
    type: '',
  },
  acceptedTerms: false,
}

const amountValues = ['25', '50', '100', '200', '500', '1000']
const messageLimit = 200

Yup.addMethod(Yup.number, 'isLuhn', function (message) {
  return this.test('is-luhn', message, (value) => {
    return isLuhn(`${value}`.replace(/ /g, ''))
  })
})

Yup.addMethod(Yup.string, 'isValidDate', function (message) {
  return this.test('is-valid-date', message, (value) => {
    if (!value) return

    const today = new Date()
    const expiry = new Date()
    const [month, year] = value.split('/')

    // TODO: Update me to the year 2100.
    expiry.setFullYear(parseInt(`20${year}`), parseInt(month - 1))

    return today <= expiry
  })
})

const isTimeoutError = flow(getOr('')('message'), includes('Timeout'))

const Form = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const routeFor = useSelector(getRouteFor)
  const history = useHistory()
  const { payGiftCardWithCreditCard } = useGiftCardPayment()
  const { id: giftCardId } = useParams()
  const [initialValues, setInitialValues] = useState(defaultValues)
  const [isLoading, loading] = useState(!!giftCardId)

  const validationSchema = Yup.object().shape({
    amount: Yup.mixed().oneOf(
      amountValues,
      t('gift_card.new.form.errors.amount'),
    ),
    from: Yup.string().required(t('app.forms.error.required')),
    to: Yup.string().required(t('app.forms.error.required')),
    message: Yup.string()
      .required(t('app.forms.error.required'))
      .max(
        messageLimit,
        t('app.forms.error.maxLength', { maxLength: messageLimit }),
      ),
    email: Yup.string()
      .required(t('app.forms.error.required'))
      .email(t('app.forms.error.email')),
    card: Yup.object({
      number: Yup.number()
        .required(t('app.forms.error.required'))
        .isLuhn(t('gift_card.new.form.errors.card.number')),
      expiry: Yup.string()
        .required(t('app.forms.error.required'))
        .isValidDate(t('gift_card.new.form.errors.card.expiry')),
    }),
  })

  useEffect(() => {
    if (!giftCardId) return
    loading(true)
    scrollToTop()
    poll({
      fn: () => dispatch(getGiftCard(giftCardId)),
      validate: (response) =>
        ['failed', 'confirmed'].includes(response?.payment?.state),
      interval: 1000,
    })
      .then((response) => {
        loading(false)
        const state = response?.payment?.state

        if (state === 'failed') {
          dispatch(
            addErrorAlert(t('gift_card.new.state.failed'), { scroll: true }),
          )
          setInitialValues({
            ...defaultValues,
            amount: `${(response.totalAmount?.cents || 0) / 100}`,
            from: response.buyerName,
            to: response.redeemerName,
            message: response.message,
            email: response.email,
          })
        } else {
          history.push(`${routeFor(GIFT_CARD_NEW)}?thank-you`)
          setTimeout(() => {
            setInitialValues(defaultValues)
            dispatch(
              addSuccessAlert(
                t('gift_card.new.state.confirmed', { parseHtml: true }),
                {
                  scroll: true,
                },
              ),
            )
          }, 0)
        }
      })
      .catch((_error) => {
        loading(false)
        dispatch(addErrorAlert(t('app.server.error'), { scroll: true }))
      })
  }, [giftCardId])

  const handleFormSubmit = async (values, { setSubmitting }) => {
    try {
      const giftCardId = values.id
        ? await dispatch(updateGiftCard(values))
        : await dispatch(createGiftCard(values))
      const redirectUrl = await payGiftCardWithCreditCard({
        giftCardId,
        amountCents: parseInt(values.amount) * 100,
        expiry: values.card.expiry,
        cvv: values.card.cvc,
        cardNumber: values.card.number,
      })
      setSubmitting(false)
      if (redirectUrl) {
        return RoutingHelper.redirect(redirectUrl)
      }
      return history.push(routeFor(GIFT_CARD_SHOW, { id: giftCardId }))
    } catch (e) {
      setSubmitting(false)
      if (isTimeoutError(e)) {
        return history.push(routeFor(GIFT_CARD_SHOW, { id: giftCardId }))
      }
      dispatch(addErrorAlert(t('app.server.error'), { scroll: true }))
    }
  }

  if (isLoading)
    return (
      <Container className="k-u-margin-top-octuple k-u-margin-top-decuple@s-up k-u-margin-bottom-quintuple k-u-margin-bottom-decuple@s-up">
        <Loader />
      </Container>
    )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnChange={false}
      onSubmit={handleFormSubmit}
      enableReinitialize
    >
      {() => (
        <Container className="k-u-margin-top-octuple k-u-margin-top-decuple@s-up k-u-margin-bottom-quintuple k-u-margin-bottom-decuple@s-up">
          <Grid>
            <GridCol col-l="2" offset-l="1" className="k-u-hidden@m-down">
              <Stepper />
            </GridCol>
            <GridCol col-s="10" offset-s="1" col-l="6">
              <FormikForm>
                <Amount values={amountValues} />
                <Custom limit={messageLimit} />
                <Email />
                <CreditCard />
                <Terms />
                <Submit />
              </FormikForm>
            </GridCol>
          </Grid>
        </Container>
      )}
    </Formik>
  )
}

export default Form
