import React from 'react'
import {
  pxToRem,
  Title,
  Text,
  Button,
  WeirdIllustration,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { getRouteFor, FUND_MY_PROJECT } from 'kiss/routes/redux'

const HeroWrapper = styled.section`
  padding-top: ${pxToRem(100)};
  max-width: ${pxToRem(1440)};
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
    padding-top: ${pxToRem(50)};
  }

  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    padding-top: 0;
  }

  .kiss-Manifesto__hero__circle {
    position: absolute;
    margin-top: ${pxToRem(70)};
    margin-left: ${pxToRem(-430)};
    width: ${pxToRem(860)};
    height: ${pxToRem(860)};
    clip-path: circle(50% at 50% 50%);
    background-color: var(--color-primary-500);

    @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
      margin-top: ${pxToRem(-50)};
      margin-left: ${pxToRem(-500)};
    }

    @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
      width: 100%;
      height: 7%;
      margin-top: ${pxToRem(-430)};
      margin-left: 0;
    }
  }

  .kiss-Manifesto__hero__container {
    display: flex;

    @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
      margin-right: ${pxToRem(50)};
    }

    @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
      flex-wrap: wrap;
      margin-right: 0;
    }
  }

  .kiss-Manifesto__hero__main {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
      width: 50vw;
      padding-left: ${pxToRem(50)};
      justify-content: flex-start;
      gap: ${pxToRem(20)};
    }

    @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
      box-sizing: border-box;
      padding: ${pxToRem(20)};
      text-align: center;
      align-items: center;
    }

    .k-Title {
      margin-bottom: ${pxToRem(20)};
    }
    .k-Button {
      margin-top: ${pxToRem(20)};
    }
  }

  .kiss-Manifesto__hero__illu {
    @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
      margin: 0 auto;
    }

    svg {
      position: relative;
      margin-top: ${pxToRem(30)};
      margin-left: ${pxToRem(70)};
      object-fit: cover;
      display: block;

      @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
        width: 85%;
        margin-top: ${pxToRem(-50)};
        margin-left: ${pxToRem(40)};
      }
      @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
        margin-top: ${pxToRem(20)};
        margin-left: 0;
        width: 100%;
        max-height: ${pxToRem(360)};
        object-fit: cover;
        object-position: center;
      }
    }
  }
`

const Hero = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  return (
    <HeroWrapper>
      <div className="kiss-Manifesto__hero__circle" />
      <div className="kiss-Manifesto__hero__container">
        <div className="kiss-Manifesto__hero__illu">
          <WeirdIllustration />
        </div>
        <div className="kiss-Manifesto__hero__main">
          <Title noMargin modifier="secondary" family="antiqueolive">
            {t('manifesto.collective_for_common_good.title')}
          </Title>
          <Text size="huge">
            {t('manifesto.collective_for_common_good.description1', {
              parseHtml: true,
            })}
          </Text>
          <Text size="huge">
            {t('manifesto.collective_for_common_good.description2', {
              parseHtml: true,
            })}
          </Text>
          <Text size="huge">
            {t('manifesto.collective_for_common_good.description3', {
              parseHtml: true,
            })}
          </Text>
          <Button as="a" href={routeFor(FUND_MY_PROJECT)} modifier="helium">
            {t('manifesto.create_project.button')}
          </Button>
        </div>
      </div>
    </HeroWrapper>
  )
}

export default Hero
