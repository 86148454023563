import {
  CarouselNext,
  Container,
  // GiftIcon,
  mq,
  pxToRem,
  ProjectCard,
  ScreenConfig,
  Text,
} from '@kisskissbankbank/kitten'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  FOUR_GUTTER_LARGE_IN_CONTAINER,
  FOUR_GUTTER_LARGE_IN_XL_CONTAINER,
  TWO_GUTTER_THIN_IN_XXS_CONTAINER,
  TWO_IN_CONTAINER,
} from 'kiss/utils/responsive-image/image-width-definitions'
import { responsiveImageAttributes } from 'kiss/utils/responsive-image/responsive-image-attributes'
import { getSelectedProjects } from './../redux'

const StyledWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(40)};
  padding-top: ${pxToRem(50)};

  & > * {
    position: relative;
    z-index: 2;
  }

  &::before {
    z-index: 1;
    display: block;
    content: '';
    position: absolute;
    background-color: var(--color-grey-000);
    height: ${pxToRem(310)};
    width: 100%;
    left: 0;
    top: 0;
    clip-path: polygon(0 -1px, 100% -1px, 100% 100%, 0 50%);
  }

  .k-CarouselNext--showOtherPages .k-CarouselNext__inner {
    --container-padding: ${pxToRem(50)};

    @media ${mq.tabletAndDesktop} {
      --container-padding: ${pxToRem(150)};
    }
  }

  .k-CarouselNext__page {
    height: 100% !important;
  }
`
const getImageAttributes = (imageUrl, responsiveImageOptions) => {
  if (!imageUrl || !responsiveImageOptions) return

  return responsiveImageAttributes(imageUrl, responsiveImageOptions)
}

const CampaignCardComponent = ({ project }) => {
  const t = useTranslation()
  const hasVideos = () => project.image?.mp4Url || project.image?.webmUrl

  const responsiveImageOptions = {
    imageSizes: [165, 182, 270, 310, 490],
    breakpoints: [
      0,
      ScreenConfig.S.min,
      ScreenConfig.L.min,
      ScreenConfig.XL.min,
    ],
    imageSizesInViewport: [
      TWO_GUTTER_THIN_IN_XXS_CONTAINER,
      TWO_IN_CONTAINER,
      FOUR_GUTTER_LARGE_IN_CONTAINER,
      FOUR_GUTTER_LARGE_IN_XL_CONTAINER,
    ],
  }

  const imageAttributes = getImageAttributes(
    project.image?.croppedUrl,
    responsiveImageOptions,
  )

  return (
    <ProjectCard
      key={`ProjectCard${project.title}`}
      stretch
      href={project.publicUrl}
      imageProps={{
        src: imageAttributes
          ? imageAttributes.src
          : project.image?.homepageUrl ||
            project.image?.croppedUrl ||
            project.image?.url,
        srcSet: imageAttributes && imageAttributes.srcSet,
        sizes: imageAttributes && imageAttributes.sizes,
        alt: '',
        role: 'presentation',
      }}
      videoSources={
        hasVideos()
          ? [
              {
                type: 'video/webm',
                src: project.image?.webmUrl,
                key: 'image_video/webm',
              },
              {
                type: 'video/mp4',
                src: project.image?.mp4Url,
                key: 'image_video/mp4',
              },
            ]
          : []
      }
    >
      <ProjectCard.Line className="k-u-flex k-u-flex-alignItems-center">
        {/* <GiftIcon
        noMargin
        width="13"
        height="13"
        color="var(--color-primary-500)"
        className="k-u-margin-right-single"
      /> */}

        <Text size="small" color="primary1">
          {project.engagements?.length > 0
            ? t(`engagement.${project.engagements[0]?.code}.name`, {
                parseHtml: true,
              })
            : ''}
        </Text>
      </ProjectCard.Line>
      <ProjectCard.Title>{project.name}</ProjectCard.Title>
      <ProjectCard.Progress
        aria-label="Progrès de la campagne"
        value={project.fundingPercent}
      />
      <ProjectCard.Item>
        <Text size="small">{project.editorial}</Text>
      </ProjectCard.Item>
    </ProjectCard>
  )
}

const TeamSelection = () => {
  const t = useTranslation()
  const selectedProjects = useSelector(getSelectedProjects)
  const [navProps, setNavProps] = useState({})
  const { viewportIsXSOrLess, viewportIsSOrLess, viewportIsMOrLess } =
    useMediaQuery()

  return (
    <StyledWrapper>
      <div>
        <Container className="k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-sb k-u-flex-gap-double">
          <Text
            tag="h3"
            className="k-u-margin-none"
            weight="500"
            transform="uppercase"
            letterSpacing="10%"
            size="small"
          >
            {t('new_home.projects.team_selection')}
          </Text>
          <CarouselNext.Navigation
            {...navProps}
            buttonProps={{ size: 'small' }}
          />
        </Container>
        <CarouselNext
          baseGap={30}
          cycle
          itemsPerPage={viewportIsSOrLess ? 1 : 3}
          itemMinWidth={viewportIsSOrLess ? 200 : 300}
          viewportIsXSOrLess={viewportIsXSOrLess}
          viewportIsMOrLess={viewportIsMOrLess}
          navigationPropsGetter={setNavProps}
          showOtherPages
          shadowSize={viewportIsSOrLess ? 15 : 30}
        >
          {selectedProjects.map((project, index) => (
            <CampaignCardComponent project={project} key={index} />
          ))}
        </CarouselNext>
      </div>
    </StyledWrapper>
  )
}

export default TeamSelection
