import React from 'react'

import { getRouteFor } from 'kiss/routes/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import {
  SERVICES_TYPEFORM,
  BIOTYFOOD_PROJECT,
  WIA_PROJECT,
  BLAST_BTS_LINK,
} from 'kiss/routes/redux'

import {
  Button,
  ScreenConfig,
  TitleWithStroke,
  pxToRem,
} from '@kisskissbankbank/kitten'

import PromoCard from 'kiss/components/cards/promoCard'

import styled from 'styled-components'

const StyledPromoCardContainer = styled.div`
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.10);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};
  padding ${pxToRem(20)};

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    gap: ${pxToRem(30)};
    padding: ${pxToRem(30)};
  }
`

const StyledPromoCards = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(32)};

  @media (min-width: ${pxToRem(340)}) {
    margin: auto;
  }

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin: 0;
    flex-direction: row;
    gap: ${pxToRem(32)};
    justify-content: center;
  }
`
const PromoCardsContainer = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <StyledPromoCardContainer>
      <TitleWithStroke
        tag="h1"
        align="left"
        modifier="quaternary"
        noMargin
        family="antiqueolive"
        className="k-u-font-weight-700"
        cssColor="var(--color-primary-500)"
      >
        <span className="k-u-font-size-7">
          {t('services.promo_card.title')}
        </span>
      </TitleWithStroke>
      <StyledPromoCards>
        <PromoCard
          image="projects-thumbnails/biotyfood.jpg"
          description={t('services.promo_card.biotyfood.desc')}
          buttonTitle={t('services.promo_card.button')}
          buttonLink={routeFor(BIOTYFOOD_PROJECT)}
        />
        <PromoCard
          image="projects-thumbnails/wia.jpg"
          description={t('services.promo_card.wia.desc')}
          buttonTitle={t('services.promo_card.button')}
          buttonLink={routeFor(WIA_PROJECT)}
        />
        <PromoCard
          image="projects-thumbnails/blast.jpg"
          description={t('services.promo_card.blast.desc', { parseHtml: true })}
          buttonTitle={t('services.promo_card.behind_the_scenes')}
          buttonLink={routeFor(BLAST_BTS_LINK)}
        />
      </StyledPromoCards>
      <div>
        <Button
          size="small"
          fit="content"
          as="a"
          mobileFit="fluid"
          href={routeFor(SERVICES_TYPEFORM)}
          target="_blank"
          modifier="helium"
        >
          {t('services.contact_card.button')}
        </Button>
      </div>
    </StyledPromoCardContainer>
  )
}

export default PromoCardsContainer
