import {
  Button,
  Container,
  DoubleEntryTable,
  Grid,
  GridCol,
  Marger,
  Paragraph,
  parseHtml,
  Title,
  TitleWithStroke,
} from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { Helmet } from 'react-helmet'

const CookiesPage = () => {
  const t = useTranslation()
  const requiredCookiesRows = [
    {
      domain: 'kisskissbankbank.com',
      name: '_kisskissbankbank_session_new',
      expiration: t('cookies.page.time.session'),
      goal: t('cookies.page.goal.operational'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'authenticity_token',
      expiration: t('cookies.page.time.session'),
      goal: t('cookies.page.goal.operational'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'userConnected',
      expiration: t('cookies.page.time.session'),
      goal: t('cookies.page.goal.operational'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'cloudwatch_postman_token',
      expiration: t('cookies.page.time.session'),
      goal: t('cookies.page.goal.operational'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'referer_name',
      expiration: t('cookies.page.time.hour', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'referer_url',
      expiration: t('cookies.page.time.hour', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'utm_campaign',
      expiration: t('cookies.page.time.hour', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'utm_content',
      expiration: t('cookies.page.time.hour', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'utm_medium',
      expiration: t('cookies.page.time.hour', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'utm_source',
      expiration: t('cookies.page.time.hour', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: 'utm_term',
      expiration: t('cookies.page.time.hour', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: '_pk_id*',
      expiration: t('cookies.page.time.month', { count: 13 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: '_pk_ref*',
      expiration: t('cookies.page.time.month', { count: 6 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'kisskissbankbank.com',
      name: '_pk_ses*',
      expiration: t('cookies.page.time.minute', { count: 30 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'axeptio.eu',
      name: 'axeptio_all_vendors',
      expiration: t('cookies.page.time.year', { count: 1 }),
      goal: t('cookies.page.goal.operational'),
    },
    {
      domain: 'axeptio.eu',
      name: 'axeptio_authorized_vendors',
      expiration: t('cookies.page.time.year', { count: 1 }),
      goal: t('cookies.page.goal.operational'),
    },
    {
      domain: 'axeptio.eu',
      name: 'axeptio_cookies',
      expiration: t('cookies.page.time.year', { count: 1 }),
      goal: t('cookies.page.goal.operational'),
    },
    {
      domain: 'stonly.com',
      name: '_csrf	',
      expiration: t('cookies.page.time.session'),
      goal: t('cookies.page.goal.operational'),
    },
  ]

  const configurableCookiesRows = [
    {
      domain: 'hotjar.com',
      name: '_hjAbsoluteSessionInProgress',
      expiration: t('cookies.page.time.minute', { count: 30 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: '_hjFirstSeen',
      expiration: t('cookies.page.time.minute', { count: 30 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: '_hjIncludedInPageviewSample',
      expiration: t('cookies.page.time.minute', { count: 30 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: '_hjIncludedInSessionSample',
      expiration: t('cookies.page.time.minute', { count: 30 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: '_hjSession_*',
      expiration: t('cookies.page.time.minute', { count: 30 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: '_hjSessionUser_*',
      expiration: t('cookies.page.time.year', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: '_ga',
      expiration: t('cookies.page.time.year', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: '_gcl_au',
      expiration: t('cookies.page.time.month', { count: 3 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: 'ajs_anonymous_id',
      expiration: t('cookies.page.time.year', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: 'ajs_user_id',
      expiration: t('cookies.page.time.year', { count: 1 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: 'BEAMER_FIRST_VISIT_*',
      expiration: t('cookies.page.time.month', { count: 10 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'hotjar.com',
      name: 'BEAMER_USER_ID_*',
      expiration: t('cookies.page.time.month', { count: 10 }),
      goal: t('cookies.page.goal.audience'),
    },
    {
      domain: 'facebook.com',
      name: '_fbp',
      expiration: t('cookies.page.time.month', { count: 3 }),
      goal: t('cookies.page.goal.advertising'),
    },
    {
      domain: 'facebook.com',
      name: 'fr',
      expiration: t('cookies.page.time.month', { count: 3 }),
      goal: t('cookies.page.goal.advertising'),
    },
    {
      domain: 'facebook.com',
      name: 'datr',
      expiration: t('cookies.page.time.year', { count: 2 }),
      goal: t('cookies.page.goal.advertising'),
    },
    {
      domain: 'facebook.com',
      name: 'sb',
      expiration: t('cookies.page.time.year', { count: 2 }),
      goal: t('cookies.page.goal.advertising'),
    },
    {
      domain: 'unbounce.com',
      name: 'ubvt',
      expiration: t('cookies.page.time.day', { count: 3 }),
      goal: t('cookies.page.goal.audience'),
    },
  ]

  const headers = [
    t('cookies.page.header.editor'),
    t('cookies.page.header.name'),
    t('cookies.page.header.lifetime'),
    t('cookies.page.header.goal'),
  ]

  return (
    <DefaultLayout>
      <Helmet title={t('cookies.page.title')} />

      <Container>
        <Grid>
          <GridCol col="12" col-xs="10" offset-xs="1" col-l="6" offset-l="3">
            <Marger
              top={{ default: 5, fromS: 8, fromL: 10 }}
              bottom={{ default: 5, fromS: 8, fromL: 10 }}
            >
              <TitleWithStroke modifier="secondary" tag="h1">
                {t('cookies.page.title')}
              </TitleWithStroke>
              <Paragraph modifier="secondary" className="k-Paragraph--italic">
                {t('cookies.page.intro', { parseHtml: true })}
              </Paragraph>

              <Title
                modifier="tertiary"
                tag="h2"
                className="k-u-margin-top-quadruple k-u-margin-bottom-double"
              >
                {t('cookies.page.what_is_cookie.title', { parseHtml: true })}
              </Title>
              <Paragraph modifier="secondary">
                {t('cookies.page.what_is_cookie.body')}
              </Paragraph>

              <Title
                modifier="tertiary"
                tag="h2"
                className="k-u-margin-top-quadruple k-u-margin-bottom-double"
              >
                {t('cookies.page.why_cookie.title', { parseHtml: true })}
              </Title>
              <Paragraph modifier="secondary">
                {t('cookies.page.why_cookie.body_1')}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.why_cookie.body_2', { parseHtml: true })}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.why_cookie.body_3', { parseHtml: true })}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.why_cookie.body_4', { parseHtml: true })}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.why_cookie.body_5', { parseHtml: true })}
              </Paragraph>

              <Title
                modifier="tertiary"
                tag="h2"
                className="k-u-margin-top-quadruple k-u-margin-bottom-double"
              >
                {t('cookies.page.what_cookie.title', { parseHtml: true })}
              </Title>
              <Paragraph modifier="secondary">
                {t('cookies.page.what_cookie.body_1')}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.what_cookie.body_2')}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.what_cookie.body_3')}
              </Paragraph>
              <Paragraph
                modifier="secondary"
                className="k-u-margin-bottom-double"
              >
                {t('cookies.page.what_cookie.body_4')}
              </Paragraph>

              <Title
                modifier="quaternary"
                tag="h3"
                className="k-u-margin-top-quadruple k-u-margin-bottom-double"
              >
                {t('cookies.page.required_cookies')}
              </Title>

              <DoubleEntryTable firstColWidth={120}>
                <DoubleEntryTable.Header>
                  {headers.map((header) => {
                    return (
                      <DoubleEntryTable.HeaderCol key={header}>
                        {header}
                      </DoubleEntryTable.HeaderCol>
                    )
                  })}
                </DoubleEntryTable.Header>

                <DoubleEntryTable.Body>
                  {requiredCookiesRows.map(
                    ({ domain, name, expiration, goal }, rowIndex) => {
                      return (
                        <DoubleEntryTable.Row key={`${rowIndex}`}>
                          <DoubleEntryTable.TitleCol>
                            {domain}
                          </DoubleEntryTable.TitleCol>
                          <DoubleEntryTable.Col
                            style={{
                              overflowWrap: 'anywhere',
                              whiteSpace: 'initial',
                            }}
                          >
                            {parseHtml(name)}
                          </DoubleEntryTable.Col>
                          <DoubleEntryTable.Col>
                            {expiration}
                          </DoubleEntryTable.Col>
                          <DoubleEntryTable.Col>
                            {parseHtml(goal)}
                          </DoubleEntryTable.Col>
                        </DoubleEntryTable.Row>
                      )
                    },
                  )}
                </DoubleEntryTable.Body>
              </DoubleEntryTable>

              <Title
                modifier="quaternary"
                tag="h3"
                className="k-u-margin-top-quadruple k-u-margin-bottom-double"
              >
                {t('cookies.page.configurable_cookies')}
              </Title>

              <DoubleEntryTable firstColWidth={120}>
                <DoubleEntryTable.Header>
                  {headers.map((header) => {
                    return (
                      <DoubleEntryTable.HeaderCol key={header}>
                        {header}
                      </DoubleEntryTable.HeaderCol>
                    )
                  })}
                </DoubleEntryTable.Header>

                <DoubleEntryTable.Body>
                  {configurableCookiesRows.map(
                    ({ domain, name, expiration, goal }, rowIndex) => {
                      return (
                        <DoubleEntryTable.Row key={`${rowIndex}`}>
                          <DoubleEntryTable.TitleCol>
                            {domain}
                          </DoubleEntryTable.TitleCol>
                          <DoubleEntryTable.Col
                            style={{
                              overflowWrap: 'anywhere',
                              whiteSpace: 'initial',
                            }}
                          >
                            {parseHtml(name)}
                          </DoubleEntryTable.Col>
                          <DoubleEntryTable.Col>
                            {expiration}
                          </DoubleEntryTable.Col>
                          <DoubleEntryTable.Col>
                            {parseHtml(goal)}
                          </DoubleEntryTable.Col>
                        </DoubleEntryTable.Row>
                      )
                    },
                  )}
                </DoubleEntryTable.Body>
              </DoubleEntryTable>

              <Title
                modifier="tertiary"
                tag="h2"
                className="k-u-margin-top-quadruple k-u-margin-bottom-double"
              >
                {t('cookies.page.how_cookie.title', { parseHtml: true })}
              </Title>
              <Paragraph modifier="secondary">
                {t('cookies.page.how_cookie.body_1', { parseHtml: true })}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.how_cookie.body_2', { parseHtml: true })}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.how_cookie.body_3', { parseHtml: true })}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.how_cookie.body_4')}
              </Paragraph>
              <Button
                className="k-u-margin-top-double"
                modifier="helium"
                onClick={() => window?.openAxeptioCookies()}
                fit="fluid"
              >
                {t('cookies.page.how_cookie.button')}
              </Button>

              <hr className="k-u-margin-top-quadruple k-u-margin-bottom-quadruple" />

              <Title
                modifier="tertiary"
                tag="h2"
                className="k-u-margin-top-quadruple k-u-margin-bottom-double"
              >
                {t('cookies.page.glossary.title')}
              </Title>
              <Paragraph modifier="secondary">
                {t('cookies.page.glossary.body_1', { parseHtml: true })}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.glossary.body_2', { parseHtml: true })}
              </Paragraph>
              <Paragraph modifier="secondary">
                {t('cookies.page.glossary.body_3', { parseHtml: true })}
              </Paragraph>
            </Marger>
          </GridCol>
        </Grid>
      </Container>
    </DefaultLayout>
  )
}

export default CookiesPage
