import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import classNames from 'classnames'
import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'
import {
  Container,
  domElementHelper,
  Grid,
  GridCol,
  HorizontalStroke,
  Marger,
  Text,
  useContinuousIntersectionObserver,
} from '@kisskissbankbank/kitten'

const Portrait = ({ portrait, currentSlide, slides, isActiveSection }) => {
  const t = useTranslation()

  const targetEl = useRef(null)
  const [isTargetElVisible, setTargetElVisible] = useState(false)

  useEffect(() => {
    if (domElementHelper.canUseDom() && !('IntersectionObserver' in window)) {
      setTargetElVisible(true)
    }
  }, [])

  useContinuousIntersectionObserver({
    onIntersect: (entries) => {
      entries.forEach((entry) => {
        setTargetElVisible(entry.intersectionRatio >= 0.65)
      })
    },
    observedComponentRef: targetEl,
    root: null,
    rootMargin: '0px',
    threshold: [0, 0.5, 0.6, 0.65, 0.7, 0.8, 1],
  })

  return (
    <div
      ref={targetEl}
      id={`portrait_${currentSlide + 1}`}
      style={{
        backgroundImage: `url(${
          isActiveSection ? portrait.imageUrl : ''
        }), url(${portrait.bgUrl})`,
      }}
      className={classNames('stats__Portrait', {
        'Portrait--is_visible': isTargetElVisible,
        'Portrait--is_active': isActiveSection,
      })}
    >
      <Container className="Portrait__Container">
        <Grid className="Portrait__Grid">
          <GridCol col="12" col-s="10" col-l="4" offset-s="1">
            <Marger
              top={{ fromXxs: 5, fromS: 10 }}
              bottom={{ fromXxs: 5, fromS: 10 }}
            >
              <Text
                className="Portrait__Quote"
                weight="600"
                fontStyle="italic"
                color="font1"
              >
                {t(`stats.portraits.portrait_${currentSlide + 1}.quote`, {
                  parseHtml: true,
                })}
              </Text>
              <HorizontalStroke className="Portrait__HorizontalStroke" />
              <Text
                weight="500"
                tag="strong"
                color="font1"
                className="k-u-line-height-1-3 Portrait__QuoteMeta"
              >
                {t(`stats.portraits.portrait_${currentSlide + 1}.author`, {
                  parseHtml: true,
                })}
              </Text>
              <br />
              <Text
                weight="400"
                color="font1"
                className="k-u-line-height-1-3 Portrait__QuoteMeta"
              >
                {t(`stats.portraits.portrait_${currentSlide + 1}.occupation`, {
                  parseHtml: true,
                })}
              </Text>
              <Marger top={{ fromXxs: 2, fromL: 3 }}>
                {slides.map((slide, index) => (
                  <a
                    key={`bullet_${index}`}
                    className={classNames('Portrait__Bullet', {
                      'Bullet--is_active': index === currentSlide,
                    })}
                    aria-current={index === currentSlide}
                    aria-label={t('stats.portraits.button_text', {
                      portraitNumber: index + 1,
                    })}
                    href={`#portrait_${index + 1}`}
                    onClick={(e) => {
                      e.preventDefault()
                      const target = document.getElementById(
                        `portrait_${index + 1}`,
                      )
                      window.scrollTo({
                        top: target.offsetTop,
                        behavior: 'smooth',
                      })
                    }}
                  />
                ))}
              </Marger>
            </Marger>
          </GridCol>
        </Grid>
      </Container>
    </div>
  )
}

const PortraitsAndQuotes = () => {
  const sectionEl = useRef(null)
  const [isSectionElIntersecting, setSectionElIntersecting] = useState(false)
  const assetFor = useSelector(getAssetFor)

  const portraits = [
    {
      imageUrl: assetFor('stats/portrait-chloe.png'),
      bgUrl: assetFor('stats/portrait-bg-blueLight.jpg'),
    },
    {
      imageUrl: assetFor('stats/portrait-jamil.png'),
      bgUrl: assetFor('stats/portrait-bg-pink.jpg'),
    },
    {
      imageUrl: assetFor('stats/portrait-adrien.png'),
      bgUrl: assetFor('stats/portrait-bg-blueLight.jpg'),
    },
  ]

  useEffect(() => {
    if (domElementHelper.canUseDom() && !('IntersectionObserver' in window)) {
      setSectionElIntersecting(true)
    }
  }, [])

  useContinuousIntersectionObserver({
    onIntersect: (entries) => {
      entries.forEach((entry) => {
        setSectionElIntersecting(entry.intersectionRatio > 0.05)
      })
    },
    observedComponentRef: sectionEl,
    root: null,
    rootMargin: '0px',
    threshold: [0, 0.05, 0.1],
  })

  return (
    <section ref={sectionEl} className="stats__PortraitsAndQuotes">
      {portraits.map((portrait, index, array) => (
        <Portrait
          key={`portrait_${index}`}
          portrait={portrait}
          currentSlide={index}
          slides={array}
          isActiveSection={isSectionElIntersecting}
        />
      ))}
    </section>
  )
}

export default PortraitsAndQuotes
