import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getProjectsList } from 'kiss/modules/stats-page/redux'
import { getRouteFor, BROWSING_POPULAR } from 'kiss/routes/redux'
import ProjectsCarousel from 'kiss/components/carousels/projects-carousel'

const WallOfFameProjectsCarousel = () => {
  const t = useTranslation()

  const projectsList = useSelector(getProjectsList)
  const projectsCount = projectsList.length
  const routeFor = useSelector(getRouteFor)
  if (projectsCount < 1) return null

  return (
    <section className="stats__ProjectsCarousel">
      <ProjectsCarousel
        title={t('stats.projects_carousel.title')}
        description={t('stats.projects_carousel.description')}
        buttonHref={routeFor(BROWSING_POPULAR)}
        buttonLabel={t('stats.projects_carousel.button_label')}
        projects={projectsList}
        projectsCount={projectsCount}
      />
    </section>
  )
}

export default WallOfFameProjectsCarousel
