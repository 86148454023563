import {
  ArrowIcon,
  Button,
  Carousel,
  Container,
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  Grid,
  GridCol,
  HorizontalStroke,
  pxToRem,
  SCREEN_SIZE_M,
  ScreenConfig,
  Text,
  Title,
  useLazyObserver,
} from '@kisskissbankbank/kitten'
import ProjectCard from 'kiss/components/cards/project-card'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import styled from 'styled-components'

const StyledWrapper = styled.div`
  .kiss-ProjectsCarousel__button {
    flex-shrink: 0;
    margin-left: ${pxToRem(10)};

    @media (max-width: ${ScreenConfig.M.max}px) {
      position: absolute;
      bottom: 0;
      right: ${pxToRem(CONTAINER_PADDING_THIN)};

      @media (min-width: ${ScreenConfig.S.min}px) {
        right: ${pxToRem(CONTAINER_PADDING)};
      }
    }
  }

  .kiss-ProjectsCarousel__header {
    flex: 1;

    @media (max-width: ${ScreenConfig.M.max}px) {
      margin-right: -${pxToRem(CONTAINER_PADDING_THIN)};

      @media (min-width: ${ScreenConfig.S.min}px) {
        margin-right: -${pxToRem(CONTAINER_PADDING)};
      }
    }
  }
  .kiss-ProjectsCarousel__title {
    display: flex;
    align-items: center;

    @media (min-width: ${ScreenConfig.L.min}px) {
      margin-left: -${pxToRem(50)};
    }
  }

  .kiss-ProjectsCarousel__stroke {
    order: 2;
    width: 100%;
    margin-left: ${pxToRem(20)};
    margin-right: 0;

    @media (min-width: ${ScreenConfig.M.min}px) {
      order: 0;
      width: ${pxToRem(30)};
      margin-left: 0;
      margin-right: ${pxToRem(20)};
    }
  }

  .kiss-ProjectsCarousel__carouselContainer {
    position: relative;
    padding-bottom: ${pxToRem(80)};

    @media (min-width: ${ScreenConfig.S.min}px) {
      padding-bottom: 0;
    }
  }

  .kiss-ProjectsCarousel__carousel {
    @media (min-width: ${ScreenConfig.L.min}px) {
      margin-right: ${pxToRem(-50 - 20)};
    }
  }
`

const ProjectsCarousel = ({
  title,
  description,
  buttonHref,
  buttonLabel,
  projects,
  projectsCount,
}) => {
  const t = useTranslation()

  const { viewportIsSOrLess } = useMediaQuery()

  const lazyComponentRef = useRef(null)
  const shouldLoadImages = useLazyObserver(lazyComponentRef)

  const observedComponentRef = useRef(null)

  if (!projectsCount) return null

  return (
    <StyledWrapper>
      <Container className="k-u-margin-bottom-double k-u-margin-bottom-triple@s-up">
        <Grid>
          <GridCol
            col-l="10"
            offset-l="1"
            className="k-u-flex k-u-flex-alignItems-end k-u-flex-justifyContent-sb"
          >
            <div className="kiss-ProjectsCarousel__header">
              <div className="kiss-ProjectsCarousel__title">
                <HorizontalStroke className="kiss-ProjectsCarousel__stroke" />
                <Title tag="h2" modifier="quaternary" noMargin>
                  {title}
                </Title>
              </div>

              {!!description && (
                <Text
                  size="small"
                  color="font1"
                  weight="400"
                  className="k-u-margin-top-noneHalf"
                >
                  {description}
                </Text>
              )}
            </div>

            <Button
              as="a"
              href={buttonHref}
              className="kiss-ProjectsCarousel__button k-u-hidden@m-down"
            >
              <span>{buttonLabel}</span>
              <ArrowIcon width="6" />
            </Button>
          </GridCol>
        </Grid>
      </Container>

      <div ref={lazyComponentRef} />
      <Container
        fullWidthBelowScreenSize={SCREEN_SIZE_M}
        className="kiss-ProjectsCarousel__carouselContainer"
      >
        <Grid>
          <GridCol col-l="10" offset-l="1">
            <Carousel
              className="kiss-ProjectsCarousel__carousel"
              itemMinWidth={viewportIsSOrLess ? 130 : 280}
              baseItemMarginBetween={viewportIsSOrLess ? 0 : CONTAINER_PADDING}
              prevButtonText={t('app.carousel.button.previous')}
              nextButtonText={t('app.carousel.button.next')}
              paginationPosition={{ default: 'bottom', fromL: 'right' }}
              showOtherPages={viewportIsSOrLess}
              pageClickText={(page) => {
                return t('app.carousel.button.go_to_page', { page })
              }}
              firstButtonText={t('app.carousel.button.first')}
              lastButtonText={t('app.carousel.button.last')}
              shadowSize={30}
            >
              {projects.map((project) => (
                <ProjectCard
                  project={project}
                  shouldLoadImages={shouldLoadImages}
                  key={project.key}
                />
              ))}
            </Carousel>

            <Button
              as="a"
              href={buttonHref}
              className="kiss-ProjectsCarousel__button k-u-hidden@l-up"
            >
              <span>{buttonLabel}</span>
              <ArrowIcon width="6" />
            </Button>
          </GridCol>
        </Grid>
      </Container>
      <div ref={observedComponentRef} />
    </StyledWrapper>
  )
}

ProjectsCarousel.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  buttonHref: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string.isRequired,
  projects: PropTypes.array.isRequired,
  projectsCount: PropTypes.number.isRequired,
}

ProjectsCarousel.defaultProps = {
  description: null,
}

export default ProjectsCarousel
