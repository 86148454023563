import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { Title } from '@kisskissbankbank/kitten'

const Email = () => {
  const t = useTranslation()

  return (
    <section id="email-section">
      <Title
        tag="p"
        modifier="quinary"
        className="k-u-margin-top-quintuple k-u-margin-top-decuple@s-up k-u-margin-bottom-triple"
      >
        {t('gift_card.new.form.email.title')}
      </Title>

      <Label size="medium" htmlFor="email">
        {t('gift_card.new.form.email.label')}
      </Label>

      <SimpleText id="email" name="email" inputMode="email" size="large" />
    </section>
  )
}

export default Email
