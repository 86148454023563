import {
  Container,
  Grid,
  GridCol,
  HorizontalStroke,
  Text,
  Title,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const Platforms = () => {
  const t = useTranslation()

  return (
    <Container as="section" className="stats__Platforms">
      <Grid className="Platforms__Grid">
        <GridCol
          className="Platforms__TitleCol"
          col="12"
          col-s="10"
          offset-s="1"
          col-l="8"
          offset-l="2"
        >
          <Title
            tag="h2"
            modifier="tertiary"
            family="antiqueolive"
            className="k-u-margin-top-triple k-u-margin-bottom-triple"
          >
            {t('stats.platforms.title', { parseHtml: true })}
          </Title>
        </GridCol>
        <GridCol
          className="Platforms__FirstParagraphCol"
          col="10"
          offset="2"
          col-s="8"
          offset-s="3"
          col-l="5"
          offset-l="5"
        >
          <Text className="Platforms__Text" weight="400">
            {t('stats.platforms.first_part', { parseHtml: true })}
          </Text>
        </GridCol>
        <GridCol
          className="Platforms__SecondParagraphCol"
          col="10"
          offset="2"
          col-s="8"
          offset-s="3"
          col-l="5"
          offset-l="5"
        >
          <Text className="Platforms__Text" weight="400">
            {t('stats.platforms.second_part', { parseHtml: true })}
          </Text>
          <div className="Platforms__HorizontalStrokeHolder">
            <HorizontalStroke className="Stats__HorizontalStroke__Size1" />
          </div>
        </GridCol>
      </Grid>
    </Container>
  )
}

export default Platforms
