import React from 'react'

import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import useNumbers from 'kiss/hooks/use-numbers'
import { PRICING_PLANS, FUNNEL, getRouteFor } from 'kiss/routes/redux'

import { Title, Button, mq, pxToRem } from '@kisskissbankbank/kitten'
import PricingCard from 'kiss/components/cards/pricingCard'

import styled from 'styled-components'

export const pricingPlansObjects = [
  {
    name: 'Starter',
    feeRateWithoutServiceFee: 0,
    feeRateWithoutVatWithoutServiceFee: 0,
    subtitleTranslationKeys: {
      plan: 'pricing_plans.free',
      start: 'pricing_plans.offer_subtitle.start',
      offer: 'pricing_plans.offer_subtitle.starter',
      vat: 'pricing_plans.offer_subtitle.vat',
      end1: 'pricing_plans.offer_subtitle.end.1',
      end2: 'pricing_plans.offer_subtitle.end.starter',
    },
    descriptionTranslationKey: 'pricing_plans.offer_description.starter',
    listItemsTranslationKeys: [
      { title: 'pricing_plans.free_feature.1' },
      { title: 'pricing_plans.free_feature.2' },
      { title: 'pricing_plans.free_feature.3' },
      { title: 'pricing_plans.free_feature.4' },
    ],
    buttonIsActive: true,
  },
  {
    name: 'Pro',
    feeRateWithoutServiceFee: 0.060000000000000005,
    feeRateWithoutVatWithoutServiceFee: 0.05,
    subtitleTranslationKeys: {
      plan: 'pricing_plans.vat',
      start: 'pricing_plans.offer_subtitle.start',
      offer: 'pricing_plans.offer_subtitle.pro',
      vat: 'pricing_plans.offer_subtitle.vat',
      end1: 'pricing_plans.offer_subtitle.end.1',
      end2: 'pricing_plans.offer_subtitle.end.proExpert',
    },
    descriptionTranslationKey: 'pricing_plans.offer_description.pro',
    listItemsTranslationKeys: [
      { title: 'pricing_plans.pro_feature.1' },
      { title: 'pricing_plans.pro_feature.2' },
      { title: 'pricing_plans.pro_feature.3' },
      { title: 'pricing_plans.pro_feature.4' },
      { title: 'pricing_plans.pro_feature.5' },
    ],
    buttonIsActive: true,
  },
  {
    name: 'Expert',
    feeRateWithoutServiceFee: 0.12,
    feeRateWithoutVatWithoutServiceFee: 0.1,
    subtitleTranslationKeys: {
      plan: 'pricing_plans.vat',
      start: 'pricing_plans.offer_subtitle.start',
      offer: 'pricing_plans.offer_subtitle.expert',
      vat: 'pricing_plans.offer_subtitle.vat',
      end1: 'pricing_plans.offer_subtitle.end.1',
      end2: 'pricing_plans.offer_subtitle.end.proExpert',
    },
    descriptionTranslationKey: 'pricing_plans.offer_description.expert',
    listItemsTranslationKeys: [
      { title: 'pricing_plans.expert_feature.1' },
      { title: 'pricing_plans.expert_feature.2' },
      { title: 'pricing_plans.expert_feature.3' },
      { title: 'pricing_plans.expert_feature.4' },
      { title: 'pricing_plans.expert_feature.5' },
      { title: 'pricing_plans.expert_feature.6' },
    ],
    buttonIsActive: true,
  },
]

const OffersWrapper = styled.section`
  max-width: none;
  box-sizing: border-box;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  padding: ${pxToRem(60)} ${pxToRem(20)};
  background-color: var(--color-primary-100);
  clip-path: polygon(100% 0%, 0% 0%, 0% 100%, 100% 95%);
  margin-bottom: ${pxToRem(64)};

  > * {
    position: relative;
    z-index: 2;
  }

  @media ${mq.tabletAndDesktop} {
    h2 {
      max-width: 60vw;
      margin: auto;
    }
  }
  @media ${mq.tablet} {
    padding: ${pxToRem(60)} ${pxToRem(100)};
  }

  @media ${mq.desktop} {
    padding: ${pxToRem(70)} ${pxToRem(120)} ${pxToRem(70)};
  }

  .kiss-Homepage__offer__cards {
    display: flex;
    gap: ${pxToRem(20)};
    flex-direction: column;
    margin: ${pxToRem(30)} 0;
    align-items: center;

    @media ${mq.desktop} {
      display: grid;
      gap: 0 ${pxToRem(30)};
      grid-template-columns: repeat(3, 1fr);
      grid-template-rows: 1fr auto 1fr;
    }

    .kiss-Homepage__offer__card {
      grid-row: 2 / span 1;
    }
    .kiss-Homepage__offer__card--main {
      grid-row: 1 / span 3;
    }
  }
`

const Offers = () => {
  const t = useTranslation()

  const { formatPercent } = useNumbers()

  const routeFor = useSelector(getRouteFor)

  return (
    <OffersWrapper>
      <Title
        modifier="tertiary"
        tag="h2"
        family="antiqueolive"
        className="k-u-align-center k-u-margin-bottom-tripleHalf"
      >
        {t('new_home.offers.title', { parseHtml: true })}
      </Title>

      <div className="kiss-Homepage__offer__cards">
        {pricingPlansObjects.map((plan) => {
          return (
            <PricingCard
              key={plan.name}
              main={plan.name === 'Pro'}
              name={plan.name}
              isFree={plan.feeRateWithoutVatWithoutServiceFee === 0}
              rate={formatPercent(plan.feeRateWithoutServiceFee * 100, 0, 1)}
              rateWithoutVat={formatPercent(
                plan.feeRateWithoutVatWithoutServiceFee * 100,
                0,
                1,
              )}
              bottomRadiusDisplay
              subtitleTranslationKeys={plan.subtitleTranslationKeys}
              listItemsTranslationKeys={plan.listItemsTranslationKeys}
              descriptionTranslationKey={plan.descriptionTranslationKey}
              actionBlock={
                <Button
                  as="a"
                  fit="content"
                  disabled={!plan.buttonIsActive}
                  mobileFit="fluid"
                  href={routeFor(FUNNEL)}
                  modifier="helium"
                >
                  {t('pricing_plans.create_project')}
                </Button>
              }
            />
          )
        })}
      </div>

      <div className="k-u-align-center">
        <Button
          as="a"
          mobileFit="fluid"
          href={routeFor(PRICING_PLANS)}
          modifier="helium"
        >
          {t('new_home.offers.see_offers')}
        </Button>
      </div>
    </OffersWrapper>
  )
}

export default Offers
