import React from 'react'

import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import { Button, ScreenConfig, pxToRem } from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledPromoCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  max-width: 240px;
  flex: 1;

  img {
    height: 150px;
    width: 240px;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    max-width: 411px;
    min-width: 300px;
    min-height: 462px;
    flex: 1;

    img {
      height: 270px;
      width: auto;
    }
  }
`

const StyledPromoCardImage = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`
const StyledParagraphContainer = styled.div`
  margin: 16px 30px;
`

const StyledCardParagraph = styled.p`
  font-family: 'GeneralSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: var(--color-grey-900);
  letter-spacing: -0.14px;
  text-align: center;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    font-size: 16px;
    line-height: 26px;
  }
`
const StyledButtonContainer = styled.div`
  margin: 0px 30px 16px 30px;
  width: -webkit-fill-available;
  margin-top: auto;

  a {
    width: 100%;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin: auto;
    margin-bottom: ${pxToRem(16)};
    width: fit-content;
  }
`

const PromoCard = ({ image, description, buttonTitle, buttonLink }) => {
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledPromoCard>
      <StyledPromoCardImage src={assetFor(image)} alt={image} />
      <StyledParagraphContainer>
        <StyledCardParagraph>{description}</StyledCardParagraph>
      </StyledParagraphContainer>
      <StyledButtonContainer>
        <Button
          size="small"
          fit="content"
          as="a"
          mobileFit="fluid"
          href={buttonLink}
          target="_blank"
          modifier="helium"
        >
          {buttonTitle}
        </Button>
      </StyledButtonContainer>
    </StyledPromoCard>
  )
}

export default PromoCard
