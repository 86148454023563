import React from 'react'

export const ChattingIllustration = (props) => (
  <svg viewBox="0 0 560 700" preserveAspectRatio="xMaxYMin meet" {...props}>
    <defs>
      <path id="a" d="M0 0h560v700H0z" />
    </defs>
    <clipPath id="b">
      <path d="M0 0h560v700H0z" />
    </clipPath>
    <g clipPath="url(#b)">
      <path
        d="M266 230.2l-19.3 7.9c-25.5-19.2-57.2-30.7-91.6-30.7-41.3 0-78.8 16.5-106.2 43.2l4.8 4.8c-29.5 27.8-48 67.2-48 110.9 0 41.3 16.5 78.8 43.2 106.2l110.9-110.9L266 255.4v-25.2z"
        opacity=".1"
      />
      <path
        d="M47.9 448.7l109-109L46 228.8c-28.4 27.7-46 66.3-46 109 0 43.8 18.4 83.2 47.9 110.9z"
        fill="#83dafc"
      />
      <path
        d="M155.1 182.2c-41.3 0-78.8 16.5-106.2 43.2l110.9 110.9L266 230.2c-27.8-29.5-67.2-48-110.9-48z"
        fill="#002f7d"
      />
      <path
        d="M175.3 518.7c42.8 0 81.4-17.7 109-46L180 368.3l-109 109c27.2 25.6 63.9 41.4 104.3 41.4z"
        opacity=".1"
      />
      <path
        d="M166.8 510.2c42.8 0 81.4-17.7 109-46L171.5 359.8l-109 109c27.2 25.6 63.9 41.4 104.3 41.4z"
        fill="#caf4fe"
      />
      <path
        d="M375.6 345.2c24.1-4.2 42.5-25.3 42.4-50.6 0-28.4-23.1-51.3-51.4-51.3-13.7 0-26.1 5.4-35.3 14.1l-.2.2-87.2 87.4h-.3l.2.2-.2.2h.3l87.2 87.4.2.2c9.2 8.7 21.6 14.1 35.3 14.1 28.3 0 51.4-22.9 51.4-51.3 0-25.3-18.3-46.4-42.4-50.6z"
        opacity=".1"
      />
      <path
        d="M358.1 346.6c23.8-4.5 41.8-25.3 41.8-50.4 0-28.4-22.9-51.4-51.3-51.4-14.9 0-28.4 6.3-37.8 16.5l-85.3 85.3 85.3 85.3c9.4 10.2 22.9 16.5 37.8 16.5 28.4 0 51.3-23 51.3-51.4 0-25.1-18-46-41.8-50.4z"
        fill="red"
      />
      <path
        d="M508.6 577.8c-4.5-11.5-12-28.2-36.9-33.2-24.9-5-29.4-6.7-29.4-6.7-14.4-22.8-25.7-43.9-45.7-58-20-14.1-53.3-37.3-53.3-37.3-.2-5.2-17.4-17.5-24.2-22.6-3.7-2.7-5.2-2.8-5.8-2.2-1.3-.6-2 .2-2 .2-4.1 4.4-6.7 10.5-6.8 17.4v.1c0 1.3.1 2.6.2 3.9.1.6.2 1.2.3 1.9 0 .1 0 .2.1.3.3 1.3.6 2.6 1 3.9 0 .1.1.2.1.3.2.6.4 1.1.6 1.7.1.1.1.3.2.4.2.6.5 1.2.8 1.8v.1c.3.6.6 1.3 1 2 .1.1.2.3.2.4l.9 1.5c.1.2.2.3.3.5l1.2 1.8.1.1c.4.6.9 1.3 1.4 1.9.1.2.3.4.4.5.4.5.8 1 1.2 1.4.2.2.3.4.5.6.6.6 1.1 1.3 1.8 1.9 16.1 16.6 53.9 47.8 60.1 54.8 1.1 1.3 2.5 3.2 4.2 5.5-.5-.1-1-.3-1.6-.4h-.1l-1.5-.3h-.1c-.5-.1-1-.1-1.5-.2h-.2c-.5-.1-1-.1-1.5-.1h-.2c-.5 0-1-.1-1.5-.1h-1.7-1.5-.1c-4 .1-7.1.5-7.1.5s-47.8 8.2-55.7 11.4c-7.9 3.3-17.8 7.9-20.5 12.3-7.4-.1-29.2 16.3-31.1 21.1.1.1.3.2.4.3-1.2 1.7-2 3.3-1.2 4 24.9 22.8 56.6-5.6 65.4-8.8 4.2-1.5 16.7-2.2 29.4-2.2l-45.5 32.1c-7.9-2.4-15.8-1.8-23.2 3.3-1.3 1.1-2 2.4-2.2 4.2-3.7 3.8-.1 9.9 1.8 13.5 2.3 4.5 15.6 25.2 18.3 23.4 0 0 38 59.4 45.1 68.5.2.2.4.5.6.8l.1.1c.2.3.4.5.6.8l.1.1c.2.3.4.5.5.8 0 .1.1.1.1.2.2.3.4.5.5.8 0 .1.1.1.1.2.2.3.3.6.5.8.1.1.1.2.2.4s.2.3.3.5c6.7 10.8 17.5 29.6 57.2 55.7l151.8-59.2c.2-.1-43.1-117.9-47.5-129.4zM345 598c5.2-3.2 46.8-32.2 46.8-32.3 1.4.6 2.8 1.1 4.2 1.7.7.3 1.4.5 2 .8 1.6.7 3.1 1.3 4.4 1.8 2.3.9 3.7 1.6 3.7 1.6.5 1.6 1.2 3.5 1.8 5.5l-66.1 44.7c-5-5.8-10.4-11.5-16.1-16.4 6.3.4 14-4.2 19.3-7.4zm67.9 67.9c-18.6 10-35.7 6-51.7-18.1-2-3.1-4.4-6.6-7.1-10.2h.5c6.4-.2 13.6-1 25-8 8.3-5.1 27.7-17.7 37.7-24.3 2.6 7.7 4.9 14.8 6.5 19.6l.6 1.8c.1.2.1.3.2.5.1.5.3.9.4 1.4 0 .2.1.3.1.5.4 1.2.7 2.4 1 3.6 0 .1.1.2.1.3.1.5.2 1 .4 1.5 0 .1 0 .2.1.3.3 1.2.5 2.4.6 3.6v.2c.1.6.1 1.1.2 1.7v.2c.1 1.2.2 2.4.1 3.6 0 .6 0 1.1-.1 1.7v.1c-.1 1.8-.5 3.5-1 5.1v.1c-.3 1.1-.8 2.2-1.3 3.3-2.2 4.2-6.1 8.1-12.3 11.5z"
        opacity=".1"
      />
      <path
        d="M373.9 561.2s-41.6 29-46.8 32.3c-5.2 3.2-12.9 7.9-19.2 7.4-6.2-.4-22-13.2-22-13.2l47.7-33.7 27.9-6.3 12.4 13.5z"
        fill="#5e3934"
      />
      <path
        d="M373.9 561.2s-41.6 29-46.8 32.3c-5.2 3.2-12.9 7.9-19.2 7.4-6.2-.4-22-13.2-22-13.2l47.7-33.7 27.9-6.3 12.4 13.5z"
        opacity=".1"
      />
      <path
        d="M390 572.6l-66.9 45.3 2.9 9.5s4.3 5.9 10.8 5.6 13.6-1 25-8 44-28.3 44-28.3L390 572.6z"
        fill="#5e3934"
      />
      <path
        d="M390 572.6l-66.9 45.3 2.9 9.5s4.3 5.9 10.8 5.6 13.6-1 25-8 44-28.3 44-28.3L390 572.6z"
        opacity=".1"
      />
      <path
        d="M268.6 541.3c2.7-4.4 12.6-9.1 20.5-12.3 7.9-3.3 55.7-11.4 55.7-11.4s10.6-1.6 18.4.6c7.8 2.2 37.9 33.1 37.9 33.1l-12.8 15.8s-18.5-7.9-25.2-9.8c-6.7-2-52.2-2.7-61 .6-8.8 3.3-40.5 31.6-65.4 8.8-1.2-1.4 2-5.1 4.2-7.8"
        fill="#5e3934"
      />
      <path
        d="M268.6 541.3c1.9 1.6 3.3 4.7-2.4 8.9-5.7 4.2-15.1 12-15.1 12-3.5 2.7-8 4.2-13.6.2 1.9-4.9 23.7-21.2 31.1-21.1z"
        fill="#a78073"
      />
      <path
        d="M398.9 553.9l-4.4-9.2c-9.3-9.2-25.8-25-31.3-26.5-4.1-1.2-9-1.3-12.7-1.1 12.7 10.9 7 29.2 12.5 40.2 6.7 2 25.2 9.8 25.2 9.8l10.7-13.2z"
        opacity=".1"
      />
      <path
        d="M325.4 438.1s-25.6-21-28.4-23.4c-2.3-2.7-3.6-1.2-3.6-1.2-9.2 9.7-10.6 27.7 5.5 44.3 16.1 16.6 53.9 47.8 60.1 54.8 6.2 7 21.8 33.5 26.9 47.5 0 0 14.7 44 20 60.1 5.3 16.1 7.7 31.1-10.9 41.1s-35.7 6-51.7-18.1-52.2-71.9-80.7-52.2c-5.8 4.7-.3 14.8 18 41.1 0 0 38 59.4 45.1 68.5s13.3 29.8 60.9 61.2l151.8-59.2s-43.3-117.8-47.7-129.4c-4.5-11.5-12-28.2-36.9-33.2-24.9-5-29.4-6.7-29.4-6.7-14.4-22.8-25.7-43.9-45.7-58-20-14.1-53.3-37.2-53.3-37.2"
        fill="#724a44"
      />
      <path
        d="M325.4 438.1c-3.2 3.4-6.4 4.7-13-.7s-16.9-13.8-16.9-13.8c1.1-3-.5-8.9-.5-8.9s-.7-4.3 6.1.8 24.1 17.4 24.3 22.6z"
        fill="#a78073"
      />
      <path
        d="M407.3 649.8c10.3-12.7 11.9-20.2 5.3-43.5-6.6-23.3-14.4-51.6-22.2-65.5-5.7-10.2-11.3-25.2-30.3-42.3-19-17.1-70.5-55.9-73-73.1-2.1 9.5.8 21.3 11.7 32.5 16.1 16.6 53.9 47.8 60.1 54.8 6.2 7 21.8 33.5 26.9 47.5 0 0 14.7 44 20 60.1 3.7 10.7 5.9 20.9 1.5 29.5zM377.3 755.4l3.3-2.9-19.9-18.7c-12.6-9.2-26.2-35.3-33.3-46.2-7.1-11-32.5-51.7-37.1-61.6l-12.4-.2 2.8 6.4s38 59.4 45.1 68.5c6.6 8.3 12.4 26.9 51.5 54.7z"
        opacity=".1"
      />
      <path
        d="M280.6 632.1c4.8-1.9 15.9-8.7 12.7-15.6s-16.8-27.6-29.2-23.4c-9 3-4.1 11.2-1.8 15.7 2.3 4.4 15.6 25.1 18.3 23.3z"
        fill="#a78073"
      />
      <path
        d="M381.8 129.2c-2.7 7.2-7.2 25.6-8 29.3-4.1 10.5-5.9 42.8-5.9 47.7 9.8 4.6 21.8-2.1 25.2-19.9s8.6-40.3 22.8-64c7.4-12.4-20.9-9.9-20.9-9.9l-13.2 16.8z"
        fill="#efdad3"
      />
      <path
        d="M394.9 112.3l-13.1 16.9c-2.7 7.2-7.2 25.6-8 29.3-1.1 2.8-2 7.2-2.8 12.2 11.1-5.6 20.6-8.6 28.2-10.1 3.6-12 8.7-24.9 16.7-38.3 7.4-12.5-21-10-21-10z"
        opacity=".1"
      />
      <path
        d="M370 179s3.6-.9 4.4 6.2c.8 7.1-.5 17.6-.5 17.6s-4.8.7-6 3.3c0 0-3-17.4 2.1-27.1z"
        fill="#fff"
      />
      <path
        d="M352.9 114c-11.7 9.8-20.1 19.6-27.5 35.4s-17.9 42.1-20.9 48.7-9 33.8-9 33.8c-2.6 11.3-6.1 21.9-6.9 23.7-.8 1.8-1.3 6.4 9.4 3.3 10.8-3.2 24.8-8.2 38.4-53.6 1.6-4.7 31.8-55.5 33.9-58.5 5.5-8.4 33.2-16.4 41.6-21.8 8.4-5.4-3.3-17.1-3.3-17.1l-55.7 6.1z"
        fill="#efdad3"
      />
      <path
        d="M407.9 127.2c6.9-9 3.9-15.4 1.9-18-.7-.8-1.2-1.3-1.2-1.3l-55.7 6.1c-11.7 9.8-20.1 19.6-27.5 35.4-.2.5-.5 1-.7 1.6l-.3.6c-.2.4-.3.7-.5 1.1-.1.2-.2.5-.3.7-.2.3-.3.7-.5 1-.1.2-.2.5-.3.7-.2.3-.3.7-.5 1-.1.3-.2.5-.4.8-.1.3-.3.7-.4 1-.1.3-.2.6-.4.8-.1.3-.3.7-.4 1-.1.3-.2.6-.4.8-.1.3-.3.7-.4 1-.1.3-.2.6-.4.8-.2.4-.3.7-.5 1.1-.1.3-.2.5-.3.8-.2.4-.3.8-.5 1.2-.1.2-.2.5-.3.7-.2.4-.4.9-.6 1.3l-.3.6c-.2.6-.5 1.1-.7 1.7 0 .1-.1.1-.1.2-.5 1.3-1.1 2.6-1.6 3.8-.1.2-.1.3-.2.5l-.6 1.5c-.1.1-.1.3-.2.4l-.6 1.5c0 .1-.1.2-.1.2-.2.6-.5 1.2-.7 1.7 15.7-12.1 39.7-23.9 54.9-28 .6-1 1.1-1.8 1.6-2.6.1-.2.3-.4.4-.6 0 0 0-.1.1-.1.2-.4.4-.7.6-1 0 0 0-.1.1-.1.1-.1.1-.2.2-.2 0 0 0-.1.1-.1.1-.1.1-.2.1-.2 3.4-5.2 15.1-10.2 25.6-14.5 4.7-2 9.1-3.7 12-4.9z"
        opacity=".1"
      />
      <path
        d="M297.8 221.4l4.9 1s-7 36.9-13.9 36.5c-6.3-5.3 9-37.5 9-37.5z"
        fill="#fff"
      />
      <path
        d="M260.8 208.2c-1.3-.1-2.2-1.3-1.7-2.6 3.4-9.9 16.4-47.4 17.3-50.5.9-3.6 1.8-6.8 8.1-11.2s41.9-33.8 45.9-37.5c4-3.7 10.8-10.1 21.2-12.1 10.4-2 40-8.5 42.2-10.1 6.5-3.5 27.7-33.6 47-38.5s131.4-34.5 131.4-34.5c29.4 25.7 30.5 66 25.6 107.7-45.6 79.2-98 112.4-115 124.7-17 12.3-92.8 59.3-92.8 59.3-8.9-14.2-5.6-21.8 2.3-31.8 8.7-10.9 84.8-66.4 66-108.3-14.9-28.4-55.7-35.1-65.5-33.8-9.8 1.3-36.1 6.4-41.9 7.5-5.9 1.1-15.5 6.2-22.7 12.4-7.2 6.3-25.1 19.4-27.2 22.7-2.2 3.3-2.5 9.2-6.3 15.5-3.8 6.3-11.9 22-33.9 21.1z"
        fill="#ffebe0"
      />
      <path
        d="M269.8 174.6s4.2.6 2.1 8.6-6.6 18.9-7.8 21.4-4.3 1.5-6.7.2c1.5-9.7 9.3-27.3 12.4-30.2zM406.4 296.7c-4.6 3.6-12.6 10.8-16.5 6.2-3.8-4.4 5.7-15.6 7.6-17.7 7.4-8.3 17.7-18.7 30.2-5.3l-21.3 16.8z"
        fill="#fff"
      />
      <path
        d="M597.7 119c1.9-16 2.8-31.7 1.7-46.6L587.7 71s-1.4 27.8-29.7 82.7S421.3 270 389.9 303c3.9 4.5 11.8-2.6 16.5-6.2l21.4-16.7-.4-.4c21.7-13.7 46.6-29.5 55.4-35.9 16.9-12.4 69.4-45.6 114.9-124.8z"
        opacity=".1"
      />
    </g>
  </svg>
)
