import React from 'react'
import { Helmet } from 'react-helmet'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { Title, Form, Faq } from './components'
import { useTranslation } from 'kiss/hooks/use-translation'

const GiftCardNew = () => {
  const t = useTranslation()

  return (
    <DefaultLayout>
      <Helmet
        title={t('gift_card.new.seo.title')}
        meta={[
          {
            name: 'robots',
            content: 'noindex, nofollow',
          },
        ]}
      />
      <Title />
      <Form />
      <Faq />
    </DefaultLayout>
  )
}

export default GiftCardNew
