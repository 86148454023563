import React, { useState } from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useDispatch, useSelector } from 'react-redux'
import Dropdown from 'kiss/components/formik/dropdown-select'
import SelectCountries from 'kiss/components/formik/select-countries'
import { Text } from '@kisskissbankbank/kitten'
import {
  getDeliveryCountriesAmounts,
  hasDeliveryZones as hasDeliveryZonesSelector,
  getDeliveryAmountCents,
} from '../../cart/redux'
import { getShippingAddress, updateSelectedCountry } from '../redux'
import sortBy from 'ramda/src/sortBy'
import prop from 'ramda/src/prop'
import find from 'ramda/src/find'
import propEq from 'ramda/src/propEq'
import pipe from 'ramda/src/pipe'
import insert from 'ramda/src/insert'
import map from 'ramda/src/map'
import isEmpty from 'ramda/src/isEmpty'
import memoize from 'lodash/memoize'

const getDeliveryCountries = memoize(
  (
    t,
    deliveryCountriesAmounts,
    withFranceOnFirst = true,
    deliveryAmount = 0,
  ) => {
    const options = map(({ countryCode, amount }) => ({
      value: countryCode,
      disabled: amount.cents !== deliveryAmount,
      label: !amount.cents
        ? t(`countries.${countryCode}`)
        : `${t(`countries.${countryCode}`)} + ${amount.cents / 100} ${t(
            `currency_symbol.${amount.currency}`,
          )}`,
    }))(deliveryCountriesAmounts)
    return withFranceOnFirst &&
      options.includes((option) => option.countryCode === 'FR')
      ? pipe(
          sortBy(prop('label')),
          insert(0, find(propEq('value', 'FR'))(options)),
        )(options)
      : sortBy(prop('label'))(options)
  },
  (_, deliveryCountriesAmounts) => {
    return deliveryCountriesAmounts
      .map(({ amount, countryCode }) => `${countryCode}${amount.cents}`)
      .join('-')
  },
)

const Countries = () => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const deliveryCountriesAmounts = useSelector(getDeliveryCountriesAmounts)
  const deliveryAmount = useSelector(getDeliveryAmountCents)
  const hasDeliveryZones = useSelector(hasDeliveryZonesSelector)
  const hasFees = deliveryCountriesAmounts.some(
    ({ amount }) => amount.cents > 0,
  )
  const address = useSelector(getShippingAddress)
  const [isFillingCountry, setIsFillingCountry] = useState(false)

  return (
    <>
      {hasDeliveryZones ? (
        <>
          <Dropdown
            name="countryCode"
            labelText={t('contribute.shipping_address.form.country.label')}
            hideLabel={false}
            placeholder={t(
              'contribute.shipping_address.form.country.placeholder',
            )}
            onChange={({ value }) => {
              if (value) {
                dispatch(updateSelectedCountry(value))
              }
            }}
            onInputChange={(value) => setIsFillingCountry(!isEmpty(value))}
            searchable
            options={getDeliveryCountries(
              t,
              deliveryCountriesAmounts,
              !isFillingCountry,
              deliveryAmount,
            )}
          />
          <Text
            tag="p"
            weight="400"
            size="nano"
            className="k-u-margin-top-single"
          >
            {t('contribute.shipping_address.form.country.update_country')}
          </Text>
        </>
      ) : (
        <SelectCountries
          name="countryCode"
          labelText={t('contribute.shipping_address.form.country.label')}
          hideLabel={false}
          placeholder={t(
            'contribute.shipping_address.form.country.placeholder',
          )}
          onChange={({ value }) => {
            if (value) {
              dispatch(updateSelectedCountry(value))
            }
          }}
          onInputChange={(value) => setIsFillingCountry(!isEmpty(value))}
          searchable
          disabled={address.countryCode && hasFees}
          showFranceOnFirst={!isFillingCountry}
          inputProps={{
            'data-form-type': 'other',
          }}
        />
      )}
    </>
  )
}

export default Countries
