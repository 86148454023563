import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import {
  domElementHelper,
  HorizontalStroke,
  useContinuousIntersectionObserver,
} from '@kisskissbankbank/kitten'

export const NumberCard = ({
  number,
  legend,
  size = 'normal',
  transitionDelay = 0,
  isElementVisible = false,
  unit = '',
  ...props
}) => {
  return (
    <div
      className={classNames('AnimatedNumbers__AnimatedBox', {
        'AnimatedBox--is_large': size === 'large',
        'AnimatedBox--visible_element_onScreen': isElementVisible,
        'AnimatedBox--hasUnit': !!unit,
      })}
      style={{ '--AnimatedNumbers-transition-delay': `${transitionDelay}s` }}
      {...props}
    >
      <div className="AnimatedNumbers__NumberBox">
        {Array.from(number.toString(10)).map((num, index) => (
          <span className="AnimatedNumbers__NumberSpan" key={index}>
            {num}
          </span>
        ))}
        {unit !== '' && (
          <span className="AnimatedNumbers__UnitSpan">{unit}</span>
        )}
      </div>
      <HorizontalStroke
        className="AnimatedNumbers__HorizontalStroke"
        size="large"
      />
      <span className="AnimatedNumbers__LegendBox">{legend}</span>
    </div>
  )
}

const AnimatedNumbers = ({ children, ...props }) => {
  const targetEl = useRef(null)

  const [isTargetElIntersecting, setTargetElIntersecting] = useState(false)

  useEffect(() => {
    if (domElementHelper.canUseDom() && !('IntersectionObserver' in window)) {
      setTargetElIntersecting(true)
    }
  }, [])

  useContinuousIntersectionObserver({
    onIntersect: (entries) => {
      entries.forEach((entry) => {
        setTargetElIntersecting(entry.intersectionRatio > 0.1)
      })
    },
    observedComponentRef: targetEl,
    root: null,
    rootMargin: '0px',
    threshold: [0, 0.2, 0.33],
  })

  return (
    <section ref={targetEl} className="stats__AnimatedNumbers" {...props}>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          isElementVisible: isTargetElIntersecting,
        })
      })}
    </section>
  )
}

export default AnimatedNumbers
