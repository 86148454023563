import {
  ButtonImage,
  CameraIcon,
  pxToRem,
  ScreenConfig,
  Text,
} from '@kisskissbankbank/kitten'
import { useFormikContext } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'
import { MENTOR_PAGE_EDITION } from 'kiss/routes/redux'
import { getDefaultAvatar } from 'kiss/utils/default-avatar'
import { useDeepCompareEffect } from 'kiss/utils/use-deep-compare-effect'
import { usePrevious } from 'kiss/utils/use-previous'
import React, { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  getAvatarImageUrl,
  getId,
  getUsername,
  UPLOADER_ACCEPT,
  UPLOADER_MAX_SIZE,
} from '../redux'

const StyledButtonImage = styled(
  ({ isPageEditionRoute, isRejected, isDragActive, ...others }) => (
    <ButtonImage {...others} />
  ),
)`
  position: relative;
  background-color: var(--color-grey-000);
  margin: 0 auto;
  margin-top: -${pxToRem(40)};
  width: ${pxToRem(80)};
  height: ${pxToRem(80)};
  border: ${pxToRem(3)} solid var(--color-grey-000);
  border-radius: 0;

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    margin-top: -${pxToRem(50)};
    width: ${pxToRem(100)};
    height: ${pxToRem(100)};
    border-width: ${pxToRem(4)};
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin-top: -${pxToRem(60)};
    width: ${pxToRem(120)};
    height: ${pxToRem(120)};
  }

  ${({ isPageEditionRoute }) =>
    isPageEditionRoute &&
    css`
      .k-ButtonImage__img {
        display: none;
      }

      ${({ img: { src } }) =>
        src &&
        css`
          background-image: url(${src});
          background-size: cover;
          background-position: center center;
        `}
      :before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(34, 34, 34, 0.8);
        transition: background-color 0.4s ease;
      }

      :focus-visible {
        outline: auto;
      }
      :hover,
      :focus {
        opacity: 1;

        :before {
          background-color: var(--color-primary-500);
        }
      }

      ${({ isRejected, isDragActive }) =>
        (isRejected || isDragActive) &&
        css`
          &,
          :hover,
          :focus {
            :before {
              background-color: ${isDragActive
                ? 'var(--color-primary-500)'
                : 'var(--color-danger-500)'};
            }
          }
        `}
    `}
`

const ButtonImageContainer = styled.div`
  position: relative;

  .kiss-MentorPage__Avatar__CameraIcon {
    position: absolute;
    left: calc(50% - ${pxToRem(10)});
    bottom: ${pxToRem(15)};
    pointer-events: none;

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      bottom: ${pxToRem(20)};
    }
  }
`

const defaultRejectedFile = null

const Avatar = () => {
  const t = useTranslation()
  const [rejectedFile, setRejectedFile] = useState(defaultRejectedFile)
  const isPageEditionRoute = !!useRouteMatch(MENTOR_PAGE_EDITION)
  const previousIsPageEditionRoute = usePrevious(isPageEditionRoute)
  const avatarImageUrl = useSelector(getAvatarImageUrl)
  const username = useSelector(getUsername) || ' '
  const id = useSelector(getId) || '0'
  const [imageSrc, setImageSrc] = useState(getDefaultAvatar({ username, id }))

  const {
    values: { avatarImageFile },
    setFieldValue,
  } = useFormikContext()

  // Show Formik value, store value or default asset.
  useDeepCompareEffect(() => {
    if (avatarImageFile) {
      setImageSrc(URL.createObjectURL(avatarImageFile))
    } else if (avatarImageUrl) {
      setImageSrc(avatarImageUrl)
    }
  }, [avatarImageFile, avatarImageUrl])

  // Reset state.
  useEffect(() => {
    if (previousIsPageEditionRoute === isPageEditionRoute) return

    setRejectedFile(defaultRejectedFile)
  }, [isPageEditionRoute])

  const onDropAccepted = useCallback((files) => {
    files.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        setFieldValue('avatarImageFile', file)
        setRejectedFile(defaultRejectedFile)
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])

  const onDropRejected = useCallback((files) => {
    setRejectedFile(files[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: UPLOADER_ACCEPT,
      maxSize: UPLOADER_MAX_SIZE,
      multiple: false,
      onDropAccepted,
      onDropRejected,
    })

  const isRejected = !!rejectedFile || isDragReject
  const uploaderProps = isPageEditionRoute
    ? getRootProps({ isPageEditionRoute, isDragActive, isRejected })
    : {}

  return (
    <>
      <ButtonImageContainer>
        {isPageEditionRoute && <input {...getInputProps()} />}

        <StyledButtonImage
          tag={isPageEditionRoute ? 'button' : 'span'}
          type={isPageEditionRoute ? 'button' : null}
          img={{ src: imageSrc }}
          withoutPointerEvents={!isPageEditionRoute}
          {...uploaderProps}
        />

        {isPageEditionRoute && (
          <>
            <span className="k-u-a11y-visuallyHidden">
              {t('mentor_page.edition.uploader.download_text')}
            </span>
            <CameraIcon
              color="var(--color-grey-000)"
              title={null}
              className="kiss-MentorPage__Avatar__CameraIcon"
              aria-hidden="true"
            />
          </>
        )}
      </ButtonImageContainer>

      {isPageEditionRoute && (
        <Text
          tag="p"
          size="micro"
          weight={!isDragActive && isRejected ? '500' : '400'}
          color={!isDragActive && isRejected ? 'error' : 'font1'}
          lineHeight="normal"
          className="k-u-margin-top-single"
        >
          {t('mentor_page.edition.uploader.avatar_informations', {
            parseHtml: true,
          })}
        </Text>
      )}
    </>
  )
}

export default Avatar
