import {
  ArrowIcon,
  Button,
  Details,
  FlexWrapper,
  RadioSet,
  SlideModal,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useTranslation } from 'kiss/hooks/use-translation'
import { BROWSING, ENGAGEMENT_BROWSING, getRouteFor } from 'kiss/routes/redux'
import qs from 'qs'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useHistory, useLocation } from 'react-router-dom'
import useBrowsing from '../hooks/use-browsing'
import { getCategories } from '../redux'
import { getEngagementsList } from '../redux'

const Content = ({ close }) => {
  const t = useTranslation()
  const { search } = useLocation()
  const { engagement } = useParams()
  const categories = useSelector(getCategories)
  const routeFor = useSelector(getRouteFor)
  const history = useHistory()
  const { updateSearch, resetSearch, getNewSearch } = useBrowsing()
  const { state, scope, category } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })
  const defaultCheckedStateValue = state || 'all'
  const defaultCheckedSortValue = scope || 'all'

  const engagementsList = useSelector(getEngagementsList)
  const [stateSelection, setStateSelection] = useState(defaultCheckedStateValue)
  const [sortSelection, setSortSelection] = useState(defaultCheckedSortValue)
  const [engagementSelection, setEngagementSelection] = useState(engagement)
  const [categorySelection, setCategorySelection] = useState(category)
  return (
    <>
      <SlideModal.Title>
        {t('browsing.mobile.filter.title', { parseHtml: true })}
      </SlideModal.Title>
      <SlideModal.Content>
        <FlexWrapper gap={20}>
          <RadioSet
            id="mobile-filter-state"
            name="mobile-filter-state"
            label={t('browsing.mobile.filter.state')}
            weight="400"
            onChange={(e) => {
              const stateValue = e.target?.id?.split('_')
              setStateSelection(stateValue[0])
            }}
            items={[
              {
                text: t('browsing.filter.state.all'),
                id: 'all_mobile-state-filter',
                defaultChecked: defaultCheckedStateValue === 'all',
              },
              {
                text: t('browsing.filter.state.in_progress'),
                id: 'started_mobile-state-filter',
                defaultChecked: defaultCheckedStateValue === 'started',
              },
              {
                text: t('browsing.filter.state.completed'),
                id: 'successful_mobile-state-filter',
                defaultChecked: defaultCheckedStateValue === 'successful',
              },
            ]}
          />
          <RadioSet
            id="mobile-filter-sort"
            name="mobile-filter-sort"
            label={t('browsing.mobile.filter.sort', { parseHtml: true })}
            weight="400"
            onChange={(e) => {
              const stateValue = e.target?.id?.split('_')
              setSortSelection(stateValue[0])
            }}
            items={[
              {
                text: t('browsing.fitler.sort.none'),
                id: 'all_mobile-sort-filter',
                defaultChecked: defaultCheckedSortValue === 'all',
              },
              {
                text: t('browsing.fitler.sort.popular'),
                id: 'popular_mobile-sort-filter',
                defaultChecked: defaultCheckedSortValue === 'popular',
              },
            ]}
          />
          <Details
            summaryProps={{
              className:
                'k-u-flex k-u-flex-gap-single k-u-flex-alignItems-center',
            }}
            summaryRender={({ open }) => (
              <>
                <span className="k-u-font-label-medium k-u-flex-grow-single">
                  {t('browsing.mobile.filter.engagement')}
                </span>
                <ArrowIcon aria-hidden direction={open ? 'top' : 'bottom'} />
              </>
            )}
          >
            <RadioSet
              id="mobile-filter-engagement"
              name="mobile-filter-engagement"
              className="k-u-margin-top-single"
              weight="400"
              onChange={(e) => {
                const stateValue = e.target?.id?.split('_')
                const stateCode = stateValue[0]
                setEngagementSelection(stateCode === 'all' ? null : stateCode)
              }}
              items={[
                {
                  text: t('browsing.engagements.all'),
                  id: 'all_mobile-engagment-filter',
                  defaultChecked: !engagement,
                },
              ].concat(
                engagementsList.map(({ code }) => {
                  return {
                    text: t(`engagement.${code}.name`, {
                      parseHtml: true,
                    }),
                    id: `${code}_mobile-engagment-filter`,
                    defaultChecked: code === engagement,
                  }
                }),
              )}
            />
          </Details>
          <Details
            summaryProps={{
              className:
                'k-u-flex k-u-flex-gap-single k-u-flex-alignItems-center',
            }}
            summaryRender={({ open }) => (
              <>
                <span className="k-u-font-label-medium k-u-flex-grow-single">
                  {t('browsing.mobile.filter.category')}
                </span>
                <ArrowIcon aria-hidden direction={open ? 'top' : 'bottom'} />
              </>
            )}
          >
            <RadioSet
              id="mobile-filter-category"
              name="mobile-filter-category"
              className="k-u-margin-top-single"
              weight="400"
              onChange={(e) => {
                const stateValue = e.target?.id?.split('_')
                setCategorySelection(stateValue[0])
              }}
              items={[
                {
                  text: t('browsing.categories.all'),
                  value: 'all_mobile-category-filter',
                  defaultChecked: !category,
                },
              ].concat(
                categories.map(({ slug }) => {
                  return {
                    text: (
                      <span>
                        <span aria-hidden>
                          {t(`browsing.categories.${slug}.logo`)}
                        </span>{' '}
                        {t(`browsing.categories.${slug}.name`)}
                      </span>
                    ),
                    id: `${slug}_mobile-category-filter`,
                    defaultChecked: category === slug,
                  }
                }),
              )}
            />
          </Details>
        </FlexWrapper>
      </SlideModal.Content>
      <SlideModal.Actions>
        <Button
          modifier="hydrogen"
          onClick={() => {
            resetSearch()
            close()
          }}
        >
          {t('browsing.mobile.filter.init')}
        </Button>
        <Button
          modifier="beryllium"
          onClick={() => {
            const searchObject = {
              state: stateSelection === 'all' ? undefined : stateSelection,
              scope: sortSelection,
              category: categorySelection,
            }
            if (engagementSelection) {
              const route = routeFor(ENGAGEMENT_BROWSING, {
                engagement: engagementSelection,
              })
              history.push(`${route}${getNewSearch(searchObject)}`)
            } else if (engagement) {
              history.push(`${routeFor(BROWSING)}${getNewSearch(searchObject)}`)
            } else {
              updateSearch(searchObject)
            }
            close()
          }}
        >
          {t('browsing.mobile.filter.show')}
        </Button>
      </SlideModal.Actions>
    </>
  )
}

const Mobile = ({ className = '' }) => {
  const t = useTranslation()
  return (
    <div className={classNames(className, '')}>
      <SlideModal
        trigger={
          <Button fit="fluid" modifier="boron">
            {t('browsing.mobile.filter.trigger')}
          </Button>
        }
      >
        {Content}
      </SlideModal>
    </div>
  )
}

export default Mobile
