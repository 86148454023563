import React from 'react'

import { getRouteFor, SERVICES_TYPEFORM } from 'kiss/routes/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import { pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'

import VerticalInfoCard from 'kiss/components/cards/verticalInfoCard'

import styled from 'styled-components'

const StyledCardSubtitle = styled.h2`
  font-family: 'AntiqueOlive';
  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 700;
  line-height: 18px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    font-size: ${pxToRem(20)};
    line-height: 30px;
  }
`

const StyledCardParagraph = styled.p`
  font-family: 'GeneralSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.14px;
  margin-top: 16px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    font-size: 16px;
    line-height: 26px;
  }
`

const ServicesCards = () => {
  const t = useTranslation()

  const routeFor = useSelector(getRouteFor)

  return (
    <>
      <VerticalInfoCard
        imagePath="illustrations/humain/HautParleur.svg"
        title={t('services.card_one.title', { parseHtml: true })}
        paragraph={t('services.card_one.paragraph', { parseHtml: true })}
        buttonTitle={t('services.contact_title')}
        buttonLink={routeFor(SERVICES_TYPEFORM)}
      >
        <div>
          <StyledCardSubtitle>{t('services.card.how')}</StyledCardSubtitle>
          <svg
            className="k-u-margin-top-singleHalf"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="4"
            viewBox="0 0 30 4"
            fill="none"
          >
            <rect width="30" height="4" fill="#222222" />
          </svg>
          <StyledCardParagraph>
            {t('services.card_one.first_subtitle_paragraph')}
          </StyledCardParagraph>
        </div>
        <div>
          <StyledCardSubtitle>
            {t('services.card.project_type')}
          </StyledCardSubtitle>
          <svg
            className="k-u-margin-top-singleHalf"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="4"
            viewBox="0 0 30 4"
            fill="none"
          >
            <rect width="30" height="4" fill="#222222" />
          </svg>
          <StyledCardParagraph>
            {t('services.card_one.second_subtitle_paragraph')}
          </StyledCardParagraph>
        </div>
      </VerticalInfoCard>

      <VerticalInfoCard
        imagePath="illustrations/humain/collectif/Entraide.svg"
        title={t('services.card_two.title', { parseHtml: true })}
        paragraph={t('services.card_two.paragraph', { parseHtml: true })}
        buttonTitle={t('services.contact_title')}
        buttonLink={routeFor(SERVICES_TYPEFORM)}
      >
        <div>
          <StyledCardSubtitle>{t('services.card.how')}</StyledCardSubtitle>
          <svg
            className="k-u-margin-top-singleHalf"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="4"
            viewBox="0 0 30 4"
            fill="none"
          >
            <rect width="30" height="4" fill="#222222" />
          </svg>
          <StyledCardParagraph>
            {t('services.card_two.first_subtitle_paragraph')}
          </StyledCardParagraph>
        </div>
        <div>
          <StyledCardSubtitle>
            {t('services.card.project_type')}
          </StyledCardSubtitle>
          <svg
            className="k-u-margin-top-singleHalf"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="4"
            viewBox="0 0 30 4"
            fill="none"
          >
            <rect width="30" height="4" fill="#222222" />
          </svg>
          <StyledCardParagraph>
            {t('services.card_two.second_subtitle_paragraph')}
          </StyledCardParagraph>
        </div>
      </VerticalInfoCard>

      <VerticalInfoCard
        imagePath="logos/LaMaison_C.svg"
        title={t('services.card_three.title', { parseHtml: true })}
        paragraph={t('services.card_three.paragraph', { parseHtml: true })}
        buttonTitle={t('services.contact_title')}
        buttonLink={routeFor(SERVICES_TYPEFORM)}
      >
        <div>
          <StyledCardSubtitle>{t('services.card.how')}</StyledCardSubtitle>
          <svg
            className="k-u-margin-top-singleHalf"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="4"
            viewBox="0 0 30 4"
            fill="none"
          >
            <rect width="30" height="4" fill="#222222" />
          </svg>
          <StyledCardParagraph>
            {t('services.card_three.first_subtitle_paragraph')}
          </StyledCardParagraph>
        </div>
        <div>
          <StyledCardSubtitle>
            {t('services.card.project_type')}
          </StyledCardSubtitle>
          <svg
            className="k-u-margin-top-singleHalf"
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="4"
            viewBox="0 0 30 4"
            fill="none"
          >
            <rect width="30" height="4" fill="#222222" />
          </svg>
          <StyledCardParagraph>
            {t('services.card_three.second_subtitle_paragraph')}
          </StyledCardParagraph>
        </div>
      </VerticalInfoCard>
    </>
  )
}

export default ServicesCards
