import { Accordeon, Text, Title } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'

const Faq = ({ className }) => {
  const t = useTranslation()
  return (
    <div className={className}>
      <Title
        id="pricing_details"
        modifier="tertiary"
        className="k-u-margin-top-nonuple k-u-align-center"
      >
        {t('pricing_plans.faq.title')}
      </Title>
      <div className="k-u-margin-vertical-quintuple k-u-margin-bottom-decuple@s-up">
        <Accordeon closeOnClick isAnimated>
          {Array.from(Array(7)).map((_, index) => {
            return (
              <Accordeon.Item
                className="k-u-background-color-background1"
                key={index}
              >
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    lineHeight="normal"
                  >
                    {t(`pricing_plans.faq.question_${index + 1}`, {
                      parseHtml: true,
                    })}
                  </Text>
                </Accordeon.Header>

                <Accordeon.Content>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="400"
                    lineHeight="normal"
                  >
                    {t(`pricing_plans.faq.answer_${index + 1}`, {
                      parseHtml: true,
                    })}
                  </Text>
                </Accordeon.Content>
              </Accordeon.Item>
            )
          })}
        </Accordeon>
      </div>
    </div>
  )
}

export default Faq
