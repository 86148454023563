import { pxToRem, VerticalStepper } from '@kisskissbankbank/kitten'
import { useFormikContext } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'
import { scrollTo } from 'kiss/utils/animation/scroll-to'
import getOr from 'lodash/fp/getOr'
import React from 'react'
import styled from 'styled-components'

const AmountStep = (props) => {
  const t = useTranslation()
  const { errors, touched } = useFormikContext()
  const state = (() => {
    switch (true) {
      case !!touched['amount'] && !errors['amount']:
        return { success: true }
      case !!touched['amount'] && !!errors['amount']:
        return { error: true }
      case !!touched['amount']:
        return { valid: true }
      default:
        return { waiting: true }
    }
  })()

  return (
    <VerticalStepper.Step {...props} {...state}>
      <VerticalStepper.Title>
        {t('gift_card.new.stepper.amount')}
      </VerticalStepper.Title>
    </VerticalStepper.Step>
  )
}

const CustomStep = (props) => {
  const t = useTranslation()
  const { errors, touched } = useFormikContext()
  const state = (() => {
    switch (true) {
      case !!touched['from'] &&
        !!touched['to'] &&
        !!touched['message'] &&
        !errors['from'] &&
        !errors['to'] &&
        !errors['message']:
        return { success: true }
      case (!!touched['from'] && !!errors['from']) ||
        (!!touched['to'] && !!errors['to']) ||
        (!!touched['message'] && !!errors['message']):
        return { error: true }
      case !!touched['from'] || !!touched['to'] || !!touched['message']:
        return { valid: true }
      default:
        return { waiting: true }
    }
  })()

  return (
    <VerticalStepper.Step {...props} {...state}>
      <VerticalStepper.Title>
        {t('gift_card.new.stepper.custom')}
      </VerticalStepper.Title>
    </VerticalStepper.Step>
  )
}

const EmailStep = (props) => {
  const t = useTranslation()
  const { errors, touched } = useFormikContext()
  const state = (() => {
    switch (true) {
      case !!touched['email'] && !errors['email']:
        return { success: true }
      case !!touched['email'] && !!errors['email']:
        return { error: true }
      case !!touched['email']:
        return { valid: true }
      default:
        return { waiting: true }
    }
  })()

  return (
    <VerticalStepper.Step {...props} {...state}>
      <VerticalStepper.Title>
        {t('gift_card.new.stepper.email')}
      </VerticalStepper.Title>
    </VerticalStepper.Step>
  )
}

const PaymentStep = (props) => {
  const t = useTranslation()
  const { errors: globalErrors, touched: globalTouched } = useFormikContext()
  const touched = globalTouched?.card
  const errors = globalErrors?.card

  const hasErrorToShow = (name) =>
    !!getOr(false)(name)(touched) && !!getOr(false)(name)(errors)

  const state = (() => {
    switch (true) {
      case !!touched?.number &&
        !!touched?.expiry &&
        !errors?.number &&
        !errors?.expiry:
        return { success: true }
      case hasErrorToShow('number') || hasErrorToShow('expiry'):
        return { error: true }
      case !!touched?.number || touched?.expiry:
        return { valid: true }
      default:
        return { waiting: true }
    }
  })()

  return (
    <VerticalStepper.Step {...props} {...state}>
      <VerticalStepper.Title>
        {t('gift_card.new.stepper.payment')}
      </VerticalStepper.Title>
    </VerticalStepper.Step>
  )
}

const StickyStepper = styled((props) => {
  const handleClick = (section) => () => {
    scrollTo(document.getElementById(`${section}-section`), 500, {
      offset: -200,
    })
  }

  return (
    <div {...props}>
      <VerticalStepper>
        <AmountStep onClick={handleClick('amount')} role="button" />
        <CustomStep onClick={handleClick('custom')} role="button" />
        <EmailStep onClick={handleClick('email')} role="button" />
        <PaymentStep onClick={handleClick('payment')} role="button" />
      </VerticalStepper>
    </div>
  )
})`
  position: sticky;
  top: ${pxToRem(100)};

  .k-Steppers--VerticalStepper__item {
    padding-top: ${pxToRem(5)};
    padding-bottom: ${pxToRem(5)};
  }
`

export default StickyStepper
