import React from 'react'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Grid, GridCol, Marger, Title } from '@kisskissbankbank/kitten'

const SocialEdit = () => {
  const t = useTranslation()
  return (
    <div>
      <Title
        noMargin
        tag="h2"
        modifier="senary"
        className="k-u-margin-bottom-double"
      >
        {t('mentor_page.edition.informations.communities.title')}
      </Title>
      <Grid>
        <GridCol col="12" col-s="6">
          <Marger bottom="3">
            <Label htmlFor="social[websiteUrl]">
              {t('mentor.signup.form.personnal.website.label')}
            </Label>
            <SimpleText
              name="social[websiteUrl]"
              placeholder={t(
                'mentor.signup.form.personnal.website.placeholder',
              )}
            />
          </Marger>
        </GridCol>
      </Grid>
      <Grid>
        <GridCol col="12" col-s="6">
          <Marger bottom="3">
            <Label htmlFor="social[facebookUrl]">
              {t('mentor.signup.form.social.facebook.label')}
            </Label>
            <SimpleText
              name="social[facebookUrl]"
              placeholder={t('mentor.signup.form.social.facebook.placeholder')}
            />
          </Marger>
          <Marger bottom="3">
            <Label htmlFor="social[instagramUrl]">
              {t('mentor.signup.form.social.instagram.label')}
            </Label>
            <SimpleText
              name="social[instagramUrl]"
              placeholder={t('mentor.signup.form.social.instagram.placeholder')}
            />
          </Marger>
        </GridCol>
        <GridCol col="12" col-s="6">
          <Marger bottom="3">
            <Label htmlFor="social[linkedInUrl]">
              {t('mentor.signup.form.social.linkedin.label')}
            </Label>
            <SimpleText
              name="social[linkedInUrl]"
              placeholder={t('mentor.signup.form.social.linkedin.placeholder')}
            />
          </Marger>
          <Marger bottom="3">
            <Label htmlFor="social[twitterUrl]">
              {t('mentor.signup.form.social.twitter.label')}
            </Label>
            <SimpleText
              name="social[twitterUrl]"
              placeholder={t('mentor.signup.form.social.twitter.placeholder')}
            />
          </Marger>
        </GridCol>
      </Grid>
    </div>
  )
}

export default SocialEdit
