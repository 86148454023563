const COLORS_NUMBER = 14

export const getDefaultAvatar = ({
  username = '',
  id = '0',
  random = false,
}) => {
  const sanitized_id = random
    ? Math.floor(Math.random() * COLORS_NUMBER)
    : parseInt(id.split('-'), 16) % COLORS_NUMBER

  const initial = !username ? ' ' : username.match(/./gu)[0].toUpperCase()

  return `https://d3h6oub88y2tly.cloudfront.net/default-avatar/${sanitized_id}/${encodeURIComponent(
    initial,
  )}/avatar.svg`
}
