import React from 'react'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { useTranslation } from 'kiss/hooks/use-translation'

const Name = () => {
  const t = useTranslation()
  return (
    <>
      <Label htmlFor="title">
        {t('mentor.signup.form.personnal.name.label')}
      </Label>
      <SimpleText
        name="title"
        placeholder={t('mentor.signup.form.personnal.name.placeholder')}
      />
    </>
  )
}

export default Name
