import ProjectsLocationAutocomplete from 'kiss/components/fields/projects-location-autocomplete'
import { useTranslation } from 'kiss/hooks/use-translation'
import qs from 'qs'
import React from 'react'
import { useLocation } from 'react-router-dom'
import useBrowsing from '../hooks/use-browsing'

const ENTER_KEYCODE = 13

const Location = (props) => {
  const t = useTranslation()
  const { search } = useLocation()
  const { updateSearch } = useBrowsing()
  const { location } = qs.parse(search, { ignoreQueryPrefix: true })
  const defaultValue = location || ''

  return (
    <ProjectsLocationAutocomplete
      {...props}
      size="small"
      iconPosition="left"
      placeholder={t('browsing.localisation.placeholder')}
      onSelect={(location) => updateSearch({ location })}
      onKeyDown={({ keyCode, value }) => {
        if (keyCode === ENTER_KEYCODE && value === '') {
          updateSearch({ location: '' })
        }
      }}
      defaultValue={defaultValue}
    />
  )
}

export default Location
