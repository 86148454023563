import { mq, pxToRem, Text } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { useTranslation } from 'kiss/hooks/use-translation'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useMediaQuery } from '../../../../../../media-queries/redux'
import MobileFilter from './mobile-filter'

const StyledFilterWrapper = styled.div`
  overflow-y: scroll;
  margin-top: ${pxToRem(20)};
  position: absolute;
  z-index: ${Z_INDEX.global.filterModal};
  max-height: ${pxToRem(540)};
  padding: ${pxToRem(30)};
  left: 0;
  border-radius: var(--border-radius-m);
  background: var(--color-grey-000);
  box-shadow: rgba(0, 0, 0, 0.2) 0px ${pxToRem(10)} ${pxToRem(20)} 0px;

  .kiss-FilterWrapper__actions {
    display: flex;
    justify-content: space-between;
    margin-top: ${pxToRem(30)};
  }

  @media ${mq.tablet} {
    min-width: ${pxToRem(500)};
  }
`

const Filter = ({
  children,
  values,
  defaultValues,
  mobileHeaderLabel,
  mobileFooterActionLabel,
  onFilterClose,
  className,
}) => {
  const t = useTranslation()
  const history = useHistory()
  const { search } = useLocation()
  const { viewportIsSOrLess } = useMediaQuery()
  const searchParams = qs.parse(search, {
    ignoreQueryPrefix: true,
    arrayLimit: 100,
  })
  const filterRef = useRef(null)

  useEffect(() => {
    if (!filterRef.current) return
    const filterContainer = filterRef.current.querySelector(
      '.kiss-FilterWrapper',
    )
    filterContainer.classList.remove('kiss-FilterWrapper--isInvisible')
  }, [])

  const KEYCODE_ESC = 27

  const handleEscKeypress = (e) => {
    if (e.key === 'Escape' || e.keyCode === KEYCODE_ESC) {
      onFilterClose()
    }
  }
  const handleDocumentClick = (e) => {
    if (e.target?.closest('button')?.classList.contains('kiss-FilterButton'))
      return
    if (!filterRef.current) return
    if (filterRef.current.contains(e.target)) return

    onFilterClose()
  }

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick, { capture: true })
    document.addEventListener('keydown', handleEscKeypress, { capture: true })

    return () => {
      document.removeEventListener('click', handleDocumentClick, {
        capture: true,
      })
      document.removeEventListener('keydown', handleEscKeypress, {
        capture: true,
      })
    }
  }, [])

  const updateSearchParams = (values) => {
    const newSearchParams = { ...searchParams, ...values }
    const searchString = qs.stringify(newSearchParams)

    history.push({ search: searchString })
    onFilterClose()
  }

  if (viewportIsSOrLess) {
    return (
      <MobileFilter
        headerLabel={mobileHeaderLabel}
        footerActionLabel={mobileFooterActionLabel}
        onClose={onFilterClose}
        onReset={() => updateSearchParams(defaultValues)}
        onApply={() => updateSearchParams(values)}
      >
        {children}
      </MobileFilter>
    )
  }

  return (
    <div ref={filterRef}>
      <StyledFilterWrapper
        className={classNames(
          'kiss-FilterWrapper kiss-FilterWrapper--isInvisible',
          className,
        )}
      >
        {children}

        <div className="kiss-FilterWrapper__actions">
          <Text
            tag="button"
            color="font1"
            size="small"
            weight="500"
            onClick={() => updateSearchParams(defaultValues)}
            className="k-u-reset-button k-u-cursor-pointer"
          >
            {t('project.news.filters.filter.reset')}
          </Text>

          <Text
            tag="button"
            color="primary1"
            size="small"
            weight="500"
            onClick={() => updateSearchParams(values)}
            className="k-u-reset-button k-u-cursor-pointer"
          >
            {t('project.news.filters.filter.apply')}
          </Text>
        </div>
      </StyledFilterWrapper>
    </div>
  )
}

Filter.propTypes = {
  values: PropTypes.object.isRequired,
  defaultValues: PropTypes.object.isRequired,
  mobileHeaderLabel: PropTypes.node,
  mobileFooterActionLabel: PropTypes.node,
  onFilterClose: PropTypes.func,
}

Filter.defaultProps = {
  mobileHeaderLabel: null,
  mobileFooterActionLabel: null,
  onFilterClose: () => {},
}

export default Filter
