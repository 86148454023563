import React from 'react'
import CategoriesForm from 'kiss/components/formik/categories'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Marger, Title } from '@kisskissbankbank/kitten'

const Categories = () => {
  const t = useTranslation()
  return (
    <Marger top="8" bottom="8">
      <Title modifier="quinary" className="k-u-margin-bottom-triple">
        {t('mentor.signup.form.categories.title', { parseHtml: true })}
      </Title>
      <CategoriesForm name="categorySlugs" />
    </Marger>
  )
}

export default Categories
