import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  Container,
  Grid,
  GridCol,
  Title,
  Text,
  Accordeon,
  pxToRem,
  stepToRem,
  ScreenConfig,
} from '@kisskissbankbank/kitten'

const StyledFaq = styled(Container)`
  .kiss-GiftCard-Faq__text {
    line-height: 1.5;
    font-size: ${stepToRem(-1)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      font-size: ${stepToRem(0)};
    }
  }
`

const Faq = () => {
  const t = useTranslation()

  return (
    <StyledFaq>
      <Grid>
        <GridCol col-s="10" offset-s="1" col-l="8" offset-l="2">
          <Title
            tag="h3"
            modifier="quinary"
            className="k-u-margin-top-quintuple@s-up k-u-margin-top-decuple@l-up k-u-margin-bottom-triple"
          >
            {t('gift_card.faq.title', { parseHtml: true })}
          </Title>

          <div className="k-u-margin-bottom-quintuple k-u-margin-bottom-decuple@s-up">
            <Accordeon closeOnClick isAnimated>
              <Accordeon.Item>
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.question_1', { parseHtml: true })}
                  </Text>
                </Accordeon.Header>

                <Accordeon.Content>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="400"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.answer_1', { parseHtml: true })}
                  </Text>
                </Accordeon.Content>
              </Accordeon.Item>

              <Accordeon.Item>
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.question_2', { parseHtml: true })}
                  </Text>
                </Accordeon.Header>

                <Accordeon.Content>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="400"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.answer_2', { parseHtml: true })}
                  </Text>
                </Accordeon.Content>
              </Accordeon.Item>

              <Accordeon.Item>
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.question_3', { parseHtml: true })}
                  </Text>
                </Accordeon.Header>

                <Accordeon.Content>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="400"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.answer_3', { parseHtml: true })}
                  </Text>
                </Accordeon.Content>
              </Accordeon.Item>

              <Accordeon.Item>
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.question_4', { parseHtml: true })}
                  </Text>
                </Accordeon.Header>

                <Accordeon.Content>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="400"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.answer_4', { parseHtml: true })}
                  </Text>
                </Accordeon.Content>
              </Accordeon.Item>

              <Accordeon.Item>
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.question_5', { parseHtml: true })}
                  </Text>
                </Accordeon.Header>

                <Accordeon.Content>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="400"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.answer_5', { parseHtml: true })}
                  </Text>
                </Accordeon.Content>
              </Accordeon.Item>

              <Accordeon.Item>
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.question_6', { parseHtml: true })}
                  </Text>
                </Accordeon.Header>

                <Accordeon.Content>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="400"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.answer_6', { parseHtml: true })}
                  </Text>
                </Accordeon.Content>
              </Accordeon.Item>

              <Accordeon.Item>
                <Accordeon.Header>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="500"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.question_7', { parseHtml: true })}
                  </Text>
                </Accordeon.Header>

                <Accordeon.Content>
                  <Text
                    tag="p"
                    color="font1"
                    size="medium"
                    weight="400"
                    className="kiss-GiftCard-Faq__text"
                  >
                    {t('gift_card.faq.answer_7', { parseHtml: true })}
                  </Text>
                </Accordeon.Content>
              </Accordeon.Item>
            </Accordeon>
          </div>
        </GridCol>
      </Grid>
    </StyledFaq>
  )
}

export default Faq
