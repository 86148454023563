import React from 'react'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import UnderHero from './components/under-hero'
import Hero from './components/hero'
import Engagements from './components/engagements'
import Discrimination from './components/discrimination'
import Protect from './components/protect'
import Defense from './components/defense'
import { Helmet } from 'react-helmet'

const Manifesto = () => {
  const t = useTranslation()
  return (
    <DefaultLayout>
      <Helmet title={t('manifesto.seo.title')} />
      <Hero />
      <UnderHero />
      <Engagements />
      <Discrimination />
      <Protect />
      <Defense />
    </DefaultLayout>
  )
}

export default Manifesto
