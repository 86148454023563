import React from 'react'
import styled from 'styled-components'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { ChattingIllustration } from 'kiss/components/images/chatting-illustration'
import {
  Container,
  Grid,
  GridCol,
  Marger,
  HEADER_HEIGHT,
  pxToRem,
  ScreenConfig,
} from '@kisskissbankbank/kitten'

const AuthenticateLayout = ({ children }) => {
  return (
    <DefaultLayout displayFooter={false}>
      <BackgroundContainer>
        <Container>
          <Grid>
            <GridColStyled
              offset-s="2"
              col-s="8"
              offset-m="3"
              col-m="6"
              offset-l="1"
              col-l="4"
              col-xl="3"
            >
              <Marger
                top={{ default: 5, fromM: 8 }}
                bottom={{ default: 5, fromM: 8 }}
              >
                {children}
              </Marger>
            </GridColStyled>
          </Grid>
        </Container>

        <StyledChattingIllustration className="k-u-hidden@m-down" />
      </BackgroundContainer>
    </DefaultLayout>
  )
}

const BackgroundContainer = styled.div`
  @media (min-width: ${ScreenConfig.XL.min}px) {
    background: linear-gradient(
      to left,
      rgba(202, 244, 254, 0.3) 0%,
      rgba(202, 244, 254, 0.3) 58%,
      white 50%
    );
  }

  @media (min-width: ${ScreenConfig.L.min}px) and (max-width: ${ScreenConfig.L
      .max}px) {
    background: linear-gradient(
      to left,
      rgba(202, 244, 254, 0.3) 0%,
      rgba(202, 244, 254, 0.3) 50%,
      white 50%
    );
    flex: auto;
  }
`

const GridColStyled = styled(GridCol)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* Screen height minus header height*/
  min-height: calc(100vh - ${pxToRem(HEADER_HEIGHT)});
`

const StyledChattingIllustration = styled(ChattingIllustration)`
  bottom: 0;
  right: 0;
  position: fixed;
  max-height: 90vh;
  max-width: 45vw;
  height: auto;
`

export default AuthenticateLayout
