import {
  parseHtml,
  pxToRem,
  ScreenConfig,
  Title,
} from '@kisskissbankbank/kitten'
import {
  Controls,
  Displayer,
  Playground,
  Title as TitleEditor,
} from '@kisskissbankbank/underwood'
import { useField } from 'formik'
import { EditorImage, EditorWrapper } from 'kiss/components/editor'
import { useTranslation } from 'kiss/hooks/use-translation'
import { MENTOR_PAGE_EDITION } from 'kiss/routes/redux'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import debounce from 'lodash/fp/debounce'
import React, { useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import config from '../../../config'
import { getDescription, getTitle } from '../redux'

const StickyControls = styled.div`
  position: sticky;
  z-index: ${Z_INDEX.global.aboveHeader};
  top: ${pxToRem(84)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    top: ${pxToRem(104)};
  }
`

const Description = () => {
  const description = useSelector(getDescription)
  const title = useSelector(getTitle)
  const [tooblarDisabled, disableToolbar] = useState(false)
  const [, titleMeta, titleHelpers] = useField('title')
  const [, descriptionMeta, descriptionHelpers] = useField('description')
  const debouncedDraftValueChange = useCallback(
    debounce(500)((rawValue) => {
      descriptionHelpers.setValue(rawValue)
    }),
    [],
  )
  const t = useTranslation()

  return (
    <Switch>
      <Route path={MENTOR_PAGE_EDITION}>
        <EditorWrapper>
          <StickyControls>
            <div className="k-u-margin-top-single">
              <Controls whiteMode disabled={tooblarDisabled}>
                <Controls.Group>
                  <Controls.TagList
                    tags={['header-two', 'header-three', 'unstyled']}
                  />
                </Controls.Group>
                <Controls.Group>
                  <Controls.Bold />
                  <Controls.Italic />
                  <Controls.Quote />
                </Controls.Group>
                <Controls.Group>
                  <Controls.UnorderedList />
                </Controls.Group>
                <Controls.Group>
                  <Controls.Left />
                  <Controls.Center />
                  <Controls.Right />
                </Controls.Group>
                <Controls.Group>
                  <EditorImage />
                  <Controls.Video embedlyApiKey={config[APP_ENV].embedly.key} />
                  <Controls.Link />
                  <Controls.Button />
                </Controls.Group>
              </Controls>
            </div>
          </StickyControls>

          <div className="k-u-margin-top-quadruple k-u-margin-top-octuple@s-up k-u-margin-bottom-singleHalf k-u-margin-bottom-double@s-up">
            <TitleEditor
              initialValue={titleMeta.initialValue}
              onFocus={() => disableToolbar(true)}
              onBlur={() => disableToolbar(false)}
              onChange={(value) => titleHelpers.setValue(value)}
            />
          </div>

          <Playground
            initialValue={descriptionMeta.initialValue}
            placeholder={t('mentor_page.edition.description.placeholder')}
            onChange={(value) => debouncedDraftValueChange(value)}
            useRichTextStyle
            rawValue
            withoutBorder
          />
        </EditorWrapper>
      </Route>
      <Route
        render={() => {
          if (!description && !title) return null
          return (
            <div className="k-u-margin-top-quintuple k-u-margin-top-octuple@s-up">
              {title && (
                <div className="k-u-margin-bottom-singleHalf k-u-margin-bottom-double@s-up">
                  <Title
                    tag="h2"
                    modifier="secondary"
                    noMargin
                    style={{ wordWrap: 'break-word' }}
                  >
                    {parseHtml(title)}
                  </Title>
                </div>
              )}
              {description && <Displayer useRichTextStyle text={description} />}
            </div>
          )
        }}
      />
    </Switch>
  )
}

export default Description
