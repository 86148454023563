import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  useLazyQuery as useApolloLazyQuery,
  useMutation as useApolloMutation,
  useQuery as useApolloQuery,
} from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'
import { domElementHelper } from '@kisskissbankbank/kitten'
import { onError } from 'apollo-link-error'
import { createUploadLink } from 'apollo-upload-client'
import Cookies from 'js-cookie'
import { sentryCaptureException } from 'kiss/app/sentry'
import { getAuthenticityToken } from 'kiss/session/redux'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import merge from 'lodash/fp/merge'
import path from 'ramda/src/path'
import { useSelector } from 'react-redux'

class GraphqlError extends Error {
  constructor(cause) {
    super()
    this.name = flow(first, getOr('No message detected!')('message'))(cause)
    this.message = cause
  }
}

const authLink = new ApolloLink((operation, forward) => {
  // Retrieve the authorization token from cookies
  const authenticityToken = Cookies.get('authenticity_token')

  // Use the setContext method to set the HTTP headers
  operation.setContext({
    headers: {
      'X-CSRF-Token': authenticityToken || '',
    },
  })

  // Call the next link in the middleware chain
  return forward(operation)
})

export const client =
  domElementHelper.canUseDom() &&
  new ApolloClient({
    link: ApolloLink.from([
      onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ message, code, path }) =>
            console.error(
              `[GraphQL error]: Code: ${code}, Message: ${message}, Path: ${path}`,
            ),
          )
        }

        if (networkError) console.error(`[Network error]: ${networkError}`)
      }),
      authLink,
      createUploadLink({
        credentials: 'same-origin',
      }),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Project: {
          fields: {
            image: {
              merge: true,
            },
          },
        },
        User: {
          fields: {
            ordersConnection: relayStylePagination(),
            subscriptionsConnection: relayStylePagination(),
            favoriteProjects: relayStylePagination(),
          },
        },
      },
    }),
  })

const query = (
  q,
  variables = {},
  state,
  moreOptions = {},
  logBreadcrumb = {},
) => {
  if (!domElementHelper.canUseDom()) {
    return new Promise((resolve) => resolve({}))
  }

  const authenticityToken = getAuthenticityToken(state)
  const headers = {
    'X-CSRF-Token': authenticityToken,
  }

  const options = {
    variables,
    context: {
      headers,
    },
    ...moreOptions,
  }

  const request =
    path(['definitions', 0, 'operation'])(q) === 'mutation'
      ? client.mutate({
          mutation: q,
          ...options,
        })
      : client.query({
          query: q,
          ...options,
        })

  return request
    .then(({ data, errors }) => {
      // API errors.
      if (errors && !isEmpty(errors)) {
        return Promise.reject(errors)
      }

      return data
    })
    .catch((errors) => {
      if (errors.graphQLErrors) {
        sentryCaptureException(
          new GraphqlError(errors.graphQLErrors),
          merge({
            tags: {
              query: 'graphql-errors',
            },
          })(logBreadcrumb),
        )

        return Promise.reject(errors.graphQLErrors)
      }

      sentryCaptureException(
        errors,
        merge({
          tags: {
            query: 'api-errors',
          },
        })(logBreadcrumb),
      )

      return Promise.reject(errors)
    })
}

export const useQuery = (query, variables = {}, options = {}) => {
  if (!client) return {}

  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useApolloQuery(query, {
    variables,
    client,
    context: {
      headers: {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        'X-CSRF-Token': useSelector(getAuthenticityToken),
      },
    },
    ...options,
  })
}

export const useLazyQuery = (query, options = {}) => {
  if (!client) return [() => {}, {}]

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authenticityToken = useSelector(getAuthenticityToken)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useApolloLazyQuery(query, {
    query,
    client,
    context: {
      headers: {
        'X-CSRF-Token': authenticityToken,
      },
    },
    ssr: 'false',
    partialRefetch: true,
    ...options,
  })
}

export const useMutation = (query, variables = {}, options = {}) => {
  if (!client) return [() => {}, {}]

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const authenticityToken = useSelector(getAuthenticityToken)
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useApolloMutation(query, {
    variables,
    client,
    context: {
      headers: {
        'X-CSRF-Token': authenticityToken,
      },
    },
    ...options,
  })
}

export const useImperativeQuery = (query) => {
  const { refetch } = useQuery(query, {}, { skip: true })
  const imperativelyCallQuery = (variables) => {
    return refetch(variables)
  }

  return imperativelyCallQuery
}

export default query
