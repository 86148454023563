import React from 'react'

import Proptypes from 'prop-types'

import classNames from 'classnames'

import { Baseline, Ecosystem, ListItems, Partners, Legal } from './components'
import { CreatedWith } from './components/created-with'

import { StyledFooter } from './styles'
import styled from 'styled-components'

const StyledFooterContainer = styled.div`
  @media (min-width: 1440px) {
    width: 1360px;
    margin: auto;
  }
`

const Footer = ({ minimalist, keepTopMargin }) => {
  if (minimalist) {
    return (
      <StyledFooter
        className={classNames('k-u-padding-top-none', {
          'k-u-margin-top-quintuple k-u-margin-top-decuple@s-up': keepTopMargin,
        })}
      >
        <CreatedWith />
        <Partners />

        <Legal />
      </StyledFooter>
    )
  }
  return (
    <StyledFooter
      className={
        keepTopMargin && 'k-u-margin-top-quintuple k-u-margin-top-decuple@s-up'
      }
    >
      <StyledFooterContainer>
        <Baseline />

        <div>
          <ListItems />
        </div>

        <div className="k-u-margin-top-double">
          <Ecosystem />
        </div>

        <Partners />

        <Legal />
      </StyledFooterContainer>
    </StyledFooter>
  )
}

Footer.Proptypes = {
  keepTopMargin: Proptypes.bool,
  minimalist: Proptypes.bool,
}

Footer.defaultProps = {
  keepTopMargin: false,
  minimalist: false,
}
export default Footer
