import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import Checkbox from 'kiss/components/formik/checkbox'
import RgpdModal from 'kiss/components/modals/rgpd'
import { getRouteFor, CGU } from 'kiss/routes/redux'

const Terms = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <>
      <Checkbox
        name="acceptedTerms"
        className="k-u-margin-bottom-triple"
        validate={(value) => {
          if (value) return
          return t(
            'authenticate.registration.terms_of_service_and_payment.messages.errors.required',
          )
        }}
      >
        {t('gift_card.new.form.cgu', {
          terms_url: routeFor(CGU),
          parseHtml: true,
        })}
      </Checkbox>

      <div className="k-u-margin-bottom-double">
        <RgpdModal />
      </div>
    </>
  )
}

export default Terms
