import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import { useTranslation } from 'kiss/hooks/use-translation'
import getOr from 'lodash/fp/getOr'
import { useLazyQuery } from 'kiss/api/graphql-query'
import PageInfosQuery from 'kiss/graphql/queries/mentor/page_infos.graphql'
import { Button, Loader } from '@kisskissbankbank/kitten'
import { addErrorAlert, removeErrorAlerts } from 'kiss/app/alerts/redux'
import { updateMentoredProjects } from '../redux'

const infoPath = 'user.mentoredProjects.pageInfo'

const Pagination = () => {
  const { mentor } = useParams()
  const dispatch = useDispatch()
  const t = useTranslation()
  const [isLoading, loading] = useState(false)
  const [updatePagination, { data, refetch }] = useLazyQuery(PageInfosQuery, {
    variables: { slug: mentor },
  })
  const endCursor = getOr(null)(`${infoPath}.endCursor`)(data)
  const hasNextPage = getOr(false)(`${infoPath}.hasNextPage`)(data)
  useEffect(() => {
    updatePagination()
  }, [])

  return hasNextPage ? (
    <div className="k-u-margin-top-quintuple k-u-margin-bottom-triple k-u-align-center">
      <Button
        modifier="helium"
        size="large"
        disabled={isLoading}
        onClick={async () => {
          try {
            loading(true)
            dispatch(removeErrorAlerts())
            await dispatch(
              updateMentoredProjects({
                after: endCursor,
              }),
            )
            await refetch({ after: endCursor })
          } catch (e) {
            dispatch(addErrorAlert(t('app.server.error', { scroll: true })))
          } finally {
            loading(false)
          }
        }}
      >
        {isLoading ? <Loader /> : t('app.button.show_more')}
      </Button>
    </div>
  ) : null
}

export default Pagination
