import styled, { css } from 'styled-components'
import { pxToRem, TagButton } from '@kisskissbankbank/kitten'

export default styled(TagButton)`
  margin: ${pxToRem(5)};
  ${(props) =>
    props.disabled &&
    css`
      color: var(--color-grey-000);
      border-color: var(--color-grey-400);
      background-color: var(--color-grey-400);
      &:hover {
        color: var(--color-grey-000);
        border-color: var(--color-grey-400);
        background-color: var(--color-grey-400);
      }
    `}
`
