import React from 'react'

import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import {
  Button,
  pxToRem,
  ScreenConfig,
  TitleWithStroke,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.1);
  padding: ${pxToRem(20)};
  gap: ${pxToRem(16)};

  img {
    height: ${pxToRem(120)};
    object-fit: contain;
    width: 100%;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    flex-direction: column;
    min-width: ${pxToRem(240)};
    padding: ${pxToRem(30)};
    min-height: ${pxToRem(800)};
    flex: 1;
  }
`

const StyledCardBoldParagraph = styled.p`
  font-family: 'GeneralSans';
  font-size: ${pxToRem(16)};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  margin-top: ${pxToRem(16)};

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin-top: ${pxToRem(16)};
    font-size: ${pxToRem(20)};
    line-height: 30px;
    font-weight: 600;
  }
`

const StyledButtonContainer = styled.div`
  margin-top: auto;

  a {
    width: 100%;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    a {
      width: auto;
    }
  }
`

const ServiceCard = ({
  imagePath,
  title,
  paragraph,
  buttonTitle,
  buttonLink,
  children,
}) => {
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledCard>
      <div className="k-u-alignSelf-center">
        <img src={assetFor(imagePath)} alt={imagePath} />
      </div>
      <div>
        <TitleWithStroke
          tag="h1"
          align="left"
          modifier="quaternary"
          noMargin
          family="antiqueolive"
          className="k-u-font-weight-700 k-u-margin-top-singleHalf"
        >
          <span className="k-u-font-size-7">{title}</span>
        </TitleWithStroke>
        <StyledCardBoldParagraph>{paragraph}</StyledCardBoldParagraph>
      </div>
      {children}
      <StyledButtonContainer>
        <Button
          size="small"
          fit="content"
          as="a"
          mobileFit="fluid"
          href={buttonLink}
          target="_blank"
          modifier="helium"
        >
          {buttonTitle}
        </Button>
      </StyledButtonContainer>
    </StyledCard>
  )
}

ServiceCard.propTypes = {
  imagePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default ServiceCard
