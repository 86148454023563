import {
  CONTAINER_PADDING,
  CONTAINER_PADDING_THIN,
  GUTTER,
  HEADER_HEIGHT,
  pxToRem,
  ScreenConfig,
  stepToRem,
  TYPOGRAPHY,
} from '@kisskissbankbank/kitten'
import { createGlobalStyle, css } from 'styled-components'

const NUMBER_ANIM_DELAY = 0.03
const NUMBER_ANIM_TRANSITION_DURATION = '0.36s'
const MAX_DIGITS = 13

const oneColumnPlusPadding = `calc(((100vw - ${pxToRem(
  CONTAINER_PADDING,
)}) / 12) + ${pxToRem(CONTAINER_PADDING)})`

export const StatsPageStyles = createGlobalStyle`
  :root {
    --AnimatedNumbers-transition-delay: 0s;
    --TitleAndIntro-scale-factor-start: 1;
    --TitleAndIntro-scale-factor-end: 1;
  }

  .stats__LayoutBase {
    display: flex;
    flex-direction: column;
  }

  main#main #alerts, footer#footer {
    position: relative;
    z-index: 1;
  }

  div.stats__DarkBackground {
    color: var(--color-grey-000);
    position: relative;
    background-color: var(--color-grey-900);

    &::after {
      transition: opacity .8s;
      will-change: opacity;
      position: absolute;
      background-color: var(--color-grey-900);
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .stats__StyledImages {
      z-index: 0;
      pointer-events: none;
      transform: rotate(-10deg);
    }

    .StyledImages__StyledImage {
      transition: transform 1.3s;
      will-change: transform;

      transform-origin: 50% 50%;
      position: absolute;

      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      img {
        object-fit: scale-down;
        object-position: 50% 50%;
        display: block;
        width: 100%;
        height: 100%;
      }

      transform: scale(calc(var(--TitleAndIntro-scale-factor-end)) * 1.8);
      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        transform: scale(var(--TitleAndIntro-scale-factor-end));
      }
    }

    /* Modifiers */
    &.DarkBackground--is_ready {
      &::after {
        content: "";
      }

      .stats__StyledImages {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .stats__LayoutBase:not(.stats--not_animated) &.DarkBackground--visible_element_start {
      &::after {
        opacity: 0;
      }

      .StyledImages__StyledImage {
        transform: scale(calc(var(--TitleAndIntro-scale-factor-start)) * 1.8);
        @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
          transform: scale(var(--TitleAndIntro-scale-factor-start));
        }
      }

      .TitleAndIntro__IntroSection {
        opacity: 0;
      }
    }

    .stats__LayoutBase.stats--not_animated &.stats__TitleAndIntro,
    &.DarkBackground--visible_element_before,
    &.DarkBackground--visible_element_target {
      &::after {
        opacity: 0.8;
      }

      .TitleAndIntro__IntroSection {
        opacity: 1;
      }
    }

    &.DarkBackground--visible_element_after {
      &::after {
        opacity: 1;
      }

      .TitleAndIntro__IntroSection {
        opacity: 1;
      }
    }

    &.DarkBackground--visible_element_end {
      &::after {
        opacity: 1;
      }

      .stats__StyledImages, .TitleAndIntro__IntroSection {
        opacity: 0;
      }
    }
  }

  // TitleAndIntro
  section.stats__TitleAndIntro {
    position: relative;
    z-index: 1;

    .TitleAndIntro__section {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 1;
      position: relative;
    }

    .TitleAndIntro__TitleSection {
      min-height: calc(105vh - ${pxToRem(HEADER_HEIGHT)});
      text-align: center;

      @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
        padding-bottom: calc(5vh + ${pxToRem(HEADER_HEIGHT)});
      }
    }

    .TitleAndIntro__IntroSection {
      padding-top: 6rem;
      padding-bottom: 6rem;

      transition: opacity 0.5s;
      will-change: opacity;

      @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
        min-height: calc(105vh - ${pxToRem(HEADER_HEIGHT)});
        transition-delay: 0.3s;
      }
    }

    .TitleAndIntro__Title {
      color: var(--color-grey-000) !important;
    }
  }

  // AnimatedNumbers
  section.stats__AnimatedNumbers {
    position: relative;
    z-index: 1;
    box-sizing: border-box;

    padding: 0 0 ${pxToRem(30)};

    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;

    @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
      padding: 0 0 ${pxToRem(60)};
    }
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      padding: ${pxToRem(100)} 0 ${pxToRem(50)};
    }


    .AnimatedNumbers__AnimatedBox {
      margin: ${pxToRem(40 / 2)} 2vw;
      flex-basis: calc(50% - 4vw);

      * {
        --AnimatedNumbers-transition-delay: inherit;
      }

      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        margin-top: ${pxToRem(80 / 2)};
        margin-bottom: ${pxToRem(80 / 2)};
      }
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        margin-top: ${pxToRem(100 / 2)};
        margin-bottom: ${pxToRem(100 / 2)};
      }
    }

    .AnimatedNumbers__NumberBox {
      overflow-y: hidden;

      display: flex;
      align-items: center;
      justify-content: center;

      white-space: nowrap;
    }

    .AnimatedBox--hasUnit .AnimatedNumbers__NumberBox {
      padding-left: calc(${pxToRem(10)} + 1rem);
      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        padding-left: calc(${pxToRem(10)} + 2rem);
      }
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        padding-left: calc(${pxToRem(16)} + 3rem);
      }
    }

    .AnimatedNumbers__NumberSpan {
      display: inline-block;
      ${TYPOGRAPHY.fontStyles['600']}
      font-style: italic;
      font-size: ${stepToRem(6)};

      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        font-size: ${stepToRem(9)};
      }
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        font-size: ${stepToRem(16)};
      }

      line-height: 1;

      transition: transform ${NUMBER_ANIM_TRANSITION_DURATION} ease-in-out;
      will-change: transform;
      transform: translateY(100%);
    }

    .AnimatedNumbers__UnitSpan {
      margin-left: ${pxToRem(10)};
      margin-top: 0.3em;
      ${TYPOGRAPHY.fontStyles['600']}
      font-style: italic;
      font-size: ${stepToRem(0)};
      width: 1rem;

      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        margin-left: ${pxToRem(10)};
        font-size: ${stepToRem(3)};
        width: 2rem;
      }
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        margin-left: ${pxToRem(16)};
        font-size: ${stepToRem(9)};
        width: 3rem;
      }

      transition: opacity ${NUMBER_ANIM_TRANSITION_DURATION} ease;
      will-change: opacity;
      opacity: 0;
    }

    .AnimatedNumbers__HorizontalStroke {
      display: none;

      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        display: block;
        margin: ${pxToRem(15)} auto ${pxToRem(15)};
      }
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        margin: ${pxToRem(30)} auto ${pxToRem(20)};
      }

      width: ${pxToRem(30)};
      background-color: var(--color-grey-000);
      z-index: 1;

      transition: opacity ${NUMBER_ANIM_TRANSITION_DURATION} ease;
      will-change: opacity;
      opacity: 0;
    }

    .AnimatedNumbers__LegendBox {
      display: block;
      margin-top: ${pxToRem(15)};

      ${TYPOGRAPHY.fontStyles['400']}
      font-size: ${stepToRem(-1)};

      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        font-size: ${stepToRem(0)};
      }
      text-align: center;

      transition: transform ${NUMBER_ANIM_TRANSITION_DURATION} ease-in-out, opacity ${NUMBER_ANIM_TRANSITION_DURATION} ease;
      will-change: transform, opacity;

      opacity: 0;
      transform: translateY(${pxToRem(25)});
    }

    /* Modifiers */
    .AnimatedBox--is_large {
      flex-basis: 100%;

      .AnimatedNumbers__NumberSpan {
        font-size: ${stepToRem(9)};

        @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
          font-size: ${stepToRem(12)};
        }
        @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
          font-size: ${stepToRem(19)};
        }
      }
      .AnimatedNumbers__UnitSpan {
        font-size: ${stepToRem(2)};

        @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
          margin-left: ${pxToRem(20)};
          font-size: ${stepToRem(6)};
        }
        @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
          margin-left: ${pxToRem(40)};
          font-size: ${stepToRem(11)};
        }
      }
    }

    .stats--not_animated & .AnimatedNumbers__AnimatedBox,
    .AnimatedBox--visible_element_onScreen {
      .AnimatedNumbers__NumberSpan,
      .AnimatedNumbers__LegendBox {
        transform: translateY(0);
      }
      .AnimatedNumbers__NumberBox {
        ${() => {
          let rules = ''
          for (let i = 0; i < MAX_DIGITS; i++) {
            rules += `& :nth-child(${
              i + 1
            }) { transition-delay: calc(var(--AnimatedNumbers-transition-delay) + ${
              i * NUMBER_ANIM_DELAY
            }s);}`
          }
          return css`
            ${rules}
          `
        }}
      }

      .AnimatedNumbers__UnitSpan,
      .AnimatedNumbers__HorizontalStroke,
      .AnimatedNumbers__LegendBox {
        opacity: 1;
      }

      .AnimatedNumbers__HorizontalStroke,
      .AnimatedNumbers__LegendBox {
        transition-delay:
          var(--AnimatedNumbers-transition-delay),
          var(--AnimatedNumbers-transition-delay);
      }
    }
  }

  // Tables
  section.stats__Tables {
    background-color: var(--color-grey-000);
    z-index: 10;
    padding: ${pxToRem(50)} 0;

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      padding: ${pxToRem(100)} 0;
    }

    .Table__Title {
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        text-align: right;
      }
    }

    .Table__HorizontalStroke {
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        float: right;
      }
    }

    .Table_Paragraph {
      font-size: ${stepToRem(0)};
      line-height: 1.6;

      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        font-size: ${stepToRem(1)};
      }

      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        font-size: ${stepToRem(2)};
      }
    }

    .Table__Button {
      @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
        width: 100%;
        min-height: ${pxToRem(50)};
      }
    }

    .Table__TableMarger {
      @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
        margin-right: calc(-1 * ${oneColumnPlusPadding});
      }

      @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
        margin-right: ${pxToRem(-CONTAINER_PADDING_THIN)};
      }
    }

    .DoubleEntryTable__TableContainer {
      background-image: none;
    }

    .Table__DoubleEntryTable {
      border-collapse: inherit;
      table-layout: fixed;

      .DoubleEntryTable__Column--HeaderCol {
        @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
          min-width: ${pxToRem(134)};
        }
      }

      .DoubleEntryTable__Column--TitleCol {
        min-width: ${pxToRem(120)};

        @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
          min-width: ${pxToRem(240)};
          white-space: nowrap;
        }
      }

      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        width: 100%;
      }

      @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
        min-width: calc(100% - ${oneColumnPlusPadding});
        padding-right: ${oneColumnPlusPadding};
      }

      @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
        min-width: calc(100% - ${pxToRem(CONTAINER_PADDING_THIN)});
        padding-right: ${pxToRem(CONTAINER_PADDING_THIN)};
      }
    }

    .Table__Footnote {
      font-size: ${stepToRem(-2)};
      line-height: 1.5;
      display: block;

      @media (min-width: ${pxToRem(ScreenConfig.M.min)}) {
        font-size: ${stepToRem(-1)};
      }
    }
  }

  // PortraitsAndQuotes
  section.stats__PortraitsAndQuotes {
    div.stats__Portrait {
      display: flex;
      align-items: center;
      position: relative;
      background-repeat: no-repeat;
      background-position: 33% 100%, center;
      background-size: auto 60vh, cover;
      background-attachment: scroll;

      @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
        background-size: auto 70vh, cover;
      }

      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        background-attachment: fixed;
        background-position: 96% 100%, center;
        background-size: auto 90vh, cover;
      }

      min-height: ${pxToRem(700)};
      height: 110vh;
      z-index: 5;


      .Portrait__Container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        pointer-events: none;
        justify-content: flex-start;
        align-items: flex-start;

        @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
          position: absolute;

          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          pointer-events: auto;
        }

        @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
          justify-content: center;
        }

        & * {
          pointer-events: inherit;
        }
      }

      .Portrait__Grid {
        @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
          opacity: 0;
        }
        transition: opacity 0.2s ease;
        width: 100%;
      }

      .Portrait__Quote {
        display: block;
        font-size: ${stepToRem(2)};
        margin-bottom: ${pxToRem(10)};

        @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
          font-size: ${stepToRem(3)};
          margin-bottom: ${pxToRem(15)};
        }

        @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
          font-size: ${stepToRem(5)};
        }
      }

      .Portrait__HorizontalStroke {
        @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
          display: none;
        }

        background-color: var(--color-grey-900);
        width: ${pxToRem(30)};
        height: ${pxToRem(4)};
        margin-bottom: ${pxToRem(30)};
      }

      .Portrait__QuoteMeta {
        @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
          font-size: ${stepToRem(-1)};
        }
      }

      .Portrait__Bullet {
        display: inline-block;
        width: ${pxToRem(6)};
        height: ${pxToRem(6)};
        opacity: 0.3;
        background-color: var(--color-grey-900);

        & + .Portrait__Bullet {
          margin-left: ${pxToRem(8)};
        }

        &.Bullet--is_active {
          opacity: 1;
        }
      }

      /* Modifiers */
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        .stats__LayoutBase:not(.stats--not_animated) &.Portrait--is_active {
          .Portrait__Container {
            position: fixed;

            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            pointer-events: auto;
          }
        }

        .stats__LayoutBase.stats--not_animated &,
        &.Portrait--is_visible.Portrait--is_active {
          .Portrait__Grid {
            opacity: 1;
          }
        }
      }
    }
  }

  // WallOfFameProjectsCarousel
  section.stats__ProjectsCarousel {
    background-color: var(--color-grey-000);
    z-index: 1;
    padding-top: ${pxToRem(50)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      padding-top: ${pxToRem(100)};
    }
  }

  // Platform
  section.stats__Platforms {
    background-color: var(--color-grey-000);
    z-index: 1;
    padding-top: ${pxToRem(50)};
    padding-bottom: ${pxToRem(50)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      padding-top: ${pxToRem(100)};
      padding-bottom: ${pxToRem(80)};
    }

    .Platforms__Grid {
      align-content: center;
    }

    .Platforms__TitleCol {
      order: 2;
    }

    .Platforms__FirstParagraphCol {
      order: 1;
    }

    .Platforms__SecondParagraphCol {
      order: 3;
      position: relative;
    }

    .Platforms__HorizontalStrokeHolder {
      position: absolute;
      left: ${pxToRem(-GUTTER - 30)};
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
        left: ${pxToRem(-GUTTER - 50)};
      }
    }

    .Platforms__Text {
      line-height: 1.6;

      @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
        font-size: ${stepToRem(1)};
      }

      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        font-size: ${stepToRem(2)};
      }
    }

  }

  // Thanks
  section.stats__Thanks {
    background-color: var(--color-grey-000);
    z-index: 1;
    padding-top: ${pxToRem(HEADER_HEIGHT + 20)};
    padding-bottom: ${pxToRem(HEADER_HEIGHT + 20)};
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    min-height: calc(100vh + ${pxToRem(50)});

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      min-height: calc(100vh + ${pxToRem(80)});
    }

    .Thanks__Image img {
      position: absolute;

      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      bottom: ${pxToRem(50)};
      height: calc(100% - ${pxToRem(70)});

      z-index: 1;

      object-fit: scale-down;
      object-position: 50% 50%;

      pointer-events: none;

      @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
        width: 150vw;
        margin-left: -25vw;
        margin-right: -25vw;
      }

      @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
        bottom: ${pxToRem(80)};
        height: calc(100% - ${pxToRem(80)});
      }
    }

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      .Thanks__Image:last-of-type img {
        object-position: 20% calc(50% - pxToRem(70));
      }
    }

    .Thanks__Grid {
      align-content: center;
      position: relative;
      z-index: 1;
    }

    .Thanks__GridCol {
      margin-bottom: ${pxToRem(30)};

      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        margin-bottom: ${pxToRem(40)};
      }
    }

    .Thanks__HorizontalStroke {
      margin-left: auto;
      margin-right: auto;
    }

    .Thanks__Text {
      text-align: center;

      @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
        font-size: ${stepToRem(1)};
      }
      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        font-size: ${stepToRem(2)};
      }
    }

    .Thanks__ButtonColumn {
      text-align: center;
      margin-bottom: ${pxToRem(20)};

      @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
        &.Thanks__ButtonColumn__1 {
          text-align: right;
        }
        &.Thanks__ButtonColumn__2 {
          text-align: left;
        }
      }
    }
    .Thanks__ButtonContainer {
      display: inline-block;

      @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
        width: 100% !important;

        a {
            min-height: ${pxToRem(50)};
          }
      }
    }

  }

  .Stats__Title__Size1 {
    font-size: ${stepToRem(6)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      font-size: ${stepToRem(9)};
    }
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      font-size: ${stepToRem(12)};
    }
  }
  .Stats__Title__Size3 {
    font-size: ${stepToRem(4)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      font-size: ${stepToRem(5)};
    }
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      font-size: ${stepToRem(6)};
    }
  }
  .Stats__Title__Size5 {
    font-size: ${stepToRem(2)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      font-size: ${stepToRem(4)};
    }
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      font-size: ${stepToRem(5)};
    }
  }

  .Stats__HorizontalStroke__Size1 {
    width: ${pxToRem(40)};
    height: ${pxToRem(6)};
    margin-top: ${pxToRem(20)};
    margin-bottom: ${pxToRem(40)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      width: ${pxToRem(50)};
      height: ${pxToRem(6)};
    }
  }
  .Stats__HorizontalStroke__Size3 {
    width: ${pxToRem(30)};
    height: ${pxToRem(4)};
    margin-top: ${pxToRem(15)};
    margin-bottom: ${pxToRem(30)};

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      width: ${pxToRem(40)};
      height: ${pxToRem(6)};
      margin-top: ${pxToRem(20)};
      margin-bottom: ${pxToRem(40)};
    }
  }
`
