import { stringify } from 'qs'
import { PaylineError } from '../errors/payline-error'
import usePaymentErrors from '../errors/use-payment-errors'

const usePaylineTokenization = () => {
  const { createPaymentError } = usePaymentErrors()
  return {
    callPaylineTokenization: async ({
      amountCents,
      cardNumber,
      cvv,
      expiry,
      cardRegistration: {
        uuid,
        registrationUrl,
        accessKey,
        preregistrationData,
      },
    }) => {
      try {
        const response = await fetch(registrationUrl, {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
          },
          body: stringify({
            data: preregistrationData,
            accessKeyRef: accessKey,
            cardNumber: `${cardNumber}`.replace(/ /g, ''),
            cardExpirationDate: `${expiry}`.replace('/', ''),
            cardCvx: cvv,
          }),
        })
        if (!response.ok) {
          await createPaymentError({
            cardRegistrationUuid: uuid,
            amountCents,
            errorCode: response.status,
          })
          throw new Error(`query to Payline failed: ${response.status}`)
        }
        const data = await response.text()
        const [key, value] = data.split('=')
        if (key === 'errorCode') {
          await createPaymentError({
            cardRegistrationUuid: uuid,
            amountCents,
            errorCode: value,
          })
          throw new PaylineError(value)
        }
        return data
      } catch (e) {
        await createPaymentError({
          cardRegistrationUuid: uuid,
          amountCents,
          errorCode: '00000',
        })
        throw e
      }
    },
  }
}

export default usePaylineTokenization
