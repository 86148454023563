import React from 'react'
import { useSelector } from 'react-redux'
import { Helmet as ReactHelmet } from 'react-helmet'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getAvatarImageUrl, getUsername } from '../redux'

const Helmet = () => {
  const t = useTranslation()
  const mentorUserName = useSelector(getUsername)
  const mentorImage = useSelector(getAvatarImageUrl)
  return (
    <ReactHelmet>
      <title>{mentorUserName}</title>
      <meta
        property="description"
        content={t('mentor_page.seo.description', { username: mentorUserName })}
      />
      <meta
        property="og:description"
        content={t('mentor_page.seo.description', { username: mentorUserName })}
      />
      <meta property="og:title" content={mentorUserName} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={mentorImage} />
    </ReactHelmet>
  )
}

export default Helmet
