import React from 'react'
import getOr from 'lodash/fp/getOr'
import { Controls } from '@kisskissbankbank/underwood'
import { useMutation } from 'kiss/api/graphql-query'
import UploadImageMutation from 'kiss/graphql/mutations/upload_image.graphql'

const Image = () => {
  const [uploadImage] = useMutation(UploadImageMutation)

  const onUpload = (file) => {
    return new Promise((resolve) => {
      uploadImage({ variables: { file } }).then((response) => {
        const uploadedUrl = getOr('')('data.uploadImage.url')(response)
        return resolve(uploadedUrl)
      })
    })
  }

  return <Controls.Image onUpload={onUpload} />
}

export default Image
