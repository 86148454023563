import {
  Container,
  Grid,
  GridCol,
  HorizontalStroke,
  Marger,
  Paragraph,
  pxToRem,
  ScreenConfig,
  Title,
} from '@kisskissbankbank/kitten'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import MentorCard from './components/mentor-card'
import { getMentors } from './redux'

const Cards = styled.div`
  margin-right: -${pxToRem(10)};
  margin-left: -${pxToRem(10)};

  @media screen and (min-width: ${ScreenConfig.S.min}px) {
    margin-right: -${pxToRem(20)};
    margin-left: -${pxToRem(20)};
  }
`

const CardsList = styled(Marger)`
  display: flex;
  flex-wrap: wrap;
`

const Mentors = () => {
  const t = useTranslation()
  const mentors = useSelector(getMentors)
  return (
    <DefaultLayout>
      <Helmet title={t('mentors.index.title')} />

      <Container>
        <Grid>
          <GridCol col-l="6" offset-l="3">
            <Marger top={{ default: 5, fromS: 10 }} bottom="2">
              <Title
                modifier="secondary"
                noMargin
                tag="h1"
                className="k-u-align-center k-u-color-grey-900"
                family="antiqueolive"
              >
                {t('mentors.index.title')}
              </Title>
            </Marger>
            <Marger top="2" bottom={{ default: 3, fromS: 4 }}>
              <HorizontalStroke
                size="huge"
                className="k-u-margin-none k-u-margin-horizontal-auto"
                customSize={{ width: 50, height: 6 }}
              />
            </Marger>
            <Marger top={{ default: 3, fromS: 4 }} bottom="10">
              <Paragraph
                modifier="primary"
                noMargin
                className="k-u-align-center k-u-color-grey-900"
              >
                {t('mentors.index.subtitle')} {t('mentors.index.intro_mentors')}
              </Paragraph>
            </Marger>
          </GridCol>
          <GridCol>
            {mentors && (
              <Cards>
                <CardsList bottom="5">
                  {mentors.map((mentor) => (
                    <MentorCard
                      imageProps={{
                        src: mentor.image.url,
                        alt: mentor.username,
                      }}
                      username={mentor.username}
                      slug={mentor.slug}
                      key={mentor.uuid}
                    />
                  ))}
                </CardsList>
              </Cards>
            )}
          </GridCol>
        </Grid>
      </Container>
    </DefaultLayout>
  )
}

export default Mentors
