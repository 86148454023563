import React, { useState } from 'react'

import { isNotPublished } from 'kiss/utils/project/state'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import {
  currentUserIsBacking,
  getProjectRewards,
  getProjectState,
  isProjectOnlyRecurringDonation,
  isProjectRecurringDonationEnabled,
} from 'kiss/modules/project-page/page-state/selectors'

import { REWARDS_ID } from 'kiss/modules/project-page/permanent/constants/id'

import Card from 'kiss/modules/project-page/permanent/components/card'
import DesktopModal from 'kiss/modules/project-page/permanent/components/reward-modal/desktop'
import MobileModal from 'kiss/modules/project-page/permanent/components/reward-modal/mobile'
import { Alert, GUTTER, pxToRem, Title } from '@kisskissbankbank/kitten'
import KissDonationCard from 'kiss/modules/components/donation-card'

import styled from 'styled-components'

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${pxToRem(280)}, 1fr));
  gap: ${pxToRem(GUTTER)};
`

const Info = styled(Alert)`
  background-color: #e5f3ff;
  margin-right: 0;
  margin-left: 0;
  padding: ${pxToRem(15)};
`

const Rewards = () => {
  const t = useTranslation()

  const [modalReward, setModalReward] = useState(null)
  const rewards = useSelector(getProjectRewards)
  const recurringDonationEnabled = useSelector(
    isProjectRecurringDonationEnabled,
  )
  const isOnlyRecurringDonation = useSelector(isProjectOnlyRecurringDonation)

  const projectState = useSelector(getProjectState)
  const isProjectNotPublished = isNotPublished(projectState)

  const { viewportIsMOrLess } = useMediaQuery()

  const userIsBacking = useSelector(currentUserIsBacking)

  // Modal handlers
  const handleRewardClick = ({ event, subscription, isDisabled = false }) => {
    event.preventDefault()

    setModalReward({ subscription, isDisabled })
  }

  const handleClose = () => {
    setModalReward(null)
  }

  const Modal = viewportIsMOrLess ? MobileModal : DesktopModal

  return (
    <div id={REWARDS_ID}>
      <Info status="news" closeButton>
        <Title className="k-u-size-small k-u-weight-600" tag="h3">
          {t('permanent_project.unique.donation.new', { parseHtml: true })}
        </Title>
        {t('permanent_project.unique.donation.info', { parseHtml: true })}
      </Info>
      <Title
        modifier="quaternary"
        className="k-u-margin-bottom-double"
        tag="h2"
      >
        {isOnlyRecurringDonation
          ? t('permanent_project.support.title', { parseHtml: true })
          : t('permanent_project.subscriptions.title', { parseHtml: true })}
      </Title>
      <Grid>
        {rewards.map((reward) => (
          <Card
            key={reward.uuid}
            subscription={reward}
            handleModal={handleRewardClick}
            userIsBacking={userIsBacking}
          />
        ))}
        {recurringDonationEnabled && (
          <KissDonationCard
            isRecurringDonation
            onlyDonation={isOnlyRecurringDonation}
            isProjectNotPublished={isProjectNotPublished}
          />
        )}

        {!!modalReward && (
          <Modal
            reward={modalReward.subscription}
            isDisabled={modalReward.isDisabled}
            modalProps={{
              onAfterClose: handleClose,
            }}
          />
        )}
      </Grid>
      <>
        <Title
          modifier="quaternary"
          className="k-u-margin-bottom-double k-u-margin-top-double"
          tag="h2"
        >
          {t('permanent_project.unique.donation.title', { parseHtml: true })}
        </Title>
        <Grid>
          <KissDonationCard
            className="k-u-margin-bottom-quadruple"
            isProjectNotPublished={isProjectNotPublished}
          />
        </Grid>
      </>
    </div>
  )
}

export default Rewards
