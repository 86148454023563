import React from 'react'
import {
  Container,
  Grid,
  GridCol,
  TitleWithStroke,
  BulletList,
  Button,
  GUTTER,
  CONTAINER_PADDING,
  pxToRem,
  stepToRem,
  ScreenConfig,
  EmailIcon,
  Paragraph,
} from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import styled from 'styled-components'

const StyledCompany = styled(Container)`
  @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
    .kiss-GiftCard-Company__grid > :first-child {
      order: 2;
    }

    .kiss-GiftCard-Company__grid > :last-child {
      order: 1;
    }
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    .kiss-GiftCard-Company__grid {
      align-items: center;
    }

    .kiss-GiftCard-Company__imageCol {
      right: ${pxToRem(GUTTER - CONTAINER_PADDING)};
      width: calc(50% + ${CONTAINER_PADDING});
      max-width: calc(50% + ${CONTAINER_PADDING});
      position: relative;
      align-self: stretch;

      .kiss-GiftCard-Company__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .kiss-GiftCard-Company__listItem {
    font-size: ${stepToRem(-1)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      font-size: ${stepToRem(0)};
    }
  }

  @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
    .kiss-GiftCard-Company__button {
      width: 100%;
    }
  }
`

const Company = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledCompany>
      <Grid className="kiss-GiftCard-Company__grid">
        <GridCol col-s="10" offset-s="1" col-l="4">
          <TitleWithStroke
            tag="h3"
            modifier="tertiary"
            className="k-u-margin-top-quadruple k-u-margin-top-quintuple@s-up k-u-margin-top-decuple@l-up"
          >
            {t('gift_card.landing.company.title', { parseHtml: true })}
          </TitleWithStroke>

          <Paragraph
            modifier="secondary"
            className="k-u-margin-bottom-double k-u-margin-bottom-triple@s-up"
          >
            {t('gift_card.landing.company.paragraph', { parseHtml: true })}
          </Paragraph>

          <div className="k-u-margin-bottom-triple k-u-margin-bottom-quadruple@s-up">
            <BulletList
              items={[
                {
                  key: 1,
                  item: t('gift_card.landing.company.list_item_1'),
                  className: 'kiss-GiftCard-Company__listItem',
                },
                {
                  key: 2,
                  item: t('gift_card.landing.company.list_item_2'),
                  className: 'kiss-GiftCard-Company__listItem',
                },
                {
                  key: 3,
                  item: t('gift_card.landing.company.list_item_3'),
                  className: 'kiss-GiftCard-Company__listItem',
                },
              ]}
            />
          </div>

          <Button
            as="a"
            href="mailto:partenariat@kisskissbankbank.com"
            modifier="helium"
            size="large"
            className="kiss-GiftCard-Company__button k-u-margin-bottom-quintuple k-u-margin-bottom-decuple@s-up"
          >
            <EmailIcon aria-hidden />
            <span>{t('gift_card.landing.company.button')}</span>
          </Button>
        </GridCol>

        <GridCol
          className="kiss-GiftCard-Company__imageCol"
          col-s="10"
          offset-s="1"
          col-l="6"
        >
          <img
            src={assetFor('gift-card/landing/b2b.svg')}
            className="kiss-GiftCard-Company__image"
            alt=""
            aria-hidden
          />
        </GridCol>
      </Grid>
    </StyledCompany>
  )
}

export default Company
