import { gql } from '@apollo/client'
import { Button, KissKissLoadingAnimation } from '@kisskissbankbank/kitten'
import { useMutation } from 'kiss/api/graphql-query'
import config from 'kiss/config'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  FUNNEL,
  FUNNEL_STEPS,
  getRouteFor,
  VERCEL_ORGANIZATION_MANAGER_ROOT,
} from 'kiss/routes/redux'
import {
  getCurrentUserFirstOrganizationWithProject,
  isLogged,
} from 'kiss/session/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import qs from 'qs'
import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'

const CREATE_BARE_PROJECT = gql`
  mutation CreateBareProject($challengeSlug: String) {
    createBareProject(challengeSlug: $challengeSlug) {
      id
    }
  }
`

const FundMyProjectButton = ({ buttonText }) => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const isUserLogged = useSelector(isLogged)
  const userFirstOrganization = useSelector(
    getCurrentUserFirstOrganizationWithProject,
  )
  const history = useHistory()
  const { challenge } = useParams()
  const [createBareProject, { loading, called }] = useMutation(
    CREATE_BARE_PROJECT,
    {
      challengeSlug: challenge,
    },
  )

  return (
    <Button
      data-test-id="fund-my-project-start-your-project"
      size="large"
      modifier="helium"
      disabled={loading || called}
      aria-label={(loading || called) && t('fund_my_project.loading')}
      onClick={async (e) => {
        e.preventDefault()

        if (isUserLogged) {
          if (userFirstOrganization) {
            return RoutingHelper.redirect(
              `${config[APP_ENV].manager.host}${routeFor(
                VERCEL_ORGANIZATION_MANAGER_ROOT,
                {
                  organization: userFirstOrganization.node?.slug,
                },
              )}?${qs.stringify({ create: true })}`,
            )
          }
          const result = await createBareProject()
          const id = result?.data?.createBareProject.id
          return history.push(
            routeFor(FUNNEL_STEPS, {
              project: id,
              step: 'status',
            }),
          )
        } else {
          history.push(routeFor(FUNNEL))
        }
      }}
    >
      {loading || called ? <KissKissLoadingAnimation /> : buttonText}
    </Button>
  )
}

export default FundMyProjectButton
