import {
  Button,
  Container,
  cssPropertyDistributor,
  Grid,
  GridCol,
  HorizontalStroke,
  pxToRem,
  ScreenConfig,
  Text,
  Title,
} from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getTotalUsersCount } from 'kiss/modules/stats-page/redux'
import { BROWSING, FUND_MY_PROJECT, getRouteFor } from 'kiss/routes/redux'
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

const Thanks = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const assetFor = useSelector(getAssetFor)
  const totalUserCount = useSelector(getTotalUsersCount)
  const button_1 = useRef(null)
  const button_2 = useRef(null)

  useEffect(() => {
    if (window) {
      // it's important to wait until fonts are loaded
      window.onload = () => {
        cssPropertyDistributor({
          elements: [button_1.current, button_2.current],
          property: 'width',
          direction: 'max',
        })
      }
    }
  }, [])

  return (
    <Container as="section" className="stats__Thanks">
      <picture className="Thanks__Image">
        <source
          type="image/webp"
          media={`(min-width: ${pxToRem(ScreenConfig.L.min)})`}
          srcSet={assetFor('stats/thanks-desktop-1.webp')}
        />
        <source
          type="image/png"
          media={`(min-width: ${pxToRem(ScreenConfig.L.min)})`}
          srcSet={assetFor('stats/thanks-desktop-1.png')}
        />
        <source
          type="image/webp"
          media={`(min-width: ${pxToRem(ScreenConfig.S.min)})`}
          srcSet={assetFor('stats/thanks-tablet-1.webp')}
        />
        <source
          type="image/png"
          media={`(min-width: ${pxToRem(ScreenConfig.S.min)})`}
          srcSet={assetFor('stats/thanks-tablet-1.png')}
        />
        <source
          type="image/webp"
          srcSet={assetFor('stats/thanks-mobile-1.webp')}
        />
        <source
          type="image/png"
          srcSet={assetFor('stats/thanks-mobile-1.png')}
        />
        <img
          alt=""
          src={assetFor('stats/thanks-tablet-1.png')}
          loading="lazy"
        />
      </picture>
      <picture className="Thanks__Image">
        <source
          type="image/webp"
          media={`(min-width: ${pxToRem(ScreenConfig.L.min)})`}
          srcSet={assetFor('stats/thanks-desktop-2.webp')}
        />
        <source
          type="image/png"
          media={`(min-width: ${pxToRem(ScreenConfig.L.min)})`}
          srcSet={assetFor('stats/thanks-desktop-2.png')}
        />
        <source
          type="image/webp"
          media={`(min-width: ${pxToRem(ScreenConfig.S.min)})`}
          srcSet={assetFor('stats/thanks-tablet-2.webp')}
        />
        <source
          type="image/png"
          media={`(min-width: ${pxToRem(ScreenConfig.S.min)})`}
          srcSet={assetFor('stats/thanks-tablet-2.png')}
        />
        <source
          type="image/webp"
          srcSet={assetFor('stats/thanks-mobile-2.webp')}
        />
        <source
          type="image/png"
          srcSet={assetFor('stats/thanks-mobile-2.png')}
        />
        <img
          alt=""
          src={assetFor('stats/thanks-tablet-2.png')}
          loading="lazy"
        />
      </picture>
      <Grid className="Thanks__Grid">
        <GridCol
          className="Thanks__GridCol"
          col="12"
          col-s="10"
          offset-s="1"
          col-l="6"
          offset-l="3"
        >
          <Title className="k-u-align-center" tag="h2" family="antiqueolive">
            {t('stats.thanks.title', {
              kissbankersCount: t(totalUserCount || 0, { formatNumber: true }),
              parseHtml: true,
            })}
          </Title>
          <HorizontalStroke
            className="Stats__HorizontalStroke__Size1 Thanks__HorizontalStroke"
            size="large"
          />
          <Text
            className="Thanks__Text k-u-align-center"
            tag="p"
            weight="400"
            lineHeight="normal"
          >
            {t('stats.thanks.text', { parseHtml: true })}
          </Text>
        </GridCol>
        <GridCol
          col="12"
          col-s="6"
          className="Thanks__ButtonColumn Thanks__ButtonColumn__1"
        >
          <span className="Thanks__ButtonContainer" ref={button_1}>
            <Button
              size="large"
              fit="fluid"
              href={routeFor(BROWSING)}
              as="a"
              className="Thanks__Button"
            >
              {t('stats.thanks.discover_projects_button')}
            </Button>
          </span>
        </GridCol>
        <GridCol
          col="12"
          col-s="6"
          className="Thanks__ButtonColumn Thanks__ButtonColumn__2"
        >
          <span className="Thanks__ButtonContainer" ref={button_2}>
            <Button
              size="large"
              fit="fluid"
              modifier="helium"
              href={routeFor(FUND_MY_PROJECT)}
              as="a"
              className="Thanks__Button"
            >
              {t('stats.fund_my_project_button')}
            </Button>
          </span>
        </GridCol>
      </Grid>
    </Container>
  )
}

export default Thanks
