import React from 'react'

import { ScreenConfig, Title, pxToRem } from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledTestimonyContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px;
  background: white;
  justify-content: center;
  align-items: flex-start;
  border-radius: 10px;
  background: white;
`

const StyledQuotes = styled.div`
  svg {
    width: 47.56px;
    height: 30px;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    svg {
      width: 63px;
      height: 39.739px;
    }
  }
`

const StyledCardParagraph = styled.p`
  font-family: 'GeneralSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 16px;
  color: var(--color-grey-900);

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    font-size: 16px;
    line-height: 26px;
  }
`

const StyledAuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  gap: 10px;
  text-align: end;
`

const TestimonyCard = ({ paragraph1, paragraph2, authorName, authorJob }) => {
  return (
    <StyledTestimonyContainer>
      <StyledQuotes className="k-u-margin-bottom-single">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="63"
          height="40"
          viewBox="0 0 63 40"
          fill="none"
        >
          <path
            d="M0 15.9599C0 30.6599 13.58 41.5799 29.12 39.4799V29.6799C20.86 30.7999 14.56 27.5799 12.88 21.1399C14 21.4199 15.12 21.5599 16.1 21.5599C22.26 21.5599 26.74 16.9399 26.74 11.1999C26.74 4.61992 21.7 -8.7738e-05 14.14 -8.7738e-05C5.6 -8.7738e-05 0 6.01991 0 15.9599ZM33.88 15.9599C33.88 30.6599 47.46 41.5799 63 39.4799V29.6799C54.74 30.7999 48.44 27.5799 46.76 21.1399C47.88 21.4199 49 21.5599 49.98 21.5599C56.14 21.5599 60.62 16.9399 60.62 11.1999C60.62 4.61992 55.72 -8.7738e-05 48.02 -8.7738e-05C39.48 -8.7738e-05 33.88 6.01991 33.88 15.9599Z"
            fill="#E7F1FF"
          />
        </svg>
      </StyledQuotes>
      <div>
        <StyledCardParagraph className="k-u-margin-top-double">
          {paragraph1}
        </StyledCardParagraph>
        <StyledCardParagraph>{paragraph2}</StyledCardParagraph>
      </div>
      <StyledQuotes
        className="k-u-margin-top-single k-u-margin-bottom-singleHalf"
        style={{ alignSelf: 'flex-end' }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="63"
          height="41"
          viewBox="0 0 63 41"
          fill="none"
        >
          <path
            d="M63 16.6989C63 31.3989 49.42 42.3189 33.88 40.2189V30.4189C42.14 31.5389 48.44 28.3189 50.12 21.8789C49 22.1589 47.88 22.2989 46.9 22.2989C40.74 22.2989 36.26 17.6789 36.26 11.9389C36.26 5.35893 41.3 0.738926 48.86 0.738926C57.4 0.738926 63 6.75893 63 16.6989ZM29.12 16.6989C29.12 31.3989 15.54 42.3189 -3.8147e-06 40.2189V30.4189C8.26 31.5389 14.56 28.3189 16.24 21.8789C15.12 22.1589 14 22.2989 13.02 22.2989C6.86 22.2989 2.38 17.6789 2.38 11.9389C2.38 5.35893 7.28 0.738926 14.98 0.738926C23.52 0.738926 29.12 6.75893 29.12 16.6989Z"
            fill="#7BB3FF"
          />
        </svg>
      </StyledQuotes>
      <StyledAuthorInfo>
        <Title
          tag="h3"
          modifier="quaternary"
          cssColor={'var(--color-primary-500)'}
        >
          {authorName}
        </Title>
        <StyledCardParagraph>{authorJob}</StyledCardParagraph>
      </StyledAuthorInfo>
    </StyledTestimonyContainer>
  )
}

export default TestimonyCard
