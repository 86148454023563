import React from 'react'
import styled from 'styled-components'
import { CallToAction } from '../index'
import {
  pxToRem,
  stepToRem,
  Container,
  Grid,
  GridCol,
  Text,
  TitleWithStroke,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

const StyledHowItWorks = styled(Container)`
  .kiss-GiftCard-HowItWorks__subGrid {
    @media (min-width: ${pxToRem(
        ScreenConfig.S.min,
      )}) and (max-width: ${pxToRem(ScreenConfig.M.max)}) {
      display: grid;
      grid-gap: ${pxToRem(20)};
      grid-template-columns: repeat(2, 1fr);

      .kiss-GiftCard-HowItWorks__subGridCol:last-child {
        grid-column: 1 / span 2;
        max-width: calc((100% - ${pxToRem(40)}) / 2);
        justify-self: center;
      }
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      display: grid;
      grid-gap: ${pxToRem(40)};
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .kiss-GiftCard-HowItWorks__image {
    max-width: ${pxToRem(120)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      max-width: ${pxToRem(160)};
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      max-width: ${pxToRem(180)};
    }
  }

  .kiss-GiftCard-HowItWorks__itemTitle {
    font-size: ${stepToRem(0)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      font-size: ${stepToRem(1)};
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      font-size: ${stepToRem(2)};
    }
  }

  .kiss-GiftCard-HowItWorks__itemParagraph {
    font-size: ${stepToRem(-2)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      font-size: ${stepToRem(-1)};
    }
  }
`

const HowItWorks = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledHowItWorks>
      <Grid>
        <GridCol col-s="8" offset-s="2" col-l="6" offset-l="3">
          <TitleWithStroke
            tag="h3"
            modifier="tertiary"
            align="center"
            className="k-u-margin-top-decuple@l-up"
          >
            {t('gift_card.landing.how_it_works.title', { parseHtml: true })}
          </TitleWithStroke>
        </GridCol>

        <GridCol col-s="10" offset-s="1">
          <div className="kiss-GiftCard-HowItWorks__subGrid k-u-margin-bottom-quintuple">
            <div className="kiss-GiftCard-HowItWorks__subGridCol k-u-align-center">
              <img
                src={assetFor('gift-card/landing/picto_1.svg')}
                className="kiss-GiftCard-HowItWorks__image"
                alt=""
                aria-hidden
              />

              <Text
                tag="p"
                color="font1"
                size="huge"
                weight="600"
                lineHeight="normal"
                className="kiss-GiftCard-HowItWorks__itemTitle k-u-margin-bottom-single k-u-margin-top-double"
              >
                {t('gift_card.landing.how_it_works.item1.title')}
              </Text>

              <Text
                tag="p"
                color="font1"
                size="small"
                weight="400"
                lineHeight="normal"
                className="kiss-GiftCard-HowItWorks__itemParagraph k-u-margin-bottom-triple k-u-margin-bottom-single@s-up"
              >
                {t('gift_card.landing.how_it_works.item1.paragraph')}
              </Text>
            </div>

            <div className="kiss-GiftCard-HowItWorks__subGridCol k-u-align-center">
              <img
                src={assetFor('gift-card/landing/picto_2.svg')}
                className="kiss-GiftCard-HowItWorks__image"
                alt=""
                aria-hidden
              />

              <Text
                tag="p"
                color="font1"
                size="huge"
                weight="600"
                lineHeight="normal"
                className="kiss-GiftCard-HowItWorks__itemTitle k-u-margin-bottom-single k-u-margin-top-double"
              >
                {t('gift_card.landing.how_it_works.item2.title')}
              </Text>

              <Text
                tag="p"
                color="font1"
                size="small"
                weight="400"
                lineHeight="normal"
                className="kiss-GiftCard-HowItWorks__itemParagraph k-u-margin-bottom-triple k-u-margin-bottom-single@s-up"
              >
                {t('gift_card.landing.how_it_works.item2.paragraph')}
              </Text>
            </div>

            <div className="kiss-GiftCard-HowItWorks__subGridCol k-u-align-center">
              <img
                src={assetFor('gift-card/landing/picto_3.svg')}
                className="kiss-GiftCard-HowItWorks__image"
                alt=""
                aria-hidden
              />

              <Text
                tag="p"
                color="font1"
                size="huge"
                weight="600"
                lineHeight="normal"
                className="kiss-GiftCard-HowItWorks__itemTitle k-u-margin-bottom-single k-u-margin-top-double"
              >
                {t('gift_card.landing.how_it_works.item3.title')}
              </Text>

              <Text
                tag="p"
                color="font1"
                size="small"
                weight="400"
                lineHeight="normal"
                className="kiss-GiftCard-HowItWorks__itemParagraph k-u-margin-bottom-triple k-u-margin-bottom-single@s-up"
              >
                {t('gift_card.landing.how_it_works.item3.paragraph', {
                  parseHtml: true,
                })}
              </Text>
            </div>
          </div>

          <div className="k-u-align-center k-u-margin-bottom-quintuple k-u-margin-bottom-decuple@s-up">
            <CallToAction />
          </div>
        </GridCol>
      </Grid>
    </StyledHowItWorks>
  )
}

export default HowItWorks
