export const FacebookPixelHelper = {
  getUserData(user) {
    if (user) {
      const { id, firstName, lastName, email } = user
      return {
        user_id: id,
        user_firstname: firstName,
        user_lastname: lastName,
        user_email: email,
      }
    } else {
      return {}
    }
  },

  getProjectData(project) {
    if (project.uuid) {
      const { uuid, slug, name, facebookPixelRef, goalType, categories } =
        project
      return {
        project_id: uuid,
        project_reference: slug || uuid,
        project_name: name,
        project_facebook_pixel_ref: facebookPixelRef,
        project_goal_type: goalType,
        project_categories: categories.map((name) => ({ name })),
      }
    } else {
      return {}
    }
  },

  getEcommerceData(ecommerce) {
    const { cart, totalAmount, totalCurrency } = ecommerce
    return {
      value: totalAmount,
      currency: totalCurrency,
      items: cart?.lines.map((line) => {
        return {
          item_id: line.variation?.id,
          item_name: line.variation?.label,
          item_category: '',
          price: line.totalAmount?.cents / (line.quantity * 100),
          quantity: line.quantity,
        }
      }),
    }
  },

  pushToGtm(dataLayer, data) {
    // TO CLEAN

    if (!dataLayer?.push) return

    dataLayer.push(data)
  },
}
