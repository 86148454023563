import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { useFormikContext } from 'formik'
import {
  isCurrentUserAdmin as isCurrentUserAdminSelector,
  isMentor,
} from 'kiss/session/redux'
import AppHeader from 'kiss/app/header'
import { getMentorSlug } from '../redux'
import {
  getRouteFor,
  USER_PROFILE,
  MENTOR_PAGE_EDITION,
} from 'kiss/routes/redux'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'
import { SaveIcon, Button } from '@kisskissbankbank/kitten'

const Header = () => {
  const isCurrentUserAdmin = useSelector(isCurrentUserAdminSelector)
  const userIsMentor = useSelector(isMentor)
  const canEditPage = isCurrentUserAdmin || userIsMentor
  const isPageEdition = !!useRouteMatch(MENTOR_PAGE_EDITION)

  if (!canEditPage) return <AppHeader />

  return (
    <AppHeader
      loggedInBonusComponent={
        <span aria-live="polite">{isPageEdition && <SubmitButton />}</span>
      }
    />
  )
}

const SubmitButton = () => {
  const { submitForm, isSubmitting } = useFormikContext()
  const t = useTranslation()
  const history = useHistory()
  const mentorSlug = useSelector(getMentorSlug)
  const routeFor = useSelector(getRouteFor)

  const handleSubmit = async (e) => {
    e.preventDefault()
    await submitForm()
    history.push(routeFor(USER_PROFILE, { user: mentorSlug }))
  }

  if (isSubmitting) {
    return (
      <Button
        as="div"
        size="small"
        modifier="helium"
        rounded
        fit="content"
        mobileFit="icon"
      >
        <LoadingAnimation
          className="k-u-flex k-u-flex-alignItems-center k-u-align-center"
          color="var(--color-grey-000)"
          aria-hidden
        />
        <span className="k-u-hidden@l-up k-u-a11y-visuallyHidden">
          {t('mentor_page.edition.button.loading')}
        </span>
        <span className="k-u-hidden@m-down">
          {t('mentor_page.edition.button.loading')}
        </span>
      </Button>
    )
  }

  return (
    <Button
      type="button"
      size="small"
      modifier="helium"
      rounded
      onClick={handleSubmit}
      fit="content"
      mobileFit="icon"
    >
      <SaveIcon color="var(--color-grey-000)" title={null} aria-hidden />
      <span className="k-u-hidden@l-up k-u-a11y-visuallyHidden">
        {t('mentor_page.edition.button.save')}
      </span>
      <span className="k-u-hidden@m-down">
        {t('mentor_page.edition.button.save')}
      </span>
    </Button>
  )
}

export default Header
