import React from 'react'
import compose from 'ramda/src/compose'
import isEqual from 'lodash/fp/isEqual'
import { connect } from 'react-redux'
import { withMediaQueries } from '@kisskissbankbank/kitten'
import { updateMediaQueries } from './redux'

class ViewportMediaQueries extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      updateMediaQueries,
      viewportIsXXS,
      viewportIsXSOrLess,
      viewportIsSOrLess,
      viewportIsMOrLess,
      viewportIsLOrLess,
    } = this.props

    if (!isEqual(prevProps, this.props)) {
      updateMediaQueries({
        viewportIsXXS,
        viewportIsXSOrLess,
        viewportIsSOrLess,
        viewportIsMOrLess,
        viewportIsLOrLess,
      })
    }
  }

  render = () => null
}

const mapDispatchToProps = {
  updateMediaQueries,
}

export default compose(
  connect(null, mapDispatchToProps),
  withMediaQueries({
    viewportIsXXS: true,
    viewportIsXSOrLess: true,
    viewportIsSOrLess: true,
    viewportIsMOrLess: true,
    viewportIsLOrLess: true,
  }),
)(ViewportMediaQueries)
