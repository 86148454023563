import cloneDeep from 'lodash/fp/cloneDeep'

const mapFeatures = (pricingPlans) => {
  const plansCopy = cloneDeep(pricingPlans)

  const flattenedFeatures = plansCopy
    .map((item) => item.featuresByCategory)
    .flat()

  const combinedFeatures = flattenedFeatures.reduce((acc, current) => {
    const categoryIndex = acc.findIndex(
      (item) => item.category === current.category,
    )
    if (categoryIndex === -1) {
      acc.push({ category: current.category, features: current.features })
    } else {
      acc[categoryIndex].features.push(...current.features)
    }
    return acc
  }, [])

  combinedFeatures.forEach((category) => {
    category.features = category.features.reduce((acc, current) => {
      if (!acc.some((item) => item.title === current.title)) {
        acc.push(current)
      }
      return acc
    }, [])
  })

  const filteredFeatures = combinedFeatures.filter(
    (category) => category.features.length > 0,
  )

  const result = filteredFeatures.reduce((acc, current) => {
    const features = current.features.map((feature) => ({
      category: current.category,
      isCategory: false,
      ...feature,
    }))
    return [
      ...acc,
      { category: current.category, isCategory: true },
      ...features,
    ]
  }, [])

  return result
}

export { mapFeatures }
