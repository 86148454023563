import React from 'react'

import { IconBadge, QuestionMarkIcon } from '@kisskissbankbank/kitten'
import * as Tooltip from '@radix-ui/react-tooltip'

const FeatureExplanationTooltip = ({ content, className }) => {
  return (
    <Tooltip.Provider>
      <Tooltip.Root delayDuration={0}>
        <Tooltip.Trigger>
          <IconBadge
            size="small"
            border={{
              width: 2,
              color: 'var(--color-primary-300)',
              style: 'solid',
            }}
            className={className}
          >
            <QuestionMarkIcon width={10} height={10} />
          </IconBadge>
        </Tooltip.Trigger>

        <Tooltip.Portal>
          <Tooltip.Content
            className="kiss-Pricing__bubble k-u-weight-400 k-u-size-small k-u-line-height-1-3"
            side="right"
            align="center"
            sideOffset={5}
          >
            {content}
            <Tooltip.Arrow className="kiss-Pricing__bubble_arrow" />
          </Tooltip.Content>
        </Tooltip.Portal>
      </Tooltip.Root>
    </Tooltip.Provider>
  )
}

export default FeatureExplanationTooltip
