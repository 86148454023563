import { Editor, editorPropTypes } from '@kisskissbankbank/underwood'
import config from 'kiss/config'
import React from 'react'
import { useTranslation } from '../../hooks/use-translation'

const EditorWrapper = ({ onInit, children }) => {
  const t = useTranslation()
  const translations = {
    button_link: {
      title: t('editor.button_link.title'),
      text: t('editor.button_link.text'),
      url: t('editor.button_link.url'),
    },
    link: {
      text: {
        label: t('editor.link.text.label'),
      },
      title: t('editor.link.title'),
      error: t('editor.link.error'),
      button: {
        delete: t('editor.link.button.delete'),
        visit: t('editor.link.button.visit'),
      },
    },
    controls: {
      format_bold: t('editor.controls.format_bold'),
      activated_format_bold: t('editor.controls.activated_format_bold'),
      format_italic: t('editor.controls.format_italic'),
      activated_format_italic: t('editor.controls.activated_format_italic'),
      format_list_bulleted: t('editor.controls.format_list_bulleted'),
      activated_format_list_bulleted: t(
        'editor.controls.activated_format_list_bulleted',
      ),
      ['k-u-align-left']: t('editor.controls.k-u-align-left'),
      ['activated_k-u-align-left']: t(
        'editor.controls.activated_k-u-align-left',
      ),
      ['k-u-align-center']: t('editor.controls.k-u-align-center'),
      ['activated_k-u-align-center']: t(
        'editor.controls.activated_k-u-align-center',
      ),
      ['k-u-align-right']: t('editor.controls.k-u-align-right'),
      ['activated_k-u-align-right']: t(
        'editor.controls.activated_k-u-align-right',
      ),
      image: t('editor.controls.image'),
      activated_image: t('editor.controls.activated_image'),
      video: t('editor.controls.video'),
      activated_video: t('editor.controls.activated_video'),
      link: t('editor.controls.link'),
      activated_link: t('editor.controls.activated_link'),
      quote: t('editor.controls.quote'),
      activated_quote: t('editor.controls.activated_quote'),
    },
    image_upload: {
      title: t('maker.steps.detailed_description.image_upload.title'),
      button_title: t(
        'maker.steps.detailed_description.image_upload.button_title',
      ),
      button_file: t(
        'maker.steps.detailed_description.image_upload.button_file',
      ),
      button_url: t('maker.steps.detailed_description.image_upload.button_url'),
      modify_image: t(
        'maker.steps.detailed_description.image_upload.modify_image',
      ),
      modify_label: t(
        'maker.steps.detailed_description.image_upload.modify_label',
      ),
      remove_image: t(
        'maker.steps.detailed_description.image_upload.remove_image',
      ),
      add_label: t('maker.steps.detailed_description.image_upload.add_label'),
      label: t('maker.steps.detailed_description.image_upload.label'),
      url: t('maker.steps.detailed_description.image_upload.label'),
      preview: t('maker.steps.detailed_description.image_upload.preview'),
      upload: t('maker.steps.detailed_description.image_upload.upload'),
      max_size: t('maker.steps.detailed_description.image_upload.max_size', {
        parseHtml: true,
      }),
      dimension_error: t(
        'maker.steps.detailed_description.image_upload.dimension_error',
      ),
      description: {
        title: t(
          'maker.steps.detailed_description.image_upload.description.title',
        ),
        label: t(
          'maker.steps.detailed_description.image_upload.description.label',
          { parseHtml: true },
        ),
        placeholder: t(
          'maker.steps.detailed_description.image_upload.description.placeholder',
        ),
        helper: t(
          'maker.steps.detailed_description.image_upload.description.helper',
        ),
      },
      help_file: {
        formats: t(
          'maker.steps.detailed_description.image_upload.help_file.formats',
        ),
        width: t(
          'maker.steps.detailed_description.image_upload.help_file.width',
          { parseHtml: true },
        ),
        size: t('maker.steps.detailed_description.image_upload.help_file.size'),
      },
    },
    image: {
      invalid_url: t('editor.image.invalid_url'),
      invalid_extension: t('editor.image.invalid_extension'),
      max_size: t('editor.image.max_size'),
    },
    media_upload: {
      title: t('maker.steps.detailed_description.media_upload.title'),
    },
    video: {
      problem: t('editor.video.problem'),
      invalid_url: t('editor.video.invalid_url'),
    },
    submit: t('app.submit.validate'),
    form: {
      tooltip_action_label: t('app.tooltip.more_informations'),
      button_loading: t('app.loading'),
    },
  }
  return (
    <Editor
      onInit={onInit}
      translations={translations}
      configResponsiveImageHandler={config[APP_ENV].responsiveImageHandler}
    >
      {children}
    </Editor>
  )
}
EditorWrapper.propTypes = editorPropTypes.props
EditorWrapper.defaultProps = editorPropTypes.defaultProps

export { EditorWrapper }
export { default as EditorImage } from './image'
