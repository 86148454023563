import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Stepper as KittenStepper } from '@kisskissbankbank/kitten'

const Stepper = () => {
  const t = useTranslation()
  return (
    <KittenStepper role="navigation">
      <KittenStepper.Item state="progress">
        {t('mentor.signup.stepper.personnal')}
      </KittenStepper.Item>
      <KittenStepper.Item>
        {t('mentor.signup.stepper.edition')}
      </KittenStepper.Item>
    </KittenStepper>
  )
}

export default Stepper
