import { GridCol, Marger, SimpleCard } from '@kisskissbankbank/kitten'
import { getRouteFor, USER } from 'kiss/routes/redux'
import PropTypes from 'prop-types'
import React from 'react'
import { useSelector } from 'react-redux'

const MentorCard = ({ slug, username, imageProps }) => {
  const routeFor = useSelector(getRouteFor)
  return (
    <GridCol col="6" col-l="2" col-m="3" col-s="4">
      <Marger bottom="5">
        <div className="k-u-margin-left-single@s-up k-u-margin-right-single@s-up">
          <SimpleCard
            href={routeFor(USER, { user: slug })}
            title={username}
            horizontalStroke={false}
            titleProps={{
              className:
                'k-u-align-center k-u-size-small k-u-weight-500 k-u-color-grey-900',
            }}
            imageProps={imageProps}
          />
        </div>
      </Marger>
    </GridCol>
  )
}
MentorCard.propTypes = {
  slug: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  imageProps: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
}

MentorCard.defaultProps = {
  imageProps: {
    src: '',
    alt: '',
  },
}

export default MentorCard
