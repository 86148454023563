import React from 'react'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { useTranslation } from 'kiss/hooks/use-translation'

const Phone = () => {
  const t = useTranslation()
  return (
    <>
      <Label htmlFor="phone">
        {t('mentor.signup.form.personnal.phone.label')}
      </Label>
      <SimpleText
        name="phone"
        placeholder={t('mentor.signup.form.personnal.phone.placeholder')}
      />
    </>
  )
}

export default Phone
