import {
  CheckedCircleIcon,
  Container,
  Grid,
  GridCol,
  Paragraph,
  pxToRem,
  ScreenConfig,
  stepToRem,
  Text,
  TitleWithStroke,
} from '@kisskissbankbank/kitten'
import { getAssetFor } from 'kiss/assets/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { CallToAction } from '../index'

const StyledListItem = styled(({ children, ...props }) => (
  <Text
    tag="li"
    color="font1"
    size="small"
    weight="500"
    lineHeight="normal"
    {...props}
  >
    <CheckedCircleIcon
      circleColor="var(--color-success-500)"
      checkedColor="var(--color-grey-000)"
      className="k-u-margin-right-single"
    />
    {children}
  </Text>
))`
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
`

const HeroPage = styled.div`
  position: relative;

  .kiss-GiftCard-Hero__beforeTitle {
    margin-top: ${pxToRem(275)};
    font-size: ${stepToRem(-1)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      margin-top: ${pxToRem(435)};
      font-size: ${stepToRem(0)};
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      margin-top: ${pxToRem(100)};
    }
  }

  .kiss-GiftCard-Hero__image {
    position: absolute;
    top: 0;
    width: 100%;
    height: ${pxToRem(225)};
    object-fit: cover;

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      height: ${pxToRem(355)};
    }

    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      right: 0;
      width: 40%;
      height: 100%;
    }
  }
`

const Hero = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)

  return (
    <HeroPage>
      <Container>
        <Grid>
          <GridCol col-s="10" offset-s="1" col-l="5">
            <Text
              tag="h1"
              color="font1"
              size="medium"
              weight="500"
              lineHeight="normal"
              className="kiss-GiftCard-Hero__beforeTitle k-u-margin-bottom-double k-u-margin-bottom-doubleHalf@s-up"
            >
              {t('gift_card.landing.hero.before_title', { parseHtml: true })}
            </Text>

            <TitleWithStroke tag="h2" modifier="tertiary" family="antiqueolive">
              {t('gift_card.landing.hero.title')}
            </TitleWithStroke>

            <Paragraph
              modifier="secondary"
              className="k-u-margin-bottom-double k-u-margin-bottom-triple@s-up"
            >
              {t('gift_card.landing.hero.paragraph')}
            </Paragraph>

            <ul className="k-u-margin-bottom-triple k-u-margin-bottom-quadruple@s-up">
              <StyledListItem className="k-u-margin-bottom-singleHalf">
                {t('gift_card.landing.hero.list.item1')}
              </StyledListItem>
              <StyledListItem className="k-u-margin-bottom-singleHalf">
                {t('gift_card.landing.hero.list.item2')}
              </StyledListItem>
              <StyledListItem>
                {t('gift_card.landing.hero.list.item3')}
              </StyledListItem>
            </ul>

            <CallToAction
              className="k-u-margin-bottom-quintuple k-u-margin-bottom-decuple@s-up"
              data-test-id="gift-card-cta"
            />
          </GridCol>
        </Grid>
      </Container>

      <img
        src={assetFor('gift-card/landing/hero.svg')}
        className="kiss-GiftCard-Hero__image"
        alt=""
        aria-hidden
      />
    </HeroPage>
  )
}

export default Hero
