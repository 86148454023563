import React, { useState } from 'react'
import { useDeepCompareEffect } from 'kiss/utils/use-deep-compare-effect'
import styled from 'styled-components'
import { useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { MENTOR_PAGE_EDITION } from 'kiss/routes/redux'
import { getAssetFor } from 'kiss/assets/redux'
import { canEditPage as canEditPageSelector, getCoverImageUrl } from '../redux'
import IllustrationUploader from './illustration-uploader'
import { pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'

const IllustrationContainer = styled.div`
  background: var(--color-primary-100) no-repeat center;
  background-image: url(${({ backgroundImage }) => backgroundImage});
  background-size: cover;
  height: ${pxToRem(250)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    height: ${pxToRem(300)};
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    height: ${pxToRem(400)};
  }

  .kiss-MentorPage__UploaderContainer {
    height: 100%;
  }

  .kiss-MentorPage__UploaderGridCol {
    @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
`

const Illustration = () => {
  const coverImageUrl = useSelector(getCoverImageUrl)
  const assetFor = useSelector(getAssetFor)
  const canEditPage = useSelector(canEditPageSelector)
  const isPageEdition = !!useRouteMatch(MENTOR_PAGE_EDITION)
  const canEditCover = canEditPage && isPageEdition
  const {
    values: { coverImageFile },
  } = useFormikContext()
  const [backgroundImage, setBackgroundImage] = useState(
    assetFor('project/prelaunch-bg.svg'),
  )

  // Show Formik value, store value or default asset.
  useDeepCompareEffect(() => {
    if (coverImageFile) {
      setBackgroundImage(URL.createObjectURL(coverImageFile))
    } else if (coverImageUrl) {
      setBackgroundImage(coverImageUrl)
    }
  }, [coverImageFile, coverImageUrl])

  return (
    <IllustrationContainer backgroundImage={backgroundImage}>
      {canEditCover && <IllustrationUploader />}
    </IllustrationContainer>
  )
}

export default Illustration
