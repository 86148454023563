import { DEFAULT_COLORS, DELIVERY_COUNTRIES } from 'kiss/app/constants'
import { getLocale } from 'kiss/app/redux'
import config from 'kiss/config'
import {
  getRouteFor,
  VERCEL_ORGANIZATION_MANAGER_NEWS,
  VERCEL_ORGANIZATION_MANAGER_NEWS_CREATE,
  VERCEL_ORGANIZATION_MANAGER_PAGE,
  VERCEL_PROJECT_MANAGER_NEWS,
  VERCEL_PROJECT_MANAGER_NEWS_CREATE,
  VERCEL_PROJECT_MANAGER_PAGE,
} from 'kiss/routes/redux'
import { getCurrentUserSlug, isMentor } from 'kiss/session/redux'
import { isFinished } from 'kiss/utils/project/state'
import filter from 'lodash/fp/filter'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import forEach from 'lodash/fp/forEach'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import maxBy from 'lodash/fp/maxBy'
import orderBy from 'lodash/fp/orderBy'
import lodashProp from 'lodash/fp/prop'
import reduce from 'lodash/fp/reduce'
import sort from 'lodash/fp/sortBy'
import toUpper from 'lodash/fp/toUpper'
import uniqBy from 'lodash/fp/uniqBy'
import values from 'lodash/fp/values'
import isNil from 'ramda/src/isNil'
import last from 'ramda/src/last'
import map from 'ramda/src/map'
import path from 'ramda/src/path'
import pathOr from 'ramda/src/pathOr'
import pipe from 'ramda/src/pipe'
import prop from 'ramda/src/prop'
import sortBy from 'ramda/src/sortBy'
import { createSelector } from '@reduxjs/toolkit'
import { initialState, MENTORS_LIMIT, NAME } from './redux'

const LANG = {
  FR: 'fr',
  EN: 'en',
  NL: 'nl',
}

const FALLBACKS = {
  [LANG.EN]: [LANG.EN, LANG.FR],
  [LANG.FR]: [LANG.FR, LANG.EN],
  [LANG.NL]: [LANG.EN, LANG.FR],
}

const HIDDEN_CHALLENGE = 'conseillers-lbp'

export const getProject = (state) => path([NAME])(state)
export const getProjectUuid = (state) => path([NAME, 'uuid'])(state)
export const getProjectId = (state) => path([NAME, 'id'])(state)
export const getProjectSlug = (state) => path([NAME, 'slug'])(state)
export const needToShowKYCModal = (project) =>
  getOr(false)(`${NAME}.kycWarning`)(project)
export const getFacebookPixelRef = (state) =>
  getOr(null)(`${NAME}.facebookPixelRef`)(state)
export const getProjectTags = (state) =>
  getOr([])(`${NAME}.taggedNewsCount`)(state)
export const getProjectState = (state) => state[NAME].state
export const getProjectDeletedAt = (state) => state[NAME].deletedAt
export const getProjectName = (state) => state[NAME].name
export const isSubscribersCountHidden = (state) =>
  path([NAME, 'hideSubscribersCount'])(state)
export const getProjectCategoriesName = (state) =>
  state[NAME].categories?.map((c) => c.name)?.join(', ')
export const getProjectCity = (state) => state[NAME].city
export const getProjectImageUrl = (state) => path([NAME, 'image', 'url'])(state)
export const getProjectImageCropped = (state) =>
  path([NAME, 'image', 'croppedUrl'])(state)
export const getProjectImageMp4 = (state) =>
  path([NAME, 'image', 'mp4Url'])(state)
export const getProjectImageWebm = (state) =>
  path([NAME, 'image', 'webmUrl'])(state)
export const explicitContentType = (state) =>
  path([NAME, 'explicitContent'])(state)
export const getProjectVideo = (state) =>
  path([NAME, 'video', 'embed_tag'])(state)

const getTranslations = (state) => getOr({})(`${NAME}.translations`)(state)

export const getPayouts = (state) => getOr([])(`${NAME}.payouts`)(state)
export const getAcceptedPaymentProviders = (state) =>
  getOr([])(`${NAME}.acceptedPaymentProviders`)(state)
export const getProcessedAt = (state) =>
  getOr(null)(`${NAME}.processedAt`)(state)

const getProjectLocales = createSelector([getTranslations], (translations) =>
  map(({ locale }) => locale)(translations),
)

const getCurrentTranslation = createSelector(
  [getTranslations, getLocale, getProjectLocales],
  (translations, locale, projectLocales) => {
    if (projectLocales.includes(locale)) {
      return find({ locale })(translations)
    }
    let fallbackTranslation = null
    for (const fallbackLocale of FALLBACKS[locale]) {
      const translationForFallbackLocale = find({ locale: fallbackLocale })(
        translations,
      )
      if (translationForFallbackLocale) {
        fallbackTranslation = translationForFallbackLocale
        break
      }
    }
    return fallbackTranslation
  },
)

export const getVideoTag = createSelector(
  [getTranslations, getLocale, getProjectVideo, getCurrentTranslation],
  (translations, locale, oldVideoTag, currentTranslation) => {
    const translatedVideo = getOr('')('videoEmbedTag')(currentTranslation)
    return !isEmpty(translatedVideo) ? translatedVideo : oldVideoTag
  },
)

export const getPricingPlan = (state) => getOr({})(`${NAME}.pricingPlan`)(state)
export const isHandleServiceFee = (state) =>
  getOr(false)(`${NAME}.handleServiceFee`)(state)

// organization
export const getOrganization = (state) =>
  getOr(null)(`${NAME}.organization`)(state)
const getOrganizationName = (state) =>
  getOr(null)(`${NAME}.organization.name`)(state)

export const getOrganizationSlug = (state) =>
  getOr(null)(`${NAME}.organization.slug`)(state)
const getOrganizationAvatarNormalUrl = (state) =>
  getOr(null)(`${NAME}.organization.avatarImage.normalUrl`)(state)
export const getOrganizationMembers = (state) =>
  getOr(null)(`${NAME}.organization.members.edges`)(state)
export const getOrganizationContact = (state) =>
  getOr(null)(`${NAME}.organization.contact`)(state)
const getOrganizationContactSlug = (state) =>
  getOr(null)(`${NAME}.organization.contact.slug`)(state)

const getOrganizationPublishedProjects = (state) =>
  getOr([])(`${NAME}.organization.publishedProjects.edges`)(state)

const getOrganizationPublishedProjectsFormatted = (state) => {
  return flow(
    getOrganizationPublishedProjects,
    map(({ node }) => node),
  )(state)
}
export const getOrganizationProjectWithMoreSubscribers = (state) => {
  if (!getOrganization(state)) return []

  return flow(
    getOrganizationPublishedProjectsFormatted,
    filter((project) => project.state === 'started' && project.isPermanent),
    maxBy(
      (permanentProject) =>
        permanentProject?.subscriptionsConnection?.totalCount,
    ),
  )(state)
}

// owner
const getOwnerAvatarNormalUrl = (state) =>
  path([NAME, 'owner', 'image', 'normalUrl'])(state)

export const getOwner = (state) => path([NAME, 'owner'])(state)

const getOwnerSlug = (state) => path([NAME, 'owner', 'slug'])(state)

const getOwnerName = (state) => path([NAME, 'owner', 'username'])(state)

// organization or owner
export const getOrganizationOrOwnerName = (state) =>
  getOrganizationName(state) || getOwnerName(state)

export const getOrganizationOrOwnerAvatarNormalUrl = (state) =>
  getOrganizationAvatarNormalUrl(state) || getOwnerAvatarNormalUrl(state)

// owner or contact
export const getOwnerOrContactSlug = (state) =>
  getOwnerSlug(state) || getOrganizationContactSlug(state)

export const getPageColors = (state) => {
  const colors = getOr({})(`${NAME}.pageColors`)(state)
  return {
    '--color-primary-100': colors.primary_100 || DEFAULT_COLORS.primary100,
    '--color-primary-300': colors.primary_300 || DEFAULT_COLORS.primary300,
    '--color-primary-500': colors.primary_500 || DEFAULT_COLORS.primary500,
    '--color-primary-700': colors.primary_700 || DEFAULT_COLORS.primary700,
    '--color-primary-900': colors.primary_900 || DEFAULT_COLORS.primary900,
  }
}

export const hasFeature = (state) => (featureLabel) => {
  return getOr([])(`${NAME}.features`)(state)?.includes(featureLabel)
}

export const getFundedAmount = (state) =>
  pathOr(0, [NAME, 'collectedAmount', 'cents'])(state) / 100
export const getCollectedCount = (state) =>
  path([NAME, 'collectedCount'])(state)
export const getProgressPercentage = (state) =>
  path([NAME, 'fundingPercent'])(state)
export const getAmount = (state) =>
  pathOr(0, [NAME, 'goalAmount', 'cents'])(state) / 100
export const getCurrency = (state) =>
  path([NAME, 'goalAmount', 'currency'])(state)
export const getGoalCount = (state) => path([NAME, 'goalCount'])(state)
export const getGoalType = (state) => path([NAME, 'goalType'])(state)
export const isCountGoalType = (state) =>
  path([NAME, 'goalType'])(state) === 'count'
export const hasGoal = (state) =>
  getOr(false)(`${NAME}.showPermanentGoal`)(state)

export const getGoalAmountDescription = createSelector(
  [getTranslations, getLocale, getCurrentTranslation],
  (translations, locale, currentTranslation) => {
    return getOr('')('goalAmountDescription')(currentTranslation)
  },
)
export const getGoalCountDescription = createSelector(
  [getTranslations, getLocale, getCurrentTranslation],
  (translations, locale, currentTranslation) => {
    return getOr('')('goalCountDescription')(currentTranslation)
  },
)

export const getEndAt = (state) => path([NAME, 'endAt'])(state)
export const getDeletedAt = (state) => path([NAME, 'deletedAt'])(state)
export const getLastStateAt = (state) => path([NAME, 'lastStateAt'])(state)
export const getSuspendedAt = (state) => path([NAME, 'suspendedAt'])(state)
export const getDuration = (state) => path([NAME, 'duration'])(state)
export const getShortDesc = (state) => path([NAME, 'shortDesc'])(state)
export const getFacebookUrl = (state) => path([NAME, 'facebookUrl'])(state)
export const getTwitterUrl = (state) => path([NAME, 'twitterUrl'])(state)
export const getInstagramUrl = (state) => path([NAME, 'instagramUrl'])(state)
export const getWebsiteUrl = (state) => path([NAME, 'websiteUrl'])(state)
export const getYoutubeUrl = (state) => path([NAME, 'youtubeUrl'])(state)
export const isAggressivelyCached = (state) =>
  getOr(false)(`${NAME}.aggressivelyCached`)(state)
export const getNavItems = (state) =>
  getOr(initialState.navItems)(`${NAME}.navItems`)(state)
export const getMentors = (state) => getOr([])(`${NAME}.mentors`)(state)

const getMentorsSize = createSelector([getMentors], (mentors) => mentors.length)

export const hasMentors = createSelector(
  [getMentors],
  (mentors) => mentors.length > 0,
)
export const getEngagements = (state) => getOr([])(`${NAME}.engagements`)(state)
export const hasEngagements = createSelector(
  [getEngagements],
  (engagements) => engagements.length > 0,
)
export const getProjectUrl = (state) => path([NAME, 'publicUrl'])(state)
export const isCurrentUserOwner = (state) =>
  path([NAME, 'currentUser', 'isOwner'])(state)
export const isCurrentUserManager = (state) =>
  path([NAME, 'currentUser', 'isManager'])(state)
export const getCurrentUserPermissions = (state) =>
  getOr({})(`${NAME}.currentUserPermissions`)(state)
export const canCurrentUserViewPreview = (state) =>
  path([NAME, 'currentUserPermissions', 'canViewPreview'])(state)
export const canCurrentUserUpdate = (state) =>
  path([NAME, 'currentUserPermissions', 'canUpdate'])(state)

export const isDraftPageSharingEnabled = (state) =>
  path([NAME, 'draftPageSharingEnabled'])(state)

export const getDraftPageToken = (state) =>
  path([NAME, 'draftPageToken'])(state)

const rewardSort = orderBy(['exclusive', 'starred', 'amount', 'label', 'uuid'])(
  ['desc', 'desc', 'asc', 'asc', 'asc'],
)
const rewardsSortByPosition = orderBy(['position'])(['asc'])
const rewardsCanBeSorted = (state) =>
  getOr(false)(`${NAME}.canSortRewards`)(state)

export const getProjectRewards = (state) => {
  const sortedRewards = rewardsCanBeSorted(state)
    ? rewardsSortByPosition
    : rewardSort

  return flow(
    lodashProp(`${NAME}.rewards`),
    uniqBy('uuid'),
    sortedRewards,
  )(state)
}
export const getAvailableRewards = createSelector(
  [getProjectRewards],
  (rewards) => rewards.filter((reward) => !reward.soldOut),
)
export const getSoldOutRewards = createSelector(
  [getProjectRewards],
  (rewards) => rewards.filter((reward) => reward.soldOut),
)
export const getDescription = (state) => path([NAME, 'description'])(state)
export const getDescriptionFunding = (state) =>
  path([NAME, 'descriptionFunding'])(state)

export const getTeamDescription = (state) =>
  path([NAME, 'teamDescription'])(state)

export const getNews = (state) =>
  flow(
    getOr([])(`${NAME}.newsConnection.edges`),
    map(({ node }) => node),
  )(state)
export const getNewsCount = (state) =>
  getOr(0)(`${NAME}.newsConnection.totalCount`)(state)
export const getFaqs = (state) =>
  pipe(pathOr({}, [NAME, 'faqs']), sortBy(prop('createdAt')))(state)
const getOrdersEdges = (state) =>
  getOr([])(`${NAME}.ordersConnection.edges`)(state)
export const getOrders = (state) =>
  flow(
    getOrdersEdges,
    map((edge) => edge.node),
  )(state)
export const getTotalOrders = (state) =>
  getOr(0)(`${NAME}.ordersConnection.totalCount`)(state)
export const getLastProjectOrderCursor = (state) =>
  flow(getOrdersEdges, last, getOr(null)('cursor'))(state)
export const getCommentsTotalCount = (state) =>
  path([NAME, 'commentsConnection', 'totalCount'])(state)

export const currentUserHasBacked = (state) =>
  pathOr(false, [NAME, 'currentUser', 'hasBacked'])(state)

export const currentUserIsBacking = (state) => {
  return flow(
    getProjectRewards,
    find((reward) => reward?.currentUser?.isBacking),
  )(state)
}

export const getCurrentProjectRouteFor = (state) => (route, params) =>
  getRouteFor(state)(route, {
    project: path([NAME, 'slug'])(state),
    ...params,
  })

const getManagerRouteFor = createSelector(
  [getOrganizationSlug, getProjectSlug, getRouteFor],
  (organizationSlug, projectSlug, routeFor) =>
    (projectRoute, organizationRoute) => {
      const managerRoute = organizationSlug
        ? routeFor(organizationRoute, {
            organization: organizationSlug,
            project: projectSlug,
          })
        : routeFor(projectRoute, { project: projectSlug })
      return `${config[APP_ENV].manager.host}${managerRoute}`
    },
)

export const getManagerNewsRoute = createSelector(
  [getManagerRouteFor],
  (managerRouteFor) =>
    managerRouteFor(
      VERCEL_PROJECT_MANAGER_NEWS,
      VERCEL_ORGANIZATION_MANAGER_NEWS,
    ),
)

export const getManagerNewsCreateRoute = createSelector(
  [getManagerRouteFor],
  (managerRouteFor) =>
    managerRouteFor(
      VERCEL_PROJECT_MANAGER_NEWS_CREATE,
      VERCEL_ORGANIZATION_MANAGER_NEWS_CREATE,
    ),
)

export const getManagerPageRoute = createSelector(
  [getManagerRouteFor],
  (managerRouteFor) =>
    managerRouteFor(
      VERCEL_PROJECT_MANAGER_PAGE,
      VERCEL_ORGANIZATION_MANAGER_PAGE,
    ),
)

export const isProjectDonationEnabled = (state) =>
  path([NAME, 'donationEnabled'])(state)
export const isProjectRecurringDonationEnabled = (state) =>
  path([NAME, 'recurringDonation'])(state)
export const isProjectOnlyRecurringDonation = (state) =>
  path([NAME, 'onlyRecurringDonation'])(state)
export const isFiscalReceiptsEnabled = (state) =>
  path([NAME, 'fiscalReceiptsEnabled'])(state)
export const isPermanent = (state) => getOr(false)(`${NAME}.isPermanent`)(state)
export const isLogged = (state) => !!path([NAME, 'currentUser'])(state)
export const getCartDonationAmount = (state) =>
  path([NAME, 'cart', 'donation', 'cents'])(state) / 100 || ''
export const getShippingAddressCountryCode = (state) =>
  pathOr(undefined, [NAME, 'cart', 'shippingAddress', 'countryCode'])(state)

const isProjectMentor = (state) =>
  find((n) => n.slug === getCurrentUserSlug(state), getMentors(state))

export const needToShowMentorModal = createSelector(
  [isMentor, isProjectMentor, getMentorsSize],
  (isMentor, isProjectMentor, mentorSize) =>
    isMentor && !isProjectMentor && mentorSize < MENTORS_LIMIT,
)

export const isContributingActionDisabled = (state) =>
  isFinished(state[NAME].state)

const isProjectSuspended = (state) => !!getSuspendedAt(state)

export const isDonationDisabled = (state) => {
  if (isProjectSuspended(state)) return true

  return isProjectDonationEnabled(state) && isContributingActionDisabled(state)
}

export const isRewardDisabled = (state) => (reward) => {
  if (isProjectSuspended(state)) return true
  if (isContributingActionDisabled(state)) return true

  return isRewardUnavailable(state)(reward)
}

export const isRewardUnavailable = () => (reward) => {
  if (Number.isInteger(reward.remaining) && reward.remaining <= 0) return true
  if (reward.hasVariationSelection) {
    if (reward.soldOut) return true

    const totalQuantities = reward.variations.map(
      (variation) => variation.remainingQuantity,
    )

    if (totalQuantities.includes(null)) return false

    const totalRemainingQuantity = totalQuantities.reduce(
      (acc, quantity) => acc + quantity,
      0,
    )

    return totalRemainingQuantity <= 0
  }
  if (isNil(reward.variations[0].remainingQuantity)) return false

  return reward.variations[0].remainingQuantity <= 0
}

export const hasVariations = (state) =>
  pathOr([], [NAME, 'cart', 'variationsCount'])(state) > 0

export const getVariationsCount = (state) =>
  pathOr([], [NAME, 'cart', 'variationsCount'])(state)

export const getCartDeliveryAmount = (state) =>
  getOr({})(`${NAME}.cart.deliveryAmount`)(state)

export const getRewardsHaloStatus = (state) =>
  pathOr(false, [NAME, 'rewardsHaloStatus'])(state)

export const isPrelaunched = (state) =>
  pathOr(false, [NAME, 'isPrelaunched'])(state)

export const getChallenges = (state) => {
  const challenges = getOr([])(`${NAME}.challenges`)(state)
  return challenges.filter((challenge) => challenge.slug !== HIDDEN_CHALLENGE)
}
export const hasChallenges = createSelector(
  [getChallenges],
  (challenges) => challenges && challenges.length > 0,
)
export const getLanguages = (state) => pathOr([], [NAME, 'languages'])(state)
export const getProjectExtratimeState = (state) =>
  pathOr(false, [NAME, 'inExtratime'])(state)
export const isProjectExtratimeForced = (state) => {
  const extratimeState = getOr(null)(`${NAME}.extratimeState`)(state)
  return extratimeState === 'forced'
}
export const getCurrentExtratime = (state) =>
  flow(
    get('fundingCampaigns'),
    filter(({ deletedAt }) => !deletedAt),
    find({ label: 'extratime' }),
  )(state[NAME])
export const getCurrentCampaign = (state) =>
  flow(
    get('fundingCampaigns'),
    filter(({ deletedAt }) => !deletedAt),
    find({ label: 'main' }),
  )(state[NAME])

export const isAdminPanelDataFetched = (state) =>
  pathOr(false, [NAME, 'adminPanelInitialDataFetched'])(state)
export const isOwnerPanelDataFetched = (state) =>
  pathOr(false, [NAME, 'ownerPanelInitialDataFetched'])(state)
export const isIndexable = (state) => getOr(true)(`${NAME}.isIndexable`)(state)
export const isOptionsEnabled = (state) =>
  getOr(false)(`${NAME}.optionsEnabled`)(state)

const getZoneCountries = (zone) => {
  const code = toUpper(zone.zoneCode)

  return DELIVERY_COUNTRIES[code] || [code]
}

export const getDeliveryCountryAmounts = (reward) => {
  const deliveryCountryAmounts = flow(
    sort((zone) => -zone.priority),
    reduce((amounts, zone) => {
      const countries = getZoneCountries(zone)

      forEach((code) => {
        amounts[code] = {
          amount: zone.amount,
          countryCode: code,
        }
      }, countries)

      return amounts
    }, {}),
    values,
  )(reward.deliveryZones)

  return deliveryCountryAmounts
}

export const getDeliveryCostCalculationMethod = (state) =>
  getOr('max')(`${NAME}.deliveryCostCalculationMethod`)(state)

export const hasFees = (deliveryCountryAmounts) => {
  return deliveryCountryAmounts.some(({ amount }) => amount.cents > 0)
}
