import { pxToRem, Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import qs from 'qs'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import useBrowsing from '../hooks/use-browsing'
import FocusTrap from 'focus-trap-react'
import FilterButton from './components/filter-button'
import Filter from './components/filter'
import styled from 'styled-components'

const FILTER_ID = 'scope-button'

const ButtonsListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(2)};

  .kiss-ButtonsList__button {
    height: ${pxToRem(30)};

    &:hover,
    &:focus,
    &:active {
      font-weight: 500 !important;
    }
  }
`

const Sort = (props) => {
  const [showFilter, setShowFilter] = useState(false)
  const t = useTranslation()
  const { updateSearch } = useBrowsing()
  const { search } = useLocation()
  const { scope, sortArgument } = qs.parse(search, { ignoreQueryPrefix: true })

  const handleButtonClick = () => setShowFilter((current) => !current)
  const handleFilterClose = () => setShowFilter(false)

  let currentSort = 'all'

  if (sortArgument === 'funded_amount') {
    currentSort = 'amount'
  } else if (scope === 'popular') {
    currentSort = 'popular'
  }

  return (
    <FocusTrap
      active={showFilter}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div style={{ position: 'relative' }} {...props}>
        <FilterButton
          id={FILTER_ID}
          onClick={handleButtonClick}
          showFilter={showFilter}
        >
          {currentSort === 'all' && t('browsing.fitler.sort.none')}
          {currentSort === 'popular' && t('browsing.fitler.sort.popular')}
          {currentSort === 'amount' && t('browsing.fitler.sort.amount')}
        </FilterButton>

        {showFilter && (
          <Filter onFilterClose={handleFilterClose}>
            <ButtonsListWrapper>
              <Text
                as="button"
                size="small"
                className="k-u-reset-button kiss-ButtonsList__button"
                weight={currentSort === 'all' ? '500' : '400'}
                onClick={() => {
                  updateSearch({
                    scope: 'all',
                    sortArgument: null,
                    sortDirection: null,
                  })
                  handleFilterClose()
                }}
              >
                {t('browsing.fitler.sort.none')}
              </Text>

              <Text
                as="button"
                size="small"
                className="k-u-reset-button kiss-ButtonsList__button"
                weight={currentSort === 'popular' ? '500' : '400'}
                onClick={() => {
                  updateSearch({
                    scope: 'popular',
                    sortArgument: null,
                    sortDirection: null,
                  })
                  handleFilterClose()
                }}
              >
                {t('browsing.fitler.sort.popular')}
              </Text>

              <Text
                as="button"
                size="small"
                className="k-u-reset-button kiss-ButtonsList__button"
                weight={currentSort === 'amount' ? '500' : '400'}
                onClick={() => {
                  updateSearch({
                    scope: 'all',
                    sortArgument: 'funded_amount',
                    sortDirection: 'DESC',
                  })
                  handleFilterClose()
                }}
              >
                {t('browsing.fitler.sort.amount')}
              </Text>
            </ButtonsListWrapper>
          </Filter>
        )}
      </div>
    </FocusTrap>
  )
}

export default Sort
