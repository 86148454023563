import {
  Container,
  domElementHelper,
  Grid,
  GridCol,
  Text,
  Title,
  useContinuousIntersectionObserver,
} from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import { getAssetFor } from 'kiss/assets/redux'
import inRange from 'lodash/fp/inRange'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

const IntroHero = ({ title, paragraph_1, paragraph_2, children }) => {
  const assetFor = useSelector(getAssetFor)

  const [canUseDom, setDomUsability] = useState(false)
  const [visibleElement, setVisibleElement] = useState('start')
  const targetEl = useRef(null)

  useEffect(() => {
    setDomUsability(() => domElementHelper.canUseDom())
    if (domElementHelper.canUseDom() && !('IntersectionObserver' in window)) {
      setVisibleElement('target')
    }
  }, [])

  useContinuousIntersectionObserver({
    onIntersect: (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0.8) {
          setVisibleElement('target')
        } else {
          setVisibleElement('after')

          if (
            inRange(
              entry.boundingClientRect.bottom,
              entry.target.clientHeight / 2,
              entry.target.clientHeight,
            )
          ) {
            setVisibleElement('before')
          }
        }

        if (entry.boundingClientRect.top > entry.target.clientHeight) {
          setVisibleElement('start')
        }

        if (entry.boundingClientRect.bottom <= entry.target.clientHeight / 2) {
          setVisibleElement('end')
        }
      })
    },
    observedComponentRef: targetEl,
    root: null,
    rootMargin: '0px',
    threshold: [0, 0.25, 0.5, 0.75, 1],
  })

  return (
    <div
      className={classNames(
        'stats__DarkBackground',
        `DarkBackground--visible_element_${visibleElement}`,
        { 'DarkBackground--is_ready': canUseDom },
      )}
    >
      <div className="stats__StyledImages">
        <picture
          className="StyledImages__StyledImage"
          style={{
            '--TitleAndIntro-scale-factor-start': 1,
            '--TitleAndIntro-scale-factor-end': 0.9,
          }}
        >
          <source type="image/webp" srcSet={assetFor('stats/hero-3.webp')} />
          <source type="image/jpeg" srcSet={assetFor('stats/hero-3.jpg')} />
          <img src={assetFor('stats/hero-3.png')} alt="" />
        </picture>
        <picture
          className="StyledImages__StyledImage"
          style={{
            '--TitleAndIntro-scale-factor-start': 1,
            '--TitleAndIntro-scale-factor-end': 0.75,
          }}
        >
          <source type="image/webp" srcSet={assetFor('stats/hero-2.webp')} />
          <source type="image/png" srcSet={assetFor('stats/hero-2.png')} />
          <img src={assetFor('stats/hero-2.png')} alt="" />
        </picture>
        <picture
          className="StyledImages__StyledImage"
          style={{
            '--TitleAndIntro-scale-factor-start': 1,
            '--TitleAndIntro-scale-factor-end': 0.55,
          }}
        >
          <source type="image/webp" srcSet={assetFor('stats/hero-1.webp')} />
          <source type="image/png" srcSet={assetFor('stats/hero-1.png')} />
          <img src={assetFor('stats/hero-1.png')} alt="" />
        </picture>
      </div>
      <section className="stats__TitleAndIntro">
        <Container className="TitleAndIntro__section TitleAndIntro__TitleSection">
          <Title
            className="TitleAndIntro__Title Stats__Title__Size1"
            tag="h1"
            family="antiqueolive"
          >
            {title}
          </Title>
          <svg
            className="k-u-margin-top-single k-u-hidden@s-up"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 22 41"
            style={{ width: 22, height: 41 }}
          >
            <path
              fill="var(--color-grey-000)"
              d="M12.535534.5l-.000602 34.363 6.985883-6.98432L21.642136 30 11.035534 40.606602.428932 30l2.121321-2.12132L9.534932 34.863 9.535534.5h3z"
            />
          </svg>
          <svg
            className="k-u-margin-top-single k-u-hidden@xs-down k-u-hidden@l-up"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 29 51"
            style={{ width: 29, height: 51 }}
          >
            <path
              fill="var(--color-grey-000)"
              d="M15.142136 0L15.142 44.899l11.020951-11.02032L28.284271 36 14.142136 50.142136 0 36l2.12132-2.12132L12.142 43.899 12.142136 0h3z"
            />
          </svg>
          <svg
            className="k-u-margin-top-double k-u-margin-bottom-quadruple k-u-hidden@m-down"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 71"
            style={{ width: 36, height: 71 }}
          >
            <path
              fill="var(--color-grey-000)"
              d="M19.142136 0l-.00067 64.899 14.557019-14.555854 2.12132 2.12132-17.677669 17.67767-17.67767-17.67767 2.12132-2.12132L16.141466 63.899 16.142136 0h3z"
            />
          </svg>
        </Container>
        <div ref={targetEl}>
          <Container className="TitleAndIntro__section TitleAndIntro__IntroSection">
            <Grid>
              <GridCol col={12} col-s={10} offset-s={1} col-l={6} offset-l={3}>
                <Text tag="p" weight="400" size="large" lineHeight="normal">
                  {paragraph_1}
                </Text>
                <Text
                  tag="p"
                  weight="400"
                  size="large"
                  lineHeight="normal"
                  className="k-u-margin-top-double"
                >
                  {paragraph_2}
                </Text>
              </GridCol>
            </Grid>
          </Container>
        </div>
      </section>

      {children}
    </div>
  )
}

export default IntroHero
