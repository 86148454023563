import {
  Button,
  Grid,
  GridCol,
  LockOutlineIcon,
  pxToRem,
  ShieldCheckIcon,
  Text,
} from '@kisskissbankbank/kitten'
import { Formik } from 'formik'
import query from 'kiss/api/graphql-query'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import EnabledAfterMount from 'kiss/app/common/enabled-after-mount'
import SubmitLoader from 'kiss/components/buttons/submit-loader'
import Label from 'kiss/components/formik/label'
import NewPassword from 'kiss/components/formik/new-password'
import SimpleText from 'kiss/components/formik/simple-text'
import config from 'kiss/config'
import accountByEmail from 'kiss/graphql/authenticate/account_by_email.graphql'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getRouteFor as getRouteForSelector,
  MENTOR_SIGNUP,
  SIGN_IN,
  CGU,
} from 'kiss/routes/redux'
import { getReturnTo, signUp } from 'kiss/session/redux'
import { scrollTo } from 'kiss/utils/animation/scroll-to'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { addFlowConnectionFlag } from 'kiss/utils/url/query-string-helper'
import isEmpty from 'lodash/fp/isEmpty'
import trim from 'lodash/fp/trim'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import styled from 'styled-components'
import { object, string } from 'yup'
import RgpdModal from '../../../../components/modals/rgpd'
import ExplainLine from '../explain-line'
import PasswordHelper, { calculateProgress } from '../password-helper'
import { FacebookPixelHelper } from 'kiss/utils/tracking/facebook-pixel/helper'

const checkIfEmailExists = (email) => (_dispatch, getState) => {
  return query(accountByEmail, { email }, getState())
}

const ExplainBlock = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  grid-gap: ${pxToRem(20)};
  padding: ${pxToRem(20)};
  margin-top: ${pxToRem(20)};
  background-color: var(--color-grey-200);
  border-radius: var(--border-radius-m);
`

const SignUp = ({ nextUrl, signInUrl, afterRedirect, projectId }) => {
  const t = useTranslation()
  const dispatch = useDispatch()
  const recaptchaRef = useRef(null)
  const [showPasswordHelper, setShowPasswordHelper] = useState(false)
  const [isEmailExist, setIsEmailExist] = useState(false)
  const getRouteFor = useSelector(getRouteForSelector)
  const returnToProp = useSelector(getReturnTo)
  const returnTo = nextUrl || returnToProp
  const history = useHistory()
  const { search } = useLocation()
  const searchObject = qs.parse(search, { ignoreQueryPrefix: true })
  const { email: defaultEmail, mentor } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })

  const validateEmail = (email) => {
    if (isEmpty(email)) {
      return t('authenticate.registration.error.email.required')
    }

    if (!string().email().isValidSync(email)) {
      return t('authenticate.registration.error.email.valid_email')
    }

    if (isEmailExist) {
      return t('authenticate.registration.error.email.already_exists', {
        loginLink: `<a class="k-FormInfo__error__link" href="${
          signInUrl ? signInUrl : getRouteFor(SIGN_IN)
        }">${t('authenticate.registration.error.email.login_link_text')}</a>`,
        parseHtml: true,
      })
    }
  }

  const validationSchema = object().shape({
    firstName: string()
      .min(2, t('authenticate.registration.error.firstname.too_short'))
      .max(100, t('authenticate.registration.error.firstname.too_long'))
      .required(t('authenticate.registration.error.firstname.required')),
    lastName: string()
      .min(2, t('authenticate.registration.error.lastname.too_short'))
      .max(100, t('authenticate.registration.error.lastname.too_long'))
      .required(t('authenticate.registration.error.lastname.required')),
    password: string().test(
      'is-secure',
      t('authenticate.registration.error.password.secure'),
      (value) => calculateProgress(value) === 100,
    ),
  })

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        password: '',
        email: defaultEmail,
        termsOfServiceAndPayment: true,
        invitationToken: undefined,
      }}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          const response = await dispatch(
            signUp({
              email: trim(values.email),
              password: values.password,
              firstName: values.firstName,
              lastName: values.lastName,
              recaptchaToken: await recaptchaRef?.current?.executeAsync(),
              invitationToken: searchObject?.invitation_token,
              projectId,
            }),
          )

          afterRedirect()

          const trackedData = {
            event: 'sign_up',
            user_data: {
              user_id: response.signUp.user.id,
              user_firstname: values.firstName,
              user_lastname: values.lastName,
              user_email: trim(values.email),
            },
          }
          FacebookPixelHelper.pushToGtm(window.dataLayer, trackedData)

          if (mentor) {
            history.push(
              getRouteFor(MENTOR_SIGNUP, {
                mentor: response.signUp.user.slug,
              }),
            )
          } else if (searchObject?.origin === 'manager') {
            RoutingHelper.redirect(
              `${config[APP_ENV].manager.host}/${searchObject?.resolvedUrl}`,
            )
          } else {
            RoutingHelper.redirect(
              addFlowConnectionFlag(returnTo, { registered: true }),
            )
          }
        } catch (e) {
          recaptchaRef?.current?.reset()
          setSubmitting(false)
        }
      }}
    >
      {({ handleSubmit, validateField, isSubmitting, values }) => (
        <EnabledAfterMount>
          {(enabled) => (
            <form id="registration-form" onSubmit={handleSubmit}>
              <Grid>
                <GridCol
                  col-xs={12}
                  col-l={6}
                  className="k-u-margin-bottom-double"
                >
                  <Label
                    htmlFor="firstName"
                    label={t('authenticate.registration.label.firstname')}
                  />

                  <AutoSelectInput
                    name="firstName"
                    placeholder={t(
                      'authenticate.registration.placeholder.firstname',
                    )}
                    disabled={!enabled}
                    validate={(value) => {
                      if (isEmpty(value) || (value && isEmpty(value.trim()))) {
                        return t(
                          'authenticate.registration.error.firstname.required',
                        )
                      }
                    }}
                  />
                </GridCol>
                <GridCol
                  col-xs={12}
                  col-l={6}
                  className="k-u-margin-bottom-double"
                >
                  <Label
                    htmlFor="lastName"
                    label={t('authenticate.registration.label.lastname')}
                  />

                  <SimpleText
                    name="lastName"
                    placeholder={t(
                      'authenticate.registration.placeholder.lastname',
                    )}
                    disabled={!enabled}
                    validate={(value) => {
                      if (isEmpty(value) || (value && isEmpty(value.trim()))) {
                        return t(
                          'authenticate.registration.error.lastname.required',
                        )
                      }
                    }}
                  />
                </GridCol>
              </Grid>

              <Grid>
                <GridCol col-xs={12} col-l={11}>
                  <div className="k-u-margin-bottom-double">
                    <Label
                      htmlFor="email"
                      label={t('authenticate.registration.label.email')}
                    />
                    <SimpleText
                      name="email"
                      placeholder={t(
                        'authenticate.registration.placeholder.email',
                      )}
                      disabled={!enabled}
                      validate={validateEmail}
                      type="email"
                      onBlur={(e) => {
                        dispatch(checkIfEmailExists(e.target.value)).then(
                          (response) => {
                            setIsEmailExist(!!response.accountByEmail)
                            validateField('email')
                          },
                          (_error) => {
                            dispatch(
                              addErrorAlert('Error: something went wrong!', {
                                scroll: true,
                              }),
                            )
                          },
                        )
                      }}
                    />
                  </div>
                  <div className="k-u-margin-bottom-double" id="password-input">
                    <Label htmlFor="password">
                      {t('authenticate.registration.label.password')}
                    </Label>
                    <NewPassword
                      name="password"
                      iconLabel="show password"
                      hiddenIconLabel="hidden password"
                      placeholder={t(
                        'authenticate.registration.placeholder.password',
                      )}
                      disabled={!enabled}
                      aria-describedby="security-notification"
                      autoComplete="new-password"
                      onFocus={() => {
                        scrollTo(document.getElementById('password-input'))
                        setShowPasswordHelper(true)
                      }}
                      onBlur={() => setShowPasswordHelper(false)}
                    />
                    {showPasswordHelper && (
                      <PasswordHelper password={values.password} />
                    )}
                  </div>
                </GridCol>
              </Grid>

              <Text
                tag="p"
                lineHeight={1.3}
                size="small"
                className="k-u-margin-bottom-double"
              >
                {t(
                  'authenticate.registration.terms_of_service_and_payment.body',
                  {
                    terms_url: getRouteFor(CGU),
                    parseHtml: true,
                  },
                )}
              </Text>

              {isSubmitting ? (
                <SubmitLoader fit="fluid" size="medium" />
              ) : (
                <Button
                  type="submit"
                  modifier="helium"
                  data-test-id="submit"
                  fit="fluid"
                  disabled={!enabled}
                >
                  {t('authenticate.registration.action_button.body')}
                </Button>
              )}
              {!['test'].includes(APP_ENV) && (
                <ReCAPTCHA
                  ref={recaptchaRef}
                  size="invisible"
                  sitekey={config[APP_ENV].recaptcha.siteKey}
                />
              )}
              <ExplainBlock>
                <ExplainLine icon={LockOutlineIcon}>
                  <ExplainLine.Text size="micro">
                    <RgpdModal
                      signUp={true}
                      regularText
                      textColor="var(--color-grey-700)"
                    />
                  </ExplainLine.Text>
                </ExplainLine>
                <ExplainLine icon={ShieldCheckIcon}>
                  <ExplainLine.Text size="micro">
                    {t('authenticate.registration.recaptcha.body', {
                      parseHtml: true,
                    })}
                  </ExplainLine.Text>
                </ExplainLine>
              </ExplainBlock>
            </form>
          )}
        </EnabledAfterMount>
      )}
    </Formik>
  )
}

const AutoSelectInput = ({ name, disabled, ...props }) => {
  useEffect(() => {
    if (disabled) return
    !!document && document?.getElementById(name)?.focus()
  }, [disabled])

  return <SimpleText name={name} disabled={disabled} {...props} />
}

SignUp.propTypes = {
  nextUrl: PropTypes.string,
  signInUrl: PropTypes.string,
  afterRedirect: PropTypes.func,
  buttonProps: PropTypes.object,
  projectId: PropTypes.string,
  invitationToken: PropTypes.string,
}

SignUp.defaultProps = {
  nextUrl: null,
  signInUrl: null,
  afterRedirect: () => {},
  buttonProps: {},
}

export default SignUp
