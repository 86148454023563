import React from 'react'
import { useQuery } from 'kiss/api/graphql-query'
import { useSelector } from 'react-redux'
import TotalMentoredProjectsQuery from 'kiss/graphql/queries/mentor/total-mentored-projects.graphql'
import getOr from 'lodash/fp/getOr'
import { useHistory, useParams, useRouteMatch } from 'react-router-dom'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getRouteFor,
  MENTOR_PAGE,
  MENTOR_PAGE_PROJECTS_TAB,
  MENTOR_PAGE_EDITION,
} from 'kiss/routes/redux'

import { Badge, NavBar } from '@kisskissbankbank/kitten'

const Navigation = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const { mentor } = useParams()
  const { data } = useQuery(TotalMentoredProjectsQuery, {
    slug: mentor,
  })
  const total = getOr(0)('user.mentoredProjects.totalCount')(data)
  const history = useHistory()

  const descriptionRoute = routeFor(MENTOR_PAGE, { mentor })
  const projectsRoute = routeFor(MENTOR_PAGE_PROJECTS_TAB, { mentor })

  const isMatchEditionPage = !!useRouteMatch(MENTOR_PAGE_EDITION)
  const isMatchMentorPage = !!useRouteMatch({ path: MENTOR_PAGE, exact: true })

  return (
    <NavBar
      modifier="large"
      colors={{
        background: 'transparent',
      }}
    >
      <NavBar.ListItem
        active={isMatchEditionPage || isMatchMentorPage}
        linkProps={{
          href: descriptionRoute,
          onClick: (e) => {
            e.preventDefault()
            history.push(descriptionRoute)
          },
        }}
      >
        {t('mentor_page.navigation.who_are_we')}
      </NavBar.ListItem>
      <NavBar.ListItem
        active={useRouteMatch({
          path: MENTOR_PAGE_PROJECTS_TAB,
          exact: true,
        })}
        linkProps={{
          href: projectsRoute,
          onClick: (e) => {
            e.preventDefault()
            history.push(projectsRoute)
          },
        }}
      >
        {t('mentor_page.navigation.backed_projects')}
        <Badge spaced>{total}</Badge>
      </NavBar.ListItem>
    </NavBar>
  )
}

export default Navigation
