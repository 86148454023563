import {
  Button,
  EditIcon,
  Grid,
  GridCol,
  Modal,
  Text,
  VisuallyHidden,
} from '@kisskissbankbank/kitten'
import { Formik } from 'formik'
import Checkbox from 'kiss/components/formik/checkbox'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'
import { useTranslation } from 'kiss/hooks/use-translation'
import { MENTOR_PAGE_EDITION } from 'kiss/routes/redux'
import flow from 'lodash/fp/flow'
import isEmpty from 'lodash/fp/isEmpty'
import keys from 'lodash/fp/keys'
import reduce from 'lodash/fp/reduce'
import React, { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import * as Yup from 'yup'
import {
  getCustomFields,
  getFacebookUrl,
  getInstagramUrl,
  getLinkedInUrl,
  getTwitterUrl,
  getWebsiteUrl,
  isBackedAmountHidden,
  isNumberOfBackedProjectsHidden,
  updateMentor,
} from '../redux'
import SocialEdit from './social-edit'

const CUSTOM_VALUES_SIZE = 4

const InformationsEdit = () => {
  const isPageEdition = !!useRouteMatch(MENTOR_PAGE_EDITION)
  const customFields = useSelector(getCustomFields)
  const facebookUrl = useSelector(getFacebookUrl)
  const instagramUrl = useSelector(getInstagramUrl)
  const twitterUrl = useSelector(getTwitterUrl)
  const linkedInUrl = useSelector(getLinkedInUrl)
  const websiteUrl = useSelector(getWebsiteUrl)
  const hideNumberOfBackedProjects = useSelector(isNumberOfBackedProjectsHidden)
  const hideBackedAmount = useSelector(isBackedAmountHidden)
  const dispatch = useDispatch()
  const t = useTranslation()
  if (!isPageEdition) return null

  return (
    <Modal
      size="large"
      trigger={
        <Button fit="fluid" className="k-u-margin-top-triple">
          <EditIcon />
          <span>{t('app.button.edit')}</span>
        </Button>
      }
    >
      {({ close }) => {
        return (
          <>
            <Modal.Title>
              {t('mentor_page.edition.informations.modal.title')}
            </Modal.Title>
            <Modal.Content align="center">
              <Text size="large">
                {t('mentor_page.edition.informations.modal.subtitle')}
              </Text>
            </Modal.Content>
            <Formik
              initialValues={{
                hideNumberOfBackedProjects,
                hideBackedAmount,
                ...customFields,
                social: {
                  facebookUrl,
                  instagramUrl,
                  twitterUrl,
                  linkedInUrl,
                  websiteUrl,
                },
              }}
              validationSchema={Yup.object().shape({
                social: Yup.object().shape({
                  websiteUrl: Yup.string()
                    .matches(/^https?:\/\/.*/i, {
                      message: t('app.forms.error.url', {
                        placeholder: t(
                          'mentor.signup.form.social.website.placeholder',
                        ),
                      }),
                    })
                    .nullable(),
                  facebookUrl: Yup.string()
                    .matches(
                      /^(?:https?:\/\/)(?:www.)?(?:facebook.com\/)(?:.{3,})/,
                      {
                        message: t('app.forms.error.url', {
                          placeholder: t(
                            'mentor.signup.form.social.facebook.placeholder',
                          ),
                        }),
                      },
                    )
                    .nullable(),
                  instagramUrl: Yup.string()
                    .matches(
                      /^(?:https?:\/\/)(?:www.)?(?:instagram.com\/)(?:.{3,})/,
                      {
                        message: t('app.forms.error.url', {
                          placeholder: t(
                            'mentor.signup.form.social.instagram.placeholder',
                          ),
                        }),
                      },
                    )
                    .nullable(),
                  linkedInUrl: Yup.string()
                    .matches(
                      /^(?:https?:\/\/)(?:www.)?(?:linkedin.com\/)(?:.{3,})/,
                      {
                        message: t('app.forms.error.url', {
                          placeholder: t(
                            'mentor.signup.form.social.linkedin.placeholder',
                          ),
                        }),
                      },
                    )
                    .nullable(),
                  twitterUrl: Yup.string()
                    .matches(
                      /^(?:https?:\/\/)(?:www.)?(?:twitter.com\/)(?:.{3,})/,
                      {
                        message: t('app.forms.error.url', {
                          placeholder: t(
                            'mentor.signup.form.social.twitter.placeholder',
                          ),
                        }),
                      },
                    )
                    .nullable(),
                }),
                field1: Yup.object().shape({
                  title: Yup.string().max(
                    70,
                    t('app.forms.error.maxLength', { maxLength: 70 }),
                  ),
                  value: Yup.string().max(
                    70,
                    t('app.forms.error.maxLength', { maxLength: 70 }),
                  ),
                }),
                field2: Yup.object().shape({
                  title: Yup.string().max(
                    70,
                    t('app.forms.error.maxLength', { maxLength: 70 }),
                  ),
                  value: Yup.string().max(
                    70,
                    t('app.forms.error.maxLength', { maxLength: 70 }),
                  ),
                }),
                field3: Yup.object().shape({
                  title: Yup.string().max(
                    70,
                    t('app.forms.error.maxLength', { maxLength: 70 }),
                  ),
                  value: Yup.string().max(
                    70,
                    t('app.forms.error.maxLength', { maxLength: 70 }),
                  ),
                }),
                field4: Yup.object().shape({
                  title: Yup.string().max(
                    70,
                    t('app.forms.error.maxLength', { maxLength: 70 }),
                  ),
                  value: Yup.string().max(
                    70,
                    t('app.forms.error.maxLength', { maxLength: 70 }),
                  ),
                }),
              })}
              onSubmit={async (values) => {
                const filteredValues = flow(
                  keys,
                  reduce((acc, key) => {
                    const { title, value } = values[key]
                    if (!isEmpty(title) && !isEmpty(value)) {
                      return { ...acc, [key]: values[key] }
                    }
                    return acc
                  }, {}),
                )(values)
                await dispatch(
                  updateMentor({
                    customFields: filteredValues,
                    hideBackedAmount: values.hideBackedAmount,
                    hideNumberOfBackedProjects:
                      values.hideNumberOfBackedProjects,
                    ...values.social,
                  }),
                )
                close()
              }}
            >
              {({ handleSubmit, isSubmitting }) => {
                return (
                  <Modal.Content align="left">
                    <Grid>
                      {Array.from(
                        { length: CUSTOM_VALUES_SIZE },
                        (v, i) => i + 1,
                      ).map((position) => {
                        return (
                          <Fragment key={`field-position-${position}`}>
                            <GridCol
                              col="12"
                              col-s="6"
                              className="k-u-margin-bottom-triple"
                            >
                              <Label htmlFor={`field${position}[title]`}>
                                {t(
                                  'mentor_page.edition.informations.label.title',
                                  {
                                    position,
                                  },
                                )}
                              </Label>
                              <SimpleText
                                name={`field${position}[title]`}
                                limit={70}
                                placeholder={t(
                                  'mentor_page.edition.informations.placeholder.title',
                                )}
                              />
                            </GridCol>
                            <GridCol
                              col="12"
                              col-s="6"
                              className="k-u-margin-bottom-triple"
                            >
                              <Label htmlFor={`field${position}[value]`}>
                                {t(
                                  'mentor_page.edition.informations.label.value',
                                  {
                                    position,
                                  },
                                )}
                              </Label>
                              <SimpleText
                                name={`field${position}[value]`}
                                limit={70}
                                placeholder={t(
                                  'mentor_page.edition.informations.placeholder.value',
                                )}
                              />
                            </GridCol>
                          </Fragment>
                        )
                      })}
                      <GridCol col="12" className="k-u-margin-bottom-single">
                        <Checkbox name="hideNumberOfBackedProjects">
                          {t(
                            'mentor_page.edition.informations.label.show_projects',
                          )}
                        </Checkbox>
                        <Checkbox name="hideBackedAmount">
                          {t(
                            'mentor_page.edition.informations.label.show_amount',
                          )}
                        </Checkbox>
                      </GridCol>
                    </Grid>
                    <SocialEdit />
                    <Modal.Actions>
                      <Button
                        onClick={handleSubmit}
                        modifier="helium"
                        disabled={isSubmitting}
                      >
                        {isSubmitting && (
                          <>
                            <VisuallyHidden>
                              {t('app.accessibility.submit.data')}
                            </VisuallyHidden>
                            <LoadingAnimation color="var(--color-grey-000)" />
                          </>
                        )}
                        {!isSubmitting && t('app.action.save')}
                      </Button>
                    </Modal.Actions>
                  </Modal.Content>
                )
              }}
            </Formik>
          </>
        )
      }}
    </Modal>
  )
}

export default InformationsEdit
