import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'kiss/hooks/use-translation'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { Grid, GridCol, Title } from '@kisskissbankbank/kitten'

const Custom = ({ limit }) => {
  const t = useTranslation()

  return (
    <section id="custom-section">
      <Title
        tag="p"
        modifier="quinary"
        className="k-u-margin-top-quintuple k-u-margin-top-decuple@s-up k-u-margin-bottom-triple"
      >
        {t('gift_card.new.form.custom.title')}
      </Title>

      <Grid>
        <GridCol col-l="6">
          <Label size="medium" htmlFor="from">
            {t('gift_card.new.form.custom.from.label')}
          </Label>

          <SimpleText id="from" name="from" size="large" />
        </GridCol>

        <GridCol col-l="6" className="k-u-margin-top-triple@m-down">
          <Label size="medium" htmlFor="to">
            {t('gift_card.new.form.custom.to.label')}
          </Label>

          <SimpleText id="to" name="to" size="large" />
        </GridCol>
      </Grid>

      <div className="k-u-margin-top-triple">
        <Label size="medium" htmlFor="message">
          {t('gift_card.new.form.custom.message.label')}
        </Label>

        <SimpleText
          tag="textarea"
          id="message"
          name="message"
          rows="3"
          limit={limit}
          size="large"
        />
      </div>
    </section>
  )
}

Custom.propTypes = {
  limit: PropTypes.number.isRequired,
}

export default Custom
