import has from 'ramda/src/has'
import { getAuthenticityToken, getGrapeAccessToken } from 'kiss/session/redux'

const needsAuthenticityTokenMethods = ['post', 'put', 'delete']
const needsAuthenticityToken = (config = {}) =>
  !!config.method &&
  needsAuthenticityTokenMethods.includes(config.method.toLowerCase())

const hasError = has('error')
const hasErrors = has('errors')

const railsQuery = (url, config, state = {}) => {
  const authenticityToken = getAuthenticityToken(state)
  const grapeAccessToken = getGrapeAccessToken(state)

  if (needsAuthenticityToken(config) && !authenticityToken) {
    throw new Error('Missing `authenticityToken` in session state!')
  }

  const requestConfig = {
    credentials: 'same-origin',
    ...config,
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': authenticityToken,
      'X-Grape-Access-Token': grapeAccessToken,
      ...config.headers,
    },
  }

  return fetch(url, requestConfig)
}

const handleResponseError = (json) =>
  hasErrors(json) || hasError(json)
    ? Promise.reject({ ...json.errors, ...json.error, server: true })
    : json

const handleServerError = (response) => {
  if (!response.ok && [500, 503].includes(response.status)) {
    return Promise.reject({
      errors: [
        {
          status: response.status,
          detail: response.statusText,
        },
      ],
      server: true,
    })
  }
  return response.json()
}

export const create = (url, data, state) =>
  railsQuery(
    url,
    {
      method: 'POST',
      body: JSON.stringify(data),
    },
    state,
  )
    .then(handleServerError)
    .then(handleResponseError)

export default railsQuery
