import React from 'react'

import { useSelector } from 'react-redux'

import { useTranslation } from 'kiss/hooks/use-translation'

import {
  BEST_CAMPAIGNS,
  BLOG,
  BRANDING_GUIDELINES,
  CAMPAIGN_TIPS_PREPARE_YOUR_COLLECT,
  CROWDFUNDING,
  FONCTIONNALITES,
  CRITERE_SELECTIONS,
  FUND_MY_PROJECT_CAMPAIGN,
  FUND_MY_PROJECT_PRE_ORDER,
  FUND_MY_PROJECT_SUBSCRIPTION,
  getRouteFor,
  HELP,
  JOBS,
  LANDINGS_ROUTE,
  LE_STUDIO,
  LOCAL_AUTHORITIES,
  MAISON_DE_CROWDFUNDING,
  MANIFESTO,
  MENTORS,
  PAGE_LA_BANQUE_POSTALE,
  PARTNER_DEALS,
  PARTNERS,
  PRESS,
  STATS,
  TEAM_MINIPAGE,
  VALUES,
} from 'kiss/routes/redux'

import { ScreenConfig, Text } from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledItemContainer = styled.div`
  min-width: 322px;

  li {
    height: 44px;
  }

  @media (min-width: ${ScreenConfig.M.max}px) {
    min-width: auto;

    li {
      height: initial;
    }
  }
`

const ListItem = (props) => (
  <li>
    <a className="k-Footer__linkList--item" {...props} />
  </li>
)

export const ListItems = (props) => {
  const t = useTranslation()

  const routeFor = useSelector(getRouteFor)

  return (
    <div className="k-Footer__listWrapper k-u-margin-top-triple" {...props}>
      {/* 1 */}
      <StyledItemContainer>
        <Text
          size="large"
          weight="700"
          cssColor="var(--color-grey-000)"
          tag="h3"
        >
          {t('layouts.footer.fund_a_project')}
        </Text>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="4"
          viewBox="0 0 30 4"
          fill="none"
        >
          <rect width="30" height="4" fill="white" />
        </svg>
        <ul className="k-Footer__linkList">
          <ListItem href={routeFor(CAMPAIGN_TIPS_PREPARE_YOUR_COLLECT)}>
            {t('layouts.footer.method')}
          </ListItem>
          <ListItem href={routeFor(FONCTIONNALITES)}>
            {t('layouts.footer.fonctionnalites')}
          </ListItem>
          <ListItem href={routeFor(CRITERE_SELECTIONS)}>
            {t('layouts.footer.criteres_selection')}
          </ListItem>
          <ListItem href={routeFor(CROWDFUNDING)}>
            {t('layouts.footer.crowdfunding')}
          </ListItem>
          <ListItem href={routeFor(FUND_MY_PROJECT_CAMPAIGN)}>
            {t('layouts.footer.fund_my_project_campaign')}
          </ListItem>
          <ListItem href={routeFor(FUND_MY_PROJECT_PRE_ORDER)}>
            {t('layouts.footer.fund_my_project_pre_order')}
          </ListItem>
          <ListItem href={routeFor(FUND_MY_PROJECT_SUBSCRIPTION)}>
            {t('layouts.footer.fund_my_project_subscription')}
          </ListItem>
          <ListItem hrefLang="fr" href={routeFor(BRANDING_GUIDELINES)}>
            {t('layouts.footer.branding_guidelines')}
          </ListItem>
          <ListItem href={routeFor(BLOG)}>{t('layouts.footer.blog')}</ListItem>
          <ListItem href={routeFor(LE_STUDIO)} hrefLang="fr" rel="noopener">
            {t('layouts.footer.le_studio')}
          </ListItem>
          <ListItem href={routeFor(HELP)} target="_blank" rel="noopener">
            {t('layouts.footer.faq')}
          </ListItem>
        </ul>
      </StyledItemContainer>

      {/* 2 */}
      <StyledItemContainer>
        <Text
          size="large"
          weight="700"
          cssColor="var(--color-grey-000)"
          tag="h3"
        >
          {t('layouts.footer.partnerships')}
        </Text>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="4"
          viewBox="0 0 30 4"
          fill="none"
        >
          <rect width="30" height="4" fill="white" />
        </svg>
        <ul className="k-Footer__linkList">
          <ListItem href={routeFor(PARTNERS)}>
            {t('layouts.footer.partners')}
          </ListItem>
          <ListItem hrefLang="fr" href={routeFor(PARTNER_DEALS)}>
            {t('layouts.footer.partner_deals')}
          </ListItem>
          <ListItem hrefLang="fr" href={routeFor(LOCAL_AUTHORITIES)}>
            {t('layouts.footer.local_authorities')}
          </ListItem>
          <ListItem href={routeFor(MENTORS)}>
            {t('layouts.footer.mentors')}
          </ListItem>
          <ListItem href={routeFor(MAISON_DE_CROWDFUNDING)} hrefLang="fr">
            {t('layouts.footer.house')}
          </ListItem>
          <ListItem href={routeFor(PAGE_LA_BANQUE_POSTALE)}>
            {t('layouts.footer.page_la_banque_postale')}
          </ListItem>
        </ul>
      </StyledItemContainer>

      {/* 3 */}
      <StyledItemContainer>
        <Text
          size="large"
          weight="700"
          cssColor="var(--color-grey-000)"
          tag="h3"
        >
          {t('layouts.footer.about_kisskissbankbank')}
        </Text>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="4"
          viewBox="0 0 30 4"
          fill="none"
        >
          <rect width="30" height="4" fill="white" />
        </svg>
        <ul className="k-Footer__linkList">
          <ListItem href={routeFor(MANIFESTO)}>
            {t('layouts.footer.manifesto')}
          </ListItem>
          <ListItem href={routeFor(VALUES)}>
            {t('layouts.footer.values')}
          </ListItem>
          <ListItem href={routeFor(STATS)}>
            {t('layouts.footer.stats')}
          </ListItem>
          <ListItem href={routeFor(BEST_CAMPAIGNS)}>
            {t('layouts.footer.best_campaigns')}
          </ListItem>
          <ListItem href={routeFor(TEAM_MINIPAGE)}>
            {t('layouts.footer.team')}
          </ListItem>
          <ListItem
            href={routeFor(JOBS)}
            target="_blank"
            rel="noopener"
            hrefLang="fr"
          >
            {t('layouts.footer.jobs')}
          </ListItem>
          <ListItem href={routeFor(PRESS)}>
            {t('layouts.footer.press')}
          </ListItem>
        </ul>
      </StyledItemContainer>

      {/* 4 */}
      <StyledItemContainer>
        <Text
          size="large"
          weight="700"
          cssColor="var(--color-grey-000)"
          tag="h3"
        >
          {t('layouts.footer.our_engagements')}
        </Text>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="4"
          viewBox="0 0 30 4"
          fill="none"
        >
          <rect width="30" height="4" fill="white" />
        </svg>
        <ul className="k-Footer__linkList">
          <ListItem
            href={routeFor(LANDINGS_ROUTE, {
              page: 'maladie-handicap',
            })}
          >
            {t('engagement.maladie-handicap.name', {
              parseHtml: true,
            })}
          </ListItem>
          <ListItem
            href={routeFor(LANDINGS_ROUTE, {
              page: 'autoproduction-culturelle',
            })}
          >
            {t('engagement.autoproduction-culturelle.name', {
              parseHtml: true,
            })}
          </ListItem>
          <ListItem
            href={routeFor(LANDINGS_ROUTE, {
              page: 'bio',
            })}
          >
            {t('engagement.bio.name', {
              parseHtml: true,
            })}
          </ListItem>
        </ul>
      </StyledItemContainer>
    </div>
  )
}
