import React from 'react'
import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import {
  Button,
  pxToRem,
  ScreenConfig,
  TitleWithStroke,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledInfoCard = styled.div`
  display: flex;
  flex-direction: column;
  margin: 32px 20px;
  border-radius: 10px;
  padding: ${pxToRem(20)};
  margin: auto;

  img {
    height: ${pxToRem(120)};
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    flex-direction: row;
    gap: ${pxToRem(32)};
    padding: ${pxToRem(30)};
    max-width: none;
  }
`
const StyledCardParagraph = styled.p`
  font-family: 'GeneralSans';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 16px;
  margin-bottom: 16px;
  color: var(--color-grey-900);

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    font-size: 16px;
    line-height: 26px;
  }
`

const StyledButtonContainer = styled.div`
  margin-top: auto;

  a {
    width: 100%;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    a {
      width: auto;
    }
  }
`

const StyledTextContainer = styled.div`
  margin-top: 15px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin-top: 0px;
  }
`

const HorizontalColorInfoCard = ({
  backgroundColor,
  imagePath,
  title,
  paragraph,
  buttonTitle,
  buttonLink,
}) => {
  const assetFor = useSelector(getAssetFor)

  return (
    <>
      <StyledInfoCard style={{ background: backgroundColor }}>
        <div className="k-u-alignSelf-center">
          <img src={assetFor(imagePath)} alt={imagePath} />
        </div>
        <StyledTextContainer>
          <TitleWithStroke
            tag="h1"
            align="left"
            modifier="quaternary"
            noMargin
            family="antiqueolive"
            className="k-u-font-weight-700"
            cssColor="var(--color-primary-500)"
          >
            <span className="k-u-font-size-7">{title}</span>
          </TitleWithStroke>
          <StyledCardParagraph>{paragraph}</StyledCardParagraph>
          <StyledButtonContainer>
            <Button
              size="small"
              fit="content"
              as="a"
              mobileFit="fluid"
              href={buttonLink}
              target="_blank"
              modifier="helium"
            >
              {buttonTitle}
            </Button>
          </StyledButtonContainer>
        </StyledTextContainer>
      </StyledInfoCard>
    </>
  )
}

HorizontalColorInfoCard.propTypes = {
  backgroundColor: PropTypes.string,
  imagePath: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  paragraph: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  buttonLink: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default HorizontalColorInfoCard
