import React from 'react'

import { getRouteFor, SERVICES_TYPEFORM } from 'kiss/routes/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import {
  Button,
  Paragraph,
  pxToRem,
  ScreenConfig,
  TitleWithStroke,
} from '@kisskissbankbank/kitten'

import IllustrationBlock from 'kiss/components/illustrations/illustration-block'

import styled from 'styled-components'

const StyledIntroContainer = styled.div`
  display: flex;
  gap: ${pxToRem(20)};
  flex-direction: column;
  margin: 32px 20px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin-top: ${pxToRem(64)};
    margin-right: ${pxToRem(40)};
    margin-left: ${pxToRem(40)};
    margin-bottom: ${pxToRem(32)};
    gap: ${pxToRem(64)};
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledIntroChild = styled.div`
  width: 100%;
`

const StyledList = styled.ul`
  list-style: initial !important;
  padding-left: 20px;

  li {
    font-size: ${pxToRem(16)};
    font-weight: 600;
    line-height: 26px;
  }
`

const ServiceIntro = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <StyledIntroContainer>
      <StyledIntroChild>
        <IllustrationBlock
          imagePath="illustrations/humain/collectif/HightFive.svg"
          backgroundColor="var(--color-primary-100)"
        />
      </StyledIntroChild>
      <StyledIntroChild>
        <TitleWithStroke
          tag="h1"
          align="left"
          modifier="tertiary"
          noMargin
          cssColor="var(--color-primary-500)"
          family="antiqueolive"
          className="k-u-font-title-large k-u-font-weight-700"
        >
          {t('services.intro.title', { parseHtml: true })}
        </TitleWithStroke>

        <Paragraph
          modifier="tertiary"
          className="k-u-color-grey-900 k-u-font-subtitle-medium k-u-margin-top-triple"
        >
          {t('services.intro.subtitle')}
        </Paragraph>
        <Paragraph
          modifier="tertiary"
          className="k-u-color-grey-900 k-u-font-subtitle-medium k-u-margin-top-triple k-u-margin-bottom-triple"
        >
          <StyledList>
            <li>{t('services.intro.listElementOne', { parseHtml: true })}</li>
            <li>{t('services.intro.listElementTwo', { parseHtml: true })}</li>
            <li>{t('services.intro.listElementThree', { parseHtml: true })}</li>
            <li>{t('services.intro.listElementFour', { parseHtml: true })}</li>
            <li>{t('services.intro.listElementFive', { parseHtml: true })}</li>
          </StyledList>
        </Paragraph>
        <Button
          size="small"
          fit="content"
          as="a"
          mobileFit="fluid"
          href={routeFor(SERVICES_TYPEFORM)}
          target="_blank"
          modifier="helium"
        >
          {t('services.contact_title')}
        </Button>
      </StyledIntroChild>
    </StyledIntroContainer>
  )
}

export default ServiceIntro
