import React from 'react'
import Label from 'kiss/components/formik/label'
import SimpleText from 'kiss/components/formik/simple-text'
import { useTranslation } from 'kiss/hooks/use-translation'

const Instagram = () => {
  const t = useTranslation()
  return (
    <>
      <Label htmlFor="instagramUrl">
        {t('mentor.signup.form.social.instagram.label')}
      </Label>
      <SimpleText
        name="instagramUrl"
        placeholder={t('mentor.signup.form.social.instagram.placeholder')}
      />
    </>
  )
}

export default Instagram
