import {
  Container,
  Grid,
  GridCol,
  Marger,
  pxToRem,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import { MENTOR_PAGE_PROJECTS_TAB } from 'kiss/routes/redux'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import React from 'react'
import { Route, Switch } from 'react-router-dom'
import styled from 'styled-components'
import Description from './components/description'
import Form from './components/form'
import Helmet from './components/helmet'
import Illustration from './components/illustration'
import Informations from './components/informations'
import Layout from './components/layout'
import Navigation from './components/navigation'
import Projects from './components/projects'
import Socials from './components/socials'

const InformationsContainer = styled.div`
  margin-top: ${pxToRem(-50)};
  margin-bottom: ${pxToRem(40)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    margin-top: ${pxToRem(-100)};
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin-top: 0;
    margin-bottom: 0;
    pointer-events: none;

    > div {
      position: sticky;
      z-index: ${Z_INDEX.global.secondAboveHeader};
      top: ${pxToRem(180)};
    }

    ::before {
      display: block;
      content: '';
      height: 18rem;
    }
  }
`

const StickyContainer = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  z-index: ${Z_INDEX.global.aboveHeader};
`

const MentorPage = () => {
  return (
    <Form>
      <Layout>
        <Helmet />
        <Illustration />

        <InformationsContainer>
          <Container>
            <Grid>
              <GridCol col="12" col-s="10" offset-s="1" col-l="3" offset-l="0">
                <Informations />
              </GridCol>
            </Grid>
          </Container>
        </InformationsContainer>

        <StickyContainer className="k-u-background-color-background3">
          <Container>
            <Grid>
              <GridCol col="12" col-s="10" offset-s="1" col-l="9" offset-l="3">
                <Navigation />
              </GridCol>
            </Grid>
          </Container>
        </StickyContainer>

        <Marger bottom={{ default: 5, fromS: 8 }}>
          <Container>
            <Grid>
              <Switch>
                <Route
                  path={MENTOR_PAGE_PROJECTS_TAB}
                  component={() => (
                    <GridCol
                      col="12"
                      col-s="10"
                      offset-s="1"
                      col-l="8"
                      offset-l="4"
                    >
                      <Marger top={{ default: 5, fromS: 8 }}>
                        <Projects />
                      </Marger>
                    </GridCol>
                  )}
                />
                <Route
                  component={() => (
                    <>
                      <GridCol
                        col="12"
                        col-s="10"
                        offset-s="1"
                        col-l="6"
                        offset-l="4"
                      >
                        <Description />
                      </GridCol>

                      <GridCol col="1" offset="1" className="k-u-hidden@m-down">
                        <Socials />
                      </GridCol>
                    </>
                  )}
                />
              </Switch>
            </Grid>
          </Container>
        </Marger>
      </Layout>
    </Form>
  )
}

export default MentorPage
