import { Field } from '@kisskissbankbank/kitten'
import PropTypes from 'prop-types'
import React from 'react'
import { useTranslation } from '../../hooks/use-translation'

const Label = ({ htmlFor, size, label, children, tooltip }) => {
  const t = useTranslation()

  return (
    <Field.Label
      labelProps={{ htmlFor, size }}
      tooltipId={`tooltip-${htmlFor}`}
      tooltip={tooltip}
      tooltipProps={{
        actionLabel: t('app.tooltip.more_informations'),
      }}
    >
      {label || children}
    </Field.Label>
  )
}

Label.propTypes = {
  htmlFor: PropTypes.string.isRequired,
  size: PropTypes.string,
  label: PropTypes.string,
  tooltip: PropTypes.string,
}

Label.defaultProps = {
  size: 'small',
}

export default Label
