import React, { useMemo } from 'react'
import styled from 'styled-components'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import { useField } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Title, CreditCardForm, Field } from '@kisskissbankbank/kitten'
import { pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'

const Section = styled.section`
  #frmCCNum {
    background-position: calc(100% - ${pxToRem(15)}) center;
    padding-right: ${pxToRem(85)};

    @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
      background-position: calc(100% - ${pxToRem(30)}) center;
      padding-right: ${pxToRem(100)};
    }
  }
`

const CreditCard = () => {
  const t = useTranslation()
  const [, meta, helpers] = useField({
    name: 'card',
    validate: (value) => {
      const cvcElement = document.getElementById('frmCCCVC')

      return !isEmpty(cvcElement) && isEmpty(value?.cvc)
        ? { cvc: t('app.forms.error.required') }
        : undefined
    },
  })
  const numberError = meta.touched && getOr('')('error.number')(meta)
  const expiryError = meta.touched && getOr('')('error.expiry')(meta)
  const cvcError = meta.touched && getOr('')('error.cvc')(meta)

  return useMemo(() => {
    const handleChange = (values) => {
      helpers.setValue(values)
    }

    return (
      <Section id="payment-section">
        <Title
          tag="p"
          modifier="quinary"
          className="k-u-margin-top-quintuple k-u-margin-top-decuple@s-up k-u-margin-bottom-triple"
        >
          {t('gift_card.new.form.payment.title')}
        </Title>

        <CreditCardForm onChange={handleChange}>
          <CreditCardForm.Number
            label={t('gift_card.new.form.payment.number')}
            fieldComponent={(props) => (
              <div {...props} className="k-u-margin-bottom-triple" />
            )}
            labelComponent={({ children, ...props }) => (
              <Field.Label labelProps={{ ...props, size: 'medium' }}>
                {children}
              </Field.Label>
            )}
            inputComponent={(props) => (
              <Field.Input {...props} size="large" error={!!numberError} />
            )}
            errorComponent={() => {
              if (!numberError) return null
              return <Field.ErrorMessage>{numberError}</Field.ErrorMessage>
            }}
          />

          <div className="k-u-flex@s-up k-u-margin-bottom-triple">
            <CreditCardForm.Expiry
              label={t('gift_card.new.form.payment.expiry')}
              fieldComponent={(props) => (
                <div
                  {...props}
                  className="k-u-margin-right-triple k-u-margin-bottom-triple@xs-down"
                />
              )}
              labelComponent={({ children, ...props }) => (
                <Field.Label labelProps={{ ...props, size: 'medium' }}>
                  {children}
                </Field.Label>
              )}
              inputComponent={(props) => (
                <Field.Input
                  {...props}
                  size="large"
                  digits={6}
                  placeholder="MM/AA"
                  error={!!expiryError}
                />
              )}
              errorComponent={() => {
                if (!expiryError) return null
                return <Field.ErrorMessage>{expiryError}</Field.ErrorMessage>
              }}
            />

            <CreditCardForm.Cvc
              label={t('gift_card.new.form.payment.cvc')}
              fieldComponent={(props) => <div {...props} />}
              labelComponent={({ children, ...props }) => (
                <Field.Label labelProps={{ ...props, size: 'medium' }}>
                  {children}
                </Field.Label>
              )}
              inputComponent={(props) => (
                <Field.Input
                  {...props}
                  size="large"
                  digits={6}
                  error={!!cvcError}
                />
              )}
              errorComponent={() => {
                if (!cvcError) return null
                return <Field.ErrorMessage>{cvcError}</Field.ErrorMessage>
              }}
            />
          </div>
        </CreditCardForm>
      </Section>
    )
  }, [numberError, expiryError, cvcError])
}

export default CreditCard
