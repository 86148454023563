import {
  HorizontalStroke,
  Marger,
  pxToRem,
  ScreenConfig,
  Text,
  Title,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import Z_INDEX from 'kiss/utils/dom/z-index-config'
import camelCase from 'lodash/fp/camelCase'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  getBackedAmountCents,
  getBackedAmountCurrency,
  getCity,
  getCustomFields,
  getNumberOfBackedProjects,
  getPostalCode,
  getUsername,
  isBackedAmountHidden,
  isNumberOfBackedProjectsHidden,
} from '../redux'
import Avatar from './avatar'
import InformationsEdit from './informations-edit'

const Container = styled.div`
  position: relative;
  padding: 0 ${pxToRem(30)} ${pxToRem(30)} ${pxToRem(30)};
  border: var(--border);
  background-color: var(--color-grey-000);
  text-align: center;
  z-index: ${Z_INDEX.global.belowHeader};

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    pointer-events: all;
  }
`

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: normal;

  > :not(:last-child) {
    margin-bottom: ${pxToRem(20)};
  }

  @media (min-width: ${pxToRem(ScreenConfig.M.min)}) and (max-width: ${pxToRem(
      ScreenConfig.L.min,
    )}) {
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;

    > span {
      flex-grow: 1;
      flex-basis: 33%;
    }
  }
`

const AvatarContainer = styled.div`
  margin: -${pxToRem(40)} auto ${pxToRem(20)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    margin-top: -${pxToRem(50)};
  }
`

const Informations = (props) => {
  const t = useTranslation()
  const username = useSelector(getUsername)
  const city = useSelector(getCity)
  const postalCode = useSelector(getPostalCode)
  const numberOfBackedProjects = useSelector(getNumberOfBackedProjects)
  const backedAmountCents = useSelector(getBackedAmountCents)
  const backedAmountCurrency = useSelector(getBackedAmountCurrency)
  const customFields = useSelector(getCustomFields)
  const hideProjects = useSelector(isNumberOfBackedProjectsHidden)
  const hideAmount = useSelector(isBackedAmountHidden)

  return (
    <Container {...props}>
      <AvatarContainer>
        <Avatar />
      </AvatarContainer>

      <Marger bottom={{ default: 1, fromS: 1.5 }}>
        <Title tag="h1" modifier="quinary" noMargin>
          {username}
        </Title>
      </Marger>

      {city && (
        <Text
          tag="p"
          weight="500"
          color="font1"
          lineHeight="normal"
          size="small"
          className="k-u-margin-bottom-singleHalf"
        >
          {city}
          {postalCode && ` (${postalCode})`}
        </Text>
      )}

      <HorizontalStroke className="k-u-align-center-block k-u-margin-bottom-triple" />

      <FlexContainer>
        {numberOfBackedProjects > 0 && !hideProjects && (
          <Text weight="400" color="font1" lineHeight="normal" size="small">
            <Text weight="600" size="medium">
              {numberOfBackedProjects}
            </Text>
            <br />
            {t('mentor_page.informations.backed_projects', {
              count: numberOfBackedProjects,
            })}
          </Text>
        )}

        {backedAmountCents > 0 && !hideAmount && (
          <Text weight="400" color="font1" lineHeight="normal" size="small">
            <Text weight="600" size="medium">
              {t('project_info.amount_with_currency', {
                amount: t(backedAmountCents / 100, {
                  formatNumber: true,
                }),
                currency: t(`currency_symbol.${backedAmountCurrency}`),
                parseHtml: true,
              })}
            </Text>
            <br />
            {t('mentor_page.informations.backed_amount')}
          </Text>
        )}

        {customFields &&
          [...Array(4)].map((_, index) => {
            const field = customFields[`field${index + 1}`]
            if (!field) return
            const { title, value } = field
            return (
              <Text
                weight="400"
                color="font1"
                lineHeight="normal"
                size="small"
                key={`field-position-${camelCase(title)}`}
              >
                <Text weight="600" size="medium" tag="strong">
                  {value}
                </Text>
                <br />
                {title}
              </Text>
            )
          })}
      </FlexContainer>
      <InformationsEdit />
    </Container>
  )
}

export default Informations
