import Cookies from 'js-cookie'
import query from 'kiss/api/graphql-query'
import { mentorProjectPath } from 'kiss/api/rails-endpoints'
import { create } from 'kiss/api/rails-query'
import { addErrorAlert, addSuccessAlert } from 'kiss/app/alerts/redux'
import disableDraftSharing from 'kiss/graphql/mutations/project/disable_share_project.graphql'
import enableDraftSharing from 'kiss/graphql/mutations/project/enable_share_project.graphql'
import subscribeUserToProjectQuery from 'kiss/graphql/mutations/user/subscribe_user_to_project.graphql'
import unsubscribeUserFromProjectQuery from 'kiss/graphql/mutations/user/unsubscribe_user_from_project.graphql'
import projectOrdersRequest from 'kiss/graphql/queries/project/orders_list.graphql'
import ownerPanelQuery from 'kiss/graphql/queries/project/project_owner_panel.graphql'
import projectRewardsByReferenceQuery from 'kiss/graphql/queries/project/rewards_by_reference.graphql'
import {
  getCurrentUserImage,
  getCurrentUserSlug,
  getCurrentUserUsername,
} from 'kiss/session/redux'
import compact from 'lodash/fp/compact'
import concat from 'lodash/fp/concat'
import flow from 'lodash/fp/flow'
import getOr from 'lodash/fp/getOr'
import uniq from 'lodash/fp/uniq'
import isEmpty from 'ramda/src/isEmpty'
import length from 'ramda/src/length'
import {
  ADD_MENTOR,
  BACKERS_PER_LOAD,
  HIDE_REWARDS_HALO,
  INIT_NAV_ITEMS,
  NAME,
  SHOW_REWARDS_HALO,
  UPDATE_DRAFT_LINK,
  UPDATE_PROJECT,
  UPDATE_PROJECT_ORDERS,
  UPDATE_PROJECT_SLUG,
  ADD_REWARDS,
  REPLACE_NEWS,
} from './redux'
import {
  getCommentsTotalCount,
  getFaqs,
  getMentors,
  getNewsCount,
  getProjectRewards,
  getProjectSlug,
  getTotalOrders,
  isCurrentUserManager,
} from './selectors'

export const fetchProjectOwnerPanelData = () => async (dispatch, getState) => {
  const state = getState()
  const projectSlug = getProjectSlug(state)
  try {
    const ownerPanelData = await query(
      ownerPanelQuery,
      { slug: projectSlug },
      state,
    )

    dispatch({
      type: UPDATE_PROJECT,
      payload: ownerPanelData.project,
    })
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const updateProjectSlug = (projectSlug) => (dispatch) => {
  dispatch({ type: UPDATE_PROJECT_SLUG, payload: { projectSlug } })
}

export const subscribeUserToProject =
  ({ projectId, userUuid }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(subscribeUserToProjectQuery, { projectId, userUuid }, state)

      dispatch({
        type: UPDATE_PROJECT,
        payload: {
          currentUser: { ...state.currentUser, hasFollowed: true },
        },
      })
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const unsubscribeUserFromProject =
  ({ projectId, userUuid }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(
        unsubscribeUserFromProjectQuery,
        { projectId, userUuid },
        state,
      )

      dispatch({
        type: UPDATE_PROJECT,
        payload: {
          currentUser: { ...state.currentUser, hasFollowed: false },
        },
      })
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const initNavItems = () => (dispatch, getState) => {
  const state = getState()
  const navItems = [
    { key: 'description', text: 'project_tab.description' },
    {
      key: 'news',
      text: 'project_tab.news',
      badge: getNewsCount(state),
    },
    {
      key: 'comments',
      text: 'project_tab.comments',
      badge: getCommentsTotalCount(state),
    },
    {
      key: 'backers',
      text: 'project.navigation.orders',
      badge: getTotalOrders(state),
    },
  ]
  if (!isEmpty(getFaqs(state)) || isCurrentUserManager(state)) {
    navItems.push({
      key: 'faqs',
      text: 'FAQ',
      badge: length(getFaqs(state)),
    })
  }

  dispatch({ type: INIT_NAV_ITEMS, payload: navItems })
}

export const loadProjectBackers = (ordersAfter) => (dispatch, getState) => {
  const state = getState()
  const { slug } = state[NAME]

  return query(
    projectOrdersRequest,
    {
      slug,
      ordersAfter,
      ordersLimit: BACKERS_PER_LOAD,
    },
    state,
    {
      fetchPolicy: 'no-cache',
    },
  ).then(
    (result) => {
      const existingOrders = getOr([])(`${NAME}.ordersConnection.edges`)(state)
      const newOrders = getOr([])('project.ordersConnection.edges')(result)

      dispatch({
        type: UPDATE_PROJECT_ORDERS,
        payload: {
          ordersConnection: {
            edges: [...existingOrders, ...newOrders],
          },
        },
      })
    },
    (_error) => {
      dispatch(addErrorAlert('Error: something went wrong!'))
    },
  )
}

export const addMentor = (slug, successMessage) => (dispatch, getState) => {
  const state = getState()
  const url = mentorProjectPath(slug, state)
  const data = { slug }

  const mentor = {
    username: getCurrentUserUsername(state),
    image: getCurrentUserImage(state),
    slug: getCurrentUserSlug(state),
  }

  const mentors = getMentors(state)

  return create(url, data, state)
    .then(() => {
      dispatch({
        type: ADD_MENTOR,
        payload: {
          mentors: [...mentors, mentor],
        },
      })
      dispatch(addSuccessAlert(successMessage))
    })
    .catch((_errors) => {
      dispatch(addErrorAlert('Error: something went wrong!'))
    })
}

export const showRewardsHalo = () => (dispatch) => {
  return dispatch({
    type: SHOW_REWARDS_HALO,
    payload: { rewardsHaloStatus: true },
  })
}

export const hideRewardsHalo = () => (dispatch) => {
  return dispatch({
    type: HIDE_REWARDS_HALO,
    payload: { rewardsHaloStatus: false },
  })
}

export const enableShareProject = () => (dispatch, getState) => {
  const state = getState()
  const { uuid } = state[NAME]

  return query(
    enableDraftSharing,
    {
      id: uuid,
    },
    state,
  )
    .then((response) => {
      dispatch({
        type: UPDATE_DRAFT_LINK,
        payload: response.enableDraftSharing,
      })
    })
    .catch(() => {
      dispatch(addErrorAlert('Error: something went wrong!'))
    })
}

export const disableShareProject = () => (dispatch, getState) => {
  const state = getState()
  const { uuid } = state[NAME]

  return query(
    disableDraftSharing,
    {
      id: uuid,
    },
    state,
  )
    .then((response) => {
      dispatch({
        type: UPDATE_DRAFT_LINK,
        payload: response.disableDraftSharing,
      })
    })
    .catch(() => {
      dispatch(addErrorAlert('Error: something went wrong!'))
    })
}

export const loadProjectRewardsByReference =
  (references) => (dispatch, getState) => {
    const state = getState()
    const projectRewards = getProjectRewards(state)
    const slug = getProjectSlug(state)
    return query(projectRewardsByReferenceQuery, { references, slug }, state)
      .then((response) => {
        dispatch({
          type: ADD_REWARDS,
          payload: {
            rewards: concat(projectRewards)(response.project.rewards),
          },
        })
      })
      .catch((_errors) => {
        dispatch(addErrorAlert('Error: something went wrong!'))
      })
  }

export const setExclusiveRewardsToCookie =
  (exclusiveRewards) => (_dispatch, getState) => {
    const state = getState()
    const projectSlug = getProjectSlug(state)
    const name = `${projectSlug}-secret-reward`
    const secure = APP_ENV === 'production'
    const in90Days = 90
    const values = flow(
      concat(JSON.parse(Cookies.get(name) ?? '[]')),
      compact,
      uniq,
    )(exclusiveRewards)

    Cookies.set(name, JSON.stringify(values), { secure, expires: in90Days })

    return values
  }

export const getExclusiveRewardsFromCookie = () => (_dispatch, getState) => {
  const state = getState()
  const projectSlug = getProjectSlug(state)
  const name = `${projectSlug}-secret-reward`

  return JSON.parse(Cookies.get(name) || `[]`)
}

export const replaceNews = (updatedNews) => ({
  type: REPLACE_NEWS,
  payload: { updatedNews },
})
