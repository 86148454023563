import { pxToRem } from '@kisskissbankbank/kitten'
import Alert from 'kiss/app/alerts/components/alert'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMediaQuery } from 'kiss/media-queries/redux'
import {
  PROJECT_NAVIGATION_BACKERS_TAB_ROUTE,
  PROJECT_NAVIGATION_COMMENTS_TAB_ROUTE,
  PROJECT_NAVIGATION_DESCRIPTION_TAB_ROUTE,
  PROJECT_NAVIGATION_FAQS_TAB_ROUTE,
  PROJECT_NAVIGATION_NEWS_TAB_ROUTE,
  PROJECT_NAVIGATION_TAB_ROUTE,
  PROJECT_PREVIEW,
} from 'kiss/routes/redux'
import { getCurrentUser } from 'kiss/session/redux'
import { useDeepCompareEffect } from 'kiss/utils/use-deep-compare-effect'
import isEmpty from 'lodash/fp/isEmpty'
import qs from 'qs'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Route,
  Switch,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import styled from 'styled-components'
import {
  getExclusiveRewardsFromCookie,
  initNavItems,
  loadProjectRewardsByReference,
  setExclusiveRewardsToCookie,
  updateProjectSlug,
} from '../page-state/actions'
import {
  canCurrentUserViewPreview as canCurrentUserViewPreviewSelector,
  getPageColors,
  isAggressivelyCached,
  explicitContentType as explicitContentSelector,
  isPrelaunched as isPrelaunchedSelector,
} from '../page-state/selectors'
import Backers from './backers'
import Comments from './comments'
import ExpiredAlert from './components/alerts/expired'
import KycAlert from './components/alerts/kyc'
import MentorAlert from './components/alerts/mentor'
import Helmet from './components/helmet'
import Intro from './components/intro'
import Navigation from './components/navigation'
import ExplicitPopover from './components/popovers/explicit-popover'
import Titles from './components/titles'
import AdminPanel from './components/toolbars/admin-panel'
import ContributorPanel from './components/toolbars/contributor-panel'
import OwnerPanel from './components/toolbars/owner-panel'
import ContentWrapper from './components/content'
import DescriptionRewards from './description-rewards'
import Faqs from './faqs'
import News from './news'
import Prelaunch from './prelaunch'
import MobileRewardsCarousel from './rewards-carousel'

const CacheAlert = styled(Alert)`
  .k-Alert__text {
    font-size: ${pxToRem(13)};
  }
`

const ProjectPage = () => {
  const { viewportIsMOrLess } = useMediaQuery()
  const t = useTranslation()
  const dispatch = useDispatch()
  const { search } = useLocation()
  const { project: projectSlug } = useParams()
  const isPrelaunched = useSelector(isPrelaunchedSelector)
  const currentUser = useSelector(getCurrentUser)
  const explicitContentType = useSelector(explicitContentSelector)
  const aggressivelyCached = useSelector(isAggressivelyCached)
  const canCurrentUserViewPreview = useSelector(
    canCurrentUserViewPreviewSelector,
  )
  const pageColors = useSelector(getPageColors)

  useEffect(() => {
    dispatch(updateProjectSlug(projectSlug))
    initExclusiveRewards()
  }, [])

  useDeepCompareEffect(() => {
    dispatch(initNavItems())
  }, [currentUser])

  const isProjectPreviewPage = useRouteMatch(PROJECT_PREVIEW)
  const isProjectNavigationTabPage = useRouteMatch(PROJECT_NAVIGATION_TAB_ROUTE)

  const canViewPreview =
    (isProjectPreviewPage || isProjectNavigationTabPage) &&
    canCurrentUserViewPreview

  const initExclusiveRewards = () => {
    const searchParams = qs.parse(search, { ignoreQueryPrefix: true })
    const secretReward = searchParams['secret-reward']
    let references

    if (isEmpty(secretReward)) {
      references = dispatch(getExclusiveRewardsFromCookie())
    } else {
      const exclusiveRewards =
        typeof secretReward === 'string'
          ? secretReward.split(',')
          : secretReward

      references = dispatch(setExclusiveRewardsToCookie(exclusiveRewards))
    }

    if (isEmpty(references)) return

    dispatch(loadProjectRewardsByReference(references))
  }

  return (
    <DefaultLayout
      tinyLogo
      AboveHeader={() => {
        return aggressivelyCached ? (
          <CacheAlert
            uuid="aggressivelyCachedAlert"
            type="info"
            message={t('project.alert.aggressively_cached')}
          />
        ) : null
      }}
    >
      <Helmet />

      {isPrelaunched && !canViewPreview ? (
        <Prelaunch />
      ) : (
        <>
          <MentorAlert />
          <KycAlert />
          <ExpiredAlert />
          <ContributorPanel />
          <OwnerPanel />
          <AdminPanel />
          <section style={pageColors}>
            <Titles />
            <Intro />
            {viewportIsMOrLess && <MobileRewardsCarousel />}
            <Navigation />
            <ContentWrapper>
              <Switch>
                <Route path={PROJECT_NAVIGATION_DESCRIPTION_TAB_ROUTE}>
                  <DescriptionRewards />
                </Route>
                <Route path={PROJECT_NAVIGATION_NEWS_TAB_ROUTE}>
                  <News />
                </Route>
                <Route path={PROJECT_NAVIGATION_COMMENTS_TAB_ROUTE}>
                  <Comments />
                </Route>
                <Route path={PROJECT_NAVIGATION_BACKERS_TAB_ROUTE}>
                  <Backers />
                </Route>
                <Route path={PROJECT_NAVIGATION_FAQS_TAB_ROUTE}>
                  <Faqs />
                </Route>
                <Route>
                  <DescriptionRewards />
                </Route>
              </Switch>
            </ContentWrapper>
          </section>
        </>
      )}

      {explicitContentType && (
        <ExplicitPopover explicit={explicitContentType} />
      )}
    </DefaultLayout>
  )
}

export default ProjectPage
