import {
  Button,
  CarouselNext,
  Container,
  mq,
  pxToRem,
  ScreenConfig,
  Text,
  useLazyObserver,
} from '@kisskissbankbank/kitten'
import { useMediaQuery } from 'kiss/media-queries/redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  FOUR_GUTTER_LARGE_IN_CONTAINER,
  FOUR_GUTTER_LARGE_IN_XL_CONTAINER,
  TWO_GUTTER_THIN_IN_XXS_CONTAINER,
  TWO_IN_CONTAINER,
} from 'kiss/utils/responsive-image/image-width-definitions'
import { getPopularProjects } from '../redux'
import ProjectCard from 'kiss/components/cards/project-card'
import {
  BROWSING_POPULAR_AND_STARTED,
  getRouteFor,
} from '../../../routes/redux'

const StyledWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(40)};
  padding-bottom: ${pxToRem(50)};

  & > * {
    position: relative;
    z-index: 2;
  }

  &::before {
    z-index: 1;
    display: block;
    content: '';
    position: absolute;
    background-color: var(--color-grey-000);
    // height: ${pxToRem(310)};
    width: 100%;
    left: 0;
    top: 0;
    clip-path: polygon(0 -1px, 100% -1px, 100% 100%, 0 50%);
  }

  .k-CarouselNext--showOtherPages .k-CarouselNext__inner {
    --container-padding: ${pxToRem(50)};

    @media ${mq.tabletAndDesktop} {
      --container-padding: ${pxToRem(150)};
    }
  }

  .k-CarouselNext__page {
    height: 100% !important;
  }
`

const responsiveImageOptions = {
  imageSizes: [165, 182, 270, 310, 490],
  breakpoints: [0, ScreenConfig.S.min, ScreenConfig.L.min, ScreenConfig.XL.min],
  imageSizesInViewport: [
    TWO_GUTTER_THIN_IN_XXS_CONTAINER,
    TWO_IN_CONTAINER,
    FOUR_GUTTER_LARGE_IN_CONTAINER,
    FOUR_GUTTER_LARGE_IN_XL_CONTAINER,
  ],
}

const PopularProjects = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  const popularProjects = useSelector(getPopularProjects)
  const [navProps, setNavProps] = useState({})
  const { viewportIsXSOrLess, viewportIsSOrLess, viewportIsMOrLess } =
    useMediaQuery()
  const lazyElement = useRef(null)
  const shouldLoadImages = useLazyObserver(lazyElement)

  if (popularProjects.length === 0) return null

  return (
    <StyledWrapper>
      <div ref={lazyElement} />
      <div>
        <Container className="k-u-flex k-u-flex-alignItems-center k-u-flex-justifyContent-sb k-u-flex-gap-double">
          <Text
            tag="h2"
            className="k-u-margin-none k-u-margin-vertical-double@xs-down"
            weight="500"
            transform="uppercase"
            letterSpacing="10%"
            size="small"
          >
            {t('new_home.projects.popular_projects')}
          </Text>
          <CarouselNext.Navigation
            {...navProps}
            buttonProps={{ size: 'small' }}
          />
        </Container>
        <CarouselNext
          baseGap={30}
          cycle
          itemsPerPage={viewportIsSOrLess ? 1 : 3}
          itemMinWidth={viewportIsSOrLess ? 200 : 300}
          viewportIsXSOrLess={viewportIsXSOrLess}
          viewportIsMOrLess={viewportIsMOrLess}
          navigationPropsGetter={setNavProps}
          showOtherPages
          shadowSize={viewportIsSOrLess ? 10 : 30}
        >
          {popularProjects.map((item, index) => (
            <ProjectCard
              project={item}
              key={index}
              shouldLoadImages={shouldLoadImages}
              responsiveImageOptions={responsiveImageOptions}
            />
          ))}
        </CarouselNext>
      </div>
      <Button
        className="k-u-alignSelf-center"
        tag="a"
        href={routeFor(BROWSING_POPULAR_AND_STARTED)}
      >
        {t('new_home.projects.show_more')}
      </Button>
    </StyledWrapper>
  )
}

export default PopularProjects
