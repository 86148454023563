import {
  Button,
  Container,
  Grid,
  GridCol,
  Loader,
  Marger,
} from '@kisskissbankbank/kitten'
import { Formik } from 'formik'
import { useMutation } from 'kiss/api/graphql-query'
import { AlertsContext } from 'kiss/app/alerts/context'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import ScrollOnError from 'kiss/components/formik/scroll-on-error'
import { useTranslation } from 'kiss/hooks/use-translation'
import config from 'kiss/config'
import CreateMentorMutation from 'kiss/graphql/mutations/user/create_mentor.graphql'
import { getRouteFor, MENTOR_PAGE } from 'kiss/routes/redux'
import { getCurrentUserId } from 'kiss/session/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import isEmpty from 'lodash/fp/isEmpty'
import kebabCase from 'lodash/fp/kebabCase'
import omit from 'lodash/fp/omit'
import React, { useContext } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'
import * as Yup from 'yup'
import Categories from './categories'
import Engagements from './engagements'
import Personnal from './personnal'
import Role from './role'
import Social from './social'
import Stepper from './stepper'

const MentorSignUp = () => {
  const t = useTranslation()
  const { removeErrorAlerts, addErrorAlert } = useContext(AlertsContext)
  const userId = useSelector(getCurrentUserId)
  const routeFor = useSelector(getRouteFor)
  const { mentor } = useParams()
  const [createMentor] = useMutation(CreateMentorMutation)
  return (
    <Formik
      initialValues={{
        title: '',
        slug: '',
        phone: '',
        city: '',
        websiteUrl: '',
        facebookUrl: '',
        instagramUrl: '',
        linkedInUrl: '',
        financialRole: false,
        visibilityRole: false,
        supportRole: false,
        twitterUrl: '',
        categorySlugs: [],
        engagementCodes: [],
      }}
      validationSchema={Yup.object().shape({
        title: Yup.string().required(t('app.forms.error.required')),
        websiteUrl: Yup.string().required(t('app.forms.error.required')),
        phone: Yup.string().required(t('app.forms.error.required')),
      })}
      onSubmit={async (values) => {
        try {
          removeErrorAlerts()
          await createMentor({
            variables: {
              id: userId,
              mentor: omit(['location'])({
                ...values,
                slug: isEmpty(values.slug)
                  ? kebabCase(values.title)
                  : values.slug,
              }),
            },
          })
          RoutingHelper.redirect(routeFor(MENTOR_PAGE, { mentor }))
        } catch (e) {
          addErrorAlert(t('app.server.error'), {
            scroll: true,
          })
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <DefaultLayout>
            <Helmet>
              <script
                src={`https://maps.googleapis.com/maps/api/js?key=${config[APP_ENV].google.placeApiKey}&libraries=places&loading=async`}
                type="text/javascript"
              />
            </Helmet>
            <ScrollOnError
              message={t('contribute.shipping_address.form.alert.error')}
            />
            <Stepper />
            <Container>
              <Grid>
                <GridCol col-s="10" offset-s="1" col-l="5" offset-l="2">
                  <Personnal />
                  <Social />
                </GridCol>
              </Grid>
              <Grid>
                <GridCol col-s="10" offset-s="1" col-l="8" offset-l="2">
                  <Role />
                  <Engagements />
                  <Categories />
                  <Marger bottom="14">
                    <Button
                      size="large"
                      type="submit"
                      fit="fluid"
                      modifier="helium"
                      disabled={isSubmitting}
                      onClick={handleSubmit}
                    >
                      {isSubmitting ? <Loader /> : t('app.submit.continue')}
                    </Button>
                  </Marger>
                </GridCol>
              </Grid>
            </Container>
          </DefaultLayout>
        )
      }}
    </Formik>
  )
}

export default MentorSignUp
