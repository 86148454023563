import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button, pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, GIFT_CARD_NEW } from 'kiss/routes/redux'
import { scrollToTop } from 'kiss/utils/animation/scroll-to'

import styled from 'styled-components'

const CallToAction = (props) => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const history = useHistory()
  const giftCardNewUrl = routeFor(GIFT_CARD_NEW)

  const StyledGiftCardCtaButton = styled.div`
    @media (max-width: ${pxToRem(ScreenConfig.XS.max)}) {
      a {
        width: 100%;
      }
    }
  `

  return (
    <StyledGiftCardCtaButton>
      <Button
        as="a"
        href={giftCardNewUrl}
        onClick={(e) => {
          e.preventDefault()
          scrollToTop()
          history.push(giftCardNewUrl)
        }}
        modifier="helium"
        size="large"
        {...props}
      >
        {t('gift_card.cta.button')}
      </Button>
    </StyledGiftCardCtaButton>
  )
}

export default CallToAction
