import getOr from 'lodash/fp/getOr'
import orderBy from 'lodash/fp/orderBy'
import slice from 'lodash/fp/slice'
import flow from 'lodash/fp/flow'
import map from 'lodash/fp/map'
import { createSelector } from '@reduxjs/toolkit'

const NAME = 'STATS_PAGE'

export const getProjectsList = (state) => {
  return flow(
    getOr([])(`${NAME}.projectsBySelection.edges`),
    map(({ node }) => node),
  )(state)
}

export const getSuccessfulProjectsCount = (state) =>
  getOr(0)(`${NAME}.stats.successfulProjectsCount`)(state)
export const getTotalPayoutAmount = (state) =>
  getOr(0)(`${NAME}.stats.totalPayoutAmount`)(state)
export const getGlobalSuccessRate = (state) =>
  getOr(0)(`${NAME}.stats.globalSuccessRate`)(state)
export const getTotalUsersCount = (state) =>
  getOr(0)(`${NAME}.stats.totalUsersCount`)(state)
export const getMeanContribution = (state) =>
  getOr(0)(`${NAME}.stats.meanContribution`)(state)

const getStatsByYear = (state) => getOr([])(`${NAME}.stats.statsByYear`)(state)

export const getStatsByCategory = (state) =>
  getOr([])(`${NAME}.stats.statsByCategory`)(state)

export const getLastFourYearsStats = createSelector(
  [getStatsByYear],
  (statsByYear) => {
    const stats = orderBy(['year'])('desc')(statsByYear)

    return slice(0)(4)(stats)
  },
)
