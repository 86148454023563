import React from 'react'
import { useSelector } from 'react-redux'

import useNumbers from 'kiss/hooks/use-numbers'
import { useTranslation } from 'kiss/hooks/use-translation'
import { SERVICE_FEES_FOR_CONTRIBUTORS } from 'kiss/modules/pricing-plans'
import { FUNNEL, getRouteFor } from 'kiss/routes/redux'

import { Button } from '@kisskissbankbank/kitten'
import PricingCard from 'kiss/components/cards/pricingCard'

import styled from 'styled-components'

const StyledPricingCard = styled(PricingCard)`
  background-color: var(--color-grey-000);
`

const DetailsColumnHeader = ({ plan, serviceFees }) => {
  const t = useTranslation()
  const { formatPercent } = useNumbers()
  const routeFor = useSelector(getRouteFor)

  const feeRate =
    serviceFees === SERVICE_FEES_FOR_CONTRIBUTORS
      ? plan.feeRateWithoutServiceFee
      : plan.feeRate

  const feeRateWithoutVat =
    serviceFees === SERVICE_FEES_FOR_CONTRIBUTORS
      ? plan?.feeRateWithoutVatWithoutServiceFee
      : plan?.feeRateWithoutVat

  return (
    <div
      className="kiss-Pricing__table__cell_title"
      style={{ '--pricing-table-current-row': 1 }}
    >
      <StyledPricingCard
        key={plan.title}
        main={plan.title === 'Pro'}
        name={plan.title}
        rate={formatPercent(feeRate * 100, 0, 1)}
        rateWithoutVat={formatPercent(feeRateWithoutVat * 100, 0, 1)}
        bottomRadiusDisplay={false}
        subtitleTranslationKeys={{
          plan:
            plan.label === 'starter' &&
            serviceFees === SERVICE_FEES_FOR_CONTRIBUTORS
              ? 'pricing_plans.free'
              : 'pricing_plans.vat',
          start: 'pricing_plans.offer_subtitle.start',
          offer: `pricing_plans.offer_subtitle.${plan.label}`,
          vat: 'pricing_plans.offer_subtitle.vat',
          end1: 'pricing_plans.offer_subtitle.end.1',
          end2: `pricing_plans.offer_subtitle.end.${
            plan.label === 'starter' ? plan.label : 'proExpert'
          }`,
        }}
        isFree={feeRate === 0}
        shadowBoxDisplay
        actionBlock={
          <Button
            as="a"
            fit="content"
            mobileFit="fluid"
            href={routeFor(FUNNEL)}
            modifier="helium"
          >
            {t('pricing_plans.create_project')}
          </Button>
        }
      />
    </div>
  )
}

export default DetailsColumnHeader
