import React, { useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { useDropzone } from 'react-dropzone'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useFormikContext } from 'formik'
import { UPLOADER_ACCEPT, UPLOADER_MAX_SIZE } from '../redux'
import {
  CameraIcon,
  Container,
  Grid,
  GridCol,
  pxToRem,
  ScreenConfig,
  Text,
} from '@kisskissbankbank/kitten'

const Uploader = styled.div`
  position: relative;
  display: flex;
  justify-content: left;
  align-items: center;
  margin-top: ${pxToRem(20)};
  padding: ${pxToRem(20)};
  cursor: pointer;

  :before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(34, 34, 34, 0.8);
    border-radius: var(--border-radius-xl);
    transition:
      transform 0.4s ease,
      background-color 0.4s ease;
  }

  &:focus-visible {
    outline: auto;
  }

  :hover,
  :focus {
    :before {
      background-color: var(--color-primary-500);

      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        transform: scale(1.05);
      }
    }
  }

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    justify-content: center;
    padding: ${pxToRem(30)} ${pxToRem(50)};
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    flex-direction: column;
    min-width: ${pxToRem(385)};
    margin: 0;
    text-align: center;
  }

  ${({ isRejected, isDragActive }) =>
    (isRejected || isDragActive) &&
    css`
      &,
      :hover,
      :focus {
        :before {
          background-color: ${isDragActive
            ? 'var(--color-primary-500)'
            : 'var(--color-danger-500)'};
        }
      }

      @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
        :hover,
        :focus {
          :before {
            transform: scale(1.05);
          }
        }
      }
    `}

  > * {
    position: relative;
  }
`

const StyledCameraIcon = styled(CameraIcon)`
  flex-shrink: 0;
  width: ${pxToRem(25)};
  height: ${pxToRem(20)};
  margin: 0 ${pxToRem(15)} 0 0;

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    width: ${pxToRem(40)};
    height: ${pxToRem(32)};
    margin: 0 ${pxToRem(20)} 0 0;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    width: ${pxToRem(140)};
    height: ${pxToRem(112)};
    margin: 0 0 ${pxToRem(20)};
  }
`

const defaultRejectedFile = null

const IllustrationUploader = () => {
  const t = useTranslation()
  const [rejectedFile, setRejectedFile] = useState(defaultRejectedFile)
  const { setFieldValue } = useFormikContext()

  const onDropAccepted = useCallback((files) => {
    files.forEach((file) => {
      const reader = new FileReader()

      reader.onload = () => {
        setFieldValue('coverImageFile', file)
        setRejectedFile(defaultRejectedFile)
      }

      reader.readAsArrayBuffer(file)
    })
  }, [])

  const onDropRejected = useCallback((files) => {
    setRejectedFile(files[0])
  }, [])

  const { getRootProps, getInputProps, isDragActive, isDragReject } =
    useDropzone({
      accept: UPLOADER_ACCEPT,
      maxSize: UPLOADER_MAX_SIZE,
      multiple: false,
      onDropAccepted,
      onDropRejected,
    })

  const isRejected = !!rejectedFile || isDragReject

  return (
    <Container className="kiss-MentorPage__UploaderContainer">
      <Grid className="kiss-MentorPage__UploaderContainer">
        <GridCol
          col="12"
          col-s="10"
          offset-s="1"
          col-m="8"
          offset-m="2"
          col-l="6"
          offset-l="3"
          className="kiss-MentorPage__UploaderGridCol"
        >
          <Uploader {...getRootProps({ isDragActive, isRejected })}>
            <input {...getInputProps()} />

            <StyledCameraIcon color="var(--color-grey-000)" title={null} />

            <div>
              <Text
                tag="p"
                size="small"
                weight="500"
                color="background1"
                lineHeight="normal"
                className="k-u-margin-bottom-noneHalf k-u-hidden@l-up"
              >
                {t(
                  `mentor_page.edition.uploader.${
                    isRejected && !isDragActive ? 'error_text' : 'download_text'
                  }`,
                )}
              </Text>

              <Text
                tag="p"
                size="small"
                weight="500"
                color="background1"
                lineHeight="normal"
                className="k-u-margin-bottom-noneHalf k-u-hidden@m-down"
              >
                {t(
                  `mentor_page.edition.uploader.${
                    isRejected && !isDragActive ? 'error_text' : 'text'
                  }`,
                )}
              </Text>

              <Text
                tag="p"
                size="micro"
                weight="400"
                color="background1"
                lineHeight="normal"
              >
                {t('mentor_page.edition.uploader.informations', {
                  parseHtml: true,
                })}
              </Text>
            </div>
          </Uploader>
        </GridCol>
      </Grid>
    </Container>
  )
}

export default IllustrationUploader
