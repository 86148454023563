import {
  Button,
  Container,
  DoubleEntryTable,
  Grid,
  GridCol,
  HorizontalStroke,
  Marger,
  Text,
  Title,
} from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import {
  getLastFourYearsStats,
  getStatsByCategory,
  getSuccessfulProjectsCount,
  getTotalUsersCount,
} from 'kiss/modules/stats-page/redux'
import { FUND_MY_PROJECT, getRouteFor } from 'kiss/routes/redux'
import isNull from 'lodash/fp/isNull'
import take from 'lodash/fp/take'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const DEFAULT_NUMBER_OF_CATEGORIES = 4

const Tables = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const [numberOfCategoriesToShow, setNumberOfCategoriesToShow] = useState(
    DEFAULT_NUMBER_OF_CATEGORIES,
  )
  const successfulProjectsCount = useSelector(getSuccessfulProjectsCount)
  const totalUserCount = useSelector(getTotalUsersCount)
  const statsByYear = useSelector(getLastFourYearsStats)
  const statsByCategory = useSelector(getStatsByCategory)

  return (
    <section className="stats__Tables">
      <Container>
        <Grid>
          <GridCol col-s={10} col-l={3} offset-s={1}>
            <Title
              className="Table__Title"
              tag="h2"
              modifier="tertiary"
              family="antiqueolive"
            >
              {t('stats.tables.main_stats.title', { parseHtml: true })}
            </Title>
            <HorizontalStroke
              className="Stats__HorizontalStroke__Size3 Table__HorizontalStroke"
              size="large"
            />
          </GridCol>
          <GridCol col-s={10} col-l={6} offset-s={1}>
            <Text
              className="Table_Paragraph"
              weight="400"
              tag="p"
              lineHeight="normal"
              size="large"
            >
              {t('stats.tables.main_stats.paragraph_1', { parseHtml: true })}
            </Text>
            <Text
              weight="400"
              tag="p"
              lineHeight="normal"
              size="large"
              className="Table_Paragraph k-u-margin-top-double"
            >
              {t('stats.tables.main_stats.paragraph_2', {
                kissbankersCount: t(totalUserCount / 1000000, {
                  formatNumber: { decimal: 1 },
                }),
                parseHtml: true,
              })}
            </Text>
            <Marger top={3}>
              <Button
                as="a"
                className="Table__Button"
                size="large"
                modifier="helium"
                href={routeFor(FUND_MY_PROJECT)}
              >
                {t('stats.fund_my_project_button')}
              </Button>
            </Marger>
          </GridCol>

          {statsByYear.length > 0 && (
            <GridCol col-s={10} offset-s={1}>
              <Marger
                className="Table__TableMarger"
                top={{ fromXxs: 4, fromM: 5 }}
                bottom={{
                  fromXxs: 5,
                  fromM: 10,
                  fromL: 10,
                }}
              >
                <DoubleEntryTable className="Table__DoubleEntryTable">
                  <DoubleEntryTable.Header>
                    <DoubleEntryTable.HeaderCol>
                      <span className="k-u-a11y-visuallyHidden">
                        {t('stats.tables.year')}
                      </span>
                    </DoubleEntryTable.HeaderCol>

                    {statsByYear.map(({ year }) => {
                      return (
                        <DoubleEntryTable.HeaderCol key={year}>
                          {year}
                        </DoubleEntryTable.HeaderCol>
                      )
                    })}
                  </DoubleEntryTable.Header>

                  <DoubleEntryTable.Body>
                    <DoubleEntryTable.Row>
                      <DoubleEntryTable.TitleCol>
                        {t('stats.tables.amount_collected')}
                      </DoubleEntryTable.TitleCol>

                      {statsByYear.map(({ payoutAmount }, index) => {
                        return (
                          <DoubleEntryTable.Col key={`payoutAmount${index}`}>
                            {!isNull(payoutAmount) && (
                              <>
                                {t(payoutAmount.cents / 100, {
                                  formatNumber: true,
                                })}
                                &nbsp;
                                {t(`currency_symbol.${payoutAmount.currency}`)}
                              </>
                            )}
                          </DoubleEntryTable.Col>
                        )
                      })}
                    </DoubleEntryTable.Row>

                    <DoubleEntryTable.Row>
                      <DoubleEntryTable.TitleCol>
                        {t('stats.tables.successful_projects')}
                      </DoubleEntryTable.TitleCol>

                      {statsByYear.map(({ successfulProjects }, index) => {
                        return (
                          <DoubleEntryTable.Col
                            key={`successfulProjects${index}`}
                          >
                            {!isNull(successfulProjects) &&
                              t(successfulProjects, { formatNumber: true })}
                          </DoubleEntryTable.Col>
                        )
                      })}
                    </DoubleEntryTable.Row>

                    <DoubleEntryTable.Row>
                      <DoubleEntryTable.TitleCol>
                        {t('stats.tables.success_rate')}
                        <span aria-describedby="Tables_Footnote_1">*</span>
                      </DoubleEntryTable.TitleCol>

                      {statsByYear.map(({ successRate }, index) => {
                        return (
                          <DoubleEntryTable.Col key={`successRate${index}`}>
                            {!isNull(successRate) && (
                              <>
                                {t(successRate * 100, { formatNumber: true })}
                                &nbsp;%
                              </>
                            )}
                          </DoubleEntryTable.Col>
                        )
                      })}
                    </DoubleEntryTable.Row>

                    <DoubleEntryTable.Row>
                      <DoubleEntryTable.TitleCol>
                        {t('stats.tables.contributions_count')}
                      </DoubleEntryTable.TitleCol>

                      {statsByYear.map(({ contributionsCount }, index) => {
                        return (
                          <DoubleEntryTable.Col
                            key={`contributionsCount${index}`}
                          >
                            {!isNull(contributionsCount) &&
                              t(contributionsCount, { formatNumber: true })}
                          </DoubleEntryTable.Col>
                        )
                      })}
                    </DoubleEntryTable.Row>

                    <DoubleEntryTable.Row>
                      <DoubleEntryTable.TitleCol>
                        {t('stats.tables.mean_contribution')}
                      </DoubleEntryTable.TitleCol>

                      {statsByYear.map(({ meanContribution }, index) => {
                        return (
                          <DoubleEntryTable.Col
                            key={`meanContribution${index}`}
                          >
                            {!isNull(meanContribution) && (
                              <>
                                {t(meanContribution.cents / 100, {
                                  formatNumber: true,
                                })}
                                &nbsp;
                                {t(
                                  `currency_symbol.${meanContribution.currency}`,
                                )}
                              </>
                            )}
                          </DoubleEntryTable.Col>
                        )
                      })}
                    </DoubleEntryTable.Row>
                  </DoubleEntryTable.Body>
                </DoubleEntryTable>
                <Text
                  weight="400"
                  className="Table__Footnote"
                  tag="small"
                  id="Tables_Footnote_1"
                >
                  <span aria-hidden>*</span> {t('stats.tables.footnote')}
                </Text>
              </Marger>
            </GridCol>
          )}

          <GridCol col-s={10} col-l={3} offset-s={1}>
            <Title
              className="Table__Title"
              tag="h2"
              modifier="tertiary"
              family="antiqueolive"
            >
              {t('stats.tables.stats_by_cats.title', { parseHtml: true })}
            </Title>
            <HorizontalStroke
              className="Stats__HorizontalStroke__Size3 Table__HorizontalStroke"
              size="large"
            />
          </GridCol>
          <GridCol col-s={10} col-l={6} offset-s={1}>
            <Text
              className="Table_Paragraph"
              weight="400"
              tag="p"
              lineHeight="normal"
              size="large"
            >
              {t('stats.tables.stats_by_cats.paragraph', {
                projectsCount: t(successfulProjectsCount, {
                  formatNumber: true,
                }),
                parseHtml: true,
              })}
            </Text>
            <Marger top={3}>
              <Button
                as="a"
                className="Table__Button"
                size="large"
                modifier="helium"
                href={routeFor(FUND_MY_PROJECT)}
              >
                {t('stats.fund_my_project_button')}
              </Button>
            </Marger>
          </GridCol>

          {statsByCategory.length > 0 && (
            <GridCol col-s={10} offset-s={1}>
              <Marger
                className="Table__TableMarger"
                top={{ fromXxs: 4, fromM: 5 }}
              >
                <DoubleEntryTable className="Table__DoubleEntryTable">
                  <DoubleEntryTable.Header>
                    <DoubleEntryTable.HeaderCol>
                      {t('stats.tables.category')}
                    </DoubleEntryTable.HeaderCol>
                    <DoubleEntryTable.HeaderCol>
                      {t('stats.tables.amount_collected')}
                    </DoubleEntryTable.HeaderCol>
                    <DoubleEntryTable.HeaderCol>
                      {t('stats.tables.successful_projects')}
                    </DoubleEntryTable.HeaderCol>
                    <DoubleEntryTable.HeaderCol>
                      {t('stats.tables.success_rate')}
                      <span aria-describedby="Tables_Footnote_1">*</span>
                    </DoubleEntryTable.HeaderCol>
                    <DoubleEntryTable.HeaderCol>
                      {t('stats.tables.contributions_count')}
                    </DoubleEntryTable.HeaderCol>
                    <DoubleEntryTable.HeaderCol>
                      {t('stats.tables.mean_contribution')}
                    </DoubleEntryTable.HeaderCol>
                  </DoubleEntryTable.Header>

                  <DoubleEntryTable.Body>
                    {take(numberOfCategoriesToShow)(statsByCategory).map(
                      ({
                        category,
                        amountCollected,
                        successfulProjects,
                        successRate,
                        contributionsCount,
                        meanContribution,
                      }) => {
                        if (isNull(category)) return null

                        return (
                          <DoubleEntryTable.Row key={category}>
                            <DoubleEntryTable.TitleCol>
                              {t(`categories.title.${category}`)}
                            </DoubleEntryTable.TitleCol>
                            <DoubleEntryTable.Col>
                              {!isNull(amountCollected) && (
                                <>
                                  {t(amountCollected.cents / 100, {
                                    formatNumber: true,
                                  })}
                                  &nbsp;
                                  {t(
                                    `currency_symbol.${amountCollected.currency}`,
                                  )}
                                </>
                              )}
                            </DoubleEntryTable.Col>
                            <DoubleEntryTable.Col>
                              {!isNull(successfulProjects) &&
                                t(successfulProjects, { formatNumber: true })}
                            </DoubleEntryTable.Col>
                            <DoubleEntryTable.Col>
                              {!isNull(successRate) && (
                                <>
                                  {t(successRate * 100, { formatNumber: true })}
                                  &nbsp;%
                                </>
                              )}
                            </DoubleEntryTable.Col>
                            <DoubleEntryTable.Col>
                              {!isNull(contributionsCount) &&
                                t(contributionsCount, { formatNumber: true })}
                            </DoubleEntryTable.Col>
                            <DoubleEntryTable.Col>
                              {!isNull(meanContribution) && (
                                <>
                                  {t(meanContribution.cents / 100, {
                                    formatNumber: true,
                                  })}
                                  &nbsp;
                                  {t(
                                    `currency_symbol.${meanContribution.currency}`,
                                  )}
                                </>
                              )}
                            </DoubleEntryTable.Col>
                          </DoubleEntryTable.Row>
                        )
                      },
                    )}
                  </DoubleEntryTable.Body>
                </DoubleEntryTable>
              </Marger>

              {statsByCategory.length > DEFAULT_NUMBER_OF_CATEGORIES &&
                numberOfCategoriesToShow !== statsByCategory.length && (
                  <Marger top={{ fromXxs: 3, fromS: 4 }} bottom={3}>
                    <Button
                      className="k-u-margin-horizontal-auto"
                      size="large"
                      fit="content"
                      modifier="hydrogen"
                      onClick={() =>
                        setNumberOfCategoriesToShow(statsByCategory.length)
                      }
                    >
                      {t('stats.tables.stats_by_cats.see_all_cats_button')}
                    </Button>
                  </Marger>
                )}

              <Text
                weight="400"
                className="Table__Footnote"
                tag="small"
                id="Tables_Footnote_2"
              >
                <span aria-hidden>*</span> {t('stats.tables.footnote')}
              </Text>
            </GridCol>
          )}
        </Grid>
      </Container>
    </section>
  )
}

export default Tables
