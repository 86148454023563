import React from 'react'
import {
  pxToRem,
  Title,
  Button,
  Text,
  MusicIllustration,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import styled from 'styled-components'
import { useTranslation } from 'kiss/hooks/use-translation'
import { getAssetFor } from 'kiss/assets/redux'
import { useSelector } from 'react-redux'
import { getRouteFor, FUND_MY_PROJECT } from 'kiss/routes/redux'

const DefenseWrapper = styled.section`
  margin-top: ${pxToRem(160)};

  @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
    margin-top: ${pxToRem(80)};
  }

  svg {
    right: 0;
    position: absolute;
    margin-right: ${pxToRem(220)};
  }

  .kiss-Manifesto__defense__img {
    display: block;
    object-fit: cover;
    object-position: center;
    width: 100%;
    padding-top: ${pxToRem(150)};

    @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
      padding-top: ${pxToRem(200)};
    }

    @media (max-width: ${pxToRem(ScreenConfig.S.max)}) {
      padding-top: ${pxToRem(250)};
    }

    img {
      width: 100%;
      display: block;
      object-fit: cover;
      object-position: center;
    }
  }

  .kiss-Manifesto__defense__background {
    margin-top: ${pxToRem(-1)};
    background-color: var(--color-danger-700);
  }

  .kiss-Manifesto__defense__container {
    max-width: ${pxToRem(1440)};
    display: flex;
    gap: ${pxToRem(40)};
    padding: ${pxToRem(120)} ${pxToRem(100)} ${pxToRem(200)} ${pxToRem(100)};

    @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
      flex-direction: column;
      padding: ${pxToRem(70)} ${pxToRem(40)} ${pxToRem(100)} ${pxToRem(40)};
      text-align: center;
    }
  }

  .kiss-Manifesto__defense__content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: ${pxToRem(20)};

    @media (max-width: ${pxToRem(ScreenConfig.M.max)}) {
      align-items: center;
    }

    .k-Button {
      margin-top: ${pxToRem(20)};
    }
  }
`

const Defense = () => {
  const t = useTranslation()
  const assetFor = useSelector(getAssetFor)
  const routeFor = useSelector(getRouteFor)

  return (
    <DefenseWrapper>
      <MusicIllustration />
      <div className="kiss-Manifesto__defense__img">
        <img alt="" src={assetFor('manifesto/culture.png')} />
      </div>
      <div className="kiss-Manifesto__defense__background">
        <div className="kiss-Manifesto__defense__container">
          <Title
            cssColor="var(--color-grey-000)"
            tag="h2"
            modifier="primary"
            noMargin
            family="antiqueolive"
          >
            {t('manifesto.engagement.culture.title')}
          </Title>
          <div className="kiss-Manifesto__defense__content">
            <Text
              cssColor="var(--color-grey-000)"
              size="huge"
              className="k-u-padding-right-decuple@sm-up"
            >
              {t('manifesto.engagement.culture.description1')}
            </Text>
            <Text cssColor="var(--color-grey-000)" size="huge">
              {t('manifesto.engagement.culture.description2')}
            </Text>
            <Text cssColor="var(--color-grey-000)" size="huge">
              {t('manifesto.engagement.culture.description3')}
            </Text>
            <Button modifier="copper" as="a" href={routeFor(FUND_MY_PROJECT)}>
              {t('manifesto.create_project.button')}
            </Button>
          </div>
        </div>
      </div>
    </DefenseWrapper>
  )
}

export default Defense
