import {
  CONTAINER_PADDING,
  GUTTER,
  pxToRem,
  ScreenConfig,
} from '@kisskissbankbank/kitten'
import { TWO_IN_XXS_CONTAINER } from 'kiss/utils/responsive-image/image-width-definitions'
import isEmpty from 'lodash/fp/isEmpty'
import orderBy from 'lodash/fp/orderBy'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import ProjectCard from '../../../components/cards/project-card'
import { getMentoredProjectCards } from '../redux'
import NoResult from './no-result'
import Pagination from './pagination'

const StyledGrid = styled.div`
  display: grid;
  grid-gap: ${pxToRem(20)};
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    grid-gap: ${pxToRem(20)};
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    grid-gap: ${pxToRem(30)};
    grid-template-columns: repeat(3, 1fr);
  }
`

const TWO_IN_10_COLS = `((100vw - ${
  CONTAINER_PADDING * 2
}px) * .833 - ${GUTTER}px) / 2`

const THREE_IN_8_COLS = `((${CONTAINER_PADDING * 2}px) * (2 / 3) - ${
  GUTTER * 2
}px) / 3`

const THREE_IN_XL_8_COLS = `((${
  ScreenConfig.XL.min - CONTAINER_PADDING * 2
}px) * (2 / 3) - ${GUTTER * 2}px) / 3`

const responsiveImageOptions = {
  imageSizes: [230, 280, 340, 380],
  breakpoints: [0, ScreenConfig.S.min, ScreenConfig.L.min, ScreenConfig.XL.min],
  imageSizesInViewport: [
    TWO_IN_XXS_CONTAINER,
    TWO_IN_10_COLS,
    THREE_IN_8_COLS,
    THREE_IN_XL_8_COLS,
  ],
}

const Projects = () => {
  const projects = useSelector(getMentoredProjectCards)
  const sortedProject = orderBy(['campaignStartAt'], ['desc'])(projects)

  if (isEmpty(sortedProject)) return <NoResult />

  return (
    <StyledGrid>
      {sortedProject.map((project) => {
        return (
          <ProjectCard
            key={project?.slug}
            project={project}
            responsiveImageOptions={responsiveImageOptions}
            forceDesktop
          />
        )
      })}
      <Pagination />
    </StyledGrid>
  )
}

export default Projects
