import {
  pxToRem,
  StandaloneRangeDatePicker,
  Text,
} from '@kisskissbankbank/kitten'
import FocusTrap from 'focus-trap-react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { isoDateToLocaleFormat } from 'kiss/utils/iso-date-to-locale-format'
import isDate from 'lodash/fp/isDate'
import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import Filter from './components/filter'
import FilterButton from './components/filter-button'
import { getMonths } from './date-helper'

const defaultValues = { from: undefined, until: undefined }

const FILTER_ID = 'dates-tag-button'

const StyledDateFilter = styled(Filter)`
  min-width: ${pxToRem(750)};
`

const Dates = () => {
  const t = useTranslation()
  const { search } = useLocation()
  const { locale } = useParams()
  const { from, until } = qs.parse(search, { ignoreQueryPrefix: true })
  const [searchParams, setSearchParams] = useState(defaultValues)
  const [showFilter, setShowFilter] = useState(false)

  const handleButtonClick = () => setShowFilter((current) => !current)
  const handleFilterClose = () => setShowFilter(false)

  const handleDatePickerChange = (range) => {
    if (!isDate(range.from) || !isDate(range.to)) return

    setSearchParams({
      from: isoDateToLocaleFormat(range.from, { format: 'yyyy-MM-dd', locale }),
      until: isoDateToLocaleFormat(range.to, { format: 'yyyy-MM-dd', locale }),
    })
  }

  useEffect(() => setSearchParams({ from, until }), [from, until])

  const MONTHS = getMonths(locale)
  const WEEKDAYS = [
    t('project.news.filters.date.standalone.day.sunday'),
    t('project.news.filters.date.standalone.day.monday'),
    t('project.news.filters.date.standalone.day.tuesday'),
    t('project.news.filters.date.standalone.day.wednesday'),
    t('project.news.filters.date.standalone.day.thursday'),
    t('project.news.filters.date.standalone.day.friday'),
    t('project.news.filters.date.standalone.day.saturday'),
  ]

  return (
    <FocusTrap
      active={showFilter}
      focusTrapOptions={{
        clickOutsideDeactivates: true,
      }}
    >
      <div>
        <FilterButton
          onClick={handleButtonClick}
          showFilter={showFilter}
          id={FILTER_ID}
          selected={!!(from || until)}
        >
          {t('project.news.sort_by_date')}
        </FilterButton>

        {showFilter && (
          <StyledDateFilter
            values={searchParams}
            defaultValues={defaultValues}
            mobileHeaderLabel={t(
              'project.news.filters.date.standalone.title_mobile',
              {
                parseHtml: true,
              },
            )}
            mobileFooterActionLabel={t(
              'project.news.filters.date.standalone.mobile_footer',
            )}
            onFilterClose={handleFilterClose}
          >
            <Text
              tag="p"
              size="small"
              weight="500"
              color="font1"
              className="k-u-hidden@s-down k-u-margin-bottom-double"
            >
              {t('project.news.filters.date.standalone.title')}
            </Text>

            <StandaloneRangeDatePicker
              from={from ? new Date(from) : undefined}
              to={until ? new Date(until) : undefined}
              onChange={handleDatePickerChange}
              locale={locale}
              months={MONTHS}
              weekDays={WEEKDAYS}
              firstDayOfWeek={1}
              numberOfMonths={2}
            />
          </StyledDateFilter>
        )}
      </div>
    </FocusTrap>
  )
}

export default Dates
