import { Button, pxToRem, useDeepCompareEffect } from '@kisskissbankbank/kitten'
import concat from 'lodash/fp/concat'
import find from 'lodash/fp/find'
import flow from 'lodash/fp/flow'
import keys from 'lodash/fp/keys'
import map from 'lodash/fp/map'
import sortBy from 'lodash/fp/sortBy'
import union from 'lodash/fp/union'
import qs from 'qs'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getProjectTags } from '../../../../../page-state/selectors'
import MoreButton from './more'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${pxToRem(10)};
  .list-wrapper {
    padding: ${pxToRem(2)};
    overflow: hidden;
    display: flex;
    gap: ${pxToRem(5)};
    > * {
      flex: 1 0 auto;
      opacity: 1;
      transition:
        opacity var(--transition),
        visibility var(--transition);
      &.hidden {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition-delay: 0, var(--transition-timing);
      }
    }
  }
  .menu {
    flex-shrink: 0;
  }
`

const sortList = (list) => {
  let newList = sortBy(['quantity'])(list).reverse()
  sortBy(['name'])(newList)
  return newList
}

const formattedTagList = (tagList) =>
  flow(
    keys,
    map((key) => ({ id: key, name: key, quantity: tagList[key] })),
  )(tagList)

const defaultValues = { tags: [] }

const TagList = () => {
  const tagsList = formattedTagList(useSelector(getProjectTags))
  const { search } = useLocation()
  const history = useHistory()
  const { tags } = qs.parse(search, {
    ignoreQueryPrefix: true,
  })
  const [searchParams, setSearchParams] = useState(defaultValues)
  const listWrapper = useRef(null)
  const [invisibleTagList, setInVisibleTagList] = useState(tagsList)

  useDeepCompareEffect(() => {
    setSearchParams({
      tags: tags || [],
    })
  }, [tags])

  const onSelectTags = ({ target: { checked, value, name } }) => {
    const searchKey = name.replace('[]', '')

    setSearchParams({
      ...searchParams,
      [searchKey]: checked
        ? concat(searchParams[searchKey], value)
        : searchParams[searchKey].filter((id) => id !== value),
    })
  }

  const onButtonTags = ({ tagId, active }) => {
    const searchKey = 'tags'
    const newSearchParams = {
      ...searchParams,
      [searchKey]: !active
        ? concat(searchParams[searchKey])([tagId])
        : searchParams[searchKey].filter((id) => id !== tagId),
    }
    setSearchParams(newSearchParams)
    const currentParams = qs.parse(search, {
      ignoreQueryPrefix: true,
    })
    const searchString = qs.stringify({
      ...currentParams,
      ...newSearchParams,
    })

    history.push({ search: searchString })
  }

  const handleIntersection = (entries) => {
    entries.forEach((entry) => {
      // If Tag is 100% visible
      if (entry.isIntersecting) {
        entry.target.classList.remove('hidden')
        entry.target.disabled = false
        entry.target.setAttribute('aria-hidden', false)

        // Remove from invisibleTagList
        setInVisibleTagList((currentList) =>
          currentList.filter((item) => item.id !== entry.target.id),
        )
      }

      // If tag is not 100% visible
      if (!entry.isIntersecting) {
        entry.target.classList.add('hidden')
        entry.target.disabled = true
        entry.target.setAttribute('aria-hidden', true)

        // Add to invisibleTagList
        setInVisibleTagList((currentList) => {
          const currentEntryItem = find(['id', entry.target.id])(tagsList)
          return union([currentEntryItem])(currentList)
        })
      }
    })
  }

  useEffect(() => {
    if (!listWrapper || !listWrapper.current) return

    const listWrapperEl = listWrapper.current

    const options = {
      root: listWrapperEl,
      rootMargin: '0px',
      threshold: 1.0,
    }

    const observer = new IntersectionObserver(handleIntersection, options)

    Array.from(listWrapperEl.children).forEach((child) =>
      observer.observe(child),
    )

    return () => {
      Array.from(listWrapperEl?.children).forEach((child) =>
        observer.unobserve(child),
      )
    }
  }, [listWrapper])

  return (
    <Wrapper>
      <div ref={listWrapper} className="list-wrapper">
        {sortList(tagsList).map((item, index) => {
          const active = searchParams.tags?.includes(item.id)
          return (
            <Button
              key={`tag-${item.name}${index}`}
              id={item.id}
              fit="content"
              size="small"
              rounded
              active={active}
              onClick={() =>
                onButtonTags({
                  tagId: item.id,
                  active,
                })
              }
            >
              <span>
                {item.name}{' '}
                <span className="k-u-weight-400">({item.quantity})</span>
              </span>
            </Button>
          )
        })}
      </div>
      {invisibleTagList.length > 0 && (
        <MoreButton
          defaultValues={defaultValues}
          onSelectTags={onSelectTags}
          searchParams={searchParams}
        />
      )}
    </Wrapper>
  )
}

export default TagList
