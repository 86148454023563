import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Title, Button, mq, pxToRem } from '@kisskissbankbank/kitten'

import { useTranslation } from 'kiss/hooks/use-translation'
import { getRouteFor, FUNNEL } from 'kiss/routes/redux'

const SoloButtonWrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-grey-100);
  padding: ${pxToRem(20)} 0 ${pxToRem(110)};

  @media ${mq.mobile} {
    padding: ${pxToRem(50)} 0 ${pxToRem(100)};
  }
}`

const SoloButton = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <SoloButtonWrapper>
      <Title
        tag="h2"
        family="antiqueolive"
        className="k-u-margin-none k-u-margin-vertical-double@xs-down k-u-align-center"
        modifier="tertiary"
      >
        {t('new_home.solobutton.title', { parseHtml: true })}
      </Title>

      <Button
        as="a"
        modifier="helium"
        className="k-u-alignSelf-center k-u-margin-top-double"
        href={routeFor(FUNNEL)}
      >
        {t('new_home.under_hero.button')}
      </Button>
    </SoloButtonWrapper>
  )
}

export default SoloButton
