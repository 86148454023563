import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'

import FeaturesList from 'kiss/modules/pricing-plans/FeaturesList'
import DetailsTableColumn from 'kiss/modules/pricing-plans/DetailsTableColumn'
import { mapFeatures } from 'kiss/modules/pricing-plans/helpers'

const Details = ({ pricingPlans, serviceFees }) => {
  const t = useTranslation()

  const featuresList = mapFeatures(pricingPlans)

  return (
    <div
      className="kiss-Pricing__table k-u-margin-top-nonuple k-u-margin-bottom-decuple@l-up k-u-margin-bottom-triple"
      style={{
        '--pricing-table-rows-count': 1 + featuresList.length,
      }}
    >
      <div
        className="kiss-Pricing__table__col_features"
        style={{ '--pricing-table-current-col': 1 }}
      >
        <div
          className="kiss-Pricing__table__cell_title"
          style={{ '--pricing-table-current-row': 1 }}
        >
          <h2 className="k-u-a11y-visuallyHidden k-u-hidden@m-down">
            {t('pricing_plans.details.list_title')}
          </h2>
        </div>

        <FeaturesList features={featuresList} />
      </div>

      {pricingPlans.map((plan, planIndex) => {
        return (
          <DetailsTableColumn
            serviceFees={serviceFees}
            featuresList={featuresList}
            plan={plan}
            planIndex={planIndex}
            key={plan.id}
          />
        )
      })}
    </div>
  )
}

export default Details
