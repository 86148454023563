import { mq, pxToRem } from '@kisskissbankbank/kitten'
import { createGlobalStyle } from 'styled-components'

export const PricingStyles = createGlobalStyle`

  @media ${mq.desktop} {
    .k-u-a11y-visuallyHidden_at_l-up {
      opacity: 0; 
      height: auto; 
      overflow: hidden;
      white-space: nowrap;
      width: 1px;
    }
  }

  .kiss-Pricing {
    @media ${mq.tablet} {
      padding: 0 ${pxToRem(120)} ${pxToRem(70)};
    }

    @media ${mq.desktop} {
      padding: 0 ${pxToRem(0)} ${pxToRem(70)};
    }

    .kiss-Pricing__table {
      margin-block: ${pxToRem(40)};
      position: relative;

      // Styles for mobile and tablet version
      @media ${mq.mobileAndTablet} {
        display: flex;
        flex-direction: column;
        gap: ${pxToRem(50)};

        .kiss-Pricing__table__col_features {
          display: none;
        }

        .kiss-Pricing__table__col_plan {
          background-color: var(--color-grey-100);
          border-radius: var(--border-radius-m);
          display: flex;
          flex-direction: column;
          gap: ${pxToRem(10)};
        }

        .kiss-Pricing__table__cell_feature {
          display: flex;
          gap: ${pxToRem(8)};
          justify-content: space-between;
          align-items: center;
          line-height: ${pxToRem(18)};
          margin-top: ${pxToRem(8)};
          margin-bottom: ${pxToRem(8)};
        }

        .kiss-Pricing__table__cell__bottom {
          margin-top: ${pxToRem(20)};
        }

        .kiss-Pricing__table__cell_title {
          background-color: var(--color-grey-000);
          box-shadow: var(--box-shadow-s);
          border-radius: var(--border-radius-m);
          margin-bottom: ${pxToRem(4)}
        }

        .kiss-Pricing__table__cell_feature__title_container {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          flex-grow: 1;
          gap: ${pxToRem(8)};
        }

        .kiss-Pricing__table_separator_top {
          margin-top: ${pxToRem(10)};
          margin-bottom: ${pxToRem(20)};
        }

        .kiss-Pricing__table_separator_bottom {
          margin-top: ${pxToRem(20)};
          margin-bottom: ${pxToRem(10)};
        }
      }

      // Styles for desktop version
      @media ${mq.desktop} {
        display: grid;
        grid-template-columns: minmax(0, ${pxToRem(330)}) 1fr 1fr 1fr;
        grid-template-rows: repeat(var(--pricing-table-rows-count), auto);
        grid-auto-flow: column;
        gap: ${pxToRem(16)} ${pxToRem(16)};
        padding-right: ${pxToRem(20)};
        margin-bottom: ${pxToRem(30)};

        .kiss-Pricing__table__col_features,
        .kiss-Pricing__table__col_plan {
          display: contents;
        }

        .kiss-Pricing__table__col_plan::before {
          display: block;
          content: '';
          grid-column: var(--pricing-table-current-col) / span 1;
          grid-row: 2 / span var(--pricing-table-rows-count);
          margin-bottom: ${pxToRem(-30)};

          border-bottom-left-radius: var(--border-radius-m);
          border-bottom-right-radius: var(--border-radius-m);
          box-shadow: var(--box-shadow-s);
          background-color: var(--color-grey-000);
        }
        .kiss-Pricing__table__col_plan--main::before {
          border: ${pxToRem(2)} solid var(--color-primary-500);
          background-color: var(--color-grey-000);
        }

        .kiss-Pricing__table__cell_category,
        .kiss-Pricing__table__cell_feature {
          max-width: ${pxToRem(200)};

          @media (min-width: 1190px) {
            max-width: ${pxToRem(325)};
          }

          @media (min-width: 1400px) {
            max-width: ${pxToRem(400)};
          }
        }

        .kiss-Pricing__table__cell_title {
          grid-column: var(--pricing-table-current-col) / span 1;
          grid-row: var(--pricing-table-current-row) / span 1;
          display: flex;
          flex-direction: column;
          justify-content: end;
          // sticky header
          position: sticky;
          top: 0;
          transition: top var(--transition);
          background-color: var(--color-grey-100);
          z-index: 10;
          min-height: ${pxToRem(240)};
          &::after {
            content: "";
            display: block;
            background-color: var(--color-grey-100);
            width: 100%;
            background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0));
            z-index: 10;
          }
        }

        .kiss-Pricing__table__cell_category {
          grid-column: var(--pricing-table-current-col) / span 1;
          grid-row: var(--pricing-table-current-row) / span 1;
        }
        
        .kiss-Pricing__table__cell_feature {
          grid-column: var(--pricing-table-current-col) / span 1;
          grid-row: var(--pricing-table-current-row) / span 1;
          align-self: center;
        }

        .kiss-Pricing__table__col_features .kiss-Pricing__table__cell_feature {
          align-self: stretch;
          display: flex;
          gap: ${pxToRem(10)};
          justify-content: space-between;
          align-items: center;
          min-height: ${pxToRem(18)};
        }

        .kiss-Pricing__table__col_plan .kiss-Pricing__table__cell_feature {
          align-self: stretch;
          display: flex;
          align-items: center;
          justify-content: center;
          min-height: ${pxToRem(20)};
        }

        .kiss-Pricing__table_separator_top {
          margin: ${pxToRem(10)} ${pxToRem(10)} ${pxToRem(20)};
        }

        .kiss-Pricing__table_separator_bottom {
          margin: ${pxToRem(20)} ${pxToRem(10)} ${pxToRem(10)};
        }
      }

      .kiss-Pricing__table__cell_feature__title {
        align-items: center;
        flex-wrap: wrap;
        gap: ${pxToRem(3)} ${pxToRem(5)};
      }
    }
  }

  .kiss-Pricing__bubble {
    border: ${pxToRem(10)} solid transparent;
    border-radius: var(--border-radius-s);
    background-color: var(--color-primary-100);
    text-align: left;
    width: max-content;
    min-width: ${pxToRem(220)};
    max-width: ${pxToRem(400)};
    z-index: 20;

    .kiss-Pricing__bubble_arrow {
      fill: var(--color-primary-100);
    }
  }

  .kiss-Pricing__bubble.kiss-Pricing__bubble--negative {
    color: var(--color-grey-000);
    background-color: var(--color-grey-900);

    .kiss-Pricing__bubble_arrow {
      fill: var(--color-grey-900);
    }
  }
`
