import Cookies from 'js-cookie'
import query from 'kiss/api/graphql-query'
import railsQuery from 'kiss/api/rails-query'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import { getLocale } from 'kiss/app/redux'
import { setSentryUserId } from 'kiss/app/sentry'
import signInMutation from 'kiss/graphql/mutations/authenticate/sign-in.graphql'
import signUpMutation from 'kiss/graphql/mutations/authenticate/sign-up.graphql'
import currentUserQuery from 'kiss/graphql/queries/session/current_user.graphql'
import { getRouteFor, LOGOUT } from 'kiss/routes/redux'
import filter from 'lodash/fp/filter'
import findIndex from 'lodash/fp/findIndex'
import first from 'lodash/fp/first'
import flow from 'lodash/fp/flow'
import get from 'lodash/fp/get'
import getOr from 'lodash/fp/getOr'
import isEmpty from 'lodash/fp/isEmpty'
import isNil from 'ramda/src/isNil'
import mergeDeepRight from 'ramda/src/mergeDeepRight'
import pipe from 'ramda/src/pipe'

const NAME = 'session'
const GET_SESSION = `${NAME}/GET_SESSION`
const UPDATE_SESSION = `${NAME}/UPDATE_SESSION`
const UPDATE_USERNAME = `${NAME}/UPDATE_USERNAME`
const UPDATE_SESSION_FETCHED = `${NAME}/UPDATE_SESSION_FETCHED`
const UPDATE_ACCESS_TOKEN = `${NAME}/UPDATE_ACCESS_TOKEN`
const UPDATE_AUTHENTICITY_TOKEN = `${NAME}/UPDATE_AUTHENTICITY_TOKEN`

const initialState = {
  authenticityToken: null,
  masquerading: false,
  hasJustSignedUp: false,
  returnTo: '/',
  sessionFetched: false,
  currentUser: {
    id: null,
    firstName: null,
    lastName: null,
    slug: null,
    encryptedEmail: null,
    email: null,
    username: null,
    image: {},
    isAdmin: false,
    facebookData: null,
    clientAt: null,
    kycActivated: false,
    lastProjectUrl: null,
    identity: {
      acceptedAt: null,
    },
    activatedFrontFeatures: [],
  },
}

const reducer = (state = initialState, action) => {
  if (isEmpty(state)) state = initialState

  state = mergeDeepRight(state, authenticityTokenState())

  switch (action.type) {
    case UPDATE_ACCESS_TOKEN:
    case GET_SESSION:
    case UPDATE_SESSION:
    case UPDATE_SESSION_FETCHED:
    case UPDATE_AUTHENTICITY_TOKEN:
      return mergeDeepRight(state, action.payload)
    case UPDATE_USERNAME:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          username: action.payload,
        },
      }
    default:
      return state
  }
}

const authenticityTokenState = () => {
  const authenticityToken = Cookies.get('authenticity_token')
  return { authenticityToken }
}

const updateAuthenticityToken = () => (dispatch) => {
  const { authenticityToken } = authenticityTokenState()
  dispatch({ type: UPDATE_AUTHENTICITY_TOKEN, payload: { authenticityToken } })
}

export const getSessionByQuery = () => async (dispatch, getState) => {
  const state = getState()
  const userConnected = Cookies.get('user_connected')

  if (!userConnected) {
    dispatch(updateSessionFetched(true))
    return
  }

  try {
    const response = await query(currentUserQuery, {}, state)

    dispatch({ type: GET_SESSION, payload: response })
    dispatch(updateSessionFetched(true))

    setSentryUserId(response.currentUser?.id)
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
    dispatch(updateSessionFetched(true))
  }
}

const updateSessionFetched = (sessionFetched) => (dispatch) => {
  dispatch({ type: UPDATE_SESSION_FETCHED, payload: { sessionFetched } })
}

export const updateUsername = (username) => (dispatch) => {
  dispatch({ type: UPDATE_USERNAME, payload: username })
}

export const logout = () => async (dispatch, getState) => {
  const state = getState()
  const logoutRoute = getRouteFor(state)(LOGOUT)

  try {
    const response = await railsQuery(logoutRoute, { method: 'DELETE' }, state)

    // Reset the Matomo user id.
    window && window._paq && window._paq.push(['resetUserId'])

    return response.json()
  } catch (e) {
    dispatch(addErrorAlert('Error: something went wrong!'))
  }
}

export const signIn =
  ({ email, password, rememberMe, gaCode, invitationToken }) =>
  (dispatch, getState) => {
    return query(
      signInMutation,
      {
        email,
        password,
        rememberMe,
        ...(gaCode && { gaCode: Number(gaCode) }),
        ...(invitationToken && { invitationToken: invitationToken }),
      },
      getState(),
    ).then(
      (response) => {
        dispatch(updateAuthenticityToken())
        return response
      },
      (errors) => {
        const twoFactorAuthenticationIndex = findIndex({
          code: 'two_factor_authentication_required',
        })(errors)

        if (twoFactorAuthenticationIndex > -1) {
          return Promise.reject({
            code: 'redirect_to_check_ga',
            id: getOr(null)(
              `[${twoFactorAuthenticationIndex}].two_factor_authentication_code`,
            )(errors),
          })
        }

        dispatch(addErrorAlert('Error: something went wrong!'))
      },
    )
  }

export const signUp = (values) => (dispatch, getState) => {
  const state = getState()
  const locale = getLocale(state)

  return query(signUpMutation, { language: locale, ...values }, state).then(
    (response) => {
      dispatch(updateAuthenticityToken())
      dispatch({
        type: UPDATE_SESSION,
        payload: { currentUser: response.signUp.user },
      })
      return response
    },
    (_error) => {
      dispatch(addErrorAlert('Error: something went wrong!'))
    },
  )
}

export const getAuthenticityToken = (state) =>
  getOr('')(`${NAME}.authenticityToken`)(state)
export const getGrapeAccessToken = (state) =>
  getOr('')(`${NAME}.grapeAccessToken`)(state)
export const masquerading = (state) => get(`${NAME}.masquerading`)(state)
export const getCurrentUser = (state) => getOr({})(`${NAME}.currentUser`)(state)
export const getCurrentGuestEmail = (state) =>
  getOr('')(`${NAME}.currentGuest.email`)(state)
export const getCurrentUserId = (state) => get(`${NAME}.currentUser.id`)(state)
export const getCurrentUserSlug = (state) =>
  get(`${NAME}.currentUser.slug`)(state)
export const getCurrentUserEmail = (state) =>
  get(`${NAME}.currentUser.email`)(state)
export const getCurrentUserUsername = (state) =>
  get(`${NAME}.currentUser.username`)(state)
export const getCurrentUserImage = (state) =>
  get(`${NAME}.currentUser.image`)(state)
export const getCurrentUserNeedsKyc = (state) =>
  get(`${NAME}.currentUser.kycActivated`)(state)
export const getLastProjectUrl = (state) =>
  get(`${NAME}.currentUser.lastProjectUrl`)(state)

export const getCurrentUserIdentityAcceptedAt = (state) =>
  getOr(initialState?.currentUser?.identity?.acceptedAt)(
    `${NAME}.currentUser.identity.acceptedAt`,
  )(state)
export const isCurrentUserAdmin = (state) =>
  get(`${NAME}.currentUser.isAdmin`)(state)
export const getCurrentUserFirstOrganizationWithProject = (state) =>
  flow(
    getOr(null)(`${NAME}.currentUser.organizations.edges`),
    filter(({ node }) => node?.projects?.totalCount > 0),
    first,
  )(state)

export const getCurrentUserActivatedFrontFeatures = (state) =>
  state?.[NAME]?.currentUser?.activatedFrontFeatures || []

export const isLogged = (state) =>
  !pipe(get(`${NAME}.currentUser`), isNil)(state)

export const isMentor = (state) =>
  getOr(false)(`${NAME}.currentUser.isMentor`)(state)
export const sessionFetched = (state) =>
  getOr(false)(`${NAME}.sessionFetched`)(state)

export const getChatUsername = (state) =>
  getOr(undefined)(`${NAME}.currentUser.chatUsername`)(state)
export const getReturnTo = (state) => getOr('/')(`${NAME}.returnTo`)(state)

export default { [NAME]: reducer }
