import React from 'react'
import Alerts from 'kiss/app/alerts/alerts'
import Footer from 'kiss/app/footer'
import Header from './header'
import PageLayout from 'kiss/app/layouts/page-layout'

const Layout = ({ children }) => (
  <PageLayout>
    <Header />
    <main id="main" style={{ position: 'relative' }}>
      <Alerts />
      {children}
    </main>
    <Footer />
  </PageLayout>
)

export default Layout
