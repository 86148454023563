import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import propOr from 'lodash/fp/propOr'
import flow from 'lodash/fp/flow'
import size from 'lodash/fp/size'
import {
  getProjectSlug,
  getProjectUuid,
  isContributingActionDisabled,
  isRewardDisabled,
} from 'kiss/modules/project-page/page-state/selectors'
import { useTranslation } from 'kiss/hooks/use-translation'
import { addErrorAlert } from 'kiss/app/alerts/redux'
import { addVariation } from 'kiss/modules/contribute/cart/redux'
import { RoutingHelper } from 'kiss/utils/routing-helper'
import { CART, getRouteFor as getRouteForSelector } from 'kiss/routes/redux'

import BackingCard from 'kiss/components/cards/backing-card'

const CODE_NONE = 'none'

const ActiveRewardCard = ({
  reward,
  handleModal,
  isProjectNotPublished,
  isProjectFinished,
  ...props
}) => {
  const t = useTranslation()
  const projectSlug = useSelector(getProjectSlug)
  const projectId = useSelector(getProjectUuid)
  const getRouteFor = useSelector(getRouteForSelector)

  const dispatch = useDispatch()

  const [isLoading, setLoading] = useState(false)
  const isDisabled =
    useSelector(isRewardDisabled)(reward) ||
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSelector(isContributingActionDisabled)

  const hasAtLeastOneDeliveryMode =
    flow(propOr([])('deliveryModes'), size)(reward) > 0 &&
    reward?.deliveryModes?.[0]?.code !== CODE_NONE

  const noModal = !reward.hasVariationSelection && !hasAtLeastOneDeliveryMode

  const clickProps = (() => {
    switch (true) {
      case isDisabled:
        return {
          as: 'article',
        }
      case noModal:
        return {
          as: 'button',
          type: 'button',
          onClick: () => {
            if (isProjectNotPublished) {
              return dispatch(
                addErrorAlert(
                  t('project_alerts.errors.contribute_on_draft_project'),
                  {
                    scroll: true,
                  },
                ),
              )
            }
            setLoading(true)
            dispatch(
              addVariation({
                variationId: reward.variations[0].uuid,
                deliveryModeCode: propOr('none')('deliveryModes[0].code')(
                  reward,
                ),
                projectId,
                projectSlug,
              }),
            )
              .then(() => {
                RoutingHelper.redirect(
                  getRouteFor(CART, { project: projectSlug }),
                )
              })
              .catch(() => setLoading(false))
          },
        }
      default:
        return {
          as: 'button',
          type: 'button',
          onClick: () => handleModal(reward),
        }
    }
  })()

  return (
    <BackingCard
      {...props}
      reward={reward}
      className="k-u-margin-bottom-quadruple"
      buttonProps={{
        isLoading,
        isDisabled,
        as: 'span',
      }}
      isProjectNotPublished={isProjectNotPublished}
      isProjectFinished={isProjectFinished}
      truncateTitle={false}
      {...clickProps}
    />
  )
}

export default ActiveRewardCard
