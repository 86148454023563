import createCommentQuery from 'kiss/graphql/mutations/project/create_comment.graphql'
import similarProjectQuery from 'kiss/graphql/project/similarprojects_list.graphql'
import pathOr from 'ramda/src/pathOr'
import query from 'kiss/api/graphql-query'
import { LOAD_SIMILAR_PROJECTS_LIST } from '../redux'

export const createComment =
  (projectId, text) => async (_dispatch, getState) => {
    return await query(createCommentQuery, { projectId, text }, getState())
  }

export const loadSimilarProjects = (slug) => async (dispatch, getState) => {
  const similarProjects = await query(
    similarProjectQuery,
    { slug, limit: 8 },
    getState(),
  )
  dispatch({
    type: LOAD_SIMILAR_PROJECTS_LIST,
    payload: pathOr([], ['project', 'similarProjects'])(similarProjects),
  })
}
