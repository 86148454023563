import { DropdownSelect } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import qs from 'qs'
import React from 'react'
import { useLocation } from 'react-router-dom'
import useSearch from '../use-search'

const State = (props) => {
  const t = useTranslation()
  const { updateSearch } = useSearch()
  const { search } = useLocation()
  const { state } = qs.parse(search, { ignoreQueryPrefix: true })
  return (
    <DropdownSelect
      {...props}
      labelText={t('browsing.fitler.state.label')}
      defaultSelectedValue={state || 'all'}
      size="small"
      hideLabel
      id="search-select-states"
      onChange={({ value }) => {
        switch (value) {
          case 'all':
            updateSearch({ state: undefined })
            break
          default:
            updateSearch({ state: value })
        }
      }}
      options={[
        { label: t('browsing.filter.state.all'), value: 'all' },
        {
          label: t('browsing.filter.state.in_progress'),
          value: 'started',
        },
        { label: t('browsing.filter.state.completed'), value: 'successful' },
      ]}
    />
  )
}

export default State
