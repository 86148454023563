import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import {
  Button,
  CopyIcon,
  pxToRem,
  ScreenConfig,
  FacebookButtonIcon,
  TwitterButtonIcon,
  InstagramButtonIcon,
  LinkedinButtonIcon,
} from '@kisskissbankbank/kitten'
import {
  getWebsiteUrl,
  getFacebookUrl,
  getInstagramUrl,
  getTwitterUrl,
  getLinkedInUrl,
} from '../redux'

const Container = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: ${pxToRem(120)};
  top: ${pxToRem(204)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    top: ${pxToRem(224)};
  }

  > * {
    margin-bottom: ${pxToRem(15)};
  }

  .kiss-MentorPage__socials__websiteButton svg path {
    fill: inherit;
  }
`

const Socials = () => {
  const websiteUrl = useSelector(getWebsiteUrl)
  const facebookUrl = useSelector(getFacebookUrl)
  const twitterUrl = useSelector(getTwitterUrl)
  const instagramUrl = useSelector(getInstagramUrl)
  const linkedInUrl = useSelector(getLinkedInUrl)

  if (!websiteUrl && !facebookUrl && !twitterUrl && !instagramUrl) return null

  return (
    <Container>
      {websiteUrl && (
        <Button
          fit="icon"
          as="a"
          href={websiteUrl}
          target="_blank"
          rel="external"
          className="kiss-MentorPage__socials__websiteButton"
        >
          <CopyIcon />
        </Button>
      )}
      {facebookUrl && (
        <FacebookButtonIcon
          as="a"
          href={facebookUrl}
          target="_blank"
          rel="external"
        />
      )}
      {twitterUrl && (
        <TwitterButtonIcon
          as="a"
          href={twitterUrl}
          target="_blank"
          rel="external"
        />
      )}
      {instagramUrl && (
        <InstagramButtonIcon
          as="a"
          href={instagramUrl}
          target="_blank"
          rel="external"
        />
      )}
      {linkedInUrl && (
        <LinkedinButtonIcon
          as="a"
          href={linkedInUrl}
          target="_blank"
          rel="external"
        />
      )}
    </Container>
  )
}

export default Socials
