import { Alert } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import qs from 'qs'
import React from 'react'
import { useLocation } from 'react-router'

const AlertFacebookEmail = () => {
  const t = useTranslation()
  const { search } = useLocation()
  const searchParams = qs.parse(search, { ignoreQueryPrefix: true })
  if (searchParams?.alert !== 'mail-not-found') return null
  return (
    <Alert status="danger" closeButton>
      {t('app.alerts.facebook.email')}
    </Alert>
  )
}

export default AlertFacebookEmail
