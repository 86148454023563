import React from 'react'

import {
  getRouteFor,
  SERVICES_TYPEFORM,
  SERVICES_LIVE_MENTOR,
} from 'kiss/routes/redux'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'

import { pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'
import HorizontalColorInfoCard from 'kiss/components/cards/horizontalColorInfoCard'
import PersonCard from 'kiss/components/cards/personCard'
import TestimonyCard from 'kiss/components/cards/testimonyCard'

import { Helmet } from 'react-helmet'
import DefaultLayout from 'kiss/app/layouts/default-layout'

import ServiceIntro from 'kiss/modules/service-page/ServiceIntro'
import ServicesCards from 'kiss/modules/service-page/ServicesCards'
import PromoCardsContainer from 'kiss/modules/service-page/PromoCardsContainer'

import styled from 'styled-components'

const StyledCardContainer = styled.div`
  display: flex;
  gap: ${pxToRem(20)};
  flex-direction: column;
  gap: 1.25rem;
  margin: 30px 20px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin-right: ${pxToRem(40)};
    margin-left: ${pxToRem(40)};
    gap: 2rem;
    flex-direction: row;
    justify-content: space-between;
  }
`

const StyledInfoCard = styled.div`
  margin: 30px 20px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin-right: ${pxToRem(40)};
    margin-left: ${pxToRem(40)};
  }
`

const StyledPromoContainer = styled.div`
  margin: 0px 20px;
  margin-bottom: 30px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    margin: 0px 40px;
  }
`

const StyledTestimonyCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--color-primary-100);
  border-radius: 10px;
  gap: 30px;
  margin: 30px 20px;
  padding: 20px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    flex-direction: row;
    margin: 30px 40px;
    padding: 30px;
  }
`

const ServicePage = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <DefaultLayout>
      <Helmet title={t('services.page.title')} />

      <ServiceIntro />

      <StyledCardContainer>
        <ServicesCards />
      </StyledCardContainer>

      <StyledInfoCard>
        <HorizontalColorInfoCard
          backgroundColor="var(--color-primary-100)"
          imagePath="illustrations/for-good/PoigneeDeMain.svg"
          title={t('services.mentor_card.title')}
          paragraph={t('services.mentor_card.paragraph')}
          buttonTitle={t('services.mentor_card.button')}
          buttonLink={routeFor(SERVICES_LIVE_MENTOR)}
        />
      </StyledInfoCard>

      <StyledPromoContainer>
        <PromoCardsContainer />
      </StyledPromoContainer>

      <StyledTestimonyCard>
        <PersonCard
          personImagePath="people/WiaPerson.jpg"
          iconPath="illustrations/generique/PacManBleu.svg"
        />
        <TestimonyCard
          paragraph1={t('services.testimony_card.first_paragraph', {
            parseHtml: true,
          })}
          paragraph2={t('services.testimony_card.second_paragraph', {
            parseHtml: true,
          })}
          authorName={t('services.testimony_card.author_name', {
            parseHtml: true,
          })}
          authorJob={t('services.testimony_card.author_job', {
            parseHtml: true,
          })}
        />
      </StyledTestimonyCard>

      <StyledInfoCard>
        <HorizontalColorInfoCard
          backgroundColor="var(--color-primary-100)"
          imagePath="illustrations/for-good/contact.svg"
          title={t('services.contact_card.title')}
          paragraph={t('services.contact_card.paragraph')}
          buttonTitle={t('services.contact_card.button')}
          buttonLink={routeFor(SERVICES_TYPEFORM)}
        />
      </StyledInfoCard>
    </DefaultLayout>
  )
}

export default ServicePage
