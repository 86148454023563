import React, { useCallback } from 'react'
import { useBeforeunload } from 'react-beforeunload'
import { useFormikContext } from 'formik'
import isEqual from 'lodash/fp/isEqual'
import { useSelector } from 'react-redux'
import { getInitialValues } from '../redux'
import { Prompt } from 'react-router'
import { useTranslation } from 'kiss/hooks/use-translation'

const ConfirmFormExit = () => {
  const t = useTranslation()
  const { values } = useFormikContext()
  const initialValues = useSelector(getInitialValues)
  const shouldBlockNavigation = !isEqual(
    { ...values, description: undefined },
    { ...initialValues, description: undefined },
  )

  const handleBeforeUnload = useCallback(
    () => shouldBlockNavigation && t('mentor_page.edition.confirm_form_exit'),
    [shouldBlockNavigation],
  )

  useBeforeunload(handleBeforeUnload)

  return (
    <Prompt
      when={shouldBlockNavigation}
      message={t('mentor_page.edition.confirm_form_exit')}
    />
  )
}

export default ConfirmFormExit
