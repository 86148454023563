import { Text } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import qs from 'qs'
import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { getTotal } from '../redux'

const Results = ({ className }) => {
  const t = useTranslation()
  const { search } = useLocation()
  const total = useSelector(getTotal) || 0
  const { q } = qs.parse(search, { ignoreQueryPrefix: true })
  if (!q) return null
  return (
    <div className={className}>
      <Text weight="600">
        {t('search.header.result_for', {
          parseHtml: true,
          count: total,
        })}
      </Text>
      <Text weight="600" color="primary1">
        {q}
      </Text>
    </div>
  )
}

export default Results
