import React from 'react'
import { Helmet } from 'react-helmet'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { Hero, HowItWorks, Discover, Faq, Company } from './components'
import { useTranslation } from 'kiss/hooks/use-translation'

const GiftCardLanding = () => {
  const t = useTranslation()

  return (
    <DefaultLayout>
      <Helmet title={t('gift_card.landing.seo.title')} />
      <Hero />
      <HowItWorks />
      <Discover />
      <Faq />
      <Company />
    </DefaultLayout>
  )
}

export default GiftCardLanding
