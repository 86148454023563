import React from 'react'

import {
  getRouteFor,
  FUND_MY_PROJECT,
  SERVICES,
  FONCTIONNALITES,
  FINANCING_METHOD_SECTION,
  SUPPORT_SERVICES_SECTION,
} from 'kiss/routes/redux'
import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import { useTranslation } from 'kiss/hooks/use-translation'

import {
  pxToRem,
  Title,
  Text,
  Button,
  RibbonIllustration,
  FormIllustration,
  ComputerIllustration,
  mq,
  SpeakerIllustration,
} from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const WhyWrapper = styled.section`
  position: relative;
  background-color: var(--color-danger-100);
  color: var(--color-grey-900);
  text-align: center;
  clip-path: polygon(100% 0%, 0% 5%, 0% 100%, 100% 95%);

  > * {
    position: relative;
    z-index: 2;
  }

  .kiss-Homepage__why__container {
    box-sizing: border-box;
    max-width: ${pxToRem(1440)};
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    padding: ${pxToRem(110)} ${pxToRem(20)};

    @media ${mq.tabletAndDesktop} {
      padding: ${pxToRem(120)} ${pxToRem(100)};
    }
  }

  .kiss-Homepage__why__servicing__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-grey-900);
    background-color: var(--color-grey-000);
    padding: ${pxToRem(40)} ${pxToRem(30)} ${pxToRem(30)};
    border-radius: var(--border-radius-m);
    box-shadow: var(--box-shadow-m);
    overflow: hidden;

    @media ${mq.desktop} {
      flex-direction: row;
      padding: ${pxToRem(0)} ${pxToRem(30)} ${pxToRem(0)} ${pxToRem(30)};
      gap: ${pxToRem(30)};
    }
  }

  .kiss-Homepage__why__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-grey-900);
    background-color: var(--color-grey-000);
    padding: ${pxToRem(40)} ${pxToRem(30)} ${pxToRem(30)};
    border-radius: var(--border-radius-m);
    box-shadow: var(--box-shadow-m);
    overflow: hidden;
  }

  .kiss-Homepage__why__cards {
    display: grid;
    gap: ${pxToRem(30)};
    margin: ${pxToRem(40)} 0;
    grid-template-columns: 1fr;

    @media ${mq.desktop} {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  .kiss-Homepage__why__servicing_details {
    display: flex;
    flex-direction: column;
  }
  .details_title {
    margin-bottom: ${pxToRem(5)};

    @media ${mq.desktop} {
      margin-bottom: ${pxToRem(20)};
    }
  }

  .kiss-Homepage__hero__man_img {
    display: block;
    width: 228px;
    height: 200px;
    margin: 30px 0px;

    svg {
      width: fit-content;
      display: initial;
      height: inherit;
    }
  }

  .kiss-Homepage__hero__banner {
    display: none;

    img {
      display: block;
      width: 95.39px;
      height: 117.9px;
      object-fit: cover;
      object-position: center;
    }

    @media ${mq.desktop} {
      display: block;
      position: relative;
      align-self: flex-start;
    }
  }

  .kiss-Homepage__hero__top__banner {
    display: block;
    align-self: flex-start;
    height: 0;
    margin-top: -2.5rem;

    img {
      display: block;
      width: 111px;
      height: 137px;
      object-fit: cover;
      object-position: center center;
    }

    @media ${mq.desktop} {
      display: none;
    }
  }
`

const Why = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)
  const assetFor = useSelector(getAssetFor)

  return (
    <WhyWrapper>
      <div className="kiss-Homepage__why__container">
        <Title
          tag="h2"
          cssColor="inherit"
          modifier="tertiary"
          noMargin
          family="antiqueolive"
        >
          {t('new_home.why.title', { parseHtml: true })}
        </Title>
        <div className="kiss-Homepage__why__servicing__card k-u-margin-top-triple">
          <div className="kiss-Homepage__hero__top__banner">
            <img src={assetFor('home/banner.svg')} width="62" height="62" />
          </div>
          <div className="kiss-Homepage__hero__man_img">
            <SpeakerIllustration />
          </div>
          <div className="kiss-Homepage__why__servicing_details">
            <Text
              className="details_title"
              size="giant"
              weight="600"
              family="antiqueolive"
            >
              {t('new_home.why.servicing.title')}
            </Text>
            <Text lineHeight="normal" className="k-u-margin-top-single">
              {t('new_home.why.servicing.description')}
            </Text>
            <div className="k-u-align-center k-u-margin-top-double">
              <Button as="a" href={routeFor(SERVICES)} modifier="iron">
                {t('new_home.why.discover_services')}
              </Button>
            </div>
          </div>
          <div className="kiss-Homepage__hero__banner">
            <img src={assetFor('home/banner.svg')} width="62" height="62" />
          </div>
        </div>
        <div className="kiss-Homepage__why__cards">
          <div className="kiss-Homepage__why__card">
            <FormIllustration
              className="k-u-margin-bottom-double"
              width="100"
              height="160"
            />
            <Text size="giant" weight="600" family="antiqueolive">
              {t('new_home.why.funding_types.title')}
            </Text>
            <Text lineHeight="normal" className="k-u-margin-top-single">
              {t('new_home.why.funding_types.description')}
            </Text>
            <div className="k-u-align-center k-u-margin-top-double">
              <Button
                as="a"
                href={routeFor(
                  `${FUND_MY_PROJECT}#${FINANCING_METHOD_SECTION}`,
                )}
                modifier="iron"
              >
                {t('new_home.why.funding_types.cta')}
              </Button>
            </div>
          </div>
          <div className="kiss-Homepage__why__card">
            <RibbonIllustration
              className="k-u-margin-bottom-double"
              width="218"
              height="160"
            />
            <Text size="giant" weight="600" family="antiqueolive">
              {t('new_home.why.support.title')}
            </Text>
            <Text lineHeight="normal" className="k-u-margin-top-single">
              {t('new_home.why.support.description')}
            </Text>
            <div className="k-u-align-center k-u-margin-top-double">
              <Button
                as="a"
                href={routeFor(
                  `${FUND_MY_PROJECT}#${SUPPORT_SERVICES_SECTION}`,
                )}
                modifier="iron"
              >
                {t('new_home.why.support.cta')}
              </Button>
            </div>
          </div>
          <div className="kiss-Homepage__why__card">
            <ComputerIllustration
              className="k-u-margin-bottom-double"
              width="183"
              height="160"
            />
            <Text size="giant" weight="600" family="antiqueolive">
              {t('new_home.why.features.title')}
            </Text>
            <Text lineHeight="normal" className="k-u-margin-top-single">
              {t('new_home.why.features.description')}
            </Text>
            <div className="k-u-align-center k-u-margin-top-double">
              <Button as="a" href={routeFor(FONCTIONNALITES)} modifier="iron">
                {t('new_home.why.features.cta')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </WhyWrapper>
  )
}

export default Why
