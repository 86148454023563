import React from 'react'
import Checkbox from 'kiss/components/formik/checkbox'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Marger, Title } from '@kisskissbankbank/kitten'

const Role = () => {
  const t = useTranslation()
  return (
    <Marger top="8" bottom="8">
      <Marger bottom="3">
        <Title modifier="quinary">
          {t('mentor.signup.form.role.title', { parseHtml: true })}
        </Title>
      </Marger>
      <Checkbox name="financialRole">
        {t('mentor.signup.form.role.first')}
      </Checkbox>
      <Checkbox name="visibilityRole">
        {t('mentor.signup.form.role.second')}
      </Checkbox>
      <Checkbox name="supportRole">
        {t('mentor.signup.form.role.third')}
      </Checkbox>
    </Marger>
  )
}

export default Role
