import React from 'react'

import { useTranslation } from 'kiss/hooks/use-translation'
import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import { mq, Paragraph, pxToRem, Tag, Text } from '@kisskissbankbank/kitten'

import classNames from 'classnames'
import styled from 'styled-components'

const StyledFirstBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(15)};
`

const StyledPricingTitle = styled.div`
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  font-family: 'AntiqueOlive';
  display: inline-flex;
  gap: ${pxToRem(10)};
  flex-wrap: wrap;

  @media ${mq.desktop} {
    font-size: 36px;
    line-height: 46px;
  }
`

const StyledPricingTitleBis = styled.span`
  color: var(--color-primary-500);
  font-family: 'GeneralSans';
`

const StyledPricingTitleBisTtc = styled.span`
  font-family: 'AntiqueOlive';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  color: var(--color-primary-500);
  position: relative;
  top: -10px;

  @media ${mq.desktop} {
    font-size: 20px;
    line-height: 30px;
  }
`

const StyledRateDescriptionParagraph = styled.p`
  color: var(--color-grey-900);
  font-family: 'GeneralSans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: auto;

  @media ${mq.desktop} {
    font-size: 14px;
  }
`

const StyledTaxesSpan = styled.span`
  font-size: 10px;
  display: inline-flex;
  vertical-align: super;
`

const PricingCardWrapper = styled.div`
  position: relative;
  background-color: var(--color-grey-000);
  border-radius: var(--border-radius-m);
  padding: ${pxToRem(20)};
  display: flex;
  gap: ${pxToRem(15)};
  flex-direction: column;
  box-shadow: var(--box-shadow-m);
  height: fit-content;
  width: -moz-available;
  width: -webkit-fill-available;

  @media ${mq.desktop} {
    grid-row: 2 / span 1;
  }

  &.kiss-Pricing__card--without-shadow {
    box-shadow: none;
  }

  &.kiss-Pricing__card--without-border-radius {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }

  &.kiss-Pricing__card--main {
    border: ${pxToRem(2)} solid var(--color-primary-500);
    padding: ${pxToRem(20)};

    @media ${mq.desktop} {
      grid-row: 1 / span 3;
    }
  }

  .kiss-Pricing__card__description {
    margin: 0;
    font-weight: var(--font-weight-600);
    line-height: 1.3;
  }

  .kiss-Pricing__card__recommended {
    position: absolute;
    top: ${pxToRem(-13)};
    left: 30.5px;
  }
  .kiss-Pricing__card__list {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: ${pxToRem(10)};
  }
  .kiss-Pricing__card__list__item {
    margin: 0;
    padding: 0;
    display: flex;
    gap: ${pxToRem(8)};
    line-height: ${pxToRem(18)};
    align-items: center;
  }
  .kiss-Pricing__card__list__item__icon {
    width: ${pxToRem(16)};
    height: ${pxToRem(16)};
    align-self: flex-start;
  }
`

const PricingCard = ({
  name,
  rate,
  rateWithoutVat,
  className,
  main = false,
  isFree = false,
  bottomRadiusDisplay = true,
  shadowBoxDisplay = true,
  subtitleTranslationKeys,
  descriptionTranslationKey,
  listItemsTranslationKeys,
  actionBlock,
}) => {
  const t = useTranslation()

  const assetFor = useSelector(getAssetFor)

  return (
    <PricingCardWrapper
      className={classNames(className, {
        'kiss-Pricing__card--main': main,
        'kiss-Pricing__card--without-border-radius': !bottomRadiusDisplay,
        'kiss-Pricing__card--without-shadow': !shadowBoxDisplay,
      })}
    >
      {name === 'Pro' && (
        <Tag className="kiss-Pricing__card__recommended" variant="dark">
          {t('pricing_plans.recommended')}
        </Tag>
      )}

      <StyledFirstBlock>
        <StyledPricingTitle>
          <div>{name}</div>
          <div>
            <StyledPricingTitleBis>
              {isFree ? t(`${subtitleTranslationKeys.plan}`) : `${rate}`}
            </StyledPricingTitleBis>
            <StyledPricingTitleBisTtc>
              {isFree ? '' : t(`${subtitleTranslationKeys.plan}`)}
            </StyledPricingTitleBisTtc>
          </div>
        </StyledPricingTitle>

        <StyledRateDescriptionParagraph>
          {t(`${subtitleTranslationKeys.start}`)}{' '}
          <span className="k-u-weight-600 k-u-color-primary-500">
            {rateWithoutVat}
            <StyledTaxesSpan>
              {t(`${subtitleTranslationKeys.vat}`)}
              {''}
            </StyledTaxesSpan>{' '}
            {t(`${subtitleTranslationKeys.end1}`)}{' '}
          </span>
          {t(`${subtitleTranslationKeys.end2}`)}
        </StyledRateDescriptionParagraph>
      </StyledFirstBlock>

      {descriptionTranslationKey && (
        <Paragraph
          modifier="tertiary"
          className="kiss-Pricing__card__description"
        >
          {t(`${descriptionTranslationKey}`)}
        </Paragraph>
      )}

      {listItemsTranslationKeys && (
        <ul className="kiss-Pricing__card__list">
          {listItemsTranslationKeys.map((item, index) => {
            return (
              <li key={index} className="kiss-Pricing__card__list__item">
                <div className="kiss-Pricing__card__list__item__icon">
                  <img
                    src={assetFor('icons/alertes/done.svg')}
                    alt={'icons/alertes/done.svg'}
                  />
                </div>
                <div>
                  <Text size="micro" weight="400">
                    {t(`${item.title}`)}
                  </Text>
                </div>
              </li>
            )
          })}
        </ul>
      )}

      <div>{actionBlock}</div>
    </PricingCardWrapper>
  )
}
export default PricingCard
