import React from 'react'
import classNames from 'classnames'
import styled from 'styled-components'

import { useTranslation } from 'kiss/hooks/use-translation'

import {
  Text,
  Tag,
  CrossIcon,
  IconBadge,
  CheckedIcon,
  Separator,
  pxToRem,
} from '@kisskissbankbank/kitten'

import DetailsColumnHeader from 'kiss/modules/pricing-plans/DetailsColumnHeader'
import FeatureExplanationTooltip from 'kiss/modules/pricing-plans/FeatureExplanationTooltip'

const StyledTag = styled(Tag)`
  min-width: ${pxToRem(68)};
  display: flex;
  justify-content: center;
`

const StyledBadge = styled(IconBadge)`
  max-height: ${pxToRem(20)};
`

const DetailsTableColumn = ({ serviceFees, featuresList, plan, planIndex }) => {
  const t = useTranslation()

  return (
    <div
      className={classNames('kiss-Pricing__table__col_plan', {
        'kiss-Pricing__table__col_plan--main': planIndex === 1,
      })}
      style={{ '--pricing-table-current-col': planIndex + 2 }}
      key={plan.id}
    >
      <DetailsColumnHeader plan={plan} serviceFees={serviceFees} />

      {featuresList.map((feature, featureIndex) => {
        if (feature.isCategory) {
          return (
            <div
              className="kiss-Pricing__table__cell_category"
              style={{
                '--pricing-table-current-row': featureIndex + 2,
              }}
              key={feature.category}
            >
              {featureIndex !== 0 && (
                <Separator
                  darker
                  className="kiss-Pricing__table_separator_top"
                />
              )}
              <Text
                className="k-u-letter-spacing-10 k-u-a11y-visuallyHidden_at_l-up"
                transform="uppercase"
                weight="600"
                size="medium"
                tag="h4"
              >
                {t(`pricing_plans.details.${feature.category}`)}
              </Text>
              <Separator
                darker
                className="kiss-Pricing__table_separator_bottom"
              />
            </div>
          )
        }

        const targetedCategory = plan.featuresByCategory.find(
          (item) => item.category === feature.category,
        )

        if (!targetedCategory) return null
        if (
          !targetedCategory.features.some(
            (item) => item.title === feature.title,
          )
        ) {
          return (
            <div
              className={classNames('kiss-Pricing__table__cell_feature', {
                'k-u-hidden@m-down': feature.label !== 'rewards_number',
              })}
              style={{
                '--pricing-table-current-row': featureIndex + 2,
              }}
              key={feature.title}
            >
              {' '}
              {feature.label === 'rewards_number' && (
                <Text
                  size="small"
                  weight="400"
                  className="k-u-hidden@l-up kiss-Pricing__table__cell_feature__title"
                >
                  {feature.title}
                </Text>
              )}
              {feature.label === 'rewards_number' ? (
                <StyledTag variant="dark" type="info">
                  {t('pricing_plans.details.rewards_number.limited')}
                </StyledTag>
              ) : (
                <IconBadge
                  status="danger"
                  size="small"
                  border={{
                    width: 2,
                    color: 'var(--color-danger-300)',
                    style: 'solid',
                  }}
                >
                  <CrossIcon />
                </IconBadge>
              )}
              {feature.label !== 'rewards_number' && (
                <div className="k-u-a11y-visuallyHidden">
                  {t('pricing_plans.details.feature_is_not_included', {
                    plan: plan.title,
                    feature: feature.title,
                  })}
                </div>
              )}
            </div>
          )
        }

        return (
          <div
            className="kiss-Pricing__table__cell_feature"
            style={{ '--pricing-table-current-row': featureIndex + 2 }}
            key={feature.title}
            title={feature.title}
          >
            <div className="kiss-Pricing__table__cell_feature__title_container">
              <Text
                size="small"
                weight="400"
                className="k-u-hidden@l-up kiss-Pricing__table__cell_feature__title"
              >
                {feature.title}

                {feature.soon && (
                  <Tag
                    size="small"
                    className="kiss-Pricing__table__cell_feature__tag"
                  >
                    {t('pricing_plans.soon')}
                  </Tag>
                )}
              </Text>
              {feature.description && (
                <FeatureExplanationTooltip
                  content={feature.description}
                  className="k-u-hidden@l-up"
                />
              )}
            </div>
            {feature.label === 'rewards_number' ? (
              <StyledTag variant="dark" type="info">
                {t('pricing_plans.details.rewards_number.unlimited')}
              </StyledTag>
            ) : (
              <StyledBadge
                status="success"
                size="small"
                border={{
                  width: 2,
                  color: 'var(--color-success-300)',
                  style: 'solid',
                }}
              >
                <CheckedIcon />
              </StyledBadge>
            )}
            <div className="k-u-a11y-visuallyHidden k-u-hidden@m-down">
              {t('pricing_plans.details.feature_is_included', {
                plan: plan.title,
                feature: feature.title,
              })}
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default DetailsTableColumn
