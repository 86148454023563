import isEmpty from 'lodash/fp/isEmpty'
import isNumber from 'lodash/fp/isNumber'
import pickBy from 'lodash/fp/pickBy'
import qs from 'qs'
import React, { createContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useLocation } from 'react-router-dom'
import { fetchProjects, getProjects } from './redux'

export const ITEMS_PER_PAGE = 16

const initialState = {
  projects: [],
}

export const BrowsingContext = createContext(initialState)

export const BrowsingProvider = ({ children }) => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const projects = useSelector(getProjects)
  const { engagement } = useParams()
  const preventInitialCallOnMount = useRef(true)

  useEffect(() => {
    if (preventInitialCallOnMount.current) {
      preventInitialCallOnMount.current = false
      return
    }

    const {
      location,
      page,
      state,
      category,
      scope,
      sortArgument,
      sortDirection,
    } = qs.parse(search, { ignoreQueryPrefix: true })

    const offset = page ? (Number(page) - 1) * ITEMS_PER_PAGE : 0

    const searchParams = pickBy((value) => !isEmpty(value) || isNumber(0))({
      offset,
      first: ITEMS_PER_PAGE,
      scope: scope || 'all',
      states: !state ? ['started', 'successful'] : [state],
      categorySlugs: category === 'all' || !category ? undefined : [category],
      engagementCodes: !engagement ? undefined : [engagement],
      placeName: location,
      sortArgument: sortArgument,
      sortDirection: sortDirection,
    })
    dispatch(fetchProjects(searchParams))
  }, [search, engagement])

  return (
    <BrowsingContext.Provider value={{ projects }}>
      {children}
    </BrowsingContext.Provider>
  )
}
