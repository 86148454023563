import query from 'kiss/api/graphql-query'
import likeNewsQuery from 'kiss/graphql/mutations/project/like_news.graphql'
import unlikeNewsQuery from 'kiss/graphql/mutations/project/unlike_news.graphql'
import createCommentQuery from 'kiss/graphql/mutations/project/create_comment.graphql'
import updateCommentQuery from 'kiss/graphql/mutations/project/update_comment.graphql'
import likeCommentQuery from 'kiss/graphql/mutations/project/like.graphql'
import unlikeCommentQuery from 'kiss/graphql/mutations/project/unlike.graphql'
import deleteCommentQuery from 'kiss/graphql/mutations/project/delete_comment.graphql'
import newsQuery from 'kiss/graphql/queries/project/news.graphql'

import { addErrorAlert } from 'kiss/app/alerts/redux'
import { UPDATE_NEWS } from 'kiss/modules/project-page/page-state/redux'

import { path } from 'ramda'
import { getNews } from 'kiss/modules/project-page/page-state/selectors'
import { LoadMoreCommentForNewsQuery } from 'kiss/modules/project-page/common/news/LoadMoreCommentForNewsQuery'
import { replaceNews } from 'kiss/modules/project-page/page-state/actions'

const refreshNews =
  ({ slug }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      const response = await query(newsQuery, { slug }, state, {
        fetchPolicy: 'no-cache',
      })

      dispatch({
        type: UPDATE_NEWS,
        payload: {
          newsConnection: response.project.newsConnection,
        },
      })
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const like =
  ({ newsId, slug }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(likeNewsQuery, { newsId }, state)

      dispatch(refreshNews({ slug }))
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const unlike =
  ({ newsId, slug }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(unlikeNewsQuery, { newsId }, state)

      dispatch(refreshNews({ slug }))
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const postNewsComment =
  ({ commentUuid, text, newsId, slug }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(
        createCommentQuery,
        { commentId: commentUuid, newsId, text },
        state,
      )
      dispatch(refreshNews({ slug }))
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const updateNewsComment =
  ({ commentUuid, text, slug }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(updateCommentQuery, { commentId: commentUuid, text }, state)

      await dispatch(refreshNews({ slug }))
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const likeNewsComment =
  ({ commentId, slug }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(likeCommentQuery, { commentId }, state)

      dispatch(refreshNews({ slug }))
    } catch (e) {
      await dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const unlikeNewsComment =
  ({ commentId, slug }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(unlikeCommentQuery, { commentId }, state)

      dispatch(refreshNews({ slug }))
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const deleteComment =
  ({ commentId, slug }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      await query(deleteCommentQuery, { uuid: commentId }, state)

      dispatch(refreshNews({ slug }))
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }

export const loadMoreCommentsForNews =
  ({ slug, newsId, lastCursor }) =>
  async (dispatch, getState) => {
    const state = getState()

    try {
      const response = await query(
        LoadMoreCommentForNewsQuery,
        {
          slug,
          newsId,
          lastCursor,
        },
        state,
        {
          fetchPolicy: 'no-cache',
        },
      )

      const newComments = path(
        [
          'project',
          'newsConnection',
          'edges',
          0,
          'node',
          'commentsConnection',
          'edges',
        ],
        response,
      )

      if (newComments.length) {
        const existingNews = getNews(state)
        const updatedNews = existingNews.map((news) => {
          if (news.id === newsId) {
            const existingComments = news.commentsConnection.edges || []
            const updatedComments = [...existingComments, ...newComments]

            return {
              ...news,
              commentsConnection: {
                ...news.commentsConnection,
                edges: updatedComments,
              },
            }
          }
          return news
        })

        dispatch(replaceNews(updatedNews))
      }
    } catch (e) {
      dispatch(addErrorAlert('Error: something went wrong!'))
    }
  }
