import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Formik } from 'formik'
import { updateMentor, getInitialValues } from '../redux'
import isEqual from 'lodash/fp/isEqual'
import ConfirmFormExit from './confirm-form-exit'

const Form = ({ children }) => {
  const dispatch = useDispatch()
  const initialValues = useSelector(getInitialValues)
  const handleSubmit = async (values, { resetForm }) => {
    const formatedValues = {
      title: values.title,
      coverImage: values.coverImageFile,
      avatarImage: values.avatarImageFile,
      description: JSON.stringify(values.description),
    }
    if (isEqual(formatedValues, initialValues)) return

    await dispatch(updateMentor(formatedValues))
    resetForm(initialValues)
  }

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      <>
        {children}
        <ConfirmFormExit />
      </>
    </Formik>
  )
}

export default Form
