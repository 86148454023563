import React from 'react'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Paragraph } from '@kisskissbankbank/kitten'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'

const Loader = () => {
  const t = useTranslation()

  return (
    <Paragraph tag="div" className="k-u-align-center">
      {t('gift_card.new.loader')}
      <LoadingAnimation className="k-u-margin-top-single" />
    </Paragraph>
  )
}

export default Loader
