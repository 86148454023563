import { domElementHelper } from '@kisskissbankbank/kitten'
import classNames from 'classnames'
import DefaultLayout from 'kiss/app/layouts/default-layout'
import { useTranslation } from 'kiss/hooks/use-translation'
import { useMedia } from 'kiss/media-queries/media-query-hook'
import { matchMediaQuery } from 'kiss/media-queries/redux'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import AnimatedNumbers, { NumberCard } from './components/animated-numbers'
import IntroHero from './components/intro-hero'
import Platforms from './components/platforms'
import PortraitsAndQuotes from './components/portraits-and-quotes'
import WallOfFameProjectsCarousel from './components/projects-carousel'
import Tables from './components/tables'
import Thanks from './components/thanks'
import {
  getGlobalSuccessRate,
  getMeanContribution,
  getSuccessfulProjectsCount,
  getTotalPayoutAmount,
  getTotalUsersCount,
} from './redux'
import { StatsPageStyles } from './styles'

const StatsPage = () => {
  const t = useTranslation()
  const successfulProjectsCount = useSelector(getSuccessfulProjectsCount)
  const totalPayoutAmount = useSelector(getTotalPayoutAmount)
  const globalSuccessRate = useSelector(getGlobalSuccessRate)
  const totalUsersCount = useSelector(getTotalUsersCount)
  const meanContribution = useSelector(getMeanContribution)
  const viewportIsSOrLess = useSelector(matchMediaQuery)('viewportIsSOrLess')
  const prefersReducedMotion = useMedia({
    queries: ['(prefers-reduced-motion: reduce)'],
    values: [true],
    defaultValue: false,
  })

  const [animationEnabled, enableAnimation] = useState(false)

  useEffect(() => {
    enableAnimation(() => {
      if (viewportIsSOrLess) {
        return false
      }
      if (prefersReducedMotion) {
        return false
      }
      if (domElementHelper.canUseDom() && !('IntersectionObserver' in window)) {
        return false
      }
      return true
    })
  }, [])

  return (
    <DefaultLayout>
      <Helmet title={t('stats.page_title')} />
      <StatsPageStyles />
      <div
        className={classNames('stats__LayoutBase', {
          'stats--not_animated': !animationEnabled,
        })}
      >
        <IntroHero
          title={t('stats.intro.title', { parseHtml: true })}
          paragraph_1={t('stats.intro.paragraph_1', { parseHtml: true })}
          paragraph_2={t('stats.intro.paragraph_2', { parseHtml: true })}
        >
          <AnimatedNumbers>
            <NumberCard
              number={t(totalPayoutAmount.cents / 100, {
                formatNumber: true,
              })}
              legend={t('stats.animated_numbers.collected_funds')}
              unit={t(`currency_symbol.${totalPayoutAmount.currency}`)}
              size="large"
              transitionDelay={0}
            />
            <NumberCard
              number={t(successfulProjectsCount, { formatNumber: true })}
              legend={t('stats.animated_numbers.successful_projects')}
              transitionDelay={0.66}
            />
            <NumberCard
              number={t(globalSuccessRate * 100, { formatNumber: true })}
              legend={t('stats.animated_numbers.success_rate')}
              unit="%"
              transitionDelay={0.66}
            />
            <NumberCard
              number={t(totalUsersCount, { formatNumber: true })}
              legend={t('stats.animated_numbers.kissbankers')}
              transitionDelay={1.02}
            />
            <NumberCard
              number={t(meanContribution.cents / 100, { formatNumber: true })}
              legend={t('stats.animated_numbers.average_pledge')}
              unit={t(`currency_symbol.${meanContribution.currency}`)}
              transitionDelay={1.02}
            />
          </AnimatedNumbers>
        </IntroHero>
        <Tables />
        <PortraitsAndQuotes />
        <WallOfFameProjectsCarousel />
        <Platforms />
        <Thanks />
      </div>
    </DefaultLayout>
  )
}

export default StatsPage
