import { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  getFacebookPixelRef,
  getGoalType,
  getProjectCategoriesName,
  getProjectId,
  getProjectName,
  getProjectSlug,
  isCurrentUserOwner as isCurrentUserOwnerSelector,
} from 'kiss/modules/project-page/page-state/selectors'
import { getCurrentUser } from 'kiss/session/redux'
import {
  getCart,
  getTotalAmount,
  getTotalCurrency,
} from 'kiss/modules/contribute/cart/redux'

import { FacebookPixelContext } from './provider'
import { FacebookPixelHelper } from './helper'

const FacebookPixelTracker = ({ eventName }) => {
  const { dataLayer, loading } = useContext(FacebookPixelContext)

  const currentUser = useSelector(getCurrentUser)
  const isCurrentUserOwner = useSelector(isCurrentUserOwnerSelector)

  const projectData = {
    uuid: useSelector(getProjectId),
    facebookPixelRef: useSelector(getFacebookPixelRef),
    name: useSelector(getProjectName),
    slug: useSelector(getProjectSlug),
    goalType: useSelector(getGoalType),
    categories: (
      useSelector(getProjectCategoriesName)?.split(', ') || []
    ).slice(0, 3),
  }

  const cart = useSelector(getCart)
  const ecommerce = {
    cart: cart,
    totalAmount: useSelector(getTotalAmount),
    totalCurrency: useSelector(getTotalCurrency),
  }
  if (eventName === 'purchase') {
    ecommerce['transaction_id'] = cart.id
  }

  useEffect(() => {
    if (loading) return

    // Legacy dataLayer
    const pageType = isCurrentUserOwner ? 'mon projet' : 'projet'
    const pageSubtype = isCurrentUserOwner ? { subtype: 'state' } : {}

    const trackedData = {
      event: eventName,
      page: {
        type: pageType,
        ...pageSubtype,
      },
    }

    trackedData['user_data'] = FacebookPixelHelper.getUserData(currentUser)

    if (projectData['uuid']) {
      trackedData['project_data'] =
        FacebookPixelHelper.getProjectData(projectData)
    }

    if (
      eventName === 'add_to_cart' ||
      eventName === 'begin_checkout' ||
      eventName === 'purchase'
    ) {
      trackedData['ecommerce'] = FacebookPixelHelper.getEcommerceData(ecommerce)
    }

    FacebookPixelHelper.pushToGtm(dataLayer, trackedData)
  }, [loading, eventName, currentUser, isCurrentUserOwner])

  return null
}

export default FacebookPixelTracker
