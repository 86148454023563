import React, { useState } from 'react'

import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import usePricingPlans from 'kiss/hooks/use-pricing-plans'

import { ButtonGroup, Container, Text, Title } from '@kisskissbankbank/kitten'

import getOr from 'lodash/fp/getOr'
import * as Tooltip from '@radix-ui/react-tooltip'

import DefaultLayout from 'kiss/app/layouts/default-layout'
import Details from 'kiss/modules/pricing-plans/details'
import Faq from 'kiss/modules/pricing-plans/faq'
import SoloButton from 'kiss/modules/home/components/solo-button'

import { PricingStyles } from 'kiss/modules/pricing-plans/styles'

export const SERVICE_FEES_FOR_CONTRIBUTORS = 'contributors'
const SERVICE_FEES_FOR_OWNER = 'owner'

const getPricingPlans = (state) => {
  return getOr([])('PRICING_PLANS.pricingPlans')(state)
}

const PricingPlans = () => {
  const t = useTranslation()

  const rawPricingPlans = useSelector(getPricingPlans)

  const { formatPricingPlans } = usePricingPlans()

  const pricingPlans = formatPricingPlans(rawPricingPlans)

  const [serviceFees, setServiceFees] = useState(SERVICE_FEES_FOR_CONTRIBUTORS)

  return (
    <DefaultLayout
      className="k-u-background-color-background2"
      isHeaderSticky={true}
    >
      <PricingStyles />
      <Helmet title={t('pricing_plans.seo')} />
      <Container>
        <section className="kiss-Pricing">
          <Title
            modifier="tertiary"
            family="antiqueolive"
            className="k-u-margin-top-nonuple k-u-align-center k-u-margin-bottom-triple"
          >
            {t('pricing_plans.title', { parseHtml: true })}
          </Title>

          <Tooltip.Provider>
            <div
              className="
                kiss-Pricing__description
                k-u-align-center
                k-u-block
                k-u-margin-bottom-triple
              "
            >
              <Text size="small">
                {t('pricing_plans.pricing.service_fees.description1', {
                  parseHtml: true,
                })}{' '}
              </Text>

              <Tooltip.Root delayDuration={0}>
                <Tooltip.Trigger>
                  <Text
                    size="small"
                    className="k-u-link-primary1 k-u-cursor-pointer k-u-decoration-underline"
                  >
                    {t('pricing_plans.pricing.service_fees.description2', {
                      parseHtml: true,
                    })}
                  </Text>
                </Tooltip.Trigger>

                <Tooltip.Portal>
                  <Tooltip.Content
                    className="
                      kiss-Pricing__bubble
                      kiss-Pricing__bubble--negative
                      k-u-weight-400
                      k-u-size-small
                      k-u-line-height-1-3
                    "
                    side="bottom"
                    align="center"
                    sideOffset={5}
                  >
                    <Text>{t('pricing_plans.service_fees.tooltip')}</Text>
                    <Tooltip.Arrow className="kiss-Pricing__bubble_arrow" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>

              <Text size="small">
                {' '}
                {t('pricing_plans.pricing.service_fees.description3', {
                  parseHtml: true,
                })}
              </Text>
            </div>
          </Tooltip.Provider>

          <ButtonGroup>
            <ButtonGroup.Button
              mobileFit="fluid"
              size="small"
              active={serviceFees === SERVICE_FEES_FOR_CONTRIBUTORS}
              onClick={() => setServiceFees(SERVICE_FEES_FOR_CONTRIBUTORS)}
            >
              {t('pricing_plans.service_fees.contributors')}
            </ButtonGroup.Button>

            <ButtonGroup.Button
              mobileFit="fluid"
              size="small"
              active={serviceFees === SERVICE_FEES_FOR_OWNER}
              onClick={() => setServiceFees(SERVICE_FEES_FOR_OWNER)}
            >
              {t('pricing_plans.service_fees.owner')}
            </ButtonGroup.Button>
          </ButtonGroup>

          <Details pricingPlans={pricingPlans} serviceFees={serviceFees} />
          <Faq />
          <SoloButton />
        </section>
      </Container>
    </DefaultLayout>
  )
}

export default PricingPlans
