import React from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Button, Text, pxToRem, ScreenConfig } from '@kisskissbankbank/kitten'
import { getRouteFor, BROWSING } from 'kiss/routes/redux'

const StyledText = styled(Text)`
  font-size: ${pxToRem(16)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    font-size: ${pxToRem(18)};
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    font-size: ${pxToRem(20)};
  }
`

const StyledButton = styled((props) => (
  <Button as="a" modifier="helium" {...props} />
))`
  width: ${pxToRem(250)};
  height: ${pxToRem(50)};

  @media (min-width: ${pxToRem(ScreenConfig.S.min)}) {
    height: ${pxToRem(70)};
  }
`

const NoResult = () => {
  const t = useTranslation()
  const routeFor = useSelector(getRouteFor)

  return (
    <div className="k-u-align-center">
      <StyledText
        tag="p"
        color="font1"
        weight="400"
        lineHeight="normal"
        className="k-u-margin-bottom-double@xs-down k-u-margin-bottom-triple@s-up"
      >
        {t('mentor_page.no_result.paragraph')}
      </StyledText>

      <StyledButton href={routeFor(BROWSING)}>
        {t('mentor_page.no_result.button')}
      </StyledButton>
    </div>
  )
}

export default NoResult
