import isEmpty from 'lodash/fp/isEmpty'
import isNumber from 'lodash/fp/isNumber'
import pickBy from 'lodash/fp/pickBy'
import qs from 'qs'
import React, { createContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchProjects, getProjects } from './redux'

export const ITEMS_PER_PAGE = 16

const initialState = {
  projects: [],
}

export const SearchContext = createContext(initialState)

export const SearchProvider = ({ children }) => {
  const { search } = useLocation()
  const dispatch = useDispatch()
  const projects = useSelector(getProjects)
  const preventInitialCallOnMount = useRef(true)

  useEffect(() => {
    if (preventInitialCallOnMount.current) {
      preventInitialCallOnMount.current = false
      return
    }
    const { page, state, category, q } = qs.parse(search, {
      ignoreQueryPrefix: true,
    })
    const offset = page ? (Number(page) - 1) * ITEMS_PER_PAGE : 0
    const searchParams = pickBy((value) => !isEmpty(value) || isNumber(0))({
      offset,
      limit: ITEMS_PER_PAGE,
      query: q || '',
      states: !state ? ['started', 'successful'] : [state],
      categorySlugs: category === 'all' || !category ? undefined : [category],
    })
    dispatch(fetchProjects(searchParams))
  }, [search])

  return (
    <SearchContext.Provider value={{ projects }}>
      {children}
    </SearchContext.Provider>
  )
}
