import React from 'react'
import { gql } from '@apollo/client'
import PropTypes from 'prop-types'
import { useField } from 'formik'
import pull from 'lodash/fp/pull'
import TagButton from 'kiss/components/buttons/tag'
import { useQuery } from '../../api/graphql-query'
import { LoadingAnimation } from '../loading/loading-animation'

const GET_CATEGORIES = gql`
  query {
    categories {
      name
      slug
    }
  }
`

const Tag = ({ id, label, name }) => {
  const [field, , helpers] = useField(name)
  const categories = field.value || []
  const isSelected = categories.includes(id)
  return (
    <TagButton
      type="button"
      active={isSelected}
      onClick={() =>
        helpers.setValue(
          isSelected ? pull(id)(categories) : [...categories, id],
        )
      }
      disabled={!isSelected && categories.length >= 3}
    >
      {label}
    </TagButton>
  )
}

const Categories = ({ name }) => {
  const { data, loading } = useQuery(GET_CATEGORIES)
  if (loading) {
    return <LoadingAnimation className="k-u-align-center" />
  }
  if (!data) return null
  return data.categories.map((category) => (
    <Tag
      key={category.slug}
      id={category.slug}
      label={category.name}
      name={name}
    />
  ))
}

Categories.propTypes = {
  name: PropTypes.string.isRequired,
}

export default Categories
