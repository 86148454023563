import React from 'react'
import { Text, Tag, Separator } from '@kisskissbankbank/kitten'
import { useTranslation } from 'kiss/hooks/use-translation'
import FeatureExplanationTooltip from 'kiss/modules/pricing-plans/FeatureExplanationTooltip'

const FeaturesList = ({ features }) => {
  const t = useTranslation()

  return (
    <>
      {features.map((feature, index) => {
        if (feature.isCategory) {
          return (
            <div
              key={feature.category}
              className="kiss-Pricing__table__cell_category"
              style={{ '--pricing-table-current-row': index + 2 }}
            >
              {index !== 0 && (
                <Separator
                  darker
                  className="kiss-Pricing__table_separator_top"
                />
              )}
              <Text
                className="k-u-letter-spacing-10 k-u-margin-none"
                transform="uppercase"
                weight="600"
                size="medium"
                tag="h4"
              >
                {t(`pricing_plans.details.${feature.category}`)}
              </Text>
              <Separator
                darker
                className="kiss-Pricing__table_separator_bottom"
              />
            </div>
          )
        }

        return (
          <div
            className="kiss-Pricing__table__cell_feature"
            style={{ '--pricing-table-current-row': index + 2 }}
            key={feature.title}
          >
            <Text
              size="micro"
              lineHeight="1.3"
              className="kiss-Pricing__table__cell_feature__title"
            >
              {feature.title}{' '}
              {feature.soon && (
                <Tag
                  size="small"
                  className="kiss-Pricing__table__cell_feature__tag"
                >
                  {t('pricing_plans.soon')}
                </Tag>
              )}
            </Text>

            {feature.description && (
              <FeatureExplanationTooltip content={feature.description} />
            )}
          </div>
        )
      })}
    </>
  )
}

export default FeaturesList
