import React from 'react'
import { useFormikContext } from 'formik'
import { useTranslation } from 'kiss/hooks/use-translation'
import { Button } from '@kisskissbankbank/kitten'
import { LoadingAnimation } from 'kiss/components/loading/loading-animation'

const Submit = () => {
  const t = useTranslation()
  const {
    values: { amount },
    isSubmitting,
  } = useFormikContext()

  return (
    <Button
      id="gift-card-form-submit"
      type="submit"
      modifier="helium"
      size="large"
      fit="fluid"
      className="k-u-margin-top-single k-u-margin-top-triple@s-up"
      disabled={isSubmitting}
      aria-live="polite"
    >
      {isSubmitting ? (
        <LoadingAnimation
          color="var(--color-grey-000)"
          aria-label={t('app.loading')}
        />
      ) : (
        t('gift_card.new.form.submit', {
          amount: t('project_info.amount_with_currency', {
            amount: t(amount, { formatNumber: true }),
            currency: t('currency_symbol.eur'),
            parseHtml: true,
          }),
        })
      )}
    </Button>
  )
}

export default Submit
