import React from 'react'

import { useSelector } from 'react-redux'
import { getAssetFor } from 'kiss/assets/redux'

import { ScreenConfig, pxToRem } from '@kisskissbankbank/kitten'

import styled from 'styled-components'

const StyledPersonContainer = styled.div`
  min-width: none;
  max-width: 240px;
  border-radius: 10px;
  height: 254px;

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    min-width: 240px;
    max-width: 314px;
  }

  img {
    border-radius: 10px;
    object-fit: contain;
    width: 100%;
  }
`

const StyledMiniIcon = styled.div`
  position: relative;
  top: -45px;
  margin: auto;
  background: white;
  display: flex;
  width: 59px;
  height: 44px;
  padding: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 22.133px 0px;
  border-radius: 10px;

  img {
    width: 39px;
    height: 30px;
    margin: auto;
    flex-shrink: 0;
  }

  @media (min-width: ${pxToRem(ScreenConfig.L.min)}) {
    img {
      width: 46px;
      height: 34;
      flex-shrink: 0;
    }
  }
`

const PersonCard = ({ personImagePath, iconPath }) => {
  const assetFor = useSelector(getAssetFor)

  return (
    <StyledPersonContainer>
      <img src={assetFor(personImagePath)} alt={personImagePath} />
      <StyledMiniIcon>
        <img src={assetFor(iconPath)} alt={iconPath} />
      </StyledMiniIcon>
    </StyledPersonContainer>
  )
}

export default PersonCard
