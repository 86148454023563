import { GifVideo } from '@kisskissbankbank/kitten'
import { isRewardDisabled } from 'kiss/modules/project-page/page-state/selectors'
import { responsiveImageAttributes } from 'kiss/utils/responsive-image/responsive-image-attributes'
import { endsWith, isEmpty, omit } from 'lodash/fp'
import React from 'react'
import { useSelector } from 'react-redux'

const getImageAttributes = ({ image, imageProps }) => {
  if (!image) return { src: '', srcSet: '', sizes: '' }

  if (endsWith('.gif', image.url) || isEmpty(imageProps)) {
    return { src: image.normalUrl, srcSet: '', sizes: '' }
  }

  return responsiveImageAttributes(image.url, {
    imageSizes: imageProps.responsiveImageSizes,
    breakpoints: imageProps.breakpoints,
    imageSizesInViewport: imageProps.imageSizesInViewport,
  })
}

const Image = ({ reward, imageProps }) => {
  const {
    image: { thumbUrl, mp4Url, webmUrl },
  } = reward

  const rewardDisabled = useSelector(isRewardDisabled)

  if (mp4Url || webmUrl) {
    return (
      <GifVideo poster={thumbUrl} loading="lazy">
        {webmUrl && <source type="video/webm" src={webmUrl} />}
        {mp4Url && <source type="video/mp4" src={mp4Url} />}
      </GifVideo>
    )
  }

  const { src, srcSet, sizes } = getImageAttributes({
    image: reward.image,
    imageProps,
  })

  const sanitizedImageProps = omit([
    'breakpoints',
    'imageSizesInViewport',
    'responsiveImageSizes',
  ])(imageProps)

  return (
    <img
      alt=""
      {...sanitizedImageProps}
      loading="lazy"
      src={src}
      srcSet={srcSet}
      sizes={sizes}
      disabled={rewardDisabled(reward)}
    />
  )
}

export default Image
